import { SimMarket, Score, TimeSpan, OverUnder } from './types'

export const logicOverUnder =
  (handicap: number) => (outcome: OverUnder, score: Score) => {
    const oc: { [key in OverUnder]: () => boolean } = {
      u: () => score[0] + score[1] < handicap,
      o: () => score[0] + score[1] > handicap,
    }
    return oc[outcome]() ?? false
  }

export const logicOverUnder_1Q =
  (handicap: number, time: TimeSpan) =>
  (outcome: OverUnder, score: Score, cTime: any) => {
    const oc: { [key in OverUnder]: () => boolean } = {
      u: () => score[0] + score[1] < handicap,
      o: () => score[0] + score[1] > handicap,
    }
    if (cTime === '2Q' || cTime === '3Q' || cTime === '4Q') return false
    if (time === cTime && oc[outcome]()) return 'settled'
    return oc[outcome]() ?? false
  }

const ocOverUnder = ['o', 'u'] as OverUnder[]

const _OverUnder = (
  id: number,
  time: TimeSpan,
  handicap: number,
): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: ocOverUnder,
  logic:
    id >= 2010 && id <= 2063
      ? logicOverUnder_1Q(handicap, time)
      : logicOverUnder(handicap),
})

export const FT_OU_1105 = _OverUnder(2064, 'FT', 110.5)
export const FT_OU_1115 = _OverUnder(2065, 'FT', 111.5)
export const FT_OU_1125 = _OverUnder(2066, 'FT', 112.5)
export const FT_OU_1135 = _OverUnder(2067, 'FT', 113.5)
export const FT_OU_1145 = _OverUnder(2068, 'FT', 114.5)
export const FT_OU_1155 = _OverUnder(2069, 'FT', 115.5)
export const FT_OU_1165 = _OverUnder(2070, 'FT', 116.5)
export const FT_OU_1175 = _OverUnder(2071, 'FT', 117.5)
export const FT_OU_1185 = _OverUnder(2072, 'FT', 118.5)
export const FT_OU_1195 = _OverUnder(2073, 'FT', 119.5)
export const FT_OU_1205 = _OverUnder(2074, 'FT', 120.5)
export const FT_OU_1215 = _OverUnder(2075, 'FT', 121.5)
export const FT_OU_1225 = _OverUnder(2076, 'FT', 122.5)
export const FT_OU_1235 = _OverUnder(2077, 'FT', 123.5)
export const FT_OU_1245 = _OverUnder(2078, 'FT', 124.5)
export const FT_OU_1255 = _OverUnder(2079, 'FT', 125.5)
export const FT_OU_1265 = _OverUnder(2080, 'FT', 126.5)
export const FT_OU_1275 = _OverUnder(2081, 'FT', 127.5)
export const FT_OU_1285 = _OverUnder(2082, 'FT', 128.5)
export const FT_OU_1295 = _OverUnder(2083, 'FT', 129.5)
export const FT_OU_1305 = _OverUnder(2084, 'FT', 130.5)
export const FT_OU_1315 = _OverUnder(2085, 'FT', 131.5)
export const FT_OU_1325 = _OverUnder(2086, 'FT', 132.5)
export const FT_OU_1335 = _OverUnder(2087, 'FT', 133.5)
export const FT_OU_1345 = _OverUnder(2088, 'FT', 134.5)
export const FT_OU_1355 = _OverUnder(2089, 'FT', 135.5)
export const FT_OU_1365 = _OverUnder(2090, 'FT', 136.5)
export const FT_OU_1375 = _OverUnder(2091, 'FT', 137.5)
export const FT_OU_1385 = _OverUnder(2092, 'FT', 138.5)
export const FT_OU_1395 = _OverUnder(2093, 'FT', 139.5)
export const FT_OU_1405 = _OverUnder(2094, 'FT', 140.5)
export const FT_OU_1415 = _OverUnder(2095, 'FT', 141.5)
export const FT_OU_1425 = _OverUnder(2096, 'FT', 142.5)
export const FT_OU_1435 = _OverUnder(2097, 'FT', 143.5)
export const FT_OU_1445 = _OverUnder(2098, 'FT', 144.5)
export const FT_OU_1455 = _OverUnder(2099, 'FT', 145.5)
export const FT_OU_1465 = _OverUnder(2100, 'FT', 146.5)
export const FT_OU_1475 = _OverUnder(2101, 'FT', 147.5)
export const FT_OU_1485 = _OverUnder(2102, 'FT', 148.5)
export const FT_OU_1495 = _OverUnder(2103, 'FT', 149.5)
export const FT_OU_1505 = _OverUnder(2104, 'FT', 150.5)
export const FT_OU_1515 = _OverUnder(2105, 'FT', 151.5)
export const FT_OU_1525 = _OverUnder(2106, 'FT', 152.5)
export const FT_OU_1535 = _OverUnder(2107, 'FT', 153.5)
export const FT_OU_1545 = _OverUnder(2108, 'FT', 154.5)
export const FT_OU_1555 = _OverUnder(2109, 'FT', 155.5)
export const FT_OU_1565 = _OverUnder(2110, 'FT', 156.5)
export const FT_OU_1575 = _OverUnder(2111, 'FT', 157.5)
export const FT_OU_1585 = _OverUnder(2112, 'FT', 158.5)
export const FT_OU_1595 = _OverUnder(2113, 'FT', 159.5)
export const FT_OU_1605 = _OverUnder(2114, 'FT', 160.5)
export const FT_OU_1615 = _OverUnder(2115, 'FT', 161.5)
export const FT_OU_1625 = _OverUnder(2116, 'FT', 162.5)
export const FT_OU_1635 = _OverUnder(2117, 'FT', 163.5)
export const FT_OU_1645 = _OverUnder(2118, 'FT', 164.5)
export const FT_OU_1655 = _OverUnder(2119, 'FT', 165.5)
export const FT_OU_1665 = _OverUnder(2120, 'FT', 166.5)
export const FT_OU_1675 = _OverUnder(2121, 'FT', 167.5)
export const FT_OU_1685 = _OverUnder(2122, 'FT', 168.5)
export const FT_OU_1695 = _OverUnder(2123, 'FT', 169.5)
export const FT_OU_1705 = _OverUnder(2124, 'FT', 170.5)
export const FT_OU_1715 = _OverUnder(2125, 'FT', 171.5)
export const FT_OU_1725 = _OverUnder(2126, 'FT', 172.5)
export const FT_OU_1735 = _OverUnder(2127, 'FT', 173.5)
export const FT_OU_1745 = _OverUnder(2128, 'FT', 174.5)
export const FT_OU_1755 = _OverUnder(2129, 'FT', 175.5)
export const FT_OU_1765 = _OverUnder(2130, 'FT', 176.5)
export const FT_OU_1775 = _OverUnder(2131, 'FT', 177.5)
export const FT_OU_1785 = _OverUnder(2132, 'FT', 178.5)
export const FT_OU_1795 = _OverUnder(2133, 'FT', 179.5)
export const FT_OU_1805 = _OverUnder(2134, 'FT', 180.5)
export const FT_OU_1815 = _OverUnder(2135, 'FT', 181.5)
export const FT_OU_1825 = _OverUnder(2136, 'FT', 182.5)
export const FT_OU_1835 = _OverUnder(2137, 'FT', 183.5)
export const FT_OU_1845 = _OverUnder(2138, 'FT', 184.5)
export const FT_OU_1855 = _OverUnder(2139, 'FT', 185.5)
export const FT_OU_1865 = _OverUnder(2140, 'FT', 186.5)
export const FT_OU_1875 = _OverUnder(2141, 'FT', 187.5)
export const FT_OU_1885 = _OverUnder(2142, 'FT', 188.5)
export const FT_OU_1895 = _OverUnder(2143, 'FT', 189.5)
export const FT_OU_1905 = _OverUnder(2144, 'FT', 190.5)
export const FT_OU_1915 = _OverUnder(2145, 'FT', 191.5)
export const FT_OU_1925 = _OverUnder(2146, 'FT', 192.5)
export const FT_OU_1935 = _OverUnder(2147, 'FT', 193.5)
export const FT_OU_1945 = _OverUnder(2148, 'FT', 194.5)
export const FT_OU_1955 = _OverUnder(2149, 'FT', 195.5)
export const FT_OU_1965 = _OverUnder(2150, 'FT', 196.5)
export const FT_OU_1975 = _OverUnder(2151, 'FT', 197.5)
export const FT_OU_1985 = _OverUnder(2152, 'FT', 198.5)
export const FT_OU_1995 = _OverUnder(2153, 'FT', 199.5)
export const FT_OU_2005 = _OverUnder(2154, 'FT', 200.5)
export const FT_OU_2015 = _OverUnder(2155, 'FT', 201.5)
export const FT_OU_2025 = _OverUnder(2156, 'FT', 202.5)
export const FT_OU_2035 = _OverUnder(2157, 'FT', 203.5)
export const FT_OU_2045 = _OverUnder(2158, 'FT', 204.5)
export const FT_OU_2055 = _OverUnder(2159, 'FT', 205.5)
export const FT_OU_2065 = _OverUnder(2160, 'FT', 206.5)
export const FT_OU_2075 = _OverUnder(2161, 'FT', 207.5)
export const FT_OU_2085 = _OverUnder(2162, 'FT', 208.5)
export const FT_OU_2095 = _OverUnder(2163, 'FT', 209.5)
export const FT_OU_2105 = _OverUnder(2164, 'FT', 210.5)
export const FT_OU_2115 = _OverUnder(2165, 'FT', 211.5)
export const FT_OU_2125 = _OverUnder(2166, 'FT', 212.5)
export const FT_OU_2135 = _OverUnder(2167, 'FT', 213.5)
export const FT_OU_2145 = _OverUnder(2168, 'FT', 214.5)
export const FT_OU_2155 = _OverUnder(2169, 'FT', 215.5)
export const FT_OU_2165 = _OverUnder(2170, 'FT', 216.5)
export const FT_OU_2175 = _OverUnder(2171, 'FT', 217.5)
export const FT_OU_2185 = _OverUnder(2172, 'FT', 218.5)
export const FT_OU_2195 = _OverUnder(2173, 'FT', 219.5)
export const FT_OU_2205 = _OverUnder(2174, 'FT', 220.5)
export const FT_OU_2215 = _OverUnder(2175, 'FT', 221.5)
export const FT_OU_2225 = _OverUnder(2176, 'FT', 222.5)
export const FT_OU_2235 = _OverUnder(2177, 'FT', 223.5)
export const FT_OU_2245 = _OverUnder(2178, 'FT', 224.5)
export const FT_OU_2255 = _OverUnder(2179, 'FT', 225.5)
export const FT_OU_2265 = _OverUnder(2180, 'FT', 226.5)
export const FT_OU_2275 = _OverUnder(2181, 'FT', 227.5)
export const FT_OU_2285 = _OverUnder(2182, 'FT', 228.5)
export const FT_OU_2295 = _OverUnder(2183, 'FT', 229.5)
export const FT_OU_2305 = _OverUnder(2184, 'FT', 230.5)
export const FT_OU_2315 = _OverUnder(2185, 'FT', 231.5)
export const FT_OU_2325 = _OverUnder(2186, 'FT', 232.5)
export const FT_OU_2335 = _OverUnder(2187, 'FT', 233.5)
export const FT_OU_2345 = _OverUnder(2188, 'FT', 234.5)
export const FT_OU_2355 = _OverUnder(2189, 'FT', 235.5)
export const FT_OU_2365 = _OverUnder(2190, 'FT', 236.5)
export const FT_OU_2375 = _OverUnder(2191, 'FT', 237.5)
export const FT_OU_2385 = _OverUnder(2192, 'FT', 238.5)
export const FT_OU_2395 = _OverUnder(2193, 'FT', 239.5)
export const FT_OU_2405 = _OverUnder(2194, 'FT', 240.5)
export const FT_OU_2415 = _OverUnder(2195, 'FT', 241.5)
export const FT_OU_2425 = _OverUnder(2196, 'FT', 242.5)
export const FT_OU_2435 = _OverUnder(2197, 'FT', 243.5)
export const FT_OU_2445 = _OverUnder(2198, 'FT', 244.5)
export const FT_OU_2455 = _OverUnder(2199, 'FT', 245.5)
export const FT_OU_2465 = _OverUnder(2200, 'FT', 246.5)
export const FT_OU_2475 = _OverUnder(2201, 'FT', 247.5)
export const FT_OU_2485 = _OverUnder(2202, 'FT', 248.5)
export const FT_OU_2495 = _OverUnder(2203, 'FT', 249.5)
export const FT_OU_2505 = _OverUnder(2204, 'FT', 250.5)
export const FT_OU_2515 = _OverUnder(2205, 'FT', 251.5)
export const FT_OU_2525 = _OverUnder(2206, 'FT', 252.5)
export const FT_OU_2535 = _OverUnder(2207, 'FT', 253.5)
export const FT_OU_2545 = _OverUnder(2208, 'FT', 254.5)
export const FT_OU_2555 = _OverUnder(2209, 'FT', 255.5)
export const FT_OU_2565 = _OverUnder(2210, 'FT', 256.5)
export const FT_OU_2575 = _OverUnder(2211, 'FT', 257.5)
export const FT_OU_2585 = _OverUnder(2212, 'FT', 258.5)
export const FT_OU_2595 = _OverUnder(2213, 'FT', 259.5)
export const FT_OU_2605 = _OverUnder(2214, 'FT', 260.5)
export const FT_OU_2615 = _OverUnder(2215, 'FT', 261.5)
export const FT_OU_2625 = _OverUnder(2216, 'FT', 262.5)
export const FT_OU_2635 = _OverUnder(2217, 'FT', 263.5)
export const FT_OU_2645 = _OverUnder(2218, 'FT', 264.5)
export const FT_OU_2655 = _OverUnder(2219, 'FT', 265.5)
export const FT_OU_2665 = _OverUnder(2220, 'FT', 266.5)
export const FT_OU_2675 = _OverUnder(2221, 'FT', 267.5)
export const FT_OU_2685 = _OverUnder(2222, 'FT', 268.5)
export const FT_OU_2695 = _OverUnder(2223, 'FT', 269.5)
export const FT_OU_2705 = _OverUnder(2224, 'FT', 270.5)
export const FT_OU_2715 = _OverUnder(2225, 'FT', 271.5)
export const FT_OU_2725 = _OverUnder(2226, 'FT', 272.5)
export const FT_OU_2735 = _OverUnder(2227, 'FT', 273.5)
export const FT_OU_2745 = _OverUnder(2228, 'FT', 274.5)
export const FT_OU_2755 = _OverUnder(2229, 'FT', 275.5)
export const FT_OU_2765 = _OverUnder(2230, 'FT', 276.5)
export const FT_OU_2775 = _OverUnder(2231, 'FT', 277.5)
export const FT_OU_2785 = _OverUnder(2232, 'FT', 278.5)
export const FT_OU_2795 = _OverUnder(2233, 'FT', 279.5)
export const FT_OU_2805 = _OverUnder(2234, 'FT', 280.5)
export const FT_OU_2815 = _OverUnder(2235, 'FT', 281.5)
export const FT_OU_2825 = _OverUnder(2236, 'FT', 282.5)
export const FT_OU_2835 = _OverUnder(2237, 'FT', 283.5)
export const FT_OU_2845 = _OverUnder(2238, 'FT', 284.5)
export const FT_OU_2855 = _OverUnder(2239, 'FT', 285.5)
export const FT_OU_2865 = _OverUnder(2240, 'FT', 286.5)
export const FT_OU_2875 = _OverUnder(2241, 'FT', 287.5)
export const FT_OU_2885 = _OverUnder(2242, 'FT', 288.5)
export const FT_OU_2895 = _OverUnder(2243, 'FT', 289.5)
export const FT_OU_2905 = _OverUnder(2244, 'FT', 290.5)
export const FT_OU_2915 = _OverUnder(2245, 'FT', 291.5)
export const FT_OU_2925 = _OverUnder(2246, 'FT', 292.5)
export const FT_OU_2935 = _OverUnder(2247, 'FT', 293.5)
export const FT_OU_2945 = _OverUnder(2248, 'FT', 294.5)
export const FT_OU_2955 = _OverUnder(2249, 'FT', 295.5)
export const FT_OU_2965 = _OverUnder(2250, 'FT', 296.5)
export const FT_OU_2975 = _OverUnder(2251, 'FT', 297.5)
export const FT_OU_2985 = _OverUnder(2252, 'FT', 298.5)
export const FT_OU_2995 = _OverUnder(2253, 'FT', 299.5)
export const FT_OU_3005 = _OverUnder(2254, 'FT', 300.5)
export const FT_OU_3015 = _OverUnder(2255, 'FT', 301.5)
export const FT_OU_3025 = _OverUnder(2256, 'FT', 302.5)
export const FT_OU_3035 = _OverUnder(2257, 'FT', 303.5)
export const FT_OU_3045 = _OverUnder(2258, 'FT', 304.5)
export const FT_OU_3055 = _OverUnder(2259, 'FT', 305.5)
export const FT_OU_3065 = _OverUnder(2260, 'FT', 306.5)
export const FT_OU_3075 = _OverUnder(2261, 'FT', 307.5)
export const FT_OU_3085 = _OverUnder(2262, 'FT', 308.5)
export const FT_OU_3095 = _OverUnder(2263, 'FT', 309.5)
export const FT_OU_3105 = _OverUnder(2264, 'FT', 310.5)
export const FT_OU_3115 = _OverUnder(2265, 'FT', 311.5)
export const FT_OU_3125 = _OverUnder(2266, 'FT', 312.5)
export const FT_OU_3135 = _OverUnder(2267, 'FT', 313.5)
export const FT_OU_3145 = _OverUnder(2268, 'FT', 314.5)
export const FT_OU_3155 = _OverUnder(2269, 'FT', 315.5)
export const FT_OU_3165 = _OverUnder(2270, 'FT', 316.5)
export const FT_OU_3175 = _OverUnder(2271, 'FT', 317.5)
export const FT_OU_3185 = _OverUnder(2272, 'FT', 318.5)
export const FT_OU_3195 = _OverUnder(2273, 'FT', 319.5)
export const FT_OU_3205 = _OverUnder(2274, 'FT', 320.5)

// export const FIRST_Q_OU_235 = _OverUnder(1218, 'HT', 23.5)
// export const FIRST_Q_OU_245 = _OverUnder(1219, 'HT', 24.5)
// export const FIRST_Q_OU_255 = _OverUnder(1220, 'HT', 25.5)
// export const FIRST_Q_OU_265 = _OverUnder(1221, 'HT', 26.5)
export const FIRST_Q_OU_275 = _OverUnder(2010, '1Q', 27.5)
export const FIRST_Q_OU_285 = _OverUnder(2011, '1Q', 28.5)
export const FIRST_Q_OU_295 = _OverUnder(2012, '1Q', 29.5)
export const FIRST_Q_OU_305 = _OverUnder(2013, '1Q', 30.5)
export const FIRST_Q_OU_315 = _OverUnder(2014, '1Q', 31.5)
export const FIRST_Q_OU_325 = _OverUnder(2015, '1Q', 32.5)
export const FIRST_Q_OU_335 = _OverUnder(2016, '1Q', 33.5)
export const FIRST_Q_OU_345 = _OverUnder(2017, '1Q', 34.5)
export const FIRST_Q_OU_355 = _OverUnder(2018, '1Q', 35.5)
export const FIRST_Q_OU_365 = _OverUnder(2019, '1Q', 36.5)
export const FIRST_Q_OU_375 = _OverUnder(2020, '1Q', 37.5)
export const FIRST_Q_OU_385 = _OverUnder(2021, '1Q', 38.5)
export const FIRST_Q_OU_395 = _OverUnder(2022, '1Q', 39.5)
export const FIRST_Q_OU_405 = _OverUnder(2023, '1Q', 40.5)
export const FIRST_Q_OU_415 = _OverUnder(2024, '1Q', 41.5)
export const FIRST_Q_OU_425 = _OverUnder(2025, '1Q', 42.5)
export const FIRST_Q_OU_435 = _OverUnder(2026, '1Q', 43.5)
export const FIRST_Q_OU_445 = _OverUnder(2027, '1Q', 44.5)
export const FIRST_Q_OU_455 = _OverUnder(2028, '1Q', 45.5)
export const FIRST_Q_OU_465 = _OverUnder(2029, '1Q', 46.5)
export const FIRST_Q_OU_475 = _OverUnder(2030, '1Q', 47.5)
export const FIRST_Q_OU_485 = _OverUnder(2031, '1Q', 48.5)
export const FIRST_Q_OU_495 = _OverUnder(2032, '1Q', 49.5)
export const FIRST_Q_OU_505 = _OverUnder(2033, '1Q', 50.5)
export const FIRST_Q_OU_515 = _OverUnder(2034, '1Q', 51.5)
export const FIRST_Q_OU_525 = _OverUnder(2035, '1Q', 52.5)
export const FIRST_Q_OU_535 = _OverUnder(2036, '1Q', 53.5)
export const FIRST_Q_OU_545 = _OverUnder(2037, '1Q', 54.5)
export const FIRST_Q_OU_555 = _OverUnder(2038, '1Q', 55.5)
export const FIRST_Q_OU_565 = _OverUnder(2039, '1Q', 56.5)
export const FIRST_Q_OU_575 = _OverUnder(2040, '1Q', 57.5)
export const FIRST_Q_OU_585 = _OverUnder(2041, '1Q', 58.5)
export const FIRST_Q_OU_595 = _OverUnder(2042, '1Q', 59.5)
export const FIRST_Q_OU_605 = _OverUnder(2043, '1Q', 60.5)
export const FIRST_Q_OU_615 = _OverUnder(2044, '1Q', 61.5)
export const FIRST_Q_OU_625 = _OverUnder(2045, '1Q', 62.5)
export const FIRST_Q_OU_635 = _OverUnder(2046, '1Q', 63.5)
export const FIRST_Q_OU_645 = _OverUnder(2047, '1Q', 64.5)
export const FIRST_Q_OU_655 = _OverUnder(2048, '1Q', 65.5)
export const FIRST_Q_OU_665 = _OverUnder(2049, '1Q', 66.5)
export const FIRST_Q_OU_675 = _OverUnder(2050, '1Q', 67.5)
export const FIRST_Q_OU_685 = _OverUnder(2051, '1Q', 68.5)
export const FIRST_Q_OU_695 = _OverUnder(2052, '1Q', 69.5)
export const FIRST_Q_OU_705 = _OverUnder(2053, '1Q', 70.5)
export const FIRST_Q_OU_715 = _OverUnder(2054, '1Q', 71.5)
export const FIRST_Q_OU_725 = _OverUnder(2055, '1Q', 72.5)
export const FIRST_Q_OU_735 = _OverUnder(2056, '1Q', 73.5)
export const FIRST_Q_OU_745 = _OverUnder(2057, '1Q', 74.5)
export const FIRST_Q_OU_755 = _OverUnder(2058, '1Q', 75.5)
export const FIRST_Q_OU_765 = _OverUnder(2059, '1Q', 76.5)
export const FIRST_Q_OU_775 = _OverUnder(2060, '1Q', 77.5)
export const FIRST_Q_OU_785 = _OverUnder(2061, '1Q', 78.5)
export const FIRST_Q_OU_795 = _OverUnder(2062, '1Q', 79.5)
export const FIRST_Q_OU_805 = _OverUnder(2063, '1Q', 80.5)
