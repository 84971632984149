import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Selection } from '../components/selection/selection'
import { ActionArea } from '../components/actionArea/actionArea'
import { SelectionsBar } from '../components/selections-bar/selectionsBar'
import { ToastNotification } from '../components/toastNotification/toastNotification'
import { ErrorModal } from '../../../components/error-modal/errorModal'
import { StyledPage } from '../../../style/styles'
import { useNavigate } from 'react-router-dom'
import { selectRedirect, selectView } from '../../app/store/app.state'
import { selectFreeBets } from '../../betslip/store/betslip.selectors'
import { clearFreeBet } from '../../betslip/store/betslip.state'
import { useSource } from '../../../hooks/useSource'

export const BetSlipPage = () => {
  const dispatch = useDispatch()
  const betslipRef = useRef<HTMLDivElement>(null)
  const redirect = useSelector(selectRedirect)
  const { isCashier } = useSource()
  const freeBetsList = useSelector(selectFreeBets)
  const view = useSelector(selectView)
  const navigate = useNavigate()

  useEffect(() => {
    const func = () => {
      if (redirect) {
        navigate(`/${redirect}`)
      }
    }
    func()
  }, [redirect])

  if (isCashier && freeBetsList.length > 0 && view === 'betslip') {
    dispatch(clearFreeBet())
  }

  return (
    <>
      <ErrorModal />
      <StyledPage>
        <ToastNotification />
        <SelectionsBar />
        <BetSlipWrapper ref={betslipRef} data-testid="page">
          <Selection />
          <ActionArea betslipRef={betslipRef} />
        </BetSlipWrapper>
      </StyledPage>
    </>
  )
}

export const BetSlipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-width: 1024px;
  height: 100%;
`
