import styled from 'styled-components'
import { CheckBox } from '../checkbox/checkbox'
import { useDispatch, useSelector } from 'react-redux'
import { selectAccepOdds, toggleAcceptOdds } from '../../../app/store/app.state'

export const AcceptOdds = ({ alignLeft }: { alignLeft?: boolean }) => {
  const dispatch = useDispatch()
  const checkBox = useSelector(selectAccepOdds)

  const handleCheckbox = () => {
    dispatch(toggleAcceptOdds())
  }
  return (
    <AcceptOddsWrapper alignLeft={alignLeft}>
      <CheckBox checked={checkBox} onChange={handleCheckbox} />
    </AcceptOddsWrapper>
  )
}

const AcceptOddsWrapper = styled.div<{ alignLeft?: boolean }>`
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: ${({ alignLeft }) =>
    alignLeft ? 'flex-start' : 'flex-end'};
  border-radius: 2px;
  background-color: ${(props) => props.theme.colours.S090};
`
