import React from 'react'
import { TimeLine } from '../../../../sports/soccer/timeline/soccerTimeline'
import { Container, TextDisplay } from './gameDisplay.styles'
import { FormattedMessage } from 'react-intl'

export type GameDisplayProps = {
  timeline: TimeLine
}
export const GameDisplay: React.FC<GameDisplayProps> = React.memo(
  ({ timeline }) => {
    return (
      <Container>
        <TextDisplay>
          {timeline?.clock === 90 ? (
            <FormattedMessage
              id="gameDisplay_fulltime"
              defaultMessage="FULLTIME"
            />
          ) : (
            <FormattedMessage
              id="gameDisplay_heading"
              defaultMessage="RESULTS COMING IN"
            />
          )}
        </TextDisplay>
      </Container>
    )
  },
)
