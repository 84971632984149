import { getEndpoint } from 'src/api/endpoints'
import { BetResponse } from '../../../api/types'
import { apiStatusHandling } from 'src/api/apiStatusHandling'
import { errorHandling } from '../../../api/utils'
import { TData, transformPostBetRequest } from './transform-post-bet'

export const postBet = async (data: TData): Promise<BetResponse> => {
  const transformedData = transformPostBetRequest(data)

  const response = await fetch(`${getEndpoint()}/simulate/Bet`, {
    method: 'post',
    headers: {
      'accept': 'application/json',
      'content-Type': 'application/json',
      'X-SID': `${data.sessionID}`,
    },
    body: JSON.stringify(transformedData),
  }).catch((e) => {
    errorHandling(e)
  })

  if (!response?.ok) {
    errorHandling(await response?.json(), response?.status)
  }

  return apiStatusHandling(await response?.json())
}
