import { PostMessageSelection } from '../lib/postMessageValidator'
import { Outcome } from './soccer/markets/types'
import { getSport, SportsCode } from './sportMap'
import { SimBet, Source } from './types'
import { findMarketFromID } from './marketUtils'

const getSpread = (market_id: number, sport_id: number) => {
  if (market_id && sport_id) {
    const m = findMarketFromID(market_id, sport_id)
    return m.slice(-5).replace(/[^\d.|^.]*/g, '')
  }
  return ''
}

export const mozzartbetKenyaAdapter = (
  pm: PostMessageSelection,
  src: Source,
): SimBet => {
  const unsupportedSelection = {
    b9jBet: pm.selection_id,
    sport: pm.sport_id,
    supported: false,
    event: pm.event_name,
  } as SimBet

  const sportCode: SportsCode = pm.sport_id === 1 ? 'S' : 'B'
  const sportMap = getSport(src, sportCode)
  if (!sportMap) return unsupportedSelection

  let market_id = pm.market_id
  if (pm.market_id === 3) {
    market_id = pm.market_id + parseInt(pm.sign_id) + 1000
  }

  if (pm.market_id === 8) {
    market_id = pm.market_id + parseInt(pm.sign_id) + 2000
  }

  if (pm.market_id === 27 && pm.hnd) {
    market_id = Number(pm.hnd) + 3000
  }

  if (pm.market_id === 187 && pm.hnd) {
    market_id = Number(pm.hnd) + 4000
  }

  if (pm.market_id === 1 && pm.hnd) {
    market_id = Number(pm.hnd) + 5000
  }

  if (pm.market_id === 130 && pm.sign_id) {
    market_id = Number(pm.sign_id) + 6000
  }

  if (pm.market_id === 128 && pm.sign_id) {
    market_id = Number(pm.sign_id) + 6500
  }
  if (pm.market_id === 129 && pm.sign_id) {
    market_id = Number(pm.sign_id) + 6600
  }
  if (pm.market_id === 131 && pm.sign_id) {
    market_id = Number(pm.sign_id) + 6700
  }
  if (pm.market_id === 132 && pm.sign_id) {
    market_id = Number(pm.sign_id) + 6800
  }
  if (pm.market_id === 141 && pm.sign_id) {
    market_id = Number(pm.sign_id) + 7000
  }

  const MKT = `${market_id}@${pm.sign_id}`

  const { sportId, marketMap, outcomeMap, findSimMarketId } = sportMap
  const simulateMarket = marketMap.get(market_id)
  if (!simulateMarket) return unsupportedSelection

  const supported = marketMap.has(market_id)
  const simMarketID = findSimMarketId(simulateMarket, sportId) || 0
  const simOutcome = simulateMarket
    ? outcomeMap(pm.market_name, MKT)
    : ('' as Outcome)
  if (simOutcome === ('' as Outcome)) return unsupportedSelection

  return {
    b9jBet: pm.selection_id,
    matchID: pm.event_id,
    sport: pm.sport_id,
    odd: pm.odd,
    event: pm.event_name,
    market: pm.market_name || '',
    simMarketID,
    outcome: pm.sign_name, // need to double check
    simOutcome,
    supported,
    blocking: false,
    spread: getSpread(simMarketID, pm.sport_id),
    tournament_id: pm.tournament_id?.toString(),
    tournament_name: pm.tournament_name,
  }
}
