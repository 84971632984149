import * as Sim from '../../markets'
import * as MOZZARTBET from './types'

/** Maps MozzartBet markets into simulate markets */
export const MOZZARTBETSoccerMap = new Map<MOZZARTBET.Markets, Sim.Market>()
/** Maps MozzartBet outcomes into simulate outcomes */
export const MOZZARTBETSoccerOutcomeMap = new Map<
  MOZZARTBET.Outcomes,
  Sim.Outcome
>()

export const findMozzarbetKESoccerOutcome = (
  _b9jMkt: MOZZARTBET.Markets,
  outcome: MOZZARTBET.Outcomes,
): Sim.Outcome => {
  return MOZZARTBETSoccerOutcomeMap.get(outcome) || ('' as Sim.Outcome)
}

// FT 1x2 Three Way
MOZZARTBETSoccerMap.set(1, 'FT 1X2')
MOZZARTBETSoccerOutcomeMap.set('1@1', '1')
MOZZARTBETSoccerOutcomeMap.set('1@2', 'x')
MOZZARTBETSoccerOutcomeMap.set('1@3', '2')
// HT 1x2 Three Way
MOZZARTBETSoccerMap.set(4, 'HT 1X2')
MOZZARTBETSoccerOutcomeMap.set('4@1', '1')
MOZZARTBETSoccerOutcomeMap.set('4@2', 'x')
MOZZARTBETSoccerOutcomeMap.set('4@3', '2')
// Correct Score
MOZZARTBETSoccerMap.set(20, 'Correct Score')
MOZZARTBETSoccerOutcomeMap.set('20@0', '0-0')
MOZZARTBETSoccerOutcomeMap.set('20@1', '0-1')
MOZZARTBETSoccerOutcomeMap.set('20@2', '0-2')
MOZZARTBETSoccerOutcomeMap.set('20@3', '0-3')
MOZZARTBETSoccerOutcomeMap.set('20@4', '0-4')
MOZZARTBETSoccerOutcomeMap.set('20@5', '0-5')
MOZZARTBETSoccerOutcomeMap.set('20@6', '0-6')
MOZZARTBETSoccerOutcomeMap.set('20@7', '0-7')
MOZZARTBETSoccerOutcomeMap.set('20@8', '0-8')
MOZZARTBETSoccerOutcomeMap.set('20@9', '0-9')
MOZZARTBETSoccerOutcomeMap.set('20@10', '1-0')
MOZZARTBETSoccerOutcomeMap.set('20@11', '1-1')
MOZZARTBETSoccerOutcomeMap.set('20@12', '1-2')
MOZZARTBETSoccerOutcomeMap.set('20@13', '1-3')
MOZZARTBETSoccerOutcomeMap.set('20@14', '1-4')
MOZZARTBETSoccerOutcomeMap.set('20@15', '1-5')
MOZZARTBETSoccerOutcomeMap.set('20@16', '1-6')
MOZZARTBETSoccerOutcomeMap.set('20@17', '1-7')
MOZZARTBETSoccerOutcomeMap.set('20@18', '1-8')
MOZZARTBETSoccerOutcomeMap.set('20@19', '1-9')
MOZZARTBETSoccerOutcomeMap.set('20@20', '2-0')
MOZZARTBETSoccerOutcomeMap.set('20@21', '2-1')
MOZZARTBETSoccerOutcomeMap.set('20@22', '2-2')
MOZZARTBETSoccerOutcomeMap.set('20@23', '2-3')
MOZZARTBETSoccerOutcomeMap.set('20@24', '2-4')
MOZZARTBETSoccerOutcomeMap.set('20@25', '2-5')
MOZZARTBETSoccerOutcomeMap.set('20@26', '2-6')
MOZZARTBETSoccerOutcomeMap.set('20@27', '2-7')
MOZZARTBETSoccerOutcomeMap.set('20@28', '2-8')
MOZZARTBETSoccerOutcomeMap.set('20@29', '2-9')
MOZZARTBETSoccerOutcomeMap.set('20@30', '3-0')
MOZZARTBETSoccerOutcomeMap.set('20@31', '3-1')
MOZZARTBETSoccerOutcomeMap.set('20@32', '3-2')
MOZZARTBETSoccerOutcomeMap.set('20@33', '3-3')
MOZZARTBETSoccerOutcomeMap.set('20@34', '3-4')
MOZZARTBETSoccerOutcomeMap.set('20@35', '3-5')
MOZZARTBETSoccerOutcomeMap.set('20@36', '3-6')
MOZZARTBETSoccerOutcomeMap.set('20@37', '3-7')
MOZZARTBETSoccerOutcomeMap.set('20@38', '3-8')
MOZZARTBETSoccerOutcomeMap.set('20@39', '3-9')
MOZZARTBETSoccerOutcomeMap.set('20@40', '4-0')
MOZZARTBETSoccerOutcomeMap.set('20@41', '4-1')
MOZZARTBETSoccerOutcomeMap.set('20@42', '4-2')
MOZZARTBETSoccerOutcomeMap.set('20@43', '4-3')
MOZZARTBETSoccerOutcomeMap.set('20@44', '4-4')
MOZZARTBETSoccerOutcomeMap.set('20@45', '4-5')
MOZZARTBETSoccerOutcomeMap.set('20@46', '4-6')
MOZZARTBETSoccerOutcomeMap.set('20@47', '4-7')
MOZZARTBETSoccerOutcomeMap.set('20@48', '4-8')
MOZZARTBETSoccerOutcomeMap.set('20@49', '4-9')
MOZZARTBETSoccerOutcomeMap.set('20@50', '5-0')
MOZZARTBETSoccerOutcomeMap.set('20@51', '5-1')
MOZZARTBETSoccerOutcomeMap.set('20@52', '5-2')
MOZZARTBETSoccerOutcomeMap.set('20@53', '5-3')
MOZZARTBETSoccerOutcomeMap.set('20@54', '5-4')
MOZZARTBETSoccerOutcomeMap.set('20@55', '5-5')
MOZZARTBETSoccerOutcomeMap.set('20@56', '5-6')
MOZZARTBETSoccerOutcomeMap.set('20@57', '5-7')
MOZZARTBETSoccerOutcomeMap.set('20@58', '5-8')
MOZZARTBETSoccerOutcomeMap.set('20@59', '5-9')
MOZZARTBETSoccerOutcomeMap.set('20@60', '6-0')
MOZZARTBETSoccerOutcomeMap.set('20@61', '6-1')
MOZZARTBETSoccerOutcomeMap.set('20@62', '6-2')
MOZZARTBETSoccerOutcomeMap.set('20@63', '6-3')
MOZZARTBETSoccerOutcomeMap.set('20@64', '6-4')
MOZZARTBETSoccerOutcomeMap.set('20@65', '6-5')
MOZZARTBETSoccerOutcomeMap.set('20@66', '6-6')
MOZZARTBETSoccerOutcomeMap.set('20@67', '6-7')
MOZZARTBETSoccerOutcomeMap.set('20@68', '6-8')
MOZZARTBETSoccerOutcomeMap.set('20@69', '6-9')
MOZZARTBETSoccerOutcomeMap.set('20@70', '7-0')
MOZZARTBETSoccerOutcomeMap.set('20@71', '7-1')
MOZZARTBETSoccerOutcomeMap.set('20@72', '7-2')
MOZZARTBETSoccerOutcomeMap.set('20@73', '7-3')
MOZZARTBETSoccerOutcomeMap.set('20@74', '7-4')
MOZZARTBETSoccerOutcomeMap.set('20@75', '7-5')
MOZZARTBETSoccerOutcomeMap.set('20@76', '7-6')
MOZZARTBETSoccerOutcomeMap.set('20@77', '7-7')
MOZZARTBETSoccerOutcomeMap.set('20@78', '7-8')
MOZZARTBETSoccerOutcomeMap.set('20@79', '7-9')
MOZZARTBETSoccerOutcomeMap.set('20@80', '8-0')
MOZZARTBETSoccerOutcomeMap.set('20@81', '8-1')
MOZZARTBETSoccerOutcomeMap.set('20@82', '8-2')
MOZZARTBETSoccerOutcomeMap.set('20@83', '8-3')
MOZZARTBETSoccerOutcomeMap.set('20@84', '8-4')
MOZZARTBETSoccerOutcomeMap.set('20@85', '8-5')
MOZZARTBETSoccerOutcomeMap.set('20@86', '8-6')
MOZZARTBETSoccerOutcomeMap.set('20@87', '8-7')
MOZZARTBETSoccerOutcomeMap.set('20@88', '8-8')
MOZZARTBETSoccerOutcomeMap.set('20@89', '8-9')
MOZZARTBETSoccerOutcomeMap.set('20@90', '9-0')
MOZZARTBETSoccerOutcomeMap.set('20@91', '9-1')
MOZZARTBETSoccerOutcomeMap.set('20@92', '9-2')
MOZZARTBETSoccerOutcomeMap.set('20@93', '9-3')
MOZZARTBETSoccerOutcomeMap.set('20@94', '9-4')
MOZZARTBETSoccerOutcomeMap.set('20@95', '9-5')
MOZZARTBETSoccerOutcomeMap.set('20@96', '9-6')
MOZZARTBETSoccerOutcomeMap.set('20@97', '9-7')
MOZZARTBETSoccerOutcomeMap.set('20@98', '9-8')
MOZZARTBETSoccerOutcomeMap.set('20@99', '9-9')
// FT Double Chance
MOZZARTBETSoccerMap.set(2, 'FT DC')
MOZZARTBETSoccerOutcomeMap.set('2@1', '1x')
MOZZARTBETSoccerOutcomeMap.set('2@2', '12')
MOZZARTBETSoccerOutcomeMap.set('2@3', '2x')
// HT Double Chance
MOZZARTBETSoccerMap.set(297, 'HT_DC')
MOZZARTBETSoccerOutcomeMap.set('297@1', '1x')
MOZZARTBETSoccerOutcomeMap.set('297@2', '12')
MOZZARTBETSoccerOutcomeMap.set('297@3', '2x')
// Goal/No Goal
MOZZARTBETSoccerMap.set(6001, 'FT GG/NG')
MOZZARTBETSoccerMap.set(6002, 'FT GG/NG')
MOZZARTBETSoccerOutcomeMap.set('6001@1', 'gg')
MOZZARTBETSoccerOutcomeMap.set('6002@2', 'ng')
// Goal/No Goal HT
MOZZARTBETSoccerMap.set(6004, 'HT_GG/NG')
MOZZARTBETSoccerOutcomeMap.set('6004@4', 'gg')
// Odd Even
MOZZARTBETSoccerMap.set(1033, 'FT_ODD/EVEN')
MOZZARTBETSoccerOutcomeMap.set('1033@30', 'odd')
MOZZARTBETSoccerMap.set(1032, 'FT_ODD/EVEN')
MOZZARTBETSoccerOutcomeMap.set('1032@29', 'even')
// Over/Under
MOZZARTBETSoccerMap.set(1004, 'FT OU 1.5')
MOZZARTBETSoccerOutcomeMap.set('1004@1', 'u')
MOZZARTBETSoccerOutcomeMap.set('1004@12', 'o')
MOZZARTBETSoccerMap.set(1015, 'FT OU 1.5')
MOZZARTBETSoccerOutcomeMap.set('1015@1', 'u')
MOZZARTBETSoccerOutcomeMap.set('1015@12', 'o')
MOZZARTBETSoccerMap.set(1005, 'FT OU 2.5')
MOZZARTBETSoccerOutcomeMap.set('1005@2', 'u')
MOZZARTBETSoccerOutcomeMap.set('1005@4', 'o')
MOZZARTBETSoccerMap.set(1007, 'FT OU 2.5')
MOZZARTBETSoccerOutcomeMap.set('1007@2', 'u')
MOZZARTBETSoccerOutcomeMap.set('1007@4', 'o')
MOZZARTBETSoccerMap.set(1016, 'FT OU 3.5')
MOZZARTBETSoccerOutcomeMap.set('1016@13', 'u')
MOZZARTBETSoccerOutcomeMap.set('1016@5', 'o')
MOZZARTBETSoccerMap.set(1008, 'FT OU 3.5')
MOZZARTBETSoccerOutcomeMap.set('1008@13', 'u')
MOZZARTBETSoccerOutcomeMap.set('1008@5', 'o')
MOZZARTBETSoccerMap.set(1029, 'FT OU 4.5')
MOZZARTBETSoccerOutcomeMap.set('1029@26', 'u')
MOZZARTBETSoccerOutcomeMap.set('1029@7', 'o')
MOZZARTBETSoccerMap.set(1010, 'FT OU 4.5')
MOZZARTBETSoccerOutcomeMap.set('1010@26', 'u')
MOZZARTBETSoccerOutcomeMap.set('1010@7', 'o')
MOZZARTBETSoccerMap.set(1020, 'FT OU 5.5')
MOZZARTBETSoccerOutcomeMap.set('1020@17', 'o')
MOZZARTBETSoccerMap.set(1011, 'FT OU 6.5')
MOZZARTBETSoccerOutcomeMap.set('1011@8', 'o')
// Multi goal
MOZZARTBETSoccerMap.set(1027, 'Multi goal')
MOZZARTBETSoccerOutcomeMap.set('1027@24', '1-2 goals')
MOZZARTBETSoccerMap.set(1023, 'Multi goal')
MOZZARTBETSoccerOutcomeMap.set('1023@20', '1-3 goals')
MOZZARTBETSoccerMap.set(1030, 'Multi goal')
MOZZARTBETSoccerOutcomeMap.set('1030@27', '1-4 goals')
MOZZARTBETSoccerMap.set(1037, 'Multi goal')
MOZZARTBETSoccerOutcomeMap.set('1037@34', '1-5 goals')
MOZZARTBETSoccerMap.set(1006, 'Multi goal')
MOZZARTBETSoccerOutcomeMap.set('1006@3', '2-3 goals')
MOZZARTBETSoccerMap.set(1021, 'Multi goal')
MOZZARTBETSoccerOutcomeMap.set('1021@18', '2-4 goals')
MOZZARTBETSoccerMap.set(1024, 'Multi goal')
MOZZARTBETSoccerOutcomeMap.set('1024@21', '2-5 goals')
MOZZARTBETSoccerMap.set(1017, 'Multi goal')
MOZZARTBETSoccerOutcomeMap.set('1017@14', '2-6 goals')
MOZZARTBETSoccerMap.set(1022, 'Multi goal')
MOZZARTBETSoccerOutcomeMap.set('1022@19', '3-4 goals')
MOZZARTBETSoccerMap.set(1025, 'Multi goal')
MOZZARTBETSoccerOutcomeMap.set('1025@22', '3-5 goals')
MOZZARTBETSoccerMap.set(1031, 'Multi goal')
MOZZARTBETSoccerOutcomeMap.set('1031@28', '3-6 goals')
MOZZARTBETSoccerMap.set(1028, 'Multi goal')
MOZZARTBETSoccerOutcomeMap.set('1028@25', '4-5 goals')
MOZZARTBETSoccerMap.set(1009, 'Multi goal')
MOZZARTBETSoccerOutcomeMap.set('1009@6', '4-6 goals')
// HT Over/Under
MOZZARTBETSoccerMap.set(2016, 'HT_OU_0.5')
MOZZARTBETSoccerOutcomeMap.set('2016@8', 'u')
MOZZARTBETSoccerMap.set(2009, 'HT_OU_0.5')
MOZZARTBETSoccerOutcomeMap.set('2009@1', 'o')
MOZZARTBETSoccerMap.set(2013, 'HT_OU_1.5')
MOZZARTBETSoccerOutcomeMap.set('2013@5', 'u')
MOZZARTBETSoccerMap.set(2010, 'HT_OU_1.5')
MOZZARTBETSoccerOutcomeMap.set('2010@2', 'o')
MOZZARTBETSoccerMap.set(2017, 'HT_OU_2.5')
MOZZARTBETSoccerOutcomeMap.set('2017@9', 'u')
MOZZARTBETSoccerMap.set(2011, 'HT_OU_2.5')
MOZZARTBETSoccerOutcomeMap.set('2011@3', 'o')
// Halft Time Full Time
MOZZARTBETSoccerMap.set(5, 'HT_FT')
MOZZARTBETSoccerOutcomeMap.set('5@1', '1/1')
MOZZARTBETSoccerOutcomeMap.set('5@2', '1/x')
MOZZARTBETSoccerOutcomeMap.set('5@3', '1/2')
MOZZARTBETSoccerOutcomeMap.set('5@4', 'x/1')
MOZZARTBETSoccerOutcomeMap.set('5@5', 'x/x')
MOZZARTBETSoccerOutcomeMap.set('5@6', 'x/2')
MOZZARTBETSoccerOutcomeMap.set('5@7', '2/1')
MOZZARTBETSoccerOutcomeMap.set('5@8', '2/x')
MOZZARTBETSoccerOutcomeMap.set('5@9', '2/2')
// Goal/No Goal & Over/Under
MOZZARTBETSoccerMap.set(6003, 'FT GG/NG & O/U 2.5')
MOZZARTBETSoccerOutcomeMap.set('6003@3', 'gg & over')
MOZZARTBETSoccerMap.set(6009, 'FT GG/NG & O/U 3.5')
MOZZARTBETSoccerOutcomeMap.set('6009@9', 'gg & over')
// Home/Away - Over/Under
MOZZARTBETSoccerMap.set(6701, 'H/A O/U 0.5')
MOZZARTBETSoccerMap.set(6702, 'H/A O/U 1.5')
MOZZARTBETSoccerMap.set(6801, 'H/A O/U 0.5')
MOZZARTBETSoccerMap.set(6802, 'H/A O/U 1.5')
MOZZARTBETSoccerOutcomeMap.set('6701@1', 'over home')
MOZZARTBETSoccerOutcomeMap.set('6702@2', 'over home')
MOZZARTBETSoccerOutcomeMap.set('6801@1', 'over away')
MOZZARTBETSoccerOutcomeMap.set('6802@2', 'over away')
// HT Home/Away - Over/Under
MOZZARTBETSoccerMap.set(6501, 'HT H/A O/U 0.5')
MOZZARTBETSoccerMap.set(6502, 'HT H/A O/U 1.5')
MOZZARTBETSoccerMap.set(6601, 'HT H/A O/U 0.5')
MOZZARTBETSoccerMap.set(6602, 'HT H/A O/U 1.5')
MOZZARTBETSoccerOutcomeMap.set('6501@1', 'over home')
MOZZARTBETSoccerOutcomeMap.set('6502@2', 'over home')
MOZZARTBETSoccerOutcomeMap.set('6601@1', 'over away')
MOZZARTBETSoccerOutcomeMap.set('6602@2', 'over away')

// Mozzart Combinations 1x2 O/U
MOZZARTBETSoccerMap.set(7010, '1X2 & O/U 1.5')
MOZZARTBETSoccerMap.set(7011, '1X2 & O/U 1.5')
MOZZARTBETSoccerMap.set(7002, '1X2 & O/U 2.5')
MOZZARTBETSoccerMap.set(7001, '1X2 & O/U 2.5')
MOZZARTBETSoccerOutcomeMap.set('7010@10', '1 & over')
MOZZARTBETSoccerOutcomeMap.set('7011@11', '2 & over')
MOZZARTBETSoccerOutcomeMap.set('7001@1', '1 & over')
MOZZARTBETSoccerOutcomeMap.set('7002@2', '2 & over')

// Mozzart Combinations 1x2 HTFT
MOZZARTBETSoccerMap.set(7041, 'HTFT & O/U 1.5')
MOZZARTBETSoccerOutcomeMap.set('7041@41', '1 / 1 & over')
MOZZARTBETSoccerMap.set(7005, 'HTFT & O/U 2.5')
MOZZARTBETSoccerOutcomeMap.set('7005@5', '1 / 1 & over')
MOZZARTBETSoccerMap.set(7078, 'HTFT & O/U 1.5')
MOZZARTBETSoccerOutcomeMap.set('7078@78', '2 / 2 & over')
MOZZARTBETSoccerMap.set(7016, 'HTFT & O/U 2.5')
MOZZARTBETSoccerOutcomeMap.set('7016@16', '2 / 2 & over')

// Mozzart Combinations DC O/U
MOZZARTBETSoccerMap.set(7047, 'DC & O/U 1.5')
MOZZARTBETSoccerOutcomeMap.set('7047@47', '1x & over')
MOZZARTBETSoccerMap.set(7084, 'DC & O/U 1.5')
MOZZARTBETSoccerOutcomeMap.set('7084@84', '2x & over')

// Mozzart Combinations 1x2 TG
MOZZARTBETSoccerMap.set(7142, 'FT 1x2 & TG 2-6')
MOZZARTBETSoccerOutcomeMap.set('7142@142', '1 & 2-6')
MOZZARTBETSoccerMap.set(7146, 'FT 1x2 & TG 2-6')
MOZZARTBETSoccerOutcomeMap.set('7146@146', '2 & 2-6')

// Mozzart Combinations MOZZARTBET_CUSTOM
MOZZARTBETSoccerMap.set(7017, 'MOZZARTBET_CUSTOM_63')
MOZZARTBETSoccerOutcomeMap.set('7017@17', 'In range')
MOZZARTBETSoccerMap.set(7117, 'MOZZARTBET_CUSTOM_61')
MOZZARTBETSoccerOutcomeMap.set('7117@117', 'In range')
MOZZARTBETSoccerMap.set(7015, 'MOZZARTBET_CUSTOM_64')
MOZZARTBETSoccerOutcomeMap.set('7015@15', 'In range')
MOZZARTBETSoccerMap.set(7118, 'MOZZARTBET_CUSTOM_60')
MOZZARTBETSoccerOutcomeMap.set('7118@118', 'o')
MOZZARTBETSoccerMap.set(7116, 'MOZZARTBET_CUSTOM_62')
MOZZARTBETSoccerOutcomeMap.set('7116@116', 'In range')
