import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 4px;
`
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px;

  .heading {
    font-size: 10px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colours.N030};
  }

  .amount {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: ${(props) => props.theme.colours.AP050};
  }

  .weight {
    font-weight: 500;
  }

  .info {
    margin-left: 8px;

    svg {
      vertical-align: middle;
    }
  }
`
