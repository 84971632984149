import { useSelector } from 'react-redux'
import {
  selectError,
  validatesErrorModal,
} from '../../features/app/store/app.state'
import { useAppDispatch } from '../../store/store'
import { Modal } from '../modal/modal'
import { Wrapper } from './errorModal.style'
import { FormattedMessage } from 'react-intl'
import { ErrorMessage } from './errorMessage'

export const ErrorModal = () => {
  const dispatch = useAppDispatch()
  const error = useSelector(selectError)
  const handleClick = () => dispatch(validatesErrorModal())

  if (error)
    return (
      <Modal>
        <Wrapper>
          <div className="message" data-testid="error-msg">
            <ErrorMessage
              code={error.status || 500}
              values={{ ...error?.values }}
            />{' '}
          </div>
          <button onClick={handleClick} className="btn" title="Okay">
            <span>
              <FormattedMessage id="btnContinue" defaultMessage="OK Continue" />
            </span>
          </button>
        </Wrapper>
      </Modal>
    )

  return <></>
}
