import styled from 'styled-components'

export const FreeBetListItem = styled.li`
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

export const FreeBetCard = styled.div`
  border-radius: 6px;
  padding: 19px 25px;
  border: 1px solid ${(props) => props.theme.colours.S050};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: white;
  position: relative;
  background: ${(props) => props.theme.colours.S090};

  ::before,
  ::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    width: 20px;
    height: 20px;
    background-color: ${(props) => props.theme.colours.S000};
    border: 1px solid ${(props) => props.theme.colours.S050};
    border-radius: 50%;
  }

  ::before {
    left: 0;
    transform: translate(-50%, -50%);
  }
  ::after {
    right: 0;
    transform: translate(50%, -50%);
  }

  .btn {
    width: 84px;
    height: 24px;
    font-weight: 900;
    font-size: 12px;
    color: ${(props) => props.theme.colours.ST100};
    background: ${(props) => props.theme.colours.AP050G};
    border: 0.7px solid ${(props) => props.theme.colours.AP080};
    box-shadow:
      0px 3px 0px #b5892e,
      0px 6px 3px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin-top: 7px;
  }

  .title {
    color: ${(props) => props.theme.colours.N030};
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 4px;
    line-height: 16px;
  }

  .currency {
    color: ${(props) => props.theme.colours.AP050};
    font-weight: bold;
    font-size: 22px;
  }

  .box {
    padding-left: 23px;
    border-left: 1px dashed ${(props) => props.theme.colours.S050};
    width: 52%;
  }

  .expiry {
    color: ${(props) => props.theme.colours.N040};
    line-height: 16px;

    .time {
      color: ${(props) => props.theme.colours.AP050};
    }
  }

  .freeBetNoContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    .heading {
      color: ${(props) => props.theme.colours.N040};
      font-weight: 500;
    }
  }
`
