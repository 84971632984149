import * as ISol from './types'
import * as Sim from '../../markets'

export const findISolOutcome = (
  _IsolMkt: ISol.Markets,
  outcome: ISol.Outcomes,
): Sim.Outcome => {
  return ISolSoccerOutcomeMap.get(outcome) || ('' as Sim.Outcome)
}

export const ISolSoccerMap = new Map<ISol.Markets, Sim.Market>()
export const ISolSoccerOutcomeMap = new Map<ISol.Outcomes, Sim.Outcome>()

// 1X2
ISolSoccerMap.set('1X2', 'FT 1X2')
ISolSoccerMap.set('UnoXDue', 'FT 1X2')
ISolSoccerOutcomeMap.set('1', '1')
ISolSoccerOutcomeMap.set('X', 'x')
ISolSoccerOutcomeMap.set('2', '2')
ISolSoccerOutcomeMap.set('UnoXDue_1', '1')
ISolSoccerOutcomeMap.set('UnoXDue_X', 'x')
ISolSoccerOutcomeMap.set('UnoXDue_2', '2')

// Over/Under
ISolSoccerMap.set('OverUnder05', 'FT OU 0.5')
ISolSoccerMap.set('OverUnder15', 'FT OU 1.5')
ISolSoccerMap.set('OverUnder25', 'FT OU 2.5')
ISolSoccerMap.set('OverUnder35', 'FT OU 3.5')
ISolSoccerMap.set('OverUnder45', 'FT OU 4.5')
ISolSoccerMap.set('OverUnder55', 'FT OU 5.5')
ISolSoccerMap.set('OverUnder65', 'FT OU 6.5')
ISolSoccerMap.set('OverUnder75', 'FT OU 7.5')
ISolSoccerMap.set('OverUnder85', 'FT OU 8.5')
ISolSoccerOutcomeMap.set('OverUnder05_Over', 'o')
ISolSoccerOutcomeMap.set('OverUnder15_Over', 'o')
ISolSoccerOutcomeMap.set('OverUnder25_Over', 'o')
ISolSoccerOutcomeMap.set('OverUnder35_Over', 'o')
ISolSoccerOutcomeMap.set('OverUnder45_Over', 'o')
ISolSoccerOutcomeMap.set('OverUnder55_Over', 'o')
ISolSoccerOutcomeMap.set('OverUnder65_Over', 'o')
ISolSoccerOutcomeMap.set('OverUnder75_Over', 'o')
ISolSoccerOutcomeMap.set('OverUnder85_Over', 'o')
ISolSoccerOutcomeMap.set('OverUnder05_Under', 'u')
ISolSoccerOutcomeMap.set('OverUnder15_Under', 'u')
ISolSoccerOutcomeMap.set('OverUnder25_Under', 'u')
ISolSoccerOutcomeMap.set('OverUnder35_Under', 'u')
ISolSoccerOutcomeMap.set('OverUnder45_Under', 'u')
ISolSoccerOutcomeMap.set('OverUnder55_Under', 'u')
ISolSoccerOutcomeMap.set('OverUnder65_Under', 'u')
ISolSoccerOutcomeMap.set('OverUnder75_Under', 'u')
ISolSoccerOutcomeMap.set('OverUnder85_Under', 'u')

ISolSoccerMap.set('OverUnder05HT', 'HT_OU_0.5')
ISolSoccerMap.set('OverUnder15HT', 'HT_OU_1.5')
ISolSoccerMap.set('OverUnder25HT', 'HT_OU_2.5')
ISolSoccerOutcomeMap.set('OverUnder05HT_OverHT', 'o')
ISolSoccerOutcomeMap.set('OverUnder05HT_UnderHT', 'u')
ISolSoccerOutcomeMap.set('OverUnder15HT_OverHT', 'o')
ISolSoccerOutcomeMap.set('OverUnder15HT_UnderHT', 'u')
ISolSoccerOutcomeMap.set('OverUnder25HT_OverHT', 'o')
ISolSoccerOutcomeMap.set('OverUnder25HT_UnderHT', 'u')

// Goal/No Goal (both team to score)
ISolSoccerMap.set('GoalNoGoal', 'FT GG/NG')
ISolSoccerOutcomeMap.set('GoalNoGoal_GG', 'gg')
ISolSoccerOutcomeMap.set('GoalNoGoal_NG', 'ng')
ISolSoccerMap.set('GG/NG1stHalf', 'HT_GG/NG')
ISolSoccerOutcomeMap.set('GG/NG1stHalf_NG1H', 'ng')
ISolSoccerOutcomeMap.set('GG/NG1stHalf_GG1H', 'gg')

// HalfTime
ISolSoccerMap.set('HalfTime', 'HT 1X2')
ISolSoccerOutcomeMap.set('HalfTime_1', '1')
ISolSoccerOutcomeMap.set('HalfTime_X', 'x')
ISolSoccerOutcomeMap.set('HalfTime_2', '2')

// Double Chance
ISolSoccerMap.set('DoubleChance', 'FT DC')
ISolSoccerOutcomeMap.set('DoubleChance_1X', '1x')
ISolSoccerOutcomeMap.set('DoubleChance_X2', '2x')
ISolSoccerOutcomeMap.set('DoubleChance_12', '12')
ISolSoccerMap.set('DC1stHalf', 'HT_DC')
ISolSoccerOutcomeMap.set('DC1stHalf_1X', '1x')
ISolSoccerOutcomeMap.set('DC1stHalf_X2', '2x')
ISolSoccerOutcomeMap.set('DC1stHalf_12', '12')

// 1X2 Or Goal/No Goal -> chance Mix
ISolSoccerMap.set('Chance Mix', 'Chance Mix')
ISolSoccerOutcomeMap.set('ChanceMix_1orGG', '1 or gg')
ISolSoccerOutcomeMap.set('ChanceMix_1orNG', '1 or ng')
ISolSoccerOutcomeMap.set('ChanceMix_2orGG', '2 or gg')
ISolSoccerOutcomeMap.set('ChanceMix_2orNG', '2 or ng')
ISolSoccerOutcomeMap.set('ChanceMix_XorGG', 'x or ng')
ISolSoccerOutcomeMap.set('ChanceMix_XorNG', 'x or ng')

//

// 1X2 || Over/Under -> ChanceMix or OU
ISolSoccerMap.set('ChanceMix15', 'Chance Mix 1.5')
ISolSoccerOutcomeMap.set('ChanceMix_1or1-', '1 or un')
ISolSoccerOutcomeMap.set('ChanceMix_1or2+', '1 or ov')
ISolSoccerOutcomeMap.set('ChanceMix_2or1-', '2 or un')
ISolSoccerOutcomeMap.set('ChanceMix_2or2+', '2 or ov')
ISolSoccerOutcomeMap.set('ChanceMix_Xor1-', 'x or un')
ISolSoccerOutcomeMap.set('ChanceMix_Xor2+', 'x or ov')

ISolSoccerMap.set('ChanceMix25', 'Chance Mix 2.5')
ISolSoccerOutcomeMap.set('ChanceMix_1or2-', '1 or un')
ISolSoccerOutcomeMap.set('ChanceMix_1or3+', '1 or ov')
ISolSoccerOutcomeMap.set('ChanceMix_2or2-', '2 or un')
ISolSoccerOutcomeMap.set('ChanceMix_2or3+', '2 or ov')
ISolSoccerOutcomeMap.set('ChanceMix_Xor2-', 'x or un')
ISolSoccerOutcomeMap.set('ChanceMix_Xor3+', 'x or ov')

ISolSoccerMap.set('ChanceMix35', 'Chance Mix 3.5')
ISolSoccerOutcomeMap.set('ChanceMix_1or3-', '1 or un')
ISolSoccerOutcomeMap.set('ChanceMix_1or4+', '1 or ov')
ISolSoccerOutcomeMap.set('ChanceMix_2or3-', '2 or un')
ISolSoccerOutcomeMap.set('ChanceMix_2or4+', '2 or ov')
ISolSoccerOutcomeMap.set('ChanceMix_Xor3-', 'x or un')
ISolSoccerOutcomeMap.set('ChanceMix_Xor4+', 'x or ov')

// 1X2 && Over/Under
ISolSoccerMap.set('1X2 & O/U 1 5', '1X2 & O/U 1.5')
ISolSoccerOutcomeMap.set('AwayO/U1.5_2&Over', '2 & over')
ISolSoccerOutcomeMap.set('AwayO/U1.5_2&Under', '2 & under')
ISolSoccerOutcomeMap.set('DrawO/U1.5_X&Over', 'x & over')
ISolSoccerOutcomeMap.set('DrawO/U1.5_X&Under', 'x & under')
ISolSoccerOutcomeMap.set('HomeO/U1.5_1&Over', '1 & over')
ISolSoccerOutcomeMap.set('HomeO/U1.5_1&Under', '1 & under')

ISolSoccerMap.set('1X2 & O/U 2 5', '1X2 & O/U 2.5')
ISolSoccerOutcomeMap.set('AwayO/U2.5_2&Over', '2 & over')
ISolSoccerOutcomeMap.set('AwayO/U2.5_2&Under', '2 & under')
ISolSoccerOutcomeMap.set('DrawO/U2.5_X&Over', 'x & over')
ISolSoccerOutcomeMap.set('DrawO/U2.5_X&Under', 'x & under')
ISolSoccerOutcomeMap.set('HomeO/U2.5_1&Over', '1 & over')
ISolSoccerOutcomeMap.set('HomeO/U2.5_1&Under', '1 & under')

ISolSoccerMap.set('1X2 & O/U 3 5', '1X2 & O/U 3.5')
ISolSoccerOutcomeMap.set('AwayO/U3.5_2&Over', '2 & over')
ISolSoccerOutcomeMap.set('AwayO/U3.5_2&Under', '2 & under')
ISolSoccerOutcomeMap.set('DrawO/U3.5_X&Over', 'x & over')
ISolSoccerOutcomeMap.set('DrawO/U3.5_X&Under', 'x & under')
ISolSoccerOutcomeMap.set('HomeO/U3.5_1&Over', '1 & over')
ISolSoccerOutcomeMap.set('HomeO/U3.5_1&Under', '1 & under')

ISolSoccerMap.set('1X2 & O/U 4 5', '1X2 & O/U 4.5')
ISolSoccerOutcomeMap.set('Away & Over 4_5', '2 & over')
ISolSoccerOutcomeMap.set('Away & Under 4_5', '2 & under')
ISolSoccerOutcomeMap.set('Draw & Over 4_5', 'x & over')
ISolSoccerOutcomeMap.set('Draw & Under 4_5', 'x & under')
ISolSoccerOutcomeMap.set('Home & Over 4_5', '1 & over')
ISolSoccerOutcomeMap.set('Home & Under 4_5', '1 & under')

// 1X2 && Goal/No Goal
ISolSoccerMap.set('1X2+GG/NG', '1X2 & GG/NG')
ISolSoccerOutcomeMap.set('1X2+GG/NG_1+GOAL', '1 & gg')
ISolSoccerOutcomeMap.set('1X2+GG/NG_1+NOGOAL', '1 & ng')
ISolSoccerOutcomeMap.set('1X2+GG/NG_2+GOAL', '2 & gg')
ISolSoccerOutcomeMap.set('1X2+GG/NG_2+NOGOAL', '2 & ng')
ISolSoccerOutcomeMap.set('1X2+GG/NG_X+GOAL', 'x & gg')
ISolSoccerOutcomeMap.set('1X2+GG/NG_X+NOGOAL', 'x & ng')

// Double Chance && Over/Under
ISolSoccerMap.set('DCCombo15', 'DC & O/U 1.5')
ISolSoccerOutcomeMap.set('DCCombo_12&2+', '12 & over')
ISolSoccerOutcomeMap.set('DCCombo_12&1-', '12 & under')
ISolSoccerOutcomeMap.set('DCCombo_1X&2+', '1x & over')
ISolSoccerOutcomeMap.set('DCCombo_1X&1-', '1x & under')
ISolSoccerOutcomeMap.set('DCCombo_X2&2+', '2x & over')
ISolSoccerOutcomeMap.set('DCCombo_x2&1-', '2x & under')

ISolSoccerMap.set('DC & O/U 2 5', 'DC & O/U 2.5')
ISolSoccerOutcomeMap.set('12 & Over 2 5', '12 & over')
ISolSoccerOutcomeMap.set('12 & Under 2 5', '12 & under')
ISolSoccerOutcomeMap.set('1X & Over 2 5', '1x & over')
ISolSoccerOutcomeMap.set('1X & Under 2 5', '1x & under')
ISolSoccerOutcomeMap.set('X2 & Over 2 5', '2x & over')
ISolSoccerOutcomeMap.set('X2 & Under 2 5', '2x & under')

ISolSoccerMap.set('DC & O/U 3 5', 'DC & O/U 3.5')
ISolSoccerOutcomeMap.set('12 & Over 3 5', '12 & over')
ISolSoccerOutcomeMap.set('12 & Under 3 5', '12 & under')
ISolSoccerOutcomeMap.set('1X & Over 3 5', '1x & over')
ISolSoccerOutcomeMap.set('1X & Under 3 5', '1x & under')
ISolSoccerOutcomeMap.set('X2 & Over 3 5', '2x & over')
ISolSoccerOutcomeMap.set('X2 & Under 3 5', '2x & under')

ISolSoccerMap.set('DC & O/U 4 5', 'DC & O/U 4.5')
ISolSoccerOutcomeMap.set('12 & Over 4 5', '12 & over')
ISolSoccerOutcomeMap.set('12 & Under 4 5', '12 & under')
ISolSoccerOutcomeMap.set('1X & Over 4 5', '1x & over')
ISolSoccerOutcomeMap.set('1X & Under 4 5', '1x & under')
ISolSoccerOutcomeMap.set('X2 & Over 4 5', '2x & over')
ISolSoccerOutcomeMap.set('X2 & Under 4 5', '2x & under')

// Correct Score
ISolSoccerMap.set('CorrectScore', 'Correct Score')
ISolSoccerOutcomeMap.set('CorrectScore_0-0', '0-0')
ISolSoccerOutcomeMap.set('CorrectScore_0-1', '0-1')
ISolSoccerOutcomeMap.set('CorrectScore_0-2', '0-2')
ISolSoccerOutcomeMap.set('CorrectScore_0-3', '0-3')
ISolSoccerOutcomeMap.set('CorrectScore_0-4', '0-4')
ISolSoccerOutcomeMap.set('CorrectScore_1-0', '1-0')
ISolSoccerOutcomeMap.set('CorrectScore_1-1', '1-1')
ISolSoccerOutcomeMap.set('CorrectScore_1-2', '1-2')
ISolSoccerOutcomeMap.set('CorrectScore_1-3', '1-3')
ISolSoccerOutcomeMap.set('CorrectScore_1-4', '1-4')
ISolSoccerOutcomeMap.set('CorrectScore_2-0', '2-0')
ISolSoccerOutcomeMap.set('CorrectScore_2-1', '2-1')
ISolSoccerOutcomeMap.set('CorrectScore_2-2', '2-2')
ISolSoccerOutcomeMap.set('CorrectScore_2-3', '2-3')
ISolSoccerOutcomeMap.set('CorrectScore_2-4', '2-4')
ISolSoccerOutcomeMap.set('CorrectScore_3-0', '3-0')
ISolSoccerOutcomeMap.set('CorrectScore_3-1', '3-1')
ISolSoccerOutcomeMap.set('CorrectScore_3-2', '3-2')
ISolSoccerOutcomeMap.set('CorrectScore_3-3', '3-3')
ISolSoccerOutcomeMap.set('CorrectScore_3-4', '3-4')
ISolSoccerOutcomeMap.set('CorrectScore_4-0', '4-0')
ISolSoccerOutcomeMap.set('CorrectScore_4-1', '4-1')
ISolSoccerOutcomeMap.set('CorrectScore_4-2', '4-2')
ISolSoccerOutcomeMap.set('CorrectScore_4-3', '4-3')
ISolSoccerOutcomeMap.set('CorrectScore_4-4', '4-4')
ISolSoccerOutcomeMap.set('CorrectScore_Other', 'other')

ISolSoccerMap.set('CorrectScoreZ', 'Correct Score')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_0-0', '0-0')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_0-1', '0-1')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_0-2', '0-2')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_0-3', '0-3')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_0-4', '0-4')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_1-0', '1-0')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_1-1', '1-1')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_1-2', '1-2')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_1-3', '1-3')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_1-4', '1-4')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_2-0', '2-0')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_2-1', '2-1')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_2-2', '2-2')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_2-3', '2-3')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_2-4', '2-4')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_3-0', '3-0')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_3-1', '3-1')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_3-2', '3-2')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_3-3', '3-3')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_3-4', '3-4')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_4-0', '4-0')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_4-1', '4-1')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_4-2', '4-2')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_4-3', '4-3')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_4-4', '4-4')
ISolSoccerOutcomeMap.set('CorrectScore_Zoom_OTHER', 'other')

//
ISolSoccerMap.set('Correct Multi5', 'C. Score Multi')
ISolSoccerOutcomeMap.set('Correctscoremulti00110110', '0-0 / 1-1 / 0-1 / 1-0')
ISolSoccerOutcomeMap.set('Correctscoremulti02120313', '0-2 / 1-2 / 0-3 / 1-3')
ISolSoccerOutcomeMap.set('Correctscoremulti04142434', '0-4 / 1-4 / 2-4 / 3-4')
ISolSoccerOutcomeMap.set('Correctscoremulti20213031', '2-0 / 2-1 / 3-0 / 3-1')
ISolSoccerOutcomeMap.set('Correctscoremulti22233233', '2-2 / 2-3 / 3-2 / 3-3')
ISolSoccerOutcomeMap.set('Correctscoremulti40414243', '4-0 / 4-1 / 4-2 / 4-3')
ISolSoccerOutcomeMap.set('Correctscoremulti44other', '4-4 / other')

//
ISolSoccerMap.set('CorrectMulti2', 'C. Score Multi2')
ISolSoccerOutcomeMap.set('Correctscoremulti0011', '0-0 / 1-1')
ISolSoccerOutcomeMap.set('Correctscoremulti010212', '0-1 / 0-2 / 1-2')
ISolSoccerOutcomeMap.set('Correctscoremulti031323', '0-3 / 1-3 / 2-3')
ISolSoccerOutcomeMap.set('Correctscoremulti102021', '1-0 / 2-0 / 2-1')
ISolSoccerOutcomeMap.set('Correctscoremulti142434', '1-4 / 2-4 / 3-4')
ISolSoccerOutcomeMap.set('Correctscoremulti223344', '2-2 / 3-3 / 4-4')
ISolSoccerOutcomeMap.set('Correctscoremulti303132', '3-0 / 3-1 / 3-2')
ISolSoccerOutcomeMap.set('Correctscoremulti414243', '4-1 / 4-2 / 4-3')
ISolSoccerOutcomeMap.set('Correctscoremulti4004other', '4-0 / 0-4 / other')

// Multi goal
ISolSoccerMap.set('MultiGoal', 'Multi goal')
ISolSoccerOutcomeMap.set('MultiGoal_1-2G', '1-2 goals')
ISolSoccerOutcomeMap.set('MultiGoal_1-3G', '1-3 goals')
ISolSoccerOutcomeMap.set('MultiGoal_1-4G', '1-4 goals')
ISolSoccerOutcomeMap.set('MultiGoal_1-5G', '1-5 goals')
ISolSoccerOutcomeMap.set('MultiGoal_2-3G', '2-3 goals')
ISolSoccerOutcomeMap.set('MultiGoal_2-4G', '2-4 goals')
ISolSoccerOutcomeMap.set('MultiGoal_2-5G', '2-5 goals')
ISolSoccerOutcomeMap.set('MultiGoal_2-6G', '2-6 goals')
ISolSoccerOutcomeMap.set('MultiGoal_3-4G', '3-4 goals')
ISolSoccerOutcomeMap.set('MultiGoal_3-5G', '3-5 goals')
ISolSoccerOutcomeMap.set('MultiGoal_3-6G', '3-6 goals')
ISolSoccerOutcomeMap.set('MultiGoal_4-5G', '4-5 goals')
ISolSoccerOutcomeMap.set('MultiGoal_4-6G', '4-6 goals')
ISolSoccerOutcomeMap.set('MultiGoal_5-6G', '5-6 goals')
// ISolSoccerOutcomeMap.set('MultiGoal_7+G', '');

ISolSoccerMap.set('Multi Goal Zoom', 'Multi goal')
ISolSoccerOutcomeMap.set('MultiGoal_1-2Goals', '1-2 goals')
ISolSoccerOutcomeMap.set('MultiGoal_1-3Goals', '1-3 goals')
ISolSoccerOutcomeMap.set('MultiGoal_1-4Goals', '1-4 goals')
ISolSoccerOutcomeMap.set('MultiGoal_1-5Goals', '1-5 goals')
ISolSoccerOutcomeMap.set('MultiGoal_2-3Goal', '2-3 goals')
ISolSoccerOutcomeMap.set('MultiGoal_2-4Goals', '2-4 goals')
ISolSoccerOutcomeMap.set('MultiGoal_2-5Goals', '2-5 goals')
ISolSoccerOutcomeMap.set('MultiGoal_2-6Goals', '2-6 goals')
ISolSoccerOutcomeMap.set('MultiGoal_3-4Goals', '3-4 goals')
ISolSoccerOutcomeMap.set('MultiGoal_3-5Goals', '3-5 goals')
ISolSoccerOutcomeMap.set('MultiGoal_3-6Goals', '3-6 goals')
ISolSoccerOutcomeMap.set('MultiGoal_4-5Goals', '4-5 goals')
ISolSoccerOutcomeMap.set('MultiGoal_4-6Goal', '4-6 goals')
ISolSoccerOutcomeMap.set('MultiGoal_5-6Goals', '5-6 goals')
// ISolSoccerOutcomeMap.set('MultiGoal_7+Goal', '');

ISolSoccerMap.set('OddEven', 'FT_ODD/EVEN')
ISolSoccerOutcomeMap.set('OddEven_Odd', 'odd')
ISolSoccerOutcomeMap.set('OddEven_Even', 'even')

ISolSoccerMap.set('HalfTimeFullTime', 'HT_FT')
ISolSoccerOutcomeMap.set('HalfTimeFullTime_HF1/1', '1/1')
ISolSoccerOutcomeMap.set('HalfTimeFullTime_HF1/X', '1/x')
ISolSoccerOutcomeMap.set('HalfTimeFullTime_HF1/2', '1/2')
ISolSoccerOutcomeMap.set('HalfTimeFullTime_HFX/1', 'x/1')
ISolSoccerOutcomeMap.set('HalfTimeFullTime_HFX/X', 'x/x')
ISolSoccerOutcomeMap.set('HalfTimeFullTime_HFX/2', 'x/2')
ISolSoccerOutcomeMap.set('HalfTimeFullTime_HF2/1', '2/1')
ISolSoccerOutcomeMap.set('HalfTimeFullTime_HF2/X', '2/x')
ISolSoccerOutcomeMap.set('HalfTimeFullTime_HF2/2', '2/2')

ISolSoccerMap.set('H/ATeamO/U0.5', 'H/A O/U 0.5')
ISolSoccerMap.set('H/ATeamO/U1.5', 'H/A O/U 1.5')
ISolSoccerMap.set('H/ATeamO/U2.5', 'H/A O/U 2.5')
ISolSoccerMap.set('H/A Team O/U 35', 'H/A O/U 3.5')
ISolSoccerOutcomeMap.set('H/ATeamO/U0.5_AwayOver0.5', 'over away')
ISolSoccerOutcomeMap.set('H/ATeamO/U0.5_AwayUnder0.5', 'under away')
ISolSoccerOutcomeMap.set('H/ATeamO/U0.5_HomeOver0.5', 'over home')
ISolSoccerOutcomeMap.set('H/ATeamO/U0.5_HomeUnder0.5', 'under home')
ISolSoccerOutcomeMap.set('H/ATeamO/U1.5_AwayOver1.5', 'over away')
ISolSoccerOutcomeMap.set('H/ATeamO/U1.5_AwayUnder1.5', 'under away')
ISolSoccerOutcomeMap.set('H/ATeamO/U1.5_HomeOver1.5', 'over home')
ISolSoccerOutcomeMap.set('H/ATeamO/U1.5_HomeUnder1.5', 'under home')
ISolSoccerOutcomeMap.set('H/ATeamO/U2.5_AwayOver2.5', 'over away')
ISolSoccerOutcomeMap.set('H/ATeamO/U2.5_AwayUnder2.5', 'under away')
ISolSoccerOutcomeMap.set('H/ATeamO/U2.5_HomeOver2.5', 'over home')
ISolSoccerOutcomeMap.set('H/ATeamO/U2.5_HomeUnder2.5', 'under home')
ISolSoccerOutcomeMap.set('H/ATeamO/U35HAwayOver35', 'over away')
ISolSoccerOutcomeMap.set('H/ATeamO/U35AwayUnder35', 'under away')
ISolSoccerOutcomeMap.set('H/ATeamO/U35HomeOver35', 'over home')
ISolSoccerOutcomeMap.set('H/ATeamO/U35HomeUnder35', 'under home')

// TO CHECK H/ATeamO/U35HAwayOver35
