export type Brand =
  | 'certification'
  | 'bet9ja'
  | 'mozzartbet-ke'
  | 'mozzartbet-srb'
  | 'teapuesto-pe'
  | 'yajuego-co'
  | 'mozzartbet-hrv'
  | 'bolabet-zmb'
export const BRAND: Brand = process.env.REACT_APP_BRAND! as Brand
export const TARGET = process.env.REACT_APP_BUILD_TARGET
export const ACTIVETOURNAMENT = TARGET === 'prod' ? '170880' : '354121'
export const LANGUAGE = process.env.REACT_APP_LANGUAGE || 'en'

export type SimSports = 'Soccer' | 'Basketball'
