import { Metadata } from 'src/api/types'
import { BRAND } from 'src/config'
import { getClientVariables } from 'src/config/client-variables'
import { splitTeams } from 'src/lib/utils/uiUtils'
import { findSportById } from 'src/sports/marketUtils'
import { SimBet } from 'src/sports/types'

export const createMetadataObject = (
  stake: number,
  totalOdds: number,
  selections: SimBet[],
): Metadata | undefined => {
  if (
    BRAND !== 'mozzartbet-ke' &&
    BRAND !== 'mozzartbet-srb' &&
    BRAND !== 'mozzartbet-hrv'
  )
    return undefined

  return {
    ticket: {
      status: 'active',
      currency: {
        code: getClientVariables().currency,
      },
      stake: stake,
      winningData: {
        maxWinning:
          Math.round((stake * totalOdds + Number.EPSILON) * 100) / 100,
      },
      details: {
        events: selections.map((s) => {
          const { team1, team2 } = splitTeams(s.event)
          return {
            eventId: s.matchID,
            competition: s.tournament_name,
            sport: {
              id: s.sport,
              name: findSportById(s.sport),
            },
            data: {
              participants: [
                {
                  name: team1,
                },
                {
                  name: team2,
                },
              ],
            },
            bets: [
              {
                marketId: s.simMarketID ? s.simMarketID.toString() : '',
                marketName: s.market,
                oddValue: s.odd.toString(),
                signId: s.outcome,
              },
            ],
          }
        }),
      },
    },
  }
}
