import * as Sim from '../../markets'
import * as TEAPUESTO from './types'

export const TEAPUESTOSoccerMap = new Map<TEAPUESTO.Markets, Sim.Market>()
export const TEAPUESTOSoccerOutcomeMap = new Map<
  TEAPUESTO.Outcomes,
  Sim.Outcome
>()

export const findteapuestoSoccerOutcome = (
  _b9jMkt: TEAPUESTO.Markets,
  outcome: TEAPUESTO.Outcomes,
): Sim.Outcome => {
  return TEAPUESTOSoccerOutcomeMap.get(outcome) || ('' as Sim.Outcome)
}

// FT 1x2 Three Way
TEAPUESTOSoccerMap.set('5832', 'FT 1X2')
TEAPUESTOSoccerOutcomeMap.set('8717', '1')
TEAPUESTOSoccerOutcomeMap.set('8722', 'x')
TEAPUESTOSoccerOutcomeMap.set('8727', '2')
// HT 1x2 Three Way
TEAPUESTOSoccerMap.set('6192', 'HT 1X2')
TEAPUESTOSoccerOutcomeMap.set('10307', '1')
TEAPUESTOSoccerOutcomeMap.set('10312', 'x')
TEAPUESTOSoccerOutcomeMap.set('10317', '2')
// Correct Score
TEAPUESTOSoccerMap.set('5812', 'Correct Score')
TEAPUESTOSoccerOutcomeMap.set('8552', '1-0')
TEAPUESTOSoccerOutcomeMap.set('8557', '2-0')
TEAPUESTOSoccerOutcomeMap.set('8562', '2-1')
TEAPUESTOSoccerOutcomeMap.set('8567', '3-0')
TEAPUESTOSoccerOutcomeMap.set('8572', '3-1')
TEAPUESTOSoccerOutcomeMap.set('8577', '3-2')
TEAPUESTOSoccerOutcomeMap.set('8582', '4-0')
TEAPUESTOSoccerOutcomeMap.set('8587', '4-1')
TEAPUESTOSoccerOutcomeMap.set('8592', '4-2')
TEAPUESTOSoccerOutcomeMap.set('8597', '0-0')
TEAPUESTOSoccerOutcomeMap.set('8602', '1-1')
TEAPUESTOSoccerOutcomeMap.set('8607', '2-2')
TEAPUESTOSoccerOutcomeMap.set('8612', '3-3')
TEAPUESTOSoccerOutcomeMap.set('8617', '0-1')
TEAPUESTOSoccerOutcomeMap.set('8622', '0-2')
TEAPUESTOSoccerOutcomeMap.set('8627', '1-2')
TEAPUESTOSoccerOutcomeMap.set('8632', '0-3')
TEAPUESTOSoccerOutcomeMap.set('8637', '1-3')
TEAPUESTOSoccerOutcomeMap.set('8642', '2-3')
TEAPUESTOSoccerOutcomeMap.set('8647', '0-4')
TEAPUESTOSoccerOutcomeMap.set('8652', '1-4')
TEAPUESTOSoccerOutcomeMap.set('8657', '2-4')
TEAPUESTOSoccerOutcomeMap.set('8662', '3-4')
TEAPUESTOSoccerOutcomeMap.set('8667', '4-3')
TEAPUESTOSoccerOutcomeMap.set('8672', '4-4')
TEAPUESTOSoccerOutcomeMap.set('8677', 'other')
// FT Double Chance
TEAPUESTOSoccerMap.set('5822', 'FT DC')
TEAPUESTOSoccerOutcomeMap.set('8692', '1x')
TEAPUESTOSoccerOutcomeMap.set('8697', '12')
TEAPUESTOSoccerOutcomeMap.set('8702', '2x')
// HT Double Chance
TEAPUESTOSoccerMap.set('6462', 'HT_DC')
TEAPUESTOSoccerOutcomeMap.set('11162', '1x')
TEAPUESTOSoccerOutcomeMap.set('11167', '12')
TEAPUESTOSoccerOutcomeMap.set('11172', '2x')
// FT Goal/No Goal
TEAPUESTOSoccerMap.set('5802', 'FT GG/NG')
TEAPUESTOSoccerOutcomeMap.set('8457', 'gg')
TEAPUESTOSoccerOutcomeMap.set('8462', 'ng')
// HT Goal/No Goal
TEAPUESTOSoccerMap.set('6487', 'HT_GG/NG')
TEAPUESTOSoccerOutcomeMap.set('11287', 'gg')
TEAPUESTOSoccerOutcomeMap.set('11292', 'ng')
// Odd Even
TEAPUESTOSoccerMap.set('5817', 'FT_ODD/EVEN')
TEAPUESTOSoccerOutcomeMap.set('8682', 'odd')
TEAPUESTOSoccerOutcomeMap.set('8687', 'even')
TEAPUESTOSoccerMap.set('6562', 'HT_ODD/EVEN')
TEAPUESTOSoccerOutcomeMap.set('12122', 'odd')
TEAPUESTOSoccerOutcomeMap.set('12127', 'even')
// FT Over/Under
TEAPUESTOSoccerMap.set('5752', 'FT OU 0.5')
TEAPUESTOSoccerOutcomeMap.set('8317', 'u')
TEAPUESTOSoccerOutcomeMap.set('8312', 'o')
TEAPUESTOSoccerMap.set('5757', 'FT OU 1.5')
TEAPUESTOSoccerOutcomeMap.set('8327', 'u')
TEAPUESTOSoccerOutcomeMap.set('8322', 'o')
TEAPUESTOSoccerMap.set('5762', 'FT OU 2.5')
TEAPUESTOSoccerOutcomeMap.set('8337', 'u')
TEAPUESTOSoccerOutcomeMap.set('8332', 'o')
TEAPUESTOSoccerMap.set('5772', 'FT OU 3.5')
TEAPUESTOSoccerOutcomeMap.set('8357', 'u')
TEAPUESTOSoccerOutcomeMap.set('8352', 'o')
TEAPUESTOSoccerMap.set('5777', 'FT OU 4.5')
TEAPUESTOSoccerOutcomeMap.set('8362', 'u')
TEAPUESTOSoccerOutcomeMap.set('8367', 'o')
TEAPUESTOSoccerMap.set('5782', 'FT OU 5.5')
TEAPUESTOSoccerOutcomeMap.set('8377', 'u')
TEAPUESTOSoccerOutcomeMap.set('8372', 'o')
// HT Over/Under
TEAPUESTOSoccerMap.set('6272', 'HT_OU_0.5')
TEAPUESTOSoccerOutcomeMap.set('10487', 'u')
TEAPUESTOSoccerOutcomeMap.set('10482', 'o')
TEAPUESTOSoccerMap.set('6277', 'HT_OU_1.5')
TEAPUESTOSoccerOutcomeMap.set('10497', 'u')
TEAPUESTOSoccerOutcomeMap.set('10492', 'o')
TEAPUESTOSoccerMap.set('6282', 'HT_OU_2.5')
TEAPUESTOSoccerOutcomeMap.set('10507', 'u')
TEAPUESTOSoccerOutcomeMap.set('10502', 'o')
// Multi goal
TEAPUESTOSoccerMap.set('5807', 'Multi goal')
TEAPUESTOSoccerOutcomeMap.set('8472', '1-2 goals')
TEAPUESTOSoccerOutcomeMap.set('8477', '1-3 goals')
TEAPUESTOSoccerOutcomeMap.set('8482', '1-4 goals')
TEAPUESTOSoccerOutcomeMap.set('8487', '1-5 goals')
TEAPUESTOSoccerOutcomeMap.set('8492', '1-6 goals')
TEAPUESTOSoccerOutcomeMap.set('8497', '2-3 goals')
TEAPUESTOSoccerOutcomeMap.set('8502', '2-4 goals')
TEAPUESTOSoccerOutcomeMap.set('8507', '2-5 goals')
TEAPUESTOSoccerOutcomeMap.set('8512', '2-6 goals')
TEAPUESTOSoccerOutcomeMap.set('8517', '3-4 goals')
TEAPUESTOSoccerOutcomeMap.set('8522', '3-5 goals')
TEAPUESTOSoccerOutcomeMap.set('8527', '3-6 goals')
TEAPUESTOSoccerOutcomeMap.set('8532', '4-5 goals')
TEAPUESTOSoccerOutcomeMap.set('8537', '4-6 goals')
TEAPUESTOSoccerOutcomeMap.set('8542', '5-6 goals')
// Halft Time Full Time
TEAPUESTOSoccerMap.set('6202', 'HT_FT')
TEAPUESTOSoccerOutcomeMap.set('10332', '1/1')
TEAPUESTOSoccerOutcomeMap.set('10347', '1/x')
TEAPUESTOSoccerOutcomeMap.set('10362', '1/2')
TEAPUESTOSoccerOutcomeMap.set('10337', 'x/1')
TEAPUESTOSoccerOutcomeMap.set('10352', 'x/x')
TEAPUESTOSoccerOutcomeMap.set('10367', 'x/2')
TEAPUESTOSoccerOutcomeMap.set('10342', '2/1')
TEAPUESTOSoccerOutcomeMap.set('10357', '2/x')
TEAPUESTOSoccerOutcomeMap.set('10372', '2/2')
// 1X2 && Goal/No Goal
TEAPUESTOSoccerMap.set('6177', '1X2 & GG/NG')
TEAPUESTOSoccerOutcomeMap.set('10247', '1 & gg')
TEAPUESTOSoccerOutcomeMap.set('10252', '1 & ng')
TEAPUESTOSoccerOutcomeMap.set('10257', 'x & gg')
TEAPUESTOSoccerOutcomeMap.set('10262', 'x & ng')
TEAPUESTOSoccerOutcomeMap.set('10267', '2 & gg')
TEAPUESTOSoccerOutcomeMap.set('10272', '2 & ng')
// 1X2 && Over/Under
TEAPUESTOSoccerMap.set('6397', '1X2 & O/U 1.5')
TEAPUESTOSoccerOutcomeMap.set('10942', '1 & over')
TEAPUESTOSoccerOutcomeMap.set('10927', '1 & under')
TEAPUESTOSoccerOutcomeMap.set('10947', 'x & over')
TEAPUESTOSoccerOutcomeMap.set('10932', 'x & under')
TEAPUESTOSoccerOutcomeMap.set('10952', '2 & over')
TEAPUESTOSoccerOutcomeMap.set('10937', '2 & under')
TEAPUESTOSoccerMap.set('6402', '1X2 & O/U 2.5')
TEAPUESTOSoccerOutcomeMap.set('10972', '1 & over')
TEAPUESTOSoccerOutcomeMap.set('10957', '1 & under')
TEAPUESTOSoccerOutcomeMap.set('10977', 'x & over')
TEAPUESTOSoccerOutcomeMap.set('10962', 'x & under')
TEAPUESTOSoccerOutcomeMap.set('10982', '2 & over')
TEAPUESTOSoccerOutcomeMap.set('10967', '2 & under')
TEAPUESTOSoccerMap.set('6407', '1X2 & O/U 3.5')
TEAPUESTOSoccerOutcomeMap.set('11002', '1 & over')
TEAPUESTOSoccerOutcomeMap.set('10987', '1 & under')
TEAPUESTOSoccerOutcomeMap.set('11007', 'x & over')
TEAPUESTOSoccerOutcomeMap.set('10992', 'x & under')
TEAPUESTOSoccerOutcomeMap.set('11012', '2 & over')
TEAPUESTOSoccerOutcomeMap.set('10997', '2 & under')
TEAPUESTOSoccerMap.set('6412', '1X2 & O/U 4.5')
TEAPUESTOSoccerOutcomeMap.set('11032', '1 & over')
TEAPUESTOSoccerOutcomeMap.set('11017', '1 & under')
TEAPUESTOSoccerOutcomeMap.set('11037', 'x & over')
TEAPUESTOSoccerOutcomeMap.set('11022', 'x & under')
TEAPUESTOSoccerOutcomeMap.set('11042', '2 & over')
TEAPUESTOSoccerOutcomeMap.set('11027', '2 & under')
// Double Chance && Over/Under
TEAPUESTOSoccerMap.set('6597', 'DC & O/U 1.5')
TEAPUESTOSoccerOutcomeMap.set('12307', '1x & over')
TEAPUESTOSoccerOutcomeMap.set('12317', '2x & over')
TEAPUESTOSoccerOutcomeMap.set('12312', '12 & over')
TEAPUESTOSoccerOutcomeMap.set('12292', '1x & under')
TEAPUESTOSoccerOutcomeMap.set('12302', '2x & under')
TEAPUESTOSoccerOutcomeMap.set('12297', '12 & under')
TEAPUESTOSoccerMap.set('6602', 'DC & O/U 2.5')
TEAPUESTOSoccerOutcomeMap.set('12337', '1x & over')
TEAPUESTOSoccerOutcomeMap.set('12347', '2x & over')
TEAPUESTOSoccerOutcomeMap.set('12342', '12 & over')
TEAPUESTOSoccerOutcomeMap.set('12322', '1x & under')
TEAPUESTOSoccerOutcomeMap.set('12332', '2x & under')
TEAPUESTOSoccerOutcomeMap.set('12327', '12 & under')
TEAPUESTOSoccerMap.set('6607', 'DC & O/U 3.5')
TEAPUESTOSoccerOutcomeMap.set('12367', '1x & over')
TEAPUESTOSoccerOutcomeMap.set('12377', '2x & over')
TEAPUESTOSoccerOutcomeMap.set('12372', '12 & over')
TEAPUESTOSoccerOutcomeMap.set('12352', '1x & under')
TEAPUESTOSoccerOutcomeMap.set('12362', '2x & under')
TEAPUESTOSoccerOutcomeMap.set('12357', '12 & under')
TEAPUESTOSoccerMap.set('6612', 'DC & O/U 4.5')
TEAPUESTOSoccerOutcomeMap.set('12397', '1x & over')
TEAPUESTOSoccerOutcomeMap.set('12407', '2x & over')
TEAPUESTOSoccerOutcomeMap.set('12402', '12 & over')
TEAPUESTOSoccerOutcomeMap.set('12382', '1x & under')
TEAPUESTOSoccerOutcomeMap.set('12392', '2x & under')
TEAPUESTOSoccerOutcomeMap.set('12387', '12 & under')
// 1X2 Or Goal/No Goal -> chance Mix
TEAPUESTOSoccerMap.set('6102', 'Chance Mix')
TEAPUESTOSoccerOutcomeMap.set('10082', '1 or gg')
TEAPUESTOSoccerOutcomeMap.set('10087', '1 or ng')
TEAPUESTOSoccerMap.set('6112', 'Chance Mix')
TEAPUESTOSoccerOutcomeMap.set('10102', 'x or gg')
TEAPUESTOSoccerOutcomeMap.set('10107', 'x or ng')
TEAPUESTOSoccerMap.set('6112', 'Chance Mix')
TEAPUESTOSoccerOutcomeMap.set('10122', '2 or gg')
TEAPUESTOSoccerOutcomeMap.set('10127', '2 or ng')
// Home/Away - Over/Under
TEAPUESTOSoccerMap.set('7617', 'H/A O/U 0.5')
TEAPUESTOSoccerOutcomeMap.set('14487', 'over home')
TEAPUESTOSoccerOutcomeMap.set('14492', 'under home')
TEAPUESTOSoccerMap.set('6882', 'H/A O/U 0.5')
TEAPUESTOSoccerOutcomeMap.set('12862', 'over away')
TEAPUESTOSoccerOutcomeMap.set('12867', 'under away')
TEAPUESTOSoccerMap.set('6367', 'H/A O/U 1.5')
TEAPUESTOSoccerOutcomeMap.set('10782', 'over home')
TEAPUESTOSoccerOutcomeMap.set('10787', 'under home')
TEAPUESTOSoccerMap.set('6362', 'H/A O/U 1.5')
TEAPUESTOSoccerOutcomeMap.set('10772', 'over away')
TEAPUESTOSoccerOutcomeMap.set('10777', 'under away')
TEAPUESTOSoccerMap.set('6917', 'H/A O/U 2.5')
TEAPUESTOSoccerOutcomeMap.set('12932', 'over home')
TEAPUESTOSoccerOutcomeMap.set('12937', 'under home')
TEAPUESTOSoccerMap.set('7972', 'H/A O/U 2.5')
TEAPUESTOSoccerOutcomeMap.set('15732', 'over away')
TEAPUESTOSoccerOutcomeMap.set('15737', 'under away')
TEAPUESTOSoccerMap.set('8507', 'H/A O/U 3.5')
TEAPUESTOSoccerOutcomeMap.set('16442', 'over home')
TEAPUESTOSoccerOutcomeMap.set('16447', 'under home')
TEAPUESTOSoccerMap.set('8947', 'H/A O/U 3.5')
TEAPUESTOSoccerOutcomeMap.set('17562', 'over away')
TEAPUESTOSoccerOutcomeMap.set('17567', 'under away')
