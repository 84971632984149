import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { NavWrapper, NavItem } from './myBetsNav.style'
import { useSource } from '../../../../hooks/useSource'
import { FormattedMessage } from 'react-intl'
import { BRAND } from 'src/config'

export const MyBetsNav = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isActive = (path: string) => (pathname === path ? 'active' : '')
  const { isCashier } = useSource()

  return (
    <NavWrapper>
      <NavItem
        onClick={() => navigate('/mybets')}
        className={isActive('/mybets')}
      >
        <FormattedMessage id="myBetsNav_myBets" defaultMessage="My Bets" />
      </NavItem>
      {!isCashier && BRAND !== 'mozzartbet-srb' && (
        <NavItem
          onClick={() => navigate('/mybets/freebets')}
          className={isActive('/mybets/freebets')}
        >
          <FormattedMessage
            id="myBetsNav_freeBets"
            defaultMessage="Free Bets Available"
          />
        </NavItem>
      )}
    </NavWrapper>
  )
}
