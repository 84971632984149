import React, { useCallback } from 'react'
import { useAnimation } from 'framer-motion'
import { Contain, CountDownText } from './countDown.style'
import { FormattedMessage } from 'react-intl'

export const CountDown: React.FC<{
  display: string
}> = ({ display }) => {
  const cd = useAnimation()

  const countDownMotion = useCallback(async () => {
    await cd.start({
      scale: display !== 'Kick-off' ? 1.8 : 1.3,
      transition: { duration: 0.2 },
    })
    await cd.start({
      scale: 0.01,
      transition: { duration: 0.05, delay: 0.55 },
    })
  }, [cd, display])

  React.useEffect(() => {
    countDownMotion()
  }, [countDownMotion, display])
  return (
    <Contain>
      <CountDownText animate={cd}>
        {display === 'Kick-off' ? (
          <FormattedMessage id="countDown_kickoff" defaultMessage={display} />
        ) : (
          display
        )}
      </CountDownText>
    </Contain>
  )
}
