import styled from 'styled-components'

export const Wrapper = styled.div`
  color: ${({ theme }) => theme.colours.N030};
  line-height: 1.58;
  text-align: center;

  .btn {
    color: ${({ theme }) => theme.colours.N030};
    background-color: ${({ theme }) => theme.colours.S040};
    border: none;
    box-shadow: 0px 3px 0px 0px rgba(72, 72, 96, 0.5);
    width: 100%;
    height: 44px;
    border-radius: 6px;
    cursor: pointer;
  }

  .message {
    margin: 0.8rem 0 2rem 0;
  }
`
