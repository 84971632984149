import { Odo, OdoNum } from './odometer.style'

export const Odometer: React.FC<{ score: number; won: boolean | string }> = ({
  score,
  won,
}) => {
  return (
    <Odo data-testid="odometer">
      <OdoNum
        key={'odo_' + score}
        $won={!!won}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 1,
          delay: 0.1,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        {score}
      </OdoNum>
    </Odo>
  )
}
