import { Dispatch } from '@reduxjs/toolkit'
import { useCallback, useEffect } from 'react'
import TagManager from 'react-gtm-module'
import {
  validatePostMessage,
  transformPostMessage,
  PostMessage,
} from '../lib/postMessageValidator'
import { Source } from '../sports/types'
import { ErrorResponse } from '../api/types'
import { BRAND } from '../config'
import * as pm from '../mocks/postMessageMock'
import {
  bootUp,
  createError,
  updateIsCashier,
  updateLocale,
} from '../features/app/store/app.state'
import { useAppDispatch } from '../store/store'
import { mozzartbetKenyaPM } from '../mocks/post-message/mozzartbet-ke'
import { getClientVariables } from 'src/config/client-variables'
import { getEndpoint } from 'src/api/endpoints'
import { teapuestoPeruPM } from 'src/mocks/post-message/teapuesto-pe'
import { yajuegoColombiaPM } from 'src/mocks/post-message/yajuego-co'
import { bolabetZambiaPM } from 'src/mocks/post-message/bolabet-zmb'

export function useStartApp() {
  const dispatch = useAppDispatch()

  const handleMessage = useCallback(
    (messageEvent: MessageEvent) => {
      const validMessageEvent =
        messageEvent?.data &&
        typeof messageEvent.data === 'string' &&
        messageEvent.data !== 'Simulate has loaded'

      if (validMessageEvent) {
        console.log(JSON.parse(messageEvent.data))
        try {
          if (
            BRAND === 'bet9ja' ||
            BRAND === 'certification' ||
            BRAND === 'yajuego-co'
          ) {
            const transformedPM = transformPostMessage(
              JSON.parse(messageEvent.data),
            )
            const postMessage = validatePostMessage(transformedPM)
            dispatch(bootUp(postMessage))
          } else {
            const postMessage = validatePostMessage(
              JSON.parse(messageEvent.data),
            )
            if (postMessage?.locale) dispatch(updateLocale(postMessage?.locale))
            dispatch(bootUp(postMessage))
          }
        } catch (error) {
          dispatch(createError(error as ErrorResponse))
        }
      }
    },
    [dispatch],
  )

  useEffect(() => {
    parent.postMessage('Simulate has loaded', '*')

    // google tag manager
    TagManager.initialize({
      gtmId: 'GTM-P8L9JMX',
    })

    // dev if you can !!
    devEnv(dispatch)

    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [dispatch, handleMessage])
}

function devEnv(dispatch: Dispatch<any>) {
  const source = process.env.REACT_APP_SOURCE
  const { currency, minStake, maxStake, maxWin } = getClientVariables(
    source as Source,
  )
  const env = process.env.NODE_ENV
  const url = getEndpoint()

  if (env === 'development') {
    console.log(
      `%cenv: ${url} ${currency} ${minStake} ${maxStake} ${maxWin}`,
      'color: #33649c; font-size: 14px;',
    )

    console.log(
      '%c' + env + ' environment with ' + source + ' mock',
      'color: #9640c7; font-size: 14px;',
    )

    const processPM = (postMessage: PostMessage) => {
      if (postMessage?.locale) dispatch(updateLocale(postMessage?.locale))
      dispatch(bootUp(postMessage))
      console.log(
        `%c ${source} mock: `,
        'color: #339c8e; font-size: 14px;',
        postMessage,
      )
    }

    const isCashier = (postMessage: PostMessage) => {
      processPM(postMessage)
      dispatch(updateIsCashier(true))
    }

    const pmMock: { [key: string]: () => void } = {
      'new-mobile': () => processPM(transformPostMessage(pm.newMobilePM)),
      'new-desktop': () => processPM(transformPostMessage(pm.newDesktopPM)),
      'mobile-lite': () => processPM(transformPostMessage(pm.mobileLitePM)),
      'desktop': () => processPM(transformPostMessage(pm.desktopPM)),
      'old-mobile': () => processPM(transformPostMessage(pm.oldMobilePM)),
      'self-service': () => processPM(transformPostMessage(pm.selfServicePM)),
      'cashier': () => isCashier(transformPostMessage(pm.selfServicePM)),
    }

    if (BRAND === 'mozzartbet-ke') {
      return processPM(validatePostMessage(mozzartbetKenyaPM))
    }

    if (BRAND === 'mozzartbet-srb') {
      return processPM(validatePostMessage(mozzartbetKenyaPM))
    }

    if (BRAND === 'teapuesto-pe') {
      return processPM(validatePostMessage(teapuestoPeruPM))
    }

    if (BRAND === 'yajuego-co') {
      return processPM(transformPostMessage(yajuegoColombiaPM))
    }

    if (BRAND === 'mozzartbet-hrv') {
      return processPM(validatePostMessage(mozzartbetKenyaPM))
    }

    if (BRAND === 'bolabet-zmb') {
      return processPM(validatePostMessage(bolabetZambiaPM))
    }

    return source
      ? pmMock[source]()
      : console.log('%cunknown dev env: ', 'color: orange; font-size: 14px;')
  }
}
