import { useState } from 'react'
import { selectTaxValues } from '../../../betslip/store/betslip.selectors'
import { Container, Wrapper } from './winBar.style'
import { FormattedMessage } from 'react-intl'
import { Info, Remove } from 'src/components/svgs'
import PopupTaxBreakup from '../popupTaxBreakup/popupTaxBreakup'
import { useAppSelector } from 'src/store/store'
import { BRAND } from 'src/config'
import { PotentialWin } from './potentialWin'
import { getClientVariables } from 'src/config/client-variables'

export const WinBar = () => {
  const [showPopup, setShowPopup] = useState(false)
  const taxValues = useAppSelector(selectTaxValues)

  const togglePopup = () => setShowPopup(!showPopup)

  return (
    <Wrapper>
      <Container>
        <PotentialWin />
      </Container>

      {BRAND === 'mozzartbet-hrv' || BRAND === 'certification' ? (
        <Container>
          <div className="heading">
            <FormattedMessage id="winBar_tax" defaultMessage="Tax" />{' '}
            {getClientVariables().currencyCode}
            <div className="info" onClick={togglePopup}>
              <Info width="16" height="16" viewBox="0 0 16 16" />
            </div>
          </div>
          <PopupTaxBreakup show={showPopup} toggle={togglePopup}>
            <div className="header">
              <span className="header-title">
                <FormattedMessage
                  id="winBar_taxBreakdown"
                  defaultMessage="Tax Breakdown"
                />
              </span>
              <Remove
                onClick={togglePopup}
                width="16"
                height="16"
                viewBox="0 0 24 20"
              />
            </div>
            {taxValues?.tax_breakdown &&
              taxValues?.tax_breakdown.map((t) => {
                return (
                  <p className="tax-details" key={t.tax_amount + t.tax_rate}>
                    {t.tax_rate * 100}%:{' '}
                    {t.tax_amount.toLocaleString(
                      getClientVariables().countryCode,
                      {
                        style: 'currency',
                        currency: getClientVariables().currencyCode,
                        currencyDisplay: 'code',
                        maximumFractionDigits: 2,
                      },
                    )}{' '}
                  </p>
                )
              })}
            <p className="tax-details">
              <FormattedMessage
                id="winBar_taxCalculated"
                defaultMessage="Tax is calculated per win bracket"
              />
            </p>
          </PopupTaxBreakup>
          <div className="amount">{taxValues?.total_tax}</div>
        </Container>
      ) : undefined}

      {BRAND === 'mozzartbet-hrv' || BRAND === 'certification' ? (
        <Container>
          <div className="heading">
            <FormattedMessage id="winBar_payout" defaultMessage="Payout" />{' '}
            {getClientVariables().currencyCode}
          </div>
          <div className="amount">{taxValues?.payout}</div>
        </Container>
      ) : undefined}
    </Wrapper>
  )
}
