import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  selectActiveSelections,
  selectOdds,
} from '../../features/betslip/store/betslip.selectors'
import { useNavigate } from 'react-router'
import {
  BrandSimulate,
  Compas,
  Avatar,
  AvatarFilled,
  CompasFilled,
  Report,
  ReportFilled,
  SoccerYellow,
} from '../svgs'
import { NavItem, Navigation } from './navBar.style'
import { useSource } from '../../hooks/useSource'
import { selectPermissions } from '../../features/app/store/app.state'
import { BRAND } from '../../config'
import { FormattedMessage } from 'react-intl'
import theme from 'src/style/themes'

const useLinks = (isPermissionAllowed: boolean) => {
  const { pathname } = useLocation()
  const { isCashierAndSelfService } = useSource()
  const canAccessExplore =
    BRAND !== 'mozzartbet-ke' &&
    BRAND !== 'mozzartbet-srb' &&
    BRAND !== 'mozzartbet-hrv'

  const links = [
    {
      id: 'explore',
      path: '/explore',
      iconInactive: (
        <Compas
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill={theme.colours.N030}
        />
      ),
      iconActive: (
        <CompasFilled
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill={theme.colours.N030}
        />
      ),
      label: (
        <FormattedMessage id="navBar_linkExplore" defaultMessage="Explore" />
      ),
      className: 'explore',
    },
    {
      id: 'betslip',
      path: '/',
      iconActive: <BrandSimulate width="24" height="24" viewBox="0 0 24 24" />,
      label: (
        <FormattedMessage id="navBar_linkBetSlip" defaultMessage="Bet Slip" />
      ),
      className: pathname !== '/' ? 'betSlipStats' : 'betSlip',
    },
    {
      id: 'mybets',
      path: '/mybets',
      iconInactive: (
        <Avatar
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill={theme.colours.N030}
        />
      ),
      iconActive: (
        <AvatarFilled
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill={theme.colours.N030}
        />
      ),
      label: (
        <FormattedMessage id="navBar_linkMyBets" defaultMessage="My Bets" />
      ),
      className: 'mybets',
    },
    {
      id: 'report',
      path: '/report',
      iconInactive: (
        <Report
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill={theme.colours.N030}
        />
      ),
      iconActive: (
        <ReportFilled
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill={theme.colours.N030}
        />
      ),
      label: (
        <FormattedMessage id="navBar_linkReport" defaultMessage="Report" />
      ),
      className: 'report',
    },
    {
      id: 'tournament',
      path: '/tournament',
      iconInactive: <SoccerYellow width="20" height="20" viewBox="1 0 20 20" />,
      iconActive: <SoccerYellow width="20" height="20" viewBox="1 0 20 20" />,
      label: (
        <FormattedMessage
          id="navBar_linkTournament"
          defaultMessage="Eng Premier"
        />
      ),
      className: 'tournament',
    },
  ].filter((link) => {
    if (link.id === 'explore') return isPermissionAllowed

    if (link.id === 'report') return isCashierAndSelfService

    if (link.id === 'explore' || link.id === 'tournament')
      return canAccessExplore

    return true
  })

  return links
}

export const NavBar = () => {
  const [activeItemId, setActiveId] = useState('')
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const activeSelections = useSelector(selectActiveSelections)
  const odds = useSelector(selectOdds)

  const permissions = useSelector(selectPermissions)
  const links = useLinks(permissions.explore)
  const isTournament = permissions?.tournament_explore
  const getClassNames = (path: string) => {
    let classNames = ''

    if (pathname === path) {
      classNames += ' active'
    }
    return classNames
  }

  const selectActive = (id: string, path: string) => {
    navigate(path)
    setActiveId(id)
  }

  useEffect(() => {
    switch (pathname) {
      case '/':
        return setActiveId('betslip')
      case '/explore':
        return setActiveId('explore')
      case '/mybets':
      case '/mybets/freebets':
        return setActiveId('mybets')
      case '/report':
        return setActiveId('report')
      case '/tournament':
        return setActiveId('tournament')
    }
  }, [pathname])

  function renderNavItemContent({
    label,
    iconActive,
    iconInactive,
    className,
    path,
  }: any) {
    const isBetSlipStats = className === 'betSlipStats'
    const isTournamentBtn = className === 'tournament'

    if (isTournamentBtn && !isTournament) {
      return
    }

    return (
      <div className={`wrapper ${className}`}>
        {isBetSlipStats && (
          <>
            <span className="odds">{odds.toFixed(2)}</span>
            <span className="sel">{activeSelections}</span>
          </>
        )}

        {isTournamentBtn && isTournament ? (
          <div className="tournament-box">
            {!isBetSlipStats && pathname === path ? iconActive : iconInactive}

            <span className="title">{label}</span>
          </div>
        ) : (
          <>
            {!isBetSlipStats && pathname === path ? iconActive : iconInactive}

            <span className="title">{label}</span>
          </>
        )}
      </div>
    )
  }

  return (
    <div>
      <Navigation count={links.length}>
        {links.map((link) => (
          <NavItem
            id="item"
            $isActive={link.id === activeItemId}
            key={link.id}
            onClick={() => selectActive(link.id, link.path)}
            className={getClassNames(link.path)}
            data-testid="navItem"
            $isTournament={isTournament}
          >
            {renderNavItemContent(link)}
          </NavItem>
        ))}
      </Navigation>
    </div>
  )
}
