import { useSelector } from 'react-redux'
import { filterReports, selectReportsRange } from '../../store/reports.state'
import {
  FilterWrapper,
  FilterContainer,
  SelectorUnderlay,
  Selector,
} from './reportFilter.style'
import { Range } from '../../store/reports.state'
import { useAppDispatch } from '../../../../store/store'
import { FormattedMessage } from 'react-intl'

export const ReportsFilter = () => {
  const dispatch = useAppDispatch()
  const range = useSelector(selectReportsRange)

  return (
    <FilterWrapper>
      <FilterContainer>
        <SelectorUnderlay range={range} />
        <Selector
          onClick={() => dispatch(filterReports(Range.LASTSEVENDAYS))}
          selected={range === Range.LASTSEVENDAYS}
        >
          <FormattedMessage
            id="reportsFilter_thisWeek"
            defaultMessage="This Week"
          />
        </Selector>
        <Selector
          onClick={() => dispatch(filterReports(Range.YESTERDAY))}
          selected={range === Range.YESTERDAY}
        >
          <FormattedMessage
            id="reportsFilter_yesterday"
            defaultMessage="Yesterday"
          />
        </Selector>
        <Selector
          onClick={() => dispatch(filterReports(Range.TODAY))}
          selected={range === Range.TODAY}
        >
          <FormattedMessage id="reportsFilter_today" defaultMessage="Today" />
        </Selector>
      </FilterContainer>
    </FilterWrapper>
  )
}
