import { useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { BetSlipPage } from './features/betslip/pages/betslipPage'
import { ExplorePage } from './features/explore/pages/explorePage'
import { GameLoader } from './features/game/components/game-loader/gameLoader'
import { GameLayout } from './features/game/pages/gamePage'
import { MyBetsPage } from './features/my-games/pages/myBetsPage'
import { useSource } from './hooks/useSource'
import { useStartApp } from './hooks/useStartApp'
import {
  selectLocale,
  selectPermissions,
  selectView,
} from './features/app/store/app.state'
import { FreeBetTicket } from './features/betslip/components/free-betTicket/freeBetTicket'
import { ReportPage } from './features/reports/pages/reportPage'
import { Receipt } from './features/receipt/components/receipt/receipt'
import { Loader } from './features/betslip/components/loader/loader'
import { NavBarWrapper } from './components/navBarWrapper/navBarWrapper'
import { FreeBetList } from './features/my-games/components/freeBetList/freeBetList'
import { MyBets } from './features/my-games/components/myBets/myBets'
import { DesktopSource } from './sports/types'
import { TournamentPage } from './features/tournament/pages/tournamentPage'
import { IntlProvider } from 'react-intl'
import { translations } from './lang'

const desktop: DesktopSource[] = ['desktop', 'self-service', 'new-desktop']

export default function App() {
  useStartApp()
  const permissions = useSelector(selectPermissions)
  const view = useSelector(selectView)
  const { isCashierAndSelfService, isCashier, source } = useSource()
  const isGameView = view === 'game'
  const isDesktop = desktop.includes(source as DesktopSource)
  const locale = useSelector(selectLocale)

  return (
    <IntlProvider
      messages={translations[locale]}
      locale={locale}
      defaultLocale="en"
    >
      <BrowserRouter>
        {isDesktop && !isGameView && (
          <NavBarWrapper
            isDesktop={isDesktop}
            isGameView={isGameView}
            isCashier={isCashier}
          />
        )}
        <Routes>
          <Route
            path="/"
            element={
              <>
                {isGameView ? <GameLayout /> : <BetSlipPage />}
                <FreeBetTicket />
                {isCashier ? <Loader /> : undefined}
                {isCashier ? <Receipt /> : undefined}
              </>
            }
          />

          {permissions?.explore === true ? (
            <Route path="explore" element={<ExplorePage />} />
          ) : undefined}

          <Route path="mybets" element={<MyBetsPage />}>
            <Route index element={<MyBets />} />
            <Route
              path="freebets"
              element={!isCashier ? <FreeBetList /> : undefined}
            />
          </Route>

          {isCashierAndSelfService && (
            <Route path="report" element={<ReportPage />} />
          )}
          <Route path="tournament" element={<TournamentPage />} />
        </Routes>

        {!isDesktop && !isGameView && (
          <NavBarWrapper
            isDesktop={isDesktop}
            isGameView={isGameView}
            isCashier={isCashier}
          />
        )}
        <GameLoader />
      </BrowserRouter>
    </IntlProvider>
  )
}
