import { useDispatch, useSelector } from 'react-redux'
import {
  selectCanPrint,
  selectIsCashier,
  toggleCanPrint,
} from '../../features/app/store/app.state'
import Toggle from '../toggle/toggle'

const TogglePrintButton = () => {
  const dispatch = useDispatch()
  const isCashier = useSelector(selectIsCashier)
  const canPrint = useSelector(selectCanPrint)

  const handleCanPrint = () => dispatch(toggleCanPrint())

  if (isCashier) {
    return (
      <>
        <Toggle
          onChange={handleCanPrint}
          isOn={canPrint}
          id="simulate-printBet"
        />
      </>
    )
  }

  return <></>
}

export default TogglePrintButton
