import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  /* width: 80px; */
  max-width: 100%;
  height: 24px;
  padding: 2px 0;
  margin: 7px;
  background-color: ${({ theme }) => theme.colours.S090};
  color: ${({ theme }) => theme.colours.AP050};
  border-radius: 4px;
  overflow: hidden;
`

export const TextDisplay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${(props) => props.theme.fonts[1]};
  font-size: 10px;
  width: 100%;
  padding: 0 16px;
  height: 24px;
  color: ${({ theme }) => theme.colours.AP050};
  background-color: ${({ theme }) => theme.colours.ST100};
`
