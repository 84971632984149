import * as Sim from '../../markets'
import * as BOLABET from './types'

export const BOLABETSoccerMap = new Map<BOLABET.Markets, Sim.Market>()
export const BOLABETSoccerOutcomeMap = new Map<BOLABET.Outcomes, Sim.Outcome>()

export const findBolabetSoccerOutcome = (
  _b9jMkt: BOLABET.Markets,
  outcome: BOLABET.Outcomes,
): Sim.Outcome => {
  return BOLABETSoccerOutcomeMap.get(outcome) || ('' as Sim.Outcome)
}

// FT 1x2 Three Way
BOLABETSoccerMap.set('2', 'FT 1X2')
BOLABETSoccerOutcomeMap.set('2', '1')
BOLABETSoccerOutcomeMap.set('3', 'x')
BOLABETSoccerOutcomeMap.set('4', '2')
// HT 1x2 Three Way
BOLABETSoccerMap.set('75', 'HT 1X2')
BOLABETSoccerOutcomeMap.set('217', '1')
BOLABETSoccerOutcomeMap.set('218', 'x')
BOLABETSoccerOutcomeMap.set('219', '2')
// Correct Score
BOLABETSoccerMap.set('50', 'Correct Score')
BOLABETSoccerOutcomeMap.set('119', '1-0')
BOLABETSoccerOutcomeMap.set('120', '2-0')
BOLABETSoccerOutcomeMap.set('121', '2-1')
BOLABETSoccerOutcomeMap.set('122', '3-0')
BOLABETSoccerOutcomeMap.set('123', '3-1')
BOLABETSoccerOutcomeMap.set('124', '3-2')
BOLABETSoccerOutcomeMap.set('125', '4-0')
BOLABETSoccerOutcomeMap.set('126', '4-1')
BOLABETSoccerOutcomeMap.set('127', '4-2')
BOLABETSoccerOutcomeMap.set('142', '4-3')
BOLABETSoccerOutcomeMap.set('128', '0-0')
BOLABETSoccerOutcomeMap.set('129', '1-1')
BOLABETSoccerOutcomeMap.set('130', '2-2')
BOLABETSoccerOutcomeMap.set('131', '3-3')
BOLABETSoccerOutcomeMap.set('143', '4-4')
BOLABETSoccerOutcomeMap.set('132', '0-1')
BOLABETSoccerOutcomeMap.set('133', '0-2')
BOLABETSoccerOutcomeMap.set('135', '0-3')
BOLABETSoccerOutcomeMap.set('138', '0-4')
BOLABETSoccerOutcomeMap.set('134', '1-2')
BOLABETSoccerOutcomeMap.set('136', '1-3')
BOLABETSoccerOutcomeMap.set('139', '1-4')
BOLABETSoccerOutcomeMap.set('137', '2-3')
BOLABETSoccerOutcomeMap.set('140', '2-4')
BOLABETSoccerOutcomeMap.set('141', '3-4')
BOLABETSoccerOutcomeMap.set('144', 'other')

// FT Double Chance
BOLABETSoccerMap.set('3', 'FT DC')
BOLABETSoccerOutcomeMap.set('7', '1x')
BOLABETSoccerOutcomeMap.set('5', '12')
BOLABETSoccerOutcomeMap.set('6', '2x')
// HT Double Chance
BOLABETSoccerMap.set('83', 'HT_DC')
BOLABETSoccerOutcomeMap.set('290', '1x')
BOLABETSoccerOutcomeMap.set('291', '12')
BOLABETSoccerOutcomeMap.set('292', '2x')

// Odd Even
BOLABETSoccerMap.set('9', 'FT_ODD/EVEN')
BOLABETSoccerOutcomeMap.set('18', 'odd')
BOLABETSoccerOutcomeMap.set('19', 'even')
BOLABETSoccerMap.set('313', 'HT_ODD/EVEN')
BOLABETSoccerOutcomeMap.set('851', 'odd')
BOLABETSoccerOutcomeMap.set('853', 'even')
// FT Over/Under
BOLABETSoccerMap.set('198', 'FT OU 0.5')
BOLABETSoccerOutcomeMap.set('574', 'u')
BOLABETSoccerOutcomeMap.set('571', 'o')
BOLABETSoccerMap.set('39', 'FT OU 1.5')
BOLABETSoccerOutcomeMap.set('86', 'u')
BOLABETSoccerOutcomeMap.set('85', 'o')
BOLABETSoccerMap.set('40', 'FT OU 2.5')
BOLABETSoccerOutcomeMap.set('88', 'u')
BOLABETSoccerOutcomeMap.set('87', 'o')
BOLABETSoccerMap.set('41', 'FT OU 3.5')
BOLABETSoccerOutcomeMap.set('90', 'u')
BOLABETSoccerOutcomeMap.set('89', 'o')
BOLABETSoccerMap.set('100', 'FT OU 4.5')
BOLABETSoccerOutcomeMap.set('336', 'u')
BOLABETSoccerOutcomeMap.set('335', 'o')
BOLABETSoccerMap.set('139', 'FT OU 5.5')
BOLABETSoccerOutcomeMap.set('416', 'u')
BOLABETSoccerOutcomeMap.set('415', 'o')
BOLABETSoccerMap.set('195', 'FT OU 6.5')
BOLABETSoccerOutcomeMap.set('565', 'u')
BOLABETSoccerOutcomeMap.set('563', 'o')
BOLABETSoccerMap.set('301', 'FT OU 7.5')
BOLABETSoccerOutcomeMap.set('818', 'u')
BOLABETSoccerOutcomeMap.set('816', 'o')
BOLABETSoccerMap.set('1081', 'FT OU 8.5')
BOLABETSoccerOutcomeMap.set('2728', 'u')
BOLABETSoccerOutcomeMap.set('2727', 'o')
// HT Over/Under
BOLABETSoccerMap.set('92', 'HT_OU_0.5')
BOLABETSoccerOutcomeMap.set('310', 'u')
BOLABETSoccerOutcomeMap.set('309', 'o')
BOLABETSoccerMap.set('93', 'HT_OU_1.5')
BOLABETSoccerOutcomeMap.set('312', 'u')
BOLABETSoccerOutcomeMap.set('311', 'o')
BOLABETSoccerMap.set('274', 'HT_OU_2.5')
BOLABETSoccerOutcomeMap.set('762', 'u')
BOLABETSoccerOutcomeMap.set('758', 'o')

// Halft Time Full Time
BOLABETSoccerMap.set('81', 'HT_FT')
BOLABETSoccerOutcomeMap.set('279', '1/1')
BOLABETSoccerOutcomeMap.set('282', '1/x')
BOLABETSoccerOutcomeMap.set('285', '1/2')
BOLABETSoccerOutcomeMap.set('280', 'x/1')
BOLABETSoccerOutcomeMap.set('283', 'x/x')
BOLABETSoccerOutcomeMap.set('286', 'x/2')
BOLABETSoccerOutcomeMap.set('281', '2/1')
BOLABETSoccerOutcomeMap.set('284', '2/x')
BOLABETSoccerOutcomeMap.set('287', '2/2')
// 1X2 && Goal/No Goal
BOLABETSoccerMap.set('60', '1X2 & GG/NG')
BOLABETSoccerOutcomeMap.set('165', '1 & gg')
BOLABETSoccerOutcomeMap.set('166', '1 & ng')
BOLABETSoccerOutcomeMap.set('167', 'x & gg')
BOLABETSoccerOutcomeMap.set('168', 'x & ng')
BOLABETSoccerOutcomeMap.set('169', '2 & gg')
BOLABETSoccerOutcomeMap.set('170', '2 & ng')
// 1X2 && Over/Under
BOLABETSoccerMap.set('46', '1X2 & O/U 1.5')
BOLABETSoccerOutcomeMap.set('102', '1 & over')
BOLABETSoccerOutcomeMap.set('99', '1 & under')
BOLABETSoccerOutcomeMap.set('103', 'x & over')
BOLABETSoccerOutcomeMap.set('100', 'x & under')
BOLABETSoccerOutcomeMap.set('104', '2 & over')
BOLABETSoccerOutcomeMap.set('101', '2 & under')
BOLABETSoccerMap.set('47', '1X2 & O/U 2.5')
BOLABETSoccerOutcomeMap.set('108', '1 & over')
BOLABETSoccerOutcomeMap.set('105', '1 & under')
BOLABETSoccerOutcomeMap.set('109', 'x & over')
BOLABETSoccerOutcomeMap.set('106', 'x & under')
BOLABETSoccerOutcomeMap.set('110', '2 & over')
BOLABETSoccerOutcomeMap.set('107', '2 & under')
BOLABETSoccerMap.set('48', '1X2 & O/U 3.5')
BOLABETSoccerOutcomeMap.set('114', '1 & over')
BOLABETSoccerOutcomeMap.set('111', '1 & under')
BOLABETSoccerOutcomeMap.set('115', 'x & over')
BOLABETSoccerOutcomeMap.set('112', 'x & under')
BOLABETSoccerOutcomeMap.set('116', '2 & over')
BOLABETSoccerOutcomeMap.set('113', '2 & under')
BOLABETSoccerMap.set('141', '1X2 & O/U 4.5')
BOLABETSoccerOutcomeMap.set('430', '1 & over')
BOLABETSoccerOutcomeMap.set('419', '1 & under')
BOLABETSoccerOutcomeMap.set('435', 'x & over')
BOLABETSoccerOutcomeMap.set('421', 'x & under')
BOLABETSoccerOutcomeMap.set('445', '2 & over')
BOLABETSoccerOutcomeMap.set('426', '2 & under')
