import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 8px;
  background-color: ${({ theme }) => theme.colours.S050};
  border-radius: 6px;
`
export const Market = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  min-width: 84px;
  text-align: center;
  padding: 4px;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
  background-image: ${({ theme }) => theme.colours.S090G};
  color: ${({ theme }) => theme.colours.N040};
  border-radius: 6px;
`

export const SelectionWithMarketWrapper = styled.div<{ $isActive: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 33.3333333%;
  gap: 2px;
  margin-top: 12px;
  padding: 18px 8px 8px;
  background-color: ${({ $isActive, theme }) =>
    $isActive ? `${theme.colours.AP050}` : `${theme.colours.S070}`};
  border-radius: 6px;
`
export const IconFlag = styled.div<{ $isActive: boolean }>`
  position: absolute;
  top: -10px;
  transform: ${({ $isActive }) => ($isActive ? 'scale(1.3)' : '')};
  transition: all 0.1s;
`

export const Odds = styled.div<{ $isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${({ $isActive, theme }) =>
    $isActive ? `${theme.colours.ST100}` : `${theme.colours.S050O}`};
  color: ${({ theme }) => theme.colours.AP050};
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  padding: 4px;
  border-radius: 6px;
`

export const H6 = styled.h6<{ isActive?: boolean }>`
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colours.N030};
  margin: auto;
`
export const EventName = styled(H6)<{ $isActive: boolean }>`
  color: ${({ $isActive, theme }) =>
    $isActive ? `${theme.colours.ST100}` : `${theme.colours.N030}`};
`

export const TeamContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colours.S070};
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 6px;
  width: 100%;
  justify-content: flex-end;

  &:last-of-type {
    justify-content: flex-start;
  }
`

export const OddsContainer = styled.div<{ $isActive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${({ $isActive, theme }) =>
    $isActive ? `${theme.colours.AP050}` : `${theme.colours.S050O}`};
  padding: 4px;
  color: ${({ $isActive, theme }) =>
    $isActive ? `${theme.colours.ST100}` : `${theme.colours.AP050}`};
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  border-radius: 6px;

  .market {
    color: ${({ $isActive, theme }) =>
      $isActive ? `${theme.colours.ST100}` : `${theme.colours.N030}`};
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
`

export const SelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 8px;
  background-color: ${({ theme }) => theme.colours.S050};
  border-radius: 6px;
`

export const TitleWrapper = styled.div`
  display: flex;
  gap: 8px;
`

export const SelectionContainer = styled.div<{ length: number }>`
  display: grid;
  gap: 8px;
  grid-template-columns: ${({ length }) =>
    length === 3 ? '1fr 1fr 1fr' : '1fr 1fr'};
`
