import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
type Props = {
  code: number
  values: any
}

export const ErrorMessage = ({ code, values }: Props) => {
  const errors: { [n: number]: ReactNode } = {
    401: <FormattedMessage id="error401" defaultMessage="Session expired" />,
    403: <FormattedMessage id="error403" defaultMessage="Unsuccessful login" />,
    404: <FormattedMessage id="error404" defaultMessage="Page Not found" />,
    408: <FormattedMessage id="error408" defaultMessage="Request Timeout" />,
    422: <FormattedMessage id="error422" defaultMessage="Invalid Betslip" />,
    424: (
      <FormattedMessage
        id="error424"
        defaultMessage="Your selections have changed"
      />
    ),
    425: (
      <FormattedMessage
        id="error425"
        defaultMessage="FreeBet is not valid or has expired"
      />
    ),

    1000: (
      <FormattedMessage
        id="error1000"
        defaultMessage="No selection to Simulate"
      />
    ),

    1001: (
      <FormattedMessage id="errorPM" defaultMessage="Invalid post message" />
    ),

    1002: (
      <FormattedMessage
        id="errorSelectionNum"
        defaultMessage="The maximum number of selection is 40"
      />
    ),

    1003: (
      <FormattedMessage id="errorNoSelection" defaultMessage="No Selections" />
    ),
    1004: (
      <FormattedMessage
        id="errorMaxStake"
        values={{ ...values }}
        defaultMessage="Maximum Stake is {value1} {value2}"
      />
    ),
    1005: (
      <FormattedMessage
        id="errorMinStake"
        values={{ ...values }}
        defaultMessage="Minimum Stake is {value1} {value2}"
      />
    ),
    1006: (
      <FormattedMessage
        id="errorMaxWin"
        values={{ ...values }}
        defaultMessage="Winnings of over {value1} {value2} exceed the maximum"
      />
    ),
    1100: (
      <FormattedMessage
        id="errorAccountStatus_2"
        defaultMessage="Winnings not paid"
      />
    ),
    1101: (
      <FormattedMessage
        id="errorAccountStatus_1"
        defaultMessage="Not enough funds"
      />
    ),
    1102: (
      <FormattedMessage
        id="errorAccountStatus0"
        defaultMessage="No money paid"
      />
    ),
    1103: (
      <FormattedMessage id="errorAccountStatus1" defaultMessage="Bet paid" />
    ),
    1104: (
      <FormattedMessage id="errorAccountStatus2" defaultMessage="Win paid" />
    ),
    1105: (
      <FormattedMessage
        id="errorBetStatus_10"
        defaultMessage="Bet Not placed"
      />
    ),
    1106: <FormattedMessage id="errorBetStatus_1" defaultMessage="Lost" />,
    1107: <FormattedMessage id="errorBetStatus0" defaultMessage="Running" />,
    1108: <FormattedMessage id="errorBetStatus1" defaultMessage="Won" />,
    1150: (
      <FormattedMessage
        id="errorAccountStatus__5"
        defaultMessage="Responsible gambling limits reached"
      />
    ),

    500: (
      <FormattedMessage
        id="error500"
        defaultMessage="Oops, a problem occured..."
      />
    ),
  }

  return errors[code] || errors[500]
}
