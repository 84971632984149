import React from 'react'
import App from './App'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { GlobalStyle } from './style/globalStyle'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { createRoot } from 'react-dom/client'
import theme from './style/themes'

async function enableMocking() {
  if (process.env.NODE_ENV !== 'development') {
    return
  }

  const { worker } = await import('./mocks/browser')

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start()
}

const persistor = persistStore(store)

const container = document.getElementById('root')
const root = createRoot(container!)

enableMocking().then(() => {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <App />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>,
  )
})
