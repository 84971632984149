import { Clock } from '../../../../components/svgs'
import { EventHeaderWrapper } from '../../pages/gamePage.style'
import { useTimeline } from '../../../../hooks/useTimeline'
import { SimSports } from '../../../../config'
import { useSelector } from 'react-redux'
import { selectSpeed } from '../../../app/store/app.state'
import { ProgressBar } from '../progress-bar/progressBar'
import { FormattedMessage } from 'react-intl'

type EventHeaderProps = {
  icon: JSX.Element
  sport: SimSports
}

export const EventHeader: React.FC<EventHeaderProps> = ({ icon, sport }) => {
  const { timeline } = useTimeline(sport)
  const speed = useSelector(selectSpeed)
  const progress =
    sport === 'Basketball' ? timeline?.clock * 1.875 || 0 : timeline?.clock || 0

  const [n, segment] = (timeline?.segment ?? '').split(' ')

  return (
    <>
      <EventHeaderWrapper>
        <div className="box">
          <div>{icon}</div>
          <div className="eventName">
            <FormattedMessage
              id={`eventHeader_${sport}`}
              defaultMessage={sport}
            />
          </div>
        </div>
        <div className="box">
          <div className="time">
            {n && segment && (
              <>
                <FormattedMessage
                  id={`eventHeader_${n as '1st' | '2nd' | '3rd' | '4th'}`}
                  defaultMessage={n}
                />
                <FormattedMessage
                  id={`eventHeader_${segment as 'Quarter' | 'Half'}`}
                  defaultMessage={segment}
                />
              </>
            )}
          </div>
          <span className="bold">{timeline?.clock}</span>
          <div className="icon-clock">
            <Clock width="16" height="16" viewBox="0 -2 24 24" />
          </div>
        </div>
      </EventHeaderWrapper>
      <ProgressBar progress={progress} speed={speed} />
    </>
  )
}
