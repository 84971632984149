import styled from 'styled-components'

export const ProgressBarContainer = styled.div<{
  progress: number
  speed: number
}>`
  display: flex;
  width: 100%;
  height: 5px;
  background-color: ${(props) => props.theme.colours.S090O};
  box-shadow:
    inset 0px 1px 0px rgba(0, 0, 0, 0.1987),
    inset 0px -1px 0px rgba(255, 255, 255, 0.290428);

  margin-bottom: 1px;

  svg {
    width: 100%;
    line {
      stroke-width: 10px;
      transition: ${({ progress, speed }) => {
        return progress === 0 ? 'none' : `all ${speed}ms ease-out`
      }};
    }
  }
`
