import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 320px;
  max-width: 1024px;
  width: 100%;
  margin-bottom: 8px;
  /* overflow-x: scroll; */
`

export const Cartouche = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.ul<{ ref: any }>`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  list-style-type: none;
  overflow-x: auto;
  margin: 0 auto;
  padding: 9px 0;
  /* filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.4)); */

  a {
    text-decoration: none;
  }
`
export const Item = styled.li<{ selected: boolean; visible?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  flex-direction: column;
  padding: 0px 12px;
  font-size: 12px;
  color: ${({ theme }) => theme.colours.N030};
  border-radius: 4px;
  line-height: 16px;

  .market {
    color: ${({ theme }) => theme.colours.N030};
    text-align: center;
  }

  .scrollMarket {
    font-size: 12px;
    color: ${({ theme }) => theme.colours.N030};
  }

  &:hover {
    cursor: pointer;
  }

  path {
    fill: ${({ theme }) => theme.colours.N030};
  }

  ${({ selected }) =>
    selected &&
    // visible &&
    css`
      background-color: transparent;
      &:hover {
        position: relative;
        cursor: pointer;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        width: 100%;
        margin: auto;
        background: ${({ theme }) => theme.colours.AP050};
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        margin-bottom: -8px;
      }
    `}
`
