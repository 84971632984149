import { SimMarket, Score, TimeSpan, OneXTwoTG } from './types'

export const logic1X2TG = (outcome: OneXTwoTG, score: Score) => {
  const oc: { [key in OneXTwoTG]: () => boolean } = {
    '1 & 2-6': () =>
      score[0] > score[1] && score[0] + score[1] > 2 && score[0] + score[1] < 6,
    '2 & 2-6': () =>
      score[1] > score[0] && score[0] + score[1] > 2 && score[0] + score[1] < 6,
  }
  return oc[outcome]() ?? false
}

const oc1X2TG = ['1 & 2-6', '2 & 2-6'] as OneXTwoTG[]

const _TGOU = (id: number, time: TimeSpan, handicap = 0): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: oc1X2TG,
  logic: logic1X2TG,
})

export const FT_1X2TG_26 = _TGOU(54, 'FT')
