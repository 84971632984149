import * as Sim from '.'

export const simulateBasketballMarkets = new Map<Sim.Market, Sim.SimMarket>()
simulateBasketballMarkets.set('2 Way', Sim.FT_TWO_WAY)
simulateBasketballMarkets.set('3 Way', Sim.FT_THREE_WAY)

simulateBasketballMarkets.set('1X2 1st Quarter', Sim.FIRST_QUARTER_1X2)

simulateBasketballMarkets.set('1X2 1st Half', Sim.FIRST_HALF_1X2)

simulateBasketballMarkets.set('1X2 (5.5 points)', Sim.FT_1X2_HNDN_55)

simulateBasketballMarkets.set('HomeAway', Sim.WINHA)

simulateBasketballMarkets.set('HT_FT', Sim.HT_FT)

simulateBasketballMarkets.set('Handicap -35.5', Sim.FT_HND_0355)
simulateBasketballMarkets.set('Handicap -34.5', Sim.FT_HND_0345)
simulateBasketballMarkets.set('Handicap -33.5', Sim.FT_HND_0335)
simulateBasketballMarkets.set('Handicap -32.5', Sim.FT_HND_0325)
simulateBasketballMarkets.set('Handicap -31.5', Sim.FT_HND_0315)
simulateBasketballMarkets.set('Handicap -30.5', Sim.FT_HND_0305)
simulateBasketballMarkets.set('Handicap -29.5', Sim.FT_HND_0295)
simulateBasketballMarkets.set('Handicap -28.5', Sim.FT_HND_0285)
simulateBasketballMarkets.set('Handicap -27.5', Sim.FT_HND_0275)
simulateBasketballMarkets.set('Handicap -26.5', Sim.FT_HND_0265)
simulateBasketballMarkets.set('Handicap -25.5', Sim.FT_HND_0255)
simulateBasketballMarkets.set('Handicap -24.5', Sim.FT_HND_0245)
simulateBasketballMarkets.set('Handicap -23.5', Sim.FT_HND_0235)
simulateBasketballMarkets.set('Handicap -22.5', Sim.FT_HND_0225)
simulateBasketballMarkets.set('Handicap -21.5', Sim.FT_HND_0215)
simulateBasketballMarkets.set('Handicap -20.5', Sim.FT_HND_0205)
simulateBasketballMarkets.set('Handicap -19.5', Sim.FT_HND_0195)
simulateBasketballMarkets.set('Handicap -18.5', Sim.FT_HND_0185)
simulateBasketballMarkets.set('Handicap -17.5', Sim.FT_HND_0175)
simulateBasketballMarkets.set('Handicap -16.5', Sim.FT_HND_0165)
simulateBasketballMarkets.set('Handicap -15.5', Sim.FT_HND_0155)
simulateBasketballMarkets.set('Handicap -14.5', Sim.FT_HND_0145)
simulateBasketballMarkets.set('Handicap -13.5', Sim.FT_HND_0135)
simulateBasketballMarkets.set('Handicap -12.5', Sim.FT_HND_0125)
simulateBasketballMarkets.set('Handicap -11.5', Sim.FT_HND_0115)
simulateBasketballMarkets.set('Handicap -10.5', Sim.FT_HND_0105)
simulateBasketballMarkets.set('Handicap -9.5', Sim.FT_HND_095)
simulateBasketballMarkets.set('Handicap -8.5', Sim.FT_HND_085)
simulateBasketballMarkets.set('Handicap -7.5', Sim.FT_HND_075)
simulateBasketballMarkets.set('Handicap -6.5', Sim.FT_HND_065)
simulateBasketballMarkets.set('Handicap -5.5', Sim.FT_HND_055)
simulateBasketballMarkets.set('Handicap -4.5', Sim.FT_HND_045)
simulateBasketballMarkets.set('Handicap -3.5', Sim.FT_HND_035)
simulateBasketballMarkets.set('Handicap -2.5', Sim.FT_HND_025)
simulateBasketballMarkets.set('Handicap -1.5', Sim.FT_HND_015)
simulateBasketballMarkets.set('Handicap -0.5', Sim.FT_HND_005)
simulateBasketballMarkets.set('Handicap 0.5', Sim.FT_HND_105)
simulateBasketballMarkets.set('Handicap 1.5', Sim.FT_HND_115)
simulateBasketballMarkets.set('Handicap 2.5', Sim.FT_HND_125)
simulateBasketballMarkets.set('Handicap 3.5', Sim.FT_HND_135)
simulateBasketballMarkets.set('Handicap 4.5', Sim.FT_HND_145)
simulateBasketballMarkets.set('Handicap 5.5', Sim.FT_HND_155)
simulateBasketballMarkets.set('Handicap 6.5', Sim.FT_HND_165)
simulateBasketballMarkets.set('Handicap 7.5', Sim.FT_HND_175)
simulateBasketballMarkets.set('Handicap 8.5', Sim.FT_HND_185)
simulateBasketballMarkets.set('Handicap 9.5', Sim.FT_HND_195)
simulateBasketballMarkets.set('Handicap 10.5', Sim.FT_HND_1105)
simulateBasketballMarkets.set('Handicap 11.5', Sim.FT_HND_1115)
simulateBasketballMarkets.set('Handicap 12.5', Sim.FT_HND_1125)
simulateBasketballMarkets.set('Handicap 13.5', Sim.FT_HND_1135)
simulateBasketballMarkets.set('Handicap 14.5', Sim.FT_HND_1145)
simulateBasketballMarkets.set('Handicap 15.5', Sim.FT_HND_1155)
simulateBasketballMarkets.set('Handicap 16.5', Sim.FT_HND_1165)
simulateBasketballMarkets.set('Handicap 17.5', Sim.FT_HND_1175)
simulateBasketballMarkets.set('Handicap 18.5', Sim.FT_HND_1185)
simulateBasketballMarkets.set('Handicap 19.5', Sim.FT_HND_1195)
simulateBasketballMarkets.set('Handicap 20.5', Sim.FT_HND_1205)
simulateBasketballMarkets.set('Handicap 21.5', Sim.FT_HND_1215)
simulateBasketballMarkets.set('Handicap 22.5', Sim.FT_HND_1225)
simulateBasketballMarkets.set('Handicap 23.5', Sim.FT_HND_1235)
simulateBasketballMarkets.set('Handicap 24.5', Sim.FT_HND_1245)
simulateBasketballMarkets.set('Handicap 25.5', Sim.FT_HND_1255)
simulateBasketballMarkets.set('Handicap 26.5', Sim.FT_HND_1265)
simulateBasketballMarkets.set('Handicap 27.5', Sim.FT_HND_1275)
simulateBasketballMarkets.set('Handicap 28.5', Sim.FT_HND_1285)
simulateBasketballMarkets.set('Handicap 29.5', Sim.FT_HND_1295)
simulateBasketballMarkets.set('Handicap 30.5', Sim.FT_HND_1305)
simulateBasketballMarkets.set('Handicap 31.5', Sim.FT_HND_1315)
simulateBasketballMarkets.set('Handicap 32.5', Sim.FT_HND_1325)
simulateBasketballMarkets.set('Handicap 33.5', Sim.FT_HND_1335)
simulateBasketballMarkets.set('Handicap 34.5', Sim.FT_HND_1345)
simulateBasketballMarkets.set('Handicap 35.5', Sim.FT_HND_1355)

simulateBasketballMarkets.set('O/U 110.5', Sim.FT_OU_1105)
simulateBasketballMarkets.set('O/U 111.5', Sim.FT_OU_1115)
simulateBasketballMarkets.set('O/U 112.5', Sim.FT_OU_1125)
simulateBasketballMarkets.set('O/U 113.5', Sim.FT_OU_1135)
simulateBasketballMarkets.set('O/U 114.5', Sim.FT_OU_1145)
simulateBasketballMarkets.set('O/U 115.5', Sim.FT_OU_1155)
simulateBasketballMarkets.set('O/U 116.5', Sim.FT_OU_1165)
simulateBasketballMarkets.set('O/U 117.5', Sim.FT_OU_1175)
simulateBasketballMarkets.set('O/U 118.5', Sim.FT_OU_1185)
simulateBasketballMarkets.set('O/U 119.5', Sim.FT_OU_1195)
simulateBasketballMarkets.set('O/U 120.5', Sim.FT_OU_1205)
simulateBasketballMarkets.set('O/U 121.5', Sim.FT_OU_1215)
simulateBasketballMarkets.set('O/U 122.5', Sim.FT_OU_1225)
simulateBasketballMarkets.set('O/U 123.5', Sim.FT_OU_1235)
simulateBasketballMarkets.set('O/U 124.5', Sim.FT_OU_1245)
simulateBasketballMarkets.set('O/U 125.5', Sim.FT_OU_1255)
simulateBasketballMarkets.set('O/U 126.5', Sim.FT_OU_1265)
simulateBasketballMarkets.set('O/U 127.5', Sim.FT_OU_1275)
simulateBasketballMarkets.set('O/U 128.5', Sim.FT_OU_1285)
simulateBasketballMarkets.set('O/U 129.5', Sim.FT_OU_1295)
simulateBasketballMarkets.set('O/U 130.5', Sim.FT_OU_1305)
simulateBasketballMarkets.set('O/U 131.5', Sim.FT_OU_1315)
simulateBasketballMarkets.set('O/U 132.5', Sim.FT_OU_1325)
simulateBasketballMarkets.set('O/U 133.5', Sim.FT_OU_1335)
simulateBasketballMarkets.set('O/U 134.5', Sim.FT_OU_1345)
simulateBasketballMarkets.set('O/U 135.5', Sim.FT_OU_1355)
simulateBasketballMarkets.set('O/U 136.5', Sim.FT_OU_1365)
simulateBasketballMarkets.set('O/U 137.5', Sim.FT_OU_1375)
simulateBasketballMarkets.set('O/U 138.5', Sim.FT_OU_1385)
simulateBasketballMarkets.set('O/U 139.5', Sim.FT_OU_1395)
simulateBasketballMarkets.set('O/U 140.5', Sim.FT_OU_1405)
simulateBasketballMarkets.set('O/U 141.5', Sim.FT_OU_1415)
simulateBasketballMarkets.set('O/U 142.5', Sim.FT_OU_1425)
simulateBasketballMarkets.set('O/U 143.5', Sim.FT_OU_1435)
simulateBasketballMarkets.set('O/U 144.5', Sim.FT_OU_1445)
simulateBasketballMarkets.set('O/U 145.5', Sim.FT_OU_1455)
simulateBasketballMarkets.set('O/U 146.5', Sim.FT_OU_1465)
simulateBasketballMarkets.set('O/U 147.5', Sim.FT_OU_1475)
simulateBasketballMarkets.set('O/U 148.5', Sim.FT_OU_1485)
simulateBasketballMarkets.set('O/U 149.5', Sim.FT_OU_1495)
simulateBasketballMarkets.set('O/U 150.5', Sim.FT_OU_1505)
simulateBasketballMarkets.set('O/U 151.5', Sim.FT_OU_1515)
simulateBasketballMarkets.set('O/U 152.5', Sim.FT_OU_1525)
simulateBasketballMarkets.set('O/U 153.5', Sim.FT_OU_1535)
simulateBasketballMarkets.set('O/U 154.5', Sim.FT_OU_1545)
simulateBasketballMarkets.set('O/U 155.5', Sim.FT_OU_1555)
simulateBasketballMarkets.set('O/U 156.5', Sim.FT_OU_1565)
simulateBasketballMarkets.set('O/U 157.5', Sim.FT_OU_1575)
simulateBasketballMarkets.set('O/U 158.5', Sim.FT_OU_1585)
simulateBasketballMarkets.set('O/U 159.5', Sim.FT_OU_1595)
simulateBasketballMarkets.set('O/U 160.5', Sim.FT_OU_1605)
simulateBasketballMarkets.set('O/U 161.5', Sim.FT_OU_1615)
simulateBasketballMarkets.set('O/U 162.5', Sim.FT_OU_1625)
simulateBasketballMarkets.set('O/U 163.5', Sim.FT_OU_1635)
simulateBasketballMarkets.set('O/U 164.5', Sim.FT_OU_1645)
simulateBasketballMarkets.set('O/U 165.5', Sim.FT_OU_1655)
simulateBasketballMarkets.set('O/U 166.5', Sim.FT_OU_1665)
simulateBasketballMarkets.set('O/U 167.5', Sim.FT_OU_1675)
simulateBasketballMarkets.set('O/U 168.5', Sim.FT_OU_1685)
simulateBasketballMarkets.set('O/U 169.5', Sim.FT_OU_1695)
simulateBasketballMarkets.set('O/U 170.5', Sim.FT_OU_1705)
simulateBasketballMarkets.set('O/U 171.5', Sim.FT_OU_1715)
simulateBasketballMarkets.set('O/U 172.5', Sim.FT_OU_1725)
simulateBasketballMarkets.set('O/U 173.5', Sim.FT_OU_1735)
simulateBasketballMarkets.set('O/U 174.5', Sim.FT_OU_1745)
simulateBasketballMarkets.set('O/U 175.5', Sim.FT_OU_1755)
simulateBasketballMarkets.set('O/U 176.5', Sim.FT_OU_1765)
simulateBasketballMarkets.set('O/U 177.5', Sim.FT_OU_1775)
simulateBasketballMarkets.set('O/U 178.5', Sim.FT_OU_1785)
simulateBasketballMarkets.set('O/U 179.5', Sim.FT_OU_1795)
simulateBasketballMarkets.set('O/U 180.5', Sim.FT_OU_1805)
simulateBasketballMarkets.set('O/U 181.5', Sim.FT_OU_1815)
simulateBasketballMarkets.set('O/U 182.5', Sim.FT_OU_1825)
simulateBasketballMarkets.set('O/U 183.5', Sim.FT_OU_1835)
simulateBasketballMarkets.set('O/U 184.5', Sim.FT_OU_1845)
simulateBasketballMarkets.set('O/U 185.5', Sim.FT_OU_1855)
simulateBasketballMarkets.set('O/U 186.5', Sim.FT_OU_1865)
simulateBasketballMarkets.set('O/U 187.5', Sim.FT_OU_1875)
simulateBasketballMarkets.set('O/U 188.5', Sim.FT_OU_1885)
simulateBasketballMarkets.set('O/U 189.5', Sim.FT_OU_1895)
simulateBasketballMarkets.set('O/U 190.5', Sim.FT_OU_1905)
simulateBasketballMarkets.set('O/U 191.5', Sim.FT_OU_1915)
simulateBasketballMarkets.set('O/U 192.5', Sim.FT_OU_1925)
simulateBasketballMarkets.set('O/U 193.5', Sim.FT_OU_1935)
simulateBasketballMarkets.set('O/U 194.5', Sim.FT_OU_1945)
simulateBasketballMarkets.set('O/U 195.5', Sim.FT_OU_1955)
simulateBasketballMarkets.set('O/U 196.5', Sim.FT_OU_1965)
simulateBasketballMarkets.set('O/U 197.5', Sim.FT_OU_1975)
simulateBasketballMarkets.set('O/U 198.5', Sim.FT_OU_1985)
simulateBasketballMarkets.set('O/U 199.5', Sim.FT_OU_1995)
simulateBasketballMarkets.set('O/U 200.5', Sim.FT_OU_2005)
simulateBasketballMarkets.set('O/U 201.5', Sim.FT_OU_2015)
simulateBasketballMarkets.set('O/U 202.5', Sim.FT_OU_2025)
simulateBasketballMarkets.set('O/U 203.5', Sim.FT_OU_2035)
simulateBasketballMarkets.set('O/U 204.5', Sim.FT_OU_2045)
simulateBasketballMarkets.set('O/U 205.5', Sim.FT_OU_2055)
simulateBasketballMarkets.set('O/U 206.5', Sim.FT_OU_2065)
simulateBasketballMarkets.set('O/U 207.5', Sim.FT_OU_2075)
simulateBasketballMarkets.set('O/U 208.5', Sim.FT_OU_2085)
simulateBasketballMarkets.set('O/U 209.5', Sim.FT_OU_2095)
simulateBasketballMarkets.set('O/U 210.5', Sim.FT_OU_2105)
simulateBasketballMarkets.set('O/U 211.5', Sim.FT_OU_2115)
simulateBasketballMarkets.set('O/U 212.5', Sim.FT_OU_2125)
simulateBasketballMarkets.set('O/U 213.5', Sim.FT_OU_2135)
simulateBasketballMarkets.set('O/U 214.5', Sim.FT_OU_2145)
simulateBasketballMarkets.set('O/U 215.5', Sim.FT_OU_2155)
simulateBasketballMarkets.set('O/U 216.5', Sim.FT_OU_2165)
simulateBasketballMarkets.set('O/U 217.5', Sim.FT_OU_2175)
simulateBasketballMarkets.set('O/U 218.5', Sim.FT_OU_2185)
simulateBasketballMarkets.set('O/U 219.5', Sim.FT_OU_2195)
simulateBasketballMarkets.set('O/U 220.5', Sim.FT_OU_2205)
simulateBasketballMarkets.set('O/U 221.5', Sim.FT_OU_2215)
simulateBasketballMarkets.set('O/U 222.5', Sim.FT_OU_2225)
simulateBasketballMarkets.set('O/U 223.5', Sim.FT_OU_2235)
simulateBasketballMarkets.set('O/U 224.5', Sim.FT_OU_2245)
simulateBasketballMarkets.set('O/U 225.5', Sim.FT_OU_2255)
simulateBasketballMarkets.set('O/U 226.5', Sim.FT_OU_2265)
simulateBasketballMarkets.set('O/U 227.5', Sim.FT_OU_2275)
simulateBasketballMarkets.set('O/U 228.5', Sim.FT_OU_2285)
simulateBasketballMarkets.set('O/U 229.5', Sim.FT_OU_2295)
simulateBasketballMarkets.set('O/U 230.5', Sim.FT_OU_2305)
simulateBasketballMarkets.set('O/U 231.5', Sim.FT_OU_2315)
simulateBasketballMarkets.set('O/U 232.5', Sim.FT_OU_2325)
simulateBasketballMarkets.set('O/U 233.5', Sim.FT_OU_2335)
simulateBasketballMarkets.set('O/U 234.5', Sim.FT_OU_2345)
simulateBasketballMarkets.set('O/U 235.5', Sim.FT_OU_2355)
simulateBasketballMarkets.set('O/U 236.5', Sim.FT_OU_2365)
simulateBasketballMarkets.set('O/U 237.5', Sim.FT_OU_2375)
simulateBasketballMarkets.set('O/U 238.5', Sim.FT_OU_2385)
simulateBasketballMarkets.set('O/U 239.5', Sim.FT_OU_2395)
simulateBasketballMarkets.set('O/U 240.5', Sim.FT_OU_2405)
simulateBasketballMarkets.set('O/U 241.5', Sim.FT_OU_2415)
simulateBasketballMarkets.set('O/U 242.5', Sim.FT_OU_2425)
simulateBasketballMarkets.set('O/U 243.5', Sim.FT_OU_2435)
simulateBasketballMarkets.set('O/U 244.5', Sim.FT_OU_2445)
simulateBasketballMarkets.set('O/U 245.5', Sim.FT_OU_2455)
simulateBasketballMarkets.set('O/U 246.5', Sim.FT_OU_2465)
simulateBasketballMarkets.set('O/U 247.5', Sim.FT_OU_2475)
simulateBasketballMarkets.set('O/U 248.5', Sim.FT_OU_2485)
simulateBasketballMarkets.set('O/U 249.5', Sim.FT_OU_2495)
simulateBasketballMarkets.set('O/U 250.5', Sim.FT_OU_2505)
simulateBasketballMarkets.set('O/U 251.5', Sim.FT_OU_2515)
simulateBasketballMarkets.set('O/U 252.5', Sim.FT_OU_2525)
simulateBasketballMarkets.set('O/U 253.5', Sim.FT_OU_2535)
simulateBasketballMarkets.set('O/U 254.5', Sim.FT_OU_2545)
simulateBasketballMarkets.set('O/U 255.5', Sim.FT_OU_2555)
simulateBasketballMarkets.set('O/U 256.5', Sim.FT_OU_2565)
simulateBasketballMarkets.set('O/U 257.5', Sim.FT_OU_2575)
simulateBasketballMarkets.set('O/U 258.5', Sim.FT_OU_2585)
simulateBasketballMarkets.set('O/U 259.5', Sim.FT_OU_2595)
simulateBasketballMarkets.set('O/U 260.5', Sim.FT_OU_2605)
simulateBasketballMarkets.set('O/U 261.5', Sim.FT_OU_2615)
simulateBasketballMarkets.set('O/U 262.5', Sim.FT_OU_2625)
simulateBasketballMarkets.set('O/U 263.5', Sim.FT_OU_2635)
simulateBasketballMarkets.set('O/U 264.5', Sim.FT_OU_2645)
simulateBasketballMarkets.set('O/U 265.5', Sim.FT_OU_2655)
simulateBasketballMarkets.set('O/U 266.5', Sim.FT_OU_2665)
simulateBasketballMarkets.set('O/U 267.5', Sim.FT_OU_2675)
simulateBasketballMarkets.set('O/U 268.5', Sim.FT_OU_2685)
simulateBasketballMarkets.set('O/U 269.5', Sim.FT_OU_2695)
simulateBasketballMarkets.set('O/U 270.5', Sim.FT_OU_2705)
simulateBasketballMarkets.set('O/U 271.5', Sim.FT_OU_2715)
simulateBasketballMarkets.set('O/U 272.5', Sim.FT_OU_2725)
simulateBasketballMarkets.set('O/U 273.5', Sim.FT_OU_2735)
simulateBasketballMarkets.set('O/U 274.5', Sim.FT_OU_2745)
simulateBasketballMarkets.set('O/U 275.5', Sim.FT_OU_2755)
simulateBasketballMarkets.set('O/U 276.5', Sim.FT_OU_2765)
simulateBasketballMarkets.set('O/U 277.5', Sim.FT_OU_2775)
simulateBasketballMarkets.set('O/U 278.5', Sim.FT_OU_2785)
simulateBasketballMarkets.set('O/U 279.5', Sim.FT_OU_2795)
simulateBasketballMarkets.set('O/U 280.5', Sim.FT_OU_2805)
simulateBasketballMarkets.set('O/U 281.5', Sim.FT_OU_2815)
simulateBasketballMarkets.set('O/U 282.5', Sim.FT_OU_2825)
simulateBasketballMarkets.set('O/U 283.5', Sim.FT_OU_2835)
simulateBasketballMarkets.set('O/U 284.5', Sim.FT_OU_2845)
simulateBasketballMarkets.set('O/U 285.5', Sim.FT_OU_2855)
simulateBasketballMarkets.set('O/U 286.5', Sim.FT_OU_2865)
simulateBasketballMarkets.set('O/U 287.5', Sim.FT_OU_2875)
simulateBasketballMarkets.set('O/U 288.5', Sim.FT_OU_2885)
simulateBasketballMarkets.set('O/U 289.5', Sim.FT_OU_2895)
simulateBasketballMarkets.set('O/U 290.5', Sim.FT_OU_2905)
simulateBasketballMarkets.set('O/U 291.5', Sim.FT_OU_2915)
simulateBasketballMarkets.set('O/U 292.5', Sim.FT_OU_2925)
simulateBasketballMarkets.set('O/U 293.5', Sim.FT_OU_2935)
simulateBasketballMarkets.set('O/U 294.5', Sim.FT_OU_2945)
simulateBasketballMarkets.set('O/U 295.5', Sim.FT_OU_2955)
simulateBasketballMarkets.set('O/U 296.5', Sim.FT_OU_2965)
simulateBasketballMarkets.set('O/U 297.5', Sim.FT_OU_2975)
simulateBasketballMarkets.set('O/U 298.5', Sim.FT_OU_2985)
simulateBasketballMarkets.set('O/U 299.5', Sim.FT_OU_2995)
simulateBasketballMarkets.set('O/U 300.5', Sim.FT_OU_3005)
simulateBasketballMarkets.set('O/U 301.5', Sim.FT_OU_3015)
simulateBasketballMarkets.set('O/U 302.5', Sim.FT_OU_3025)
simulateBasketballMarkets.set('O/U 303.5', Sim.FT_OU_3035)
simulateBasketballMarkets.set('O/U 304.5', Sim.FT_OU_3045)
simulateBasketballMarkets.set('O/U 305.5', Sim.FT_OU_3055)
simulateBasketballMarkets.set('O/U 306.5', Sim.FT_OU_3065)
simulateBasketballMarkets.set('O/U 307.5', Sim.FT_OU_3075)
simulateBasketballMarkets.set('O/U 308.5', Sim.FT_OU_3085)
simulateBasketballMarkets.set('O/U 309.5', Sim.FT_OU_3095)
simulateBasketballMarkets.set('O/U 310.5', Sim.FT_OU_3105)
simulateBasketballMarkets.set('O/U 311.5', Sim.FT_OU_3115)
simulateBasketballMarkets.set('O/U 312.5', Sim.FT_OU_3125)
simulateBasketballMarkets.set('O/U 313.5', Sim.FT_OU_3135)
simulateBasketballMarkets.set('O/U 314.5', Sim.FT_OU_3145)
simulateBasketballMarkets.set('O/U 315.5', Sim.FT_OU_3155)
simulateBasketballMarkets.set('O/U 316.5', Sim.FT_OU_3165)
simulateBasketballMarkets.set('O/U 317.5', Sim.FT_OU_3175)
simulateBasketballMarkets.set('O/U 318.5', Sim.FT_OU_3185)
simulateBasketballMarkets.set('O/U 319.5', Sim.FT_OU_3195)
simulateBasketballMarkets.set('O/U 320.5', Sim.FT_OU_3205)

// simulateBasketballMarkets.set('O/U 1st Quarter 23.5', Sim.FIRST_Q_OU_235)
// simulateBasketballMarkets.set('O/U 1st Quarter 24.5', Sim.FIRST_Q_OU_245)
// simulateBasketballMarkets.set('O/U 1st Quarter 25.5', Sim.FIRST_Q_OU_255)
// simulateBasketballMarkets.set('O/U 1st Quarter 26.5', Sim.FIRST_Q_OU_265)
simulateBasketballMarkets.set('O/U 1st Quarter 27.5', Sim.FIRST_Q_OU_275)
simulateBasketballMarkets.set('O/U 1st Quarter 28.5', Sim.FIRST_Q_OU_285)
simulateBasketballMarkets.set('O/U 1st Quarter 29.5', Sim.FIRST_Q_OU_295)
simulateBasketballMarkets.set('O/U 1st Quarter 30.5', Sim.FIRST_Q_OU_305)
simulateBasketballMarkets.set('O/U 1st Quarter 31.5', Sim.FIRST_Q_OU_315)
simulateBasketballMarkets.set('O/U 1st Quarter 32.5', Sim.FIRST_Q_OU_325)
simulateBasketballMarkets.set('O/U 1st Quarter 33.5', Sim.FIRST_Q_OU_335)
simulateBasketballMarkets.set('O/U 1st Quarter 34.5', Sim.FIRST_Q_OU_345)
simulateBasketballMarkets.set('O/U 1st Quarter 35.5', Sim.FIRST_Q_OU_355)
simulateBasketballMarkets.set('O/U 1st Quarter 36.5', Sim.FIRST_Q_OU_365)
simulateBasketballMarkets.set('O/U 1st Quarter 37.5', Sim.FIRST_Q_OU_375)
simulateBasketballMarkets.set('O/U 1st Quarter 38.5', Sim.FIRST_Q_OU_385)
simulateBasketballMarkets.set('O/U 1st Quarter 39.5', Sim.FIRST_Q_OU_395)
simulateBasketballMarkets.set('O/U 1st Quarter 40.5', Sim.FIRST_Q_OU_405)
simulateBasketballMarkets.set('O/U 1st Quarter 41.5', Sim.FIRST_Q_OU_415)
simulateBasketballMarkets.set('O/U 1st Quarter 42.5', Sim.FIRST_Q_OU_425)
simulateBasketballMarkets.set('O/U 1st Quarter 43.5', Sim.FIRST_Q_OU_435)
simulateBasketballMarkets.set('O/U 1st Quarter 44.5', Sim.FIRST_Q_OU_445)
simulateBasketballMarkets.set('O/U 1st Quarter 45.5', Sim.FIRST_Q_OU_455)
simulateBasketballMarkets.set('O/U 1st Quarter 46.5', Sim.FIRST_Q_OU_465)
simulateBasketballMarkets.set('O/U 1st Quarter 47.5', Sim.FIRST_Q_OU_475)
simulateBasketballMarkets.set('O/U 1st Quarter 48.5', Sim.FIRST_Q_OU_485)
simulateBasketballMarkets.set('O/U 1st Quarter 49.5', Sim.FIRST_Q_OU_495)
simulateBasketballMarkets.set('O/U 1st Quarter 50.5', Sim.FIRST_Q_OU_505)
simulateBasketballMarkets.set('O/U 1st Quarter 51.5', Sim.FIRST_Q_OU_515)
simulateBasketballMarkets.set('O/U 1st Quarter 52.5', Sim.FIRST_Q_OU_525)
simulateBasketballMarkets.set('O/U 1st Quarter 53.5', Sim.FIRST_Q_OU_535)
simulateBasketballMarkets.set('O/U 1st Quarter 54.5', Sim.FIRST_Q_OU_545)
simulateBasketballMarkets.set('O/U 1st Quarter 55.5', Sim.FIRST_Q_OU_555)
simulateBasketballMarkets.set('O/U 1st Quarter 56.5', Sim.FIRST_Q_OU_565)
simulateBasketballMarkets.set('O/U 1st Quarter 57.5', Sim.FIRST_Q_OU_575)
simulateBasketballMarkets.set('O/U 1st Quarter 58.5', Sim.FIRST_Q_OU_585)
simulateBasketballMarkets.set('O/U 1st Quarter 59.5', Sim.FIRST_Q_OU_595)
simulateBasketballMarkets.set('O/U 1st Quarter 60.5', Sim.FIRST_Q_OU_605)
simulateBasketballMarkets.set('O/U 1st Quarter 61.5', Sim.FIRST_Q_OU_615)
simulateBasketballMarkets.set('O/U 1st Quarter 62.5', Sim.FIRST_Q_OU_625)
simulateBasketballMarkets.set('O/U 1st Quarter 63.5', Sim.FIRST_Q_OU_635)
simulateBasketballMarkets.set('O/U 1st Quarter 64.5', Sim.FIRST_Q_OU_645)
simulateBasketballMarkets.set('O/U 1st Quarter 65.5', Sim.FIRST_Q_OU_655)
simulateBasketballMarkets.set('O/U 1st Quarter 66.5', Sim.FIRST_Q_OU_665)
simulateBasketballMarkets.set('O/U 1st Quarter 67.5', Sim.FIRST_Q_OU_675)
simulateBasketballMarkets.set('O/U 1st Quarter 68.5', Sim.FIRST_Q_OU_685)
simulateBasketballMarkets.set('O/U 1st Quarter 69.5', Sim.FIRST_Q_OU_695)
simulateBasketballMarkets.set('O/U 1st Quarter 70.5', Sim.FIRST_Q_OU_705)
simulateBasketballMarkets.set('O/U 1st Quarter 71.5', Sim.FIRST_Q_OU_715)
simulateBasketballMarkets.set('O/U 1st Quarter 72.5', Sim.FIRST_Q_OU_725)
simulateBasketballMarkets.set('O/U 1st Quarter 73.5', Sim.FIRST_Q_OU_735)
simulateBasketballMarkets.set('O/U 1st Quarter 74.5', Sim.FIRST_Q_OU_745)
simulateBasketballMarkets.set('O/U 1st Quarter 75.5', Sim.FIRST_Q_OU_755)
simulateBasketballMarkets.set('O/U 1st Quarter 76.5', Sim.FIRST_Q_OU_765)
simulateBasketballMarkets.set('O/U 1st Quarter 77.5', Sim.FIRST_Q_OU_775)
simulateBasketballMarkets.set('O/U 1st Quarter 78.5', Sim.FIRST_Q_OU_785)
simulateBasketballMarkets.set('O/U 1st Quarter 79.5', Sim.FIRST_Q_OU_795)
simulateBasketballMarkets.set('O/U 1st Quarter 80.5', Sim.FIRST_Q_OU_805)
