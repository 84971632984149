import React from 'react'
import { SimBet } from '../../../../sports/types'
import { useAppDispatch, useAppSelector } from '../../../../store/store'
import { selectBets } from '../../../betslip/store/betslip.selectors'
import { clicksOnExploreItem } from '../../../betslip/store/betslip.state'
import { OddsContainer } from './matchSelection.style'
import * as Sim from '../../../../sports/soccer/markets'
import { showOutcome } from '../../../../lib/utils/uiUtils'

interface ISelection {
  odds: number
  bet: SimBet
}

export const Selection: React.FC<ISelection> = ({ odds, bet }) => {
  const dispatch = useAppDispatch()
  const betslip = useAppSelector(selectBets)
  const isActive = betslip.some((b) => b.b9jBet === bet.b9jBet)

  const handleClick = () => {
    dispatch(clicksOnExploreItem(bet))
  }

  return (
    <>
      <OddsContainer
        onClick={handleClick}
        $isActive={isActive}
        data-testid="selection"
      >
        <div className="market">
          {showOutcome(bet.simOutcome as Sim.Outcome, bet.event)}
        </div>
        <div>{odds}</div>
      </OddsContainer>
    </>
  )
}
