import { useDispatch } from 'react-redux'
import { SimBet } from '../../../../sports/types'
import { Warning, Remove } from '../../../../components/svgs/index'
import { removeSelection } from '../../../betslip/store/betslip.state'
import {
  Unsupported,
  BetSlipItem,
  Cartouche,
  Delete,
  Item,
  Bet,
  Odd,
} from './selectionItem.style'
import {
  showOutcome,
  titleCaseSentence,
  truncateString,
} from '../../../../lib/utils/uiUtils'
import { FormattedMessage } from 'react-intl'

interface ISelectionItem {
  bet: SimBet
}
export const SelectionItem: React.FC<ISelectionItem> = ({ bet }) => {
  return (
    <BetSlipItem data-testid="selection-item">
      {!bet.supported ? (
        <UnsupportedSelection />
      ) : (
        <ActiveSelection bet={bet} />
      )}
    </BetSlipItem>
  )
}

interface IActiveSelection {
  bet: SimBet
}
export const ActiveSelection: React.FC<IActiveSelection> = ({ bet }) => {
  const { simOutcome, event, market, odd, blocking, b9jBet, spread, outcome } =
    bet
  const dispatch = useDispatch()

  const deleteSelection = (id: string) => {
    dispatch(removeSelection(id))
  }
  const displayOutcome = showOutcome(simOutcome, event, spread, outcome)
  return (
    <>
      {blocking && (
        <Cartouche>
          <Warning width="17" height="17" viewBox="0 0 24 24" />
        </Cartouche>
      )}
      <Item $warning={blocking}>
        <Bet>
          <div className="title">
            <span className="title-outcome">
              {truncateString(displayOutcome, 28)}
            </span>
            <span className="title-market">&nbsp;{market}</span>
          </div>
          <div className="event">
            <span className="event-icon">
              {bet.sport === 1 && '⚽'}
              {bet.sport === 2 && '🏀'}
            </span>
            <span className="event-match">{titleCaseSentence(event)}</span>
          </div>
        </Bet>
        <Odd data-testid="betItem-odd" $warning={blocking}>
          {odd}
        </Odd>
        <Delete
          onClick={() => deleteSelection(b9jBet)}
          $warning={blocking}
          data-testid="remove-bet"
        >
          <Remove width="17" height="17" viewBox="0 0 24 24" />
        </Delete>
      </Item>
    </>
  )
}

export const UnsupportedSelection = () => (
  <Unsupported data-testid="unsupported">
    <FormattedMessage
      id="unsupportedSelection_info1"
      defaultMessage="This betting market is not supported by Simulate yet"
    />
    <br />
    <FormattedMessage
      id="unsupportedSelection_info2"
      defaultMessage="It will not be included in your bet"
    />
  </Unsupported>
)
