import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SimBet, Source, TPopularEvent, Sport } from '../../../sports/types'
import { AppThunk, RootState } from '../../../store/store'
import { getExploreMarket } from '../api/getExploreMarket'
import { getExploreSport } from '../api/getExploreSport'
import { createError } from '../../app/store/app.state'
import { APIStatus, ErrorResponse } from '../../../api/types'
import { getSport, SportsCode } from '../../../sports/sportMap'
import { getSymbolToUse, getSpread } from '../../../sports/adapter'
import { findMarketFromID } from '../../../sports/marketUtils'

export type AvailableTournaments = {
  tournament_id: string
  tournament_name: string
}

export type ExploreState = {
  status: APIStatus
  book: SimBet[]
  active_markets: number[]
  selectedMarket: string | number
  activeSport: Sport
  availableTournaments: AvailableTournaments[]
  activeTournament: string
}

export const initialState: ExploreState = {
  status: 'idle',
  book: [] as SimBet[],
  active_markets: [] as number[],
  selectedMarket: 'explore',
  activeSport: 'Soccer',
  activeTournament: '',
  availableTournaments: [],
}

const exploreSlice = createSlice({
  name: 'explore',
  initialState,
  reducers: {
    setStatus(state, { payload }: PayloadAction<APIStatus>) {
      state.status = payload
    },
    createBook(state, { payload }: PayloadAction<SimBet[]>) {
      state.book = payload
      state.status = 'idle'
    },
    setAvailableTournaments(state, { payload }: PayloadAction<SimBet[]>) {
      if (payload.length === 0) state.availableTournaments = []
      if (payload.length > 0) {
        state.activeTournament = payload[0].tournament_id
        state.availableTournaments = payload
          .map((c) => {
            return {
              tournament_id: c.tournament_id,
              tournament_name: c.tournament_name,
            }
          })
          .filter(
            (value, index, self) =>
              self.findIndex(
                (t) =>
                  t.tournament_id === value.tournament_id &&
                  t.tournament_name === value.tournament_name,
              ) === index,
          )
      }
    },
    setActiveMarkets(state, { payload }: PayloadAction<number[]>) {
      if (payload.length > 0) state.active_markets = payload
    },
    setSelectedMarket(state, { payload }: PayloadAction<string | number>) {
      state.selectedMarket = payload
    },
    setActiveSport(state, { payload }: PayloadAction<Sport>) {
      state.activeSport = payload
      state.selectedMarket = 'explore'
    },
    setActiveTournaments(state, { payload }: PayloadAction<string>) {
      state.activeTournament = payload
    },
  },
})

export default exploreSlice.reducer

export const {
  setStatus,
  createBook,
  setActiveMarkets,
  setSelectedMarket,
  setActiveSport,
  setAvailableTournaments,
  setActiveTournaments,
} = exploreSlice.actions

export const fetchExplore = (): AppThunk => async (dispatch, getState) => {
  const state = getState()
  const { sessionID } = state.app
  const { selectedMarket } = state.explore
  const { activeSport } = state.explore
  const { permissions } = state.app

  dispatch(setStatus('loading'))

  let odds

  try {
    if (typeof selectedMarket === 'number') {
      odds = await getExploreMarket(sessionID, selectedMarket)
    } else {
      odds = await getExploreSport(sessionID, activeSport)
    }

    let symbol = ''
    let source: Source = ''

    if (odds?.active_markets) {
      dispatch(setActiveMarkets(odds.active_markets))
    }

    if (odds.popular_events === null) {
      dispatch(createBook([]))
    } else {
      const mapToSimBet = (e: TPopularEvent): SimBet => {
        if (e.sport_id === 2 && !permissions?.basketball) {
          return {} as SimBet
        }

        // get the symbol to split on
        // on the explore page we can have bets from both logispin and iSolutions
        // we can use desktop for iSolutions bets
        // and new-mobile for Logispin bets
        e.code.includes('#') ? (source = 'desktop') : (source = 'new-mobile')
        symbol = getSymbolToUse(source)

        // extract matchId and sport head, MKT, outcome
        const [head, MKT] = e.code.split(symbol)

        const [, sport] = head.split('$')

        // find which sport map to use
        const sportMap = getSport(source, sport as SportsCode)
        if (!sportMap) {
          return {} as SimBet
        }

        const simulateMarket = findMarketFromID(e.market_id, e.sport_id) // find if there is a matching market in the map
        // const supported = sportMap.marketMap.has(MKT) // find if market is supported
        const spread = getSpread('', MKT, e?.market_id, e?.sport_id)
        // const simMarketID =
        //   simulateMarkets.get(simulateMarket || ('' as Sim.Market))?.id || 0 // used for backend call
        const simMarketID =
          sportMap.findSimMarketId(simulateMarket, e.sport_id) || 0

        return {
          b9jBet: e.code,
          matchID: e.event_id,
          odd_id: e.odd_id,
          sport: e.sport_id, // placeholder
          odd: e.odd,
          event: e.event_name,
          market: findMarketFromID(e.market_id, e.sport_id),
          simMarketID: simMarketID,
          outcome: e.selected_option,
          simOutcome: e.selected_option,
          supported: true,
          blocking: false,
          stats: e?.stats,
          spread,
          tournament_id: e.tournament_id?.toString(),
          tournament_name: e.tournament_name,
        }
      }

      dispatch(
        createBook(
          odds.popular_events
            .map(mapToSimBet)
            .filter((value: any) => Object.keys(value).length !== 0),
        ),
      )
      dispatch(
        setAvailableTournaments(
          odds.popular_events
            .map(mapToSimBet)
            .filter((value: any) => Object.keys(value).length !== 0),
        ),
      )
    }
  } catch (e) {
    dispatch(createError(e as ErrorResponse))
  }
}

export const selectSportsBook = (state: RootState) => state.explore.book
export const selectActiveSport = (state: RootState) => state.explore.activeSport
export const selectSportsBookStatus = (state: RootState) =>
  state.explore.status as APIStatus
export const selectActiveMarkets = (state: RootState) =>
  state.explore.active_markets
export const selectSelectedMarket = (state: RootState) =>
  state.explore.selectedMarket
export const selectAvailableTournaments = (state: RootState) =>
  state.explore.availableTournaments

export const selectSportsBookByTournament = (state: RootState) =>
  state.explore.book.filter(
    (b) => b.tournament_id === state.explore.activeTournament,
  )

export const selectActiveTournament = (state: RootState) =>
  state.explore.availableTournaments.find(
    (t) => t.tournament_id === state.explore.activeTournament,
  )
