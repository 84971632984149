import { createSelector } from '@reduxjs/toolkit'
import { selectSessionID } from '../../app/store/app.state'
import { RootState } from '../../../store/store'
import { filterSelections, totalOdds, totalToWin } from '../../../store/utils'
import { switchOnSource } from '../../../api/utils'

// selectors -----------------------------------------------------------------------

export const selectBeslip = (state: RootState) => state.betslip
export const selectStake = (state: RootState) => state.betslip.stake
export const selectKeyboard = (state: RootState) => state.betslip.keyboard
export const selectMenu = (state: RootState) => state.betslip.menu
export const selectBets = (state: RootState) => state.betslip.bets

export const selectNotification = (state: RootState) =>
  state.betslip.notification

export const selectFreeBetsDrawer = (state: RootState) =>
  state.betslip.freeBetsDrawer

export const selectActivefreeBet = (state: RootState) =>
  state.betslip.activeFreeBet

export const selectFreeBetAmount = (state: RootState) =>
  state.betslip.activeFreeBet.amount

export const selectFreeBets = (state: RootState) =>
  state.betslip.incentives?.free_bets || []

export const selectFreeBetLength = (state: RootState) =>
  state.betslip.incentives?.free_bets?.length || 0

export const selectDisableDigit = (state: RootState) =>
  state.betslip.stake.toString().length >= 7

export const selectTaxValues = (state: RootState) => state.betslip.taxValues

export const seletFilteredSelections = createSelector(selectBets, (bets) =>
  filterSelections(bets),
)

export const selectOdds = createSelector(
  seletFilteredSelections,
  (bets): number => totalOdds(bets),
)

export const selectToWin = createSelector(
  [selectOdds, selectStake, selectFreeBetAmount],
  (odds: number, stake: number, freeBetAmount: number): number =>
    totalToWin(odds, stake, freeBetAmount),
)

export const selectBlocking = createSelector(selectBets, (bets) =>
  bets.some((bet) => bet.blocking === true),
)

export const selectDisplayStake = createSelector(
  [selectStake, selectBlocking],
  (stake, blocked) =>
    !blocked ? (stake > 0 ? stake.toLocaleString() : '') : '',
)

export const selectActiveSelections = createSelector(
  seletFilteredSelections,
  (bets): number => bets.filter((bets) => !bets.blocking).length,
)

export const selectFreeBetToWin = createSelector(
  [selectOdds, selectFreeBetAmount, selectStake],
  (odds: number, stake: number, freeBet: number): number =>
    totalToWin(odds, stake) - freeBet,
)

export const selectDisableQuickStake = createSelector(
  [selectActivefreeBet, selectStake, selectBlocking],
  (activeFB, stake, blockUI) =>
    stake.toString().length >= 7 || blockUI || activeFB.amount > 0,
)

export const selectPlaceBet = createSelector(
  [
    selectFreeBetAmount,
    selectSessionID,
    selectBlocking,
    selectActiveSelections,
  ],
  (freeBetAmount, sessionID, blockUI, activeSelections) => ({
    disablePlaceBet: !sessionID || blockUI || activeSelections === 0,
    freeBetAmount,
    sessionID,
  }),
)

export const selectCockpitValues = createSelector(
  [
    selectStake,
    selectOdds,
    selectToWin,
    selectActiveSelections,
    selectBlocking,
    selectKeyboard,
    selectFreeBetAmount,
    selectFreeBetToWin,
  ],
  (
    stake,
    odds,
    toWin,
    activeSelections,
    blocked,
    keyboard,
    freeBetAmount,
    freeBetToWin,
  ) => ({
    stake: !blocked ? (stake > 0 ? stake : '') : '',
    odds: !blocked ? odds : 0.0,
    toWin: !blocked ? (freeBetToWin ? freeBetToWin : toWin) : 0.0,
    activeSelections,
    blocked,
    keyboard,
    freeBetAmount,
  }),
)

export const selectPostBetApiData = (state: RootState) => {
  return {
    bets: filterSelections(state.betslip.bets),
    stake: state.betslip.stake,
    acceptOdds: state.app.acceptOdds,
    free_bet_uid: state.betslip.activeFreeBet.free_bet_uid,
    source: state.app.forceWin ? 42 : switchOnSource(state.app.source),
    odds: totalOdds(filterSelections(state.betslip.bets)),
    sessionID: state.app.sessionID,
    game_speed: state.app.speed,
  }
}
