import { Source } from 'src/sports/types'
import { BRAND, Brand } from './index'
import { GameSpeed } from 'src/features/app/store/app.state'

type ClientVariables = {
  maxWin: number
  minStake: number
  maxStake: number
  currency: string
  quickStakesAmounts: number[]
  defaultSpeed?: GameSpeed
  countryCode: string
  currencyCode: string
  defaultStake: number
}

const values: { [key in Brand]: (source?: Source) => ClientVariables } = {
  'bet9ja': (source?: Source) => {
    return {
      maxWin: 10000000,
      minStake: source === 'self-service' ? 150 : 50,
      maxStake: 1000000,
      currency: '₦',
      currencyCode: 'NGN',
      quickStakesAmounts: [100, 200, 500, 1000],
      countryCode: 'en',
      defaultStake: 100,
    }
  },
  'mozzartbet-ke': () => {
    return {
      maxWin: 2000000,
      minStake: 10,
      maxStake: 20000,
      currency: 'KES',
      currencyCode: 'KES',
      quickStakesAmounts: [10, 50, 100, 1000],
      countryCode: 'en',
      defaultStake: 10,
    }
  },
  'certification': () => {
    return {
      maxWin: 40000,
      minStake: 1,
      maxStake: 4000,
      currency: '€',
      currencyCode: 'EUR',
      quickStakesAmounts: [2, 4, 6, 8],
      countryCode: 'hr',
      defaultStake: 2,
    }
  },
  'teapuesto-pe': () => {
    return {
      maxWin: 10000000,
      minStake: 2,
      maxStake: 1000000,
      currency: 'S/',
      currencyCode: 'PEN',
      quickStakesAmounts: [50, 100, 200, 500],
      countryCode: 'es',
      defaultStake: 50,
    }
  },
  'mozzartbet-srb': () => {
    return {
      maxWin: 1000000,
      minStake: 20,
      maxStake: 20000,
      currency: 'RSD',
      currencyCode: 'RSD',
      quickStakesAmounts: [20, 100, 500, 1000],
      countryCode: 'sr',
      defaultStake: 20,
    }
  },
  'yajuego-co': () => {
    return {
      maxWin: 50000000,
      minStake: 500,
      maxStake: 5000000,
      currency: '$',
      currencyCode: 'COP',
      quickStakesAmounts: [500, 700, 1000, 2500, 5000],
      countryCode: 'es',
      defaultStake: 500,
    }
  },
  'mozzartbet-hrv': () => {
    return {
      maxWin: 1000000,
      minStake: 20,
      maxStake: 20000,
      currency: '€',
      currencyCode: 'EUR',
      quickStakesAmounts: [20, 100, 500, 1000],
      countryCode: 'hr',
      defaultStake: 20,
    }
  },
  'bolabet-zmb': () => {
    return {
      maxWin: 15000,
      minStake: 1,
      maxStake: 200,
      currency: '€',
      currencyCode: 'EUR',
      quickStakesAmounts: [2, 4, 6, 8],
      countryCode: 'zm',
      defaultStake: 2,
    }
  },
}

export const getClientVariables = (source?: Source) => {
  if (process.env.NODE_ENV === 'test') {
    return values.bet9ja(source)
  }
  if (!BRAND) throw { status: 500 }
  return values[BRAND](source)
}
