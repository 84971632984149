import * as Sim from '../../markets'
import * as MOZZARTBET from './types'

export const MOZZARTBETBasketballMap = new Map<MOZZARTBET.Markets, Sim.Market>()
export const MOZZARTBETBasketballOutcomeMap = new Map<
  MOZZARTBET.Outcomes,
  Sim.Outcome
>()

export const findMozzartbetKEBasketballOutcome = (
  _b9jMkt: MOZZARTBET.Markets,
  outcome: MOZZARTBET.Outcomes,
): Sim.Outcome => {
  return MOZZARTBETBasketballOutcomeMap.get(outcome) || ('' as Sim.Outcome)
}

// 2 way
MOZZARTBETBasketballMap.set(196, '2 Way')
MOZZARTBETBasketballOutcomeMap.set('196@1', '1')
MOZZARTBETBasketballOutcomeMap.set('196@3', '2')
// 3way
MOZZARTBETBasketballMap.set(17, '3 Way')
MOZZARTBETBasketballOutcomeMap.set('17@1', '1')
MOZZARTBETBasketballOutcomeMap.set('17@2', 'x')
MOZZARTBETBasketballOutcomeMap.set('17@3', '2')
// HTFT
MOZZARTBETBasketballMap.set(5, 'HT_FT')
MOZZARTBETBasketballOutcomeMap.set('5@1', '1/1')
MOZZARTBETBasketballOutcomeMap.set('5@2', '1/x')
MOZZARTBETBasketballOutcomeMap.set('5@3', '1/2')
MOZZARTBETBasketballOutcomeMap.set('5@4', 'x/1')
MOZZARTBETBasketballOutcomeMap.set('5@5', 'x/x')
MOZZARTBETBasketballOutcomeMap.set('5@6', 'x/2')
MOZZARTBETBasketballOutcomeMap.set('5@7', '2/1')
MOZZARTBETBasketballOutcomeMap.set('5@8', '2/x')
MOZZARTBETBasketballOutcomeMap.set('5@9', '2/2')
// 1x2/Three Way 1st Half
MOZZARTBETBasketballMap.set(25, '1X2 1st Half')
MOZZARTBETBasketballOutcomeMap.set('25@1', '1')
MOZZARTBETBasketballOutcomeMap.set('25@2', 'x')
MOZZARTBETBasketballOutcomeMap.set('25@3', '2')
// Handicap
MOZZARTBETBasketballMap.set(4964.5, 'Handicap -35.5')
MOZZARTBETBasketballMap.set(4965.5, 'Handicap -34.5')
MOZZARTBETBasketballMap.set(4966.5, 'Handicap -33.5')
MOZZARTBETBasketballMap.set(4967.5, 'Handicap -32.5')
MOZZARTBETBasketballMap.set(4968.5, 'Handicap -31.5')
MOZZARTBETBasketballMap.set(4969.5, 'Handicap -30.5')
MOZZARTBETBasketballMap.set(4970.5, 'Handicap -29.5')
MOZZARTBETBasketballMap.set(4971.5, 'Handicap -28.5')
MOZZARTBETBasketballMap.set(4972.5, 'Handicap -27.5')
MOZZARTBETBasketballMap.set(4973.5, 'Handicap -26.5')
MOZZARTBETBasketballMap.set(4974.5, 'Handicap -25.5')
MOZZARTBETBasketballMap.set(4975.5, 'Handicap -24.5')
MOZZARTBETBasketballMap.set(4976.5, 'Handicap -23.5')
MOZZARTBETBasketballMap.set(4977.5, 'Handicap -22.5')
MOZZARTBETBasketballMap.set(4978.5, 'Handicap -21.5')
MOZZARTBETBasketballMap.set(4979.5, 'Handicap -20.5')
MOZZARTBETBasketballMap.set(4980.5, 'Handicap -19.5')
MOZZARTBETBasketballMap.set(4981.5, 'Handicap -18.5')
MOZZARTBETBasketballMap.set(4982.5, 'Handicap -17.5')
MOZZARTBETBasketballMap.set(4983.5, 'Handicap -16.5')
MOZZARTBETBasketballMap.set(4984.5, 'Handicap -15.5')
MOZZARTBETBasketballMap.set(4985.5, 'Handicap -14.5')
MOZZARTBETBasketballMap.set(4986.5, 'Handicap -13.5')
MOZZARTBETBasketballMap.set(4987.5, 'Handicap -12.5')
MOZZARTBETBasketballMap.set(4988.5, 'Handicap -11.5')
MOZZARTBETBasketballMap.set(4989.5, 'Handicap -10.5')
MOZZARTBETBasketballMap.set(4990.5, 'Handicap -9.5')
MOZZARTBETBasketballMap.set(4991.5, 'Handicap -8.5')
MOZZARTBETBasketballMap.set(4992.5, 'Handicap -7.5')
MOZZARTBETBasketballMap.set(4993.5, 'Handicap -6.5')
MOZZARTBETBasketballMap.set(4994.5, 'Handicap -5.5')
MOZZARTBETBasketballMap.set(4995.5, 'Handicap -4.5')
MOZZARTBETBasketballMap.set(4996.5, 'Handicap -3.5')
MOZZARTBETBasketballMap.set(4997.5, 'Handicap -2.5')
MOZZARTBETBasketballMap.set(4998.5, 'Handicap -1.5')
MOZZARTBETBasketballMap.set(4999.5, 'Handicap -0.5')
MOZZARTBETBasketballMap.set(5000.5, 'Handicap 0.5')
MOZZARTBETBasketballMap.set(5001.5, 'Handicap 1.5')
MOZZARTBETBasketballMap.set(5002.5, 'Handicap 2.5')
MOZZARTBETBasketballMap.set(5003.5, 'Handicap 3.5')
MOZZARTBETBasketballMap.set(5004.5, 'Handicap 4.5')
MOZZARTBETBasketballMap.set(5005.5, 'Handicap 5.5')
MOZZARTBETBasketballMap.set(5006.5, 'Handicap 6.5')
MOZZARTBETBasketballMap.set(5007.5, 'Handicap 7.5')
MOZZARTBETBasketballMap.set(5008.5, 'Handicap 8.5')
MOZZARTBETBasketballMap.set(5009.5, 'Handicap 9.5')
MOZZARTBETBasketballMap.set(5010.5, 'Handicap 10.5')
MOZZARTBETBasketballMap.set(5011.5, 'Handicap 11.5')
MOZZARTBETBasketballMap.set(5012.5, 'Handicap 12.5')
MOZZARTBETBasketballMap.set(5013.5, 'Handicap 13.5')
MOZZARTBETBasketballMap.set(5014.5, 'Handicap 14.5')
MOZZARTBETBasketballMap.set(5015.5, 'Handicap 15.5')
MOZZARTBETBasketballMap.set(5016.5, 'Handicap 16.5')
MOZZARTBETBasketballMap.set(5017.5, 'Handicap 17.5')
MOZZARTBETBasketballMap.set(5018.5, 'Handicap 18.5')
MOZZARTBETBasketballMap.set(5019.5, 'Handicap 19.5')
MOZZARTBETBasketballMap.set(5020.5, 'Handicap 20.5')
MOZZARTBETBasketballMap.set(5021.5, 'Handicap 21.5')
MOZZARTBETBasketballMap.set(5022.5, 'Handicap 22.5')
MOZZARTBETBasketballMap.set(5023.5, 'Handicap 23.5')
MOZZARTBETBasketballMap.set(5024.5, 'Handicap 24.5')
MOZZARTBETBasketballMap.set(5025.5, 'Handicap 25.5')
MOZZARTBETBasketballMap.set(5026.5, 'Handicap 26.5')
MOZZARTBETBasketballMap.set(5027.5, 'Handicap 27.5')
MOZZARTBETBasketballMap.set(5028.5, 'Handicap 28.5')
MOZZARTBETBasketballMap.set(5029.5, 'Handicap 29.5')
MOZZARTBETBasketballMap.set(5030.5, 'Handicap 30.5')
MOZZARTBETBasketballMap.set(5031.5, 'Handicap 31.5')
MOZZARTBETBasketballMap.set(5032.5, 'Handicap 32.5')
MOZZARTBETBasketballMap.set(5033.5, 'Handicap 33.5')
MOZZARTBETBasketballMap.set(5034.5, 'Handicap 34.5')
MOZZARTBETBasketballMap.set(5035.5, 'Handicap 35.5')
MOZZARTBETBasketballOutcomeMap.set('4964.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4964.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4965.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4965.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4966.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4966.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4967.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4967.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4968.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4968.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4969.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4969.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4970.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4970.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4971.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4971.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4972.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4972.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4973.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4973.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4974.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4974.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4975.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4975.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4976.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4976.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4977.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4977.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4978.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4978.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4979.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4979.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4980.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4980.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4981.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4981.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4982.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4982.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4983.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4983.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4984.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4984.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4985.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4985.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4986.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4986.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4987.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4987.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4988.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4988.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4989.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4989.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4990.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4990.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4991.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4991.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4992.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4992.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4993.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4993.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4994.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4994.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4995.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4995.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4996.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4996.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4997.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4997.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4998.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4998.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('4999.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('4999.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5000.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5000.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5001.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5001.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5002.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5002.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5003.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5003.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5004.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5004.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5005.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5005.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5006.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5006.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5007.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5007.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5008.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5008.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5009.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5009.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5010.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5010.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5011.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5011.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5012.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5012.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5013.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5013.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5014.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5014.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5015.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5015.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5016.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5016.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5017.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5017.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5018.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5018.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5019.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5019.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5020.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5020.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5021.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5021.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5022.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5022.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5023.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5023.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5024.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5024.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5025.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5025.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5026.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5026.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5027.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5027.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5028.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5028.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5029.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5029.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5030.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5030.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5031.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5031.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5032.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5032.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5033.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5033.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5034.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5034.5@3', '2')
MOZZARTBETBasketballOutcomeMap.set('5035.5@1', '1')
MOZZARTBETBasketballOutcomeMap.set('5035.5@3', '2')
// FTOU
MOZZARTBETBasketballMap.set(3110.5, 'O/U 110.5')
MOZZARTBETBasketballOutcomeMap.set('3110.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3110.5@3', 'o')
MOZZARTBETBasketballMap.set(3111.5, 'O/U 111.5')
MOZZARTBETBasketballOutcomeMap.set('3111.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3111.5@3', 'o')
MOZZARTBETBasketballMap.set(3112.5, 'O/U 112.5')
MOZZARTBETBasketballOutcomeMap.set('3112.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3112.5@3', 'o')
MOZZARTBETBasketballMap.set(3113.5, 'O/U 113.5')
MOZZARTBETBasketballOutcomeMap.set('3113.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3113.5@3', 'o')
MOZZARTBETBasketballMap.set(3114.5, 'O/U 114.5')
MOZZARTBETBasketballOutcomeMap.set('3114.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3114.5@3', 'o')
MOZZARTBETBasketballMap.set(3115.5, 'O/U 115.5')
MOZZARTBETBasketballOutcomeMap.set('3115.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3115.5@3', 'o')
MOZZARTBETBasketballMap.set(3116.5, 'O/U 116.5')
MOZZARTBETBasketballOutcomeMap.set('3116.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3116.5@3', 'o')
MOZZARTBETBasketballMap.set(3117.5, 'O/U 117.5')
MOZZARTBETBasketballOutcomeMap.set('3117.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3117.5@3', 'o')
MOZZARTBETBasketballMap.set(3118.5, 'O/U 118.5')
MOZZARTBETBasketballOutcomeMap.set('3118.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3118.5@3', 'o')
MOZZARTBETBasketballMap.set(3119.5, 'O/U 119.5')
MOZZARTBETBasketballOutcomeMap.set('3119.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3119.5@3', 'o')
MOZZARTBETBasketballMap.set(3120.5, 'O/U 120.5')
MOZZARTBETBasketballOutcomeMap.set('3120.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3120.5@3', 'o')
MOZZARTBETBasketballMap.set(3121.5, 'O/U 121.5')
MOZZARTBETBasketballOutcomeMap.set('3121.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3121.5@3', 'o')
MOZZARTBETBasketballMap.set(3122.5, 'O/U 122.5')
MOZZARTBETBasketballOutcomeMap.set('3122.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3122.5@3', 'o')
MOZZARTBETBasketballMap.set(3123.5, 'O/U 123.5')
MOZZARTBETBasketballOutcomeMap.set('3123.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3123.5@3', 'o')
MOZZARTBETBasketballMap.set(3124.5, 'O/U 124.5')
MOZZARTBETBasketballOutcomeMap.set('3124.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3124.5@3', 'o')
MOZZARTBETBasketballMap.set(3125.5, 'O/U 125.5')
MOZZARTBETBasketballOutcomeMap.set('3125.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3125.5@3', 'o')
MOZZARTBETBasketballMap.set(3126.5, 'O/U 126.5')
MOZZARTBETBasketballOutcomeMap.set('3126.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3126.5@3', 'o')
MOZZARTBETBasketballMap.set(3127.5, 'O/U 127.5')
MOZZARTBETBasketballOutcomeMap.set('3127.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3127.5@3', 'o')
MOZZARTBETBasketballMap.set(3128.5, 'O/U 128.5')
MOZZARTBETBasketballOutcomeMap.set('3128.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3128.5@3', 'o')
MOZZARTBETBasketballMap.set(3129.5, 'O/U 129.5')
MOZZARTBETBasketballOutcomeMap.set('3129.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3129.5@3', 'o')
MOZZARTBETBasketballMap.set(3130.5, 'O/U 130.5')
MOZZARTBETBasketballOutcomeMap.set('3130.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3130.5@3', 'o')
MOZZARTBETBasketballMap.set(3131.5, 'O/U 131.5')
MOZZARTBETBasketballOutcomeMap.set('3131.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3131.5@3', 'o')
MOZZARTBETBasketballMap.set(3132.5, 'O/U 132.5')
MOZZARTBETBasketballOutcomeMap.set('3132.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3132.5@3', 'o')
MOZZARTBETBasketballMap.set(3133.5, 'O/U 133.5')
MOZZARTBETBasketballOutcomeMap.set('3133.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3133.5@3', 'o')
MOZZARTBETBasketballMap.set(3134.5, 'O/U 134.5')
MOZZARTBETBasketballOutcomeMap.set('3134.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3134.5@3', 'o')
MOZZARTBETBasketballMap.set(3135.5, 'O/U 135.5')
MOZZARTBETBasketballOutcomeMap.set('3135.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3135.5@3', 'o')
MOZZARTBETBasketballMap.set(3136.5, 'O/U 136.5')
MOZZARTBETBasketballOutcomeMap.set('3136.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3136.5@3', 'o')
MOZZARTBETBasketballMap.set(3137.5, 'O/U 137.5')
MOZZARTBETBasketballOutcomeMap.set('3137.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3137.5@3', 'o')
MOZZARTBETBasketballMap.set(3138.5, 'O/U 138.5')
MOZZARTBETBasketballOutcomeMap.set('3138.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3138.5@3', 'o')
MOZZARTBETBasketballMap.set(3139.5, 'O/U 139.5')
MOZZARTBETBasketballOutcomeMap.set('3139.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3139.5@3', 'o')
MOZZARTBETBasketballMap.set(3140.5, 'O/U 140.5')
MOZZARTBETBasketballOutcomeMap.set('3140.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3140.5@3', 'o')
MOZZARTBETBasketballMap.set(3141.5, 'O/U 141.5')
MOZZARTBETBasketballOutcomeMap.set('3141.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3141.5@3', 'o')
MOZZARTBETBasketballMap.set(3142.5, 'O/U 142.5')
MOZZARTBETBasketballOutcomeMap.set('3142.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3142.5@3', 'o')
MOZZARTBETBasketballMap.set(3143.5, 'O/U 143.5')
MOZZARTBETBasketballOutcomeMap.set('3143.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3143.5@3', 'o')
MOZZARTBETBasketballMap.set(3144.5, 'O/U 144.5')
MOZZARTBETBasketballOutcomeMap.set('3144.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3144.5@3', 'o')
MOZZARTBETBasketballMap.set(3145.5, 'O/U 145.5')
MOZZARTBETBasketballOutcomeMap.set('3145.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3145.5@3', 'o')
MOZZARTBETBasketballMap.set(3146.5, 'O/U 146.5')
MOZZARTBETBasketballOutcomeMap.set('3146.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3146.5@3', 'o')
MOZZARTBETBasketballMap.set(3147.5, 'O/U 147.5')
MOZZARTBETBasketballOutcomeMap.set('3147.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3147.5@3', 'o')
MOZZARTBETBasketballMap.set(3148.5, 'O/U 148.5')
MOZZARTBETBasketballOutcomeMap.set('3148.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3148.5@3', 'o')
MOZZARTBETBasketballMap.set(3149.5, 'O/U 149.5')
MOZZARTBETBasketballOutcomeMap.set('3149.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3149.5@3', 'o')
MOZZARTBETBasketballMap.set(3150.5, 'O/U 150.5')
MOZZARTBETBasketballOutcomeMap.set('3150.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3150.5@3', 'o')
MOZZARTBETBasketballMap.set(3151.5, 'O/U 151.5')
MOZZARTBETBasketballOutcomeMap.set('3151.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3151.5@3', 'o')
MOZZARTBETBasketballMap.set(3152.5, 'O/U 152.5')
MOZZARTBETBasketballOutcomeMap.set('3152.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3152.5@3', 'o')
MOZZARTBETBasketballMap.set(3153.5, 'O/U 153.5')
MOZZARTBETBasketballOutcomeMap.set('3153.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3153.5@3', 'o')
MOZZARTBETBasketballMap.set(3154.5, 'O/U 154.5')
MOZZARTBETBasketballOutcomeMap.set('3154.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3154.5@3', 'o')
MOZZARTBETBasketballMap.set(3155.5, 'O/U 155.5')
MOZZARTBETBasketballOutcomeMap.set('3155.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3155.5@3', 'o')
MOZZARTBETBasketballMap.set(3156.5, 'O/U 156.5')
MOZZARTBETBasketballOutcomeMap.set('3156.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3156.5@3', 'o')
MOZZARTBETBasketballMap.set(3157.5, 'O/U 157.5')
MOZZARTBETBasketballOutcomeMap.set('3157.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3157.5@3', 'o')
MOZZARTBETBasketballMap.set(3158.5, 'O/U 158.5')
MOZZARTBETBasketballOutcomeMap.set('3158.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3158.5@3', 'o')
MOZZARTBETBasketballMap.set(3159.5, 'O/U 159.5')
MOZZARTBETBasketballOutcomeMap.set('3159.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3159.5@3', 'o')
MOZZARTBETBasketballMap.set(3160.5, 'O/U 160.5')
MOZZARTBETBasketballOutcomeMap.set('3160.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3160.5@3', 'o')
MOZZARTBETBasketballMap.set(3161.5, 'O/U 161.5')
MOZZARTBETBasketballOutcomeMap.set('3161.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3161.5@3', 'o')
MOZZARTBETBasketballMap.set(3162.5, 'O/U 162.5')
MOZZARTBETBasketballOutcomeMap.set('3162.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3162.5@3', 'o')
MOZZARTBETBasketballMap.set(3163.5, 'O/U 163.5')
MOZZARTBETBasketballOutcomeMap.set('3163.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3163.5@3', 'o')
MOZZARTBETBasketballMap.set(3164.5, 'O/U 164.5')
MOZZARTBETBasketballOutcomeMap.set('3164.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3164.5@3', 'o')
MOZZARTBETBasketballMap.set(3165.5, 'O/U 165.5')
MOZZARTBETBasketballOutcomeMap.set('3165.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3165.5@3', 'o')
MOZZARTBETBasketballMap.set(3166.5, 'O/U 166.5')
MOZZARTBETBasketballOutcomeMap.set('3166.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3166.5@3', 'o')
MOZZARTBETBasketballMap.set(3167.5, 'O/U 167.5')
MOZZARTBETBasketballOutcomeMap.set('3167.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3167.5@3', 'o')
MOZZARTBETBasketballMap.set(3168.5, 'O/U 168.5')
MOZZARTBETBasketballOutcomeMap.set('3168.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3168.5@3', 'o')
MOZZARTBETBasketballMap.set(3169.5, 'O/U 169.5')
MOZZARTBETBasketballOutcomeMap.set('3169.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3169.5@3', 'o')
MOZZARTBETBasketballMap.set(3170.5, 'O/U 170.5')
MOZZARTBETBasketballOutcomeMap.set('3170.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3170.5@3', 'o')
MOZZARTBETBasketballMap.set(3171.5, 'O/U 171.5')
MOZZARTBETBasketballOutcomeMap.set('3171.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3171.5@3', 'o')
MOZZARTBETBasketballMap.set(3172.5, 'O/U 172.5')
MOZZARTBETBasketballOutcomeMap.set('3172.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3172.5@3', 'o')
MOZZARTBETBasketballMap.set(3173.5, 'O/U 173.5')
MOZZARTBETBasketballOutcomeMap.set('3173.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3173.5@3', 'o')
MOZZARTBETBasketballMap.set(3174.5, 'O/U 174.5')
MOZZARTBETBasketballOutcomeMap.set('3174.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3174.5@3', 'o')
MOZZARTBETBasketballMap.set(3175.5, 'O/U 175.5')
MOZZARTBETBasketballOutcomeMap.set('3175.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3175.5@3', 'o')
MOZZARTBETBasketballMap.set(3176.5, 'O/U 176.5')
MOZZARTBETBasketballOutcomeMap.set('3176.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3176.5@3', 'o')
MOZZARTBETBasketballMap.set(3177.5, 'O/U 177.5')
MOZZARTBETBasketballOutcomeMap.set('3177.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3177.5@3', 'o')
MOZZARTBETBasketballMap.set(3178.5, 'O/U 178.5')
MOZZARTBETBasketballOutcomeMap.set('3178.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3178.5@3', 'o')
MOZZARTBETBasketballMap.set(3179.5, 'O/U 179.5')
MOZZARTBETBasketballOutcomeMap.set('3179.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3179.5@3', 'o')
MOZZARTBETBasketballMap.set(3180.5, 'O/U 180.5')
MOZZARTBETBasketballOutcomeMap.set('3180.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3180.5@3', 'o')
MOZZARTBETBasketballMap.set(3181.5, 'O/U 181.5')
MOZZARTBETBasketballOutcomeMap.set('3181.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3181.5@3', 'o')
MOZZARTBETBasketballMap.set(3182.5, 'O/U 182.5')
MOZZARTBETBasketballOutcomeMap.set('3182.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3182.5@3', 'o')
MOZZARTBETBasketballMap.set(3183.5, 'O/U 183.5')
MOZZARTBETBasketballOutcomeMap.set('3183.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3183.5@3', 'o')
MOZZARTBETBasketballMap.set(3184.5, 'O/U 184.5')
MOZZARTBETBasketballOutcomeMap.set('3184.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3184.5@3', 'o')
MOZZARTBETBasketballMap.set(3185.5, 'O/U 185.5')
MOZZARTBETBasketballOutcomeMap.set('3185.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3185.5@3', 'o')
MOZZARTBETBasketballMap.set(3186.5, 'O/U 186.5')
MOZZARTBETBasketballOutcomeMap.set('3186.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3186.5@3', 'o')
MOZZARTBETBasketballMap.set(3187.5, 'O/U 187.5')
MOZZARTBETBasketballOutcomeMap.set('3187.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3187.5@3', 'o')
MOZZARTBETBasketballMap.set(3188.5, 'O/U 188.5')
MOZZARTBETBasketballOutcomeMap.set('3188.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3188.5@3', 'o')
MOZZARTBETBasketballMap.set(3189.5, 'O/U 189.5')
MOZZARTBETBasketballOutcomeMap.set('3189.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3189.5@3', 'o')
MOZZARTBETBasketballMap.set(3190.5, 'O/U 190.5')
MOZZARTBETBasketballOutcomeMap.set('3190.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3190.5@3', 'o')
MOZZARTBETBasketballMap.set(3191.5, 'O/U 191.5')
MOZZARTBETBasketballOutcomeMap.set('3191.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3191.5@3', 'o')
MOZZARTBETBasketballMap.set(3192.5, 'O/U 192.5')
MOZZARTBETBasketballOutcomeMap.set('3192.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3192.5@3', 'o')
MOZZARTBETBasketballMap.set(3193.5, 'O/U 193.5')
MOZZARTBETBasketballOutcomeMap.set('3193.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3193.5@3', 'o')
MOZZARTBETBasketballMap.set(3194.5, 'O/U 194.5')
MOZZARTBETBasketballOutcomeMap.set('3194.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3194.5@3', 'o')
MOZZARTBETBasketballMap.set(3195.5, 'O/U 195.5')
MOZZARTBETBasketballOutcomeMap.set('3195.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3195.5@3', 'o')
MOZZARTBETBasketballMap.set(3196.5, 'O/U 196.5')
MOZZARTBETBasketballOutcomeMap.set('3196.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3196.5@3', 'o')
MOZZARTBETBasketballMap.set(3197.5, 'O/U 197.5')
MOZZARTBETBasketballOutcomeMap.set('3197.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3197.5@3', 'o')
MOZZARTBETBasketballMap.set(3198.5, 'O/U 198.5')
MOZZARTBETBasketballOutcomeMap.set('3198.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3198.5@3', 'o')
MOZZARTBETBasketballMap.set(3199.5, 'O/U 199.5')
MOZZARTBETBasketballOutcomeMap.set('3199.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3199.5@3', 'o')
MOZZARTBETBasketballMap.set(3200.5, 'O/U 200.5')
MOZZARTBETBasketballOutcomeMap.set('3200.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3200.5@3', 'o')
MOZZARTBETBasketballMap.set(3201.5, 'O/U 201.5')
MOZZARTBETBasketballOutcomeMap.set('3201.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3201.5@3', 'o')
MOZZARTBETBasketballMap.set(3202.5, 'O/U 202.5')
MOZZARTBETBasketballOutcomeMap.set('3202.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3202.5@3', 'o')
MOZZARTBETBasketballMap.set(3203.5, 'O/U 203.5')
MOZZARTBETBasketballOutcomeMap.set('3203.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3203.5@3', 'o')
MOZZARTBETBasketballMap.set(3204.5, 'O/U 204.5')
MOZZARTBETBasketballOutcomeMap.set('3204.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3204.5@3', 'o')
MOZZARTBETBasketballMap.set(3205.5, 'O/U 205.5')
MOZZARTBETBasketballOutcomeMap.set('3205.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3205.5@3', 'o')
MOZZARTBETBasketballMap.set(3206.5, 'O/U 206.5')
MOZZARTBETBasketballOutcomeMap.set('3206.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3206.5@3', 'o')
MOZZARTBETBasketballMap.set(3207.5, 'O/U 207.5')
MOZZARTBETBasketballOutcomeMap.set('3207.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3207.5@3', 'o')
MOZZARTBETBasketballMap.set(3208.5, 'O/U 208.5')
MOZZARTBETBasketballOutcomeMap.set('3208.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3208.5@3', 'o')
MOZZARTBETBasketballMap.set(3209.5, 'O/U 209.5')
MOZZARTBETBasketballOutcomeMap.set('3209.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3209.5@3', 'o')
MOZZARTBETBasketballMap.set(3210.5, 'O/U 210.5')
MOZZARTBETBasketballOutcomeMap.set('3210.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3210.5@3', 'o')
MOZZARTBETBasketballMap.set(3211.5, 'O/U 211.5')
MOZZARTBETBasketballOutcomeMap.set('3211.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3211.5@3', 'o')
MOZZARTBETBasketballMap.set(3212.5, 'O/U 212.5')
MOZZARTBETBasketballOutcomeMap.set('3212.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3212.5@3', 'o')
MOZZARTBETBasketballMap.set(3213.5, 'O/U 213.5')
MOZZARTBETBasketballOutcomeMap.set('3213.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3213.5@3', 'o')
MOZZARTBETBasketballMap.set(3214.5, 'O/U 214.5')
MOZZARTBETBasketballOutcomeMap.set('3214.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3214.5@3', 'o')
MOZZARTBETBasketballMap.set(3215.5, 'O/U 215.5')
MOZZARTBETBasketballOutcomeMap.set('3215.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3215.5@3', 'o')
MOZZARTBETBasketballMap.set(3216.5, 'O/U 216.5')
MOZZARTBETBasketballOutcomeMap.set('3216.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3216.5@3', 'o')
MOZZARTBETBasketballMap.set(3217.5, 'O/U 217.5')
MOZZARTBETBasketballOutcomeMap.set('3217.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3217.5@3', 'o')
MOZZARTBETBasketballMap.set(3218.5, 'O/U 218.5')
MOZZARTBETBasketballOutcomeMap.set('3218.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3218.5@3', 'o')
MOZZARTBETBasketballMap.set(3219.5, 'O/U 219.5')
MOZZARTBETBasketballOutcomeMap.set('3219.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3219.5@3', 'o')
MOZZARTBETBasketballMap.set(3220.5, 'O/U 220.5')
MOZZARTBETBasketballOutcomeMap.set('3220.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3220.5@3', 'o')
MOZZARTBETBasketballMap.set(3221.5, 'O/U 221.5')
MOZZARTBETBasketballOutcomeMap.set('3221.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3221.5@3', 'o')
MOZZARTBETBasketballMap.set(3222.5, 'O/U 222.5')
MOZZARTBETBasketballOutcomeMap.set('3222.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3222.5@3', 'o')
MOZZARTBETBasketballMap.set(3223.5, 'O/U 223.5')
MOZZARTBETBasketballOutcomeMap.set('3223.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3223.5@3', 'o')
MOZZARTBETBasketballMap.set(3224.5, 'O/U 224.5')
MOZZARTBETBasketballOutcomeMap.set('3224.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3224.5@3', 'o')
MOZZARTBETBasketballMap.set(3225.5, 'O/U 225.5')
MOZZARTBETBasketballOutcomeMap.set('3225.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3225.5@3', 'o')
MOZZARTBETBasketballMap.set(3226.5, 'O/U 226.5')
MOZZARTBETBasketballOutcomeMap.set('3226.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3226.5@3', 'o')
MOZZARTBETBasketballMap.set(3227.5, 'O/U 227.5')
MOZZARTBETBasketballOutcomeMap.set('3227.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3227.5@3', 'o')
MOZZARTBETBasketballMap.set(3228.5, 'O/U 228.5')
MOZZARTBETBasketballOutcomeMap.set('3228.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3228.5@3', 'o')
MOZZARTBETBasketballMap.set(3229.5, 'O/U 229.5')
MOZZARTBETBasketballOutcomeMap.set('3229.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3229.5@3', 'o')
MOZZARTBETBasketballMap.set(3230.5, 'O/U 230.5')
MOZZARTBETBasketballOutcomeMap.set('3230.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3230.5@3', 'o')
MOZZARTBETBasketballMap.set(3231.5, 'O/U 231.5')
MOZZARTBETBasketballOutcomeMap.set('3231.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3231.5@3', 'o')
MOZZARTBETBasketballMap.set(3232.5, 'O/U 232.5')
MOZZARTBETBasketballOutcomeMap.set('3232.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3232.5@3', 'o')
MOZZARTBETBasketballMap.set(3233.5, 'O/U 233.5')
MOZZARTBETBasketballOutcomeMap.set('3233.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3233.5@3', 'o')
MOZZARTBETBasketballMap.set(3234.5, 'O/U 234.5')
MOZZARTBETBasketballOutcomeMap.set('3234.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3234.5@3', 'o')
MOZZARTBETBasketballMap.set(3235.5, 'O/U 235.5')
MOZZARTBETBasketballOutcomeMap.set('3235.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3235.5@3', 'o')
MOZZARTBETBasketballMap.set(3236.5, 'O/U 236.5')
MOZZARTBETBasketballOutcomeMap.set('3236.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3236.5@3', 'o')
MOZZARTBETBasketballMap.set(3237.5, 'O/U 237.5')
MOZZARTBETBasketballOutcomeMap.set('3237.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3237.5@3', 'o')
MOZZARTBETBasketballMap.set(3238.5, 'O/U 238.5')
MOZZARTBETBasketballOutcomeMap.set('3238.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3238.5@3', 'o')
MOZZARTBETBasketballMap.set(3239.5, 'O/U 239.5')
MOZZARTBETBasketballOutcomeMap.set('3239.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3239.5@3', 'o')
MOZZARTBETBasketballMap.set(3240.5, 'O/U 240.5')
MOZZARTBETBasketballOutcomeMap.set('3240.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3240.5@3', 'o')
MOZZARTBETBasketballMap.set(3241.5, 'O/U 241.5')
MOZZARTBETBasketballOutcomeMap.set('3241.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3241.5@3', 'o')
MOZZARTBETBasketballMap.set(3242.5, 'O/U 242.5')
MOZZARTBETBasketballOutcomeMap.set('3242.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3242.5@3', 'o')
MOZZARTBETBasketballMap.set(3243.5, 'O/U 243.5')
MOZZARTBETBasketballOutcomeMap.set('3243.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3243.5@3', 'o')
MOZZARTBETBasketballMap.set(3244.5, 'O/U 244.5')
MOZZARTBETBasketballOutcomeMap.set('3244.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3244.5@3', 'o')
MOZZARTBETBasketballMap.set(3245.5, 'O/U 245.5')
MOZZARTBETBasketballOutcomeMap.set('3245.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3245.5@3', 'o')
MOZZARTBETBasketballMap.set(3246.5, 'O/U 246.5')
MOZZARTBETBasketballOutcomeMap.set('3246.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3246.5@3', 'o')
MOZZARTBETBasketballMap.set(3247.5, 'O/U 247.5')
MOZZARTBETBasketballOutcomeMap.set('3247.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3247.5@3', 'o')
MOZZARTBETBasketballMap.set(3248.5, 'O/U 248.5')
MOZZARTBETBasketballOutcomeMap.set('3248.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3248.5@3', 'o')
MOZZARTBETBasketballMap.set(3249.5, 'O/U 249.5')
MOZZARTBETBasketballOutcomeMap.set('3249.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3249.5@3', 'o')
MOZZARTBETBasketballMap.set(3250.5, 'O/U 250.5')
MOZZARTBETBasketballOutcomeMap.set('3250.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3250.5@3', 'o')
MOZZARTBETBasketballMap.set(3251.5, 'O/U 251.5')
MOZZARTBETBasketballOutcomeMap.set('3251.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3251.5@3', 'o')
MOZZARTBETBasketballMap.set(3252.5, 'O/U 252.5')
MOZZARTBETBasketballOutcomeMap.set('3252.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3252.5@3', 'o')
MOZZARTBETBasketballMap.set(3253.5, 'O/U 253.5')
MOZZARTBETBasketballOutcomeMap.set('3253.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3253.5@3', 'o')
MOZZARTBETBasketballMap.set(3254.5, 'O/U 254.5')
MOZZARTBETBasketballOutcomeMap.set('3254.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3254.5@3', 'o')
MOZZARTBETBasketballMap.set(3255.5, 'O/U 255.5')
MOZZARTBETBasketballOutcomeMap.set('3255.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3255.5@3', 'o')
MOZZARTBETBasketballMap.set(3256.5, 'O/U 256.5')
MOZZARTBETBasketballOutcomeMap.set('3256.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3256.5@3', 'o')
MOZZARTBETBasketballMap.set(3257.5, 'O/U 257.5')
MOZZARTBETBasketballOutcomeMap.set('3257.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3257.5@3', 'o')
MOZZARTBETBasketballMap.set(3258.5, 'O/U 258.5')
MOZZARTBETBasketballOutcomeMap.set('3258.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3258.5@3', 'o')
MOZZARTBETBasketballMap.set(3259.5, 'O/U 259.5')
MOZZARTBETBasketballOutcomeMap.set('3259.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3259.5@3', 'o')
MOZZARTBETBasketballMap.set(3260.5, 'O/U 260.5')
MOZZARTBETBasketballOutcomeMap.set('3260.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3260.5@3', 'o')
MOZZARTBETBasketballMap.set(3261.5, 'O/U 261.5')
MOZZARTBETBasketballOutcomeMap.set('3261.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3261.5@3', 'o')
MOZZARTBETBasketballMap.set(3262.5, 'O/U 262.5')
MOZZARTBETBasketballOutcomeMap.set('3262.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3262.5@3', 'o')
MOZZARTBETBasketballMap.set(3263.5, 'O/U 263.5')
MOZZARTBETBasketballOutcomeMap.set('3263.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3263.5@3', 'o')
MOZZARTBETBasketballMap.set(3264.5, 'O/U 264.5')
MOZZARTBETBasketballOutcomeMap.set('3264.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3264.5@3', 'o')
MOZZARTBETBasketballMap.set(3265.5, 'O/U 265.5')
MOZZARTBETBasketballOutcomeMap.set('3265.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3265.5@3', 'o')
MOZZARTBETBasketballMap.set(3266.5, 'O/U 266.5')
MOZZARTBETBasketballOutcomeMap.set('3266.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3266.5@3', 'o')
MOZZARTBETBasketballMap.set(3267.5, 'O/U 267.5')
MOZZARTBETBasketballOutcomeMap.set('3267.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3267.5@3', 'o')
MOZZARTBETBasketballMap.set(3268.5, 'O/U 268.5')
MOZZARTBETBasketballOutcomeMap.set('3268.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3268.5@3', 'o')
MOZZARTBETBasketballMap.set(3269.5, 'O/U 269.5')
MOZZARTBETBasketballOutcomeMap.set('3269.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3269.5@3', 'o')
MOZZARTBETBasketballMap.set(3270.5, 'O/U 270.5')
MOZZARTBETBasketballOutcomeMap.set('3270.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3270.5@3', 'o')
MOZZARTBETBasketballMap.set(3271.5, 'O/U 271.5')
MOZZARTBETBasketballOutcomeMap.set('3271.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3271.5@3', 'o')
MOZZARTBETBasketballMap.set(3272.5, 'O/U 272.5')
MOZZARTBETBasketballOutcomeMap.set('3272.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3272.5@3', 'o')
MOZZARTBETBasketballMap.set(3273.5, 'O/U 273.5')
MOZZARTBETBasketballOutcomeMap.set('3273.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3273.5@3', 'o')
MOZZARTBETBasketballMap.set(3274.5, 'O/U 274.5')
MOZZARTBETBasketballOutcomeMap.set('3274.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3274.5@3', 'o')
MOZZARTBETBasketballMap.set(3275.5, 'O/U 275.5')
MOZZARTBETBasketballOutcomeMap.set('3275.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3275.5@3', 'o')
MOZZARTBETBasketballMap.set(3276.5, 'O/U 276.5')
MOZZARTBETBasketballOutcomeMap.set('3276.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3276.5@3', 'o')
MOZZARTBETBasketballMap.set(3277.5, 'O/U 277.5')
MOZZARTBETBasketballOutcomeMap.set('3277.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3277.5@3', 'o')
MOZZARTBETBasketballMap.set(3278.5, 'O/U 278.5')
MOZZARTBETBasketballOutcomeMap.set('3278.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3278.5@3', 'o')
MOZZARTBETBasketballMap.set(3279.5, 'O/U 279.5')
MOZZARTBETBasketballOutcomeMap.set('3279.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3279.5@3', 'o')
MOZZARTBETBasketballMap.set(3280.5, 'O/U 280.5')
MOZZARTBETBasketballOutcomeMap.set('3280.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3280.5@3', 'o')
MOZZARTBETBasketballMap.set(3281.5, 'O/U 281.5')
MOZZARTBETBasketballOutcomeMap.set('3281.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3281.5@3', 'o')
MOZZARTBETBasketballMap.set(3282.5, 'O/U 282.5')
MOZZARTBETBasketballOutcomeMap.set('3282.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3282.5@3', 'o')
MOZZARTBETBasketballMap.set(3283.5, 'O/U 283.5')
MOZZARTBETBasketballOutcomeMap.set('3283.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3283.5@3', 'o')
MOZZARTBETBasketballMap.set(3284.5, 'O/U 284.5')
MOZZARTBETBasketballOutcomeMap.set('3284.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3284.5@3', 'o')
MOZZARTBETBasketballMap.set(3285.5, 'O/U 285.5')
MOZZARTBETBasketballOutcomeMap.set('3285.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3285.5@3', 'o')
MOZZARTBETBasketballMap.set(3286.5, 'O/U 286.5')
MOZZARTBETBasketballOutcomeMap.set('3286.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3286.5@3', 'o')
MOZZARTBETBasketballMap.set(3287.5, 'O/U 287.5')
MOZZARTBETBasketballOutcomeMap.set('3287.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3287.5@3', 'o')
MOZZARTBETBasketballMap.set(3288.5, 'O/U 288.5')
MOZZARTBETBasketballOutcomeMap.set('3288.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3288.5@3', 'o')
MOZZARTBETBasketballMap.set(3289.5, 'O/U 289.5')
MOZZARTBETBasketballOutcomeMap.set('3289.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3289.5@3', 'o')
MOZZARTBETBasketballMap.set(3290.5, 'O/U 290.5')
MOZZARTBETBasketballOutcomeMap.set('3290.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3290.5@3', 'o')
MOZZARTBETBasketballMap.set(3291.5, 'O/U 291.5')
MOZZARTBETBasketballOutcomeMap.set('3291.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3291.5@3', 'o')
MOZZARTBETBasketballMap.set(3292.5, 'O/U 292.5')
MOZZARTBETBasketballOutcomeMap.set('3292.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3292.5@3', 'o')
MOZZARTBETBasketballMap.set(3293.5, 'O/U 293.5')
MOZZARTBETBasketballOutcomeMap.set('3293.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3293.5@3', 'o')
MOZZARTBETBasketballMap.set(3294.5, 'O/U 294.5')
MOZZARTBETBasketballOutcomeMap.set('3294.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3294.5@3', 'o')
MOZZARTBETBasketballMap.set(3295.5, 'O/U 295.5')
MOZZARTBETBasketballOutcomeMap.set('3295.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3295.5@3', 'o')
MOZZARTBETBasketballMap.set(3296.5, 'O/U 296.5')
MOZZARTBETBasketballOutcomeMap.set('3296.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3296.5@3', 'o')
MOZZARTBETBasketballMap.set(3297.5, 'O/U 297.5')
MOZZARTBETBasketballOutcomeMap.set('3297.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3297.5@3', 'o')
MOZZARTBETBasketballMap.set(3298.5, 'O/U 298.5')
MOZZARTBETBasketballOutcomeMap.set('3298.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3298.5@3', 'o')
MOZZARTBETBasketballMap.set(3299.5, 'O/U 299.5')
MOZZARTBETBasketballOutcomeMap.set('3299.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3299.5@3', 'o')
MOZZARTBETBasketballMap.set(3300.5, 'O/U 300.5')
MOZZARTBETBasketballOutcomeMap.set('3300.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3300.5@3', 'o')
MOZZARTBETBasketballMap.set(3301.5, 'O/U 301.5')
MOZZARTBETBasketballOutcomeMap.set('3301.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3301.5@3', 'o')
MOZZARTBETBasketballMap.set(3302.5, 'O/U 302.5')
MOZZARTBETBasketballOutcomeMap.set('3302.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3302.5@3', 'o')
MOZZARTBETBasketballMap.set(3303.5, 'O/U 303.5')
MOZZARTBETBasketballOutcomeMap.set('3303.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3303.5@3', 'o')
MOZZARTBETBasketballMap.set(3304.5, 'O/U 304.5')
MOZZARTBETBasketballOutcomeMap.set('3304.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3304.5@3', 'o')
MOZZARTBETBasketballMap.set(3305.5, 'O/U 305.5')
MOZZARTBETBasketballOutcomeMap.set('3305.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3305.5@3', 'o')
MOZZARTBETBasketballMap.set(3306.5, 'O/U 306.5')
MOZZARTBETBasketballOutcomeMap.set('3306.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3306.5@3', 'o')
MOZZARTBETBasketballMap.set(3307.5, 'O/U 307.5')
MOZZARTBETBasketballOutcomeMap.set('3307.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3307.5@3', 'o')
MOZZARTBETBasketballMap.set(3308.5, 'O/U 308.5')
MOZZARTBETBasketballOutcomeMap.set('3308.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3308.5@3', 'o')
MOZZARTBETBasketballMap.set(3309.5, 'O/U 309.5')
MOZZARTBETBasketballOutcomeMap.set('3309.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3309.5@3', 'o')
MOZZARTBETBasketballMap.set(3310.5, 'O/U 310.5')
MOZZARTBETBasketballOutcomeMap.set('3310.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3310.5@3', 'o')
MOZZARTBETBasketballMap.set(3311.5, 'O/U 311.5')
MOZZARTBETBasketballOutcomeMap.set('3311.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3311.5@3', 'o')
MOZZARTBETBasketballMap.set(3312.5, 'O/U 312.5')
MOZZARTBETBasketballOutcomeMap.set('3312.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3312.5@3', 'o')
MOZZARTBETBasketballMap.set(3313.5, 'O/U 313.5')
MOZZARTBETBasketballOutcomeMap.set('3313.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3313.5@3', 'o')
MOZZARTBETBasketballMap.set(3314.5, 'O/U 314.5')
MOZZARTBETBasketballOutcomeMap.set('3314.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3314.5@3', 'o')
MOZZARTBETBasketballMap.set(3315.5, 'O/U 315.5')
MOZZARTBETBasketballOutcomeMap.set('3315.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3315.5@3', 'o')
MOZZARTBETBasketballMap.set(3316.5, 'O/U 316.5')
MOZZARTBETBasketballOutcomeMap.set('3316.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3316.5@3', 'o')
MOZZARTBETBasketballMap.set(3317.5, 'O/U 317.5')
MOZZARTBETBasketballOutcomeMap.set('3317.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3317.5@3', 'o')
MOZZARTBETBasketballMap.set(3318.5, 'O/U 318.5')
MOZZARTBETBasketballOutcomeMap.set('3318.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3318.5@3', 'o')
MOZZARTBETBasketballMap.set(3319.5, 'O/U 319.5')
MOZZARTBETBasketballOutcomeMap.set('3319.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3319.5@3', 'o')
MOZZARTBETBasketballMap.set(3320.5, 'O/U 320.5')
MOZZARTBETBasketballOutcomeMap.set('3320.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('3320.5@3', 'o')

// HTOU
MOZZARTBETBasketballMap.set(4027.5, 'O/U 1st Quarter 27.5')
MOZZARTBETBasketballOutcomeMap.set('4027.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4027.5@2', 'o')
MOZZARTBETBasketballMap.set(4028.5, 'O/U 1st Quarter 28.5')
MOZZARTBETBasketballOutcomeMap.set('4028.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4028.5@2', 'o')
MOZZARTBETBasketballMap.set(4029.5, 'O/U 1st Quarter 29.5')
MOZZARTBETBasketballOutcomeMap.set('4029.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4029.5@2', 'o')
MOZZARTBETBasketballMap.set(4030.5, 'O/U 1st Quarter 30.5')
MOZZARTBETBasketballOutcomeMap.set('4030.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4030.5@2', 'o')
MOZZARTBETBasketballMap.set(4031.5, 'O/U 1st Quarter 31.5')
MOZZARTBETBasketballOutcomeMap.set('4031.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4031.5@2', 'o')
MOZZARTBETBasketballMap.set(4032.5, 'O/U 1st Quarter 32.5')
MOZZARTBETBasketballOutcomeMap.set('4032.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4032.5@2', 'o')
MOZZARTBETBasketballMap.set(4033.5, 'O/U 1st Quarter 33.5')
MOZZARTBETBasketballOutcomeMap.set('4033.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4033.5@2', 'o')
MOZZARTBETBasketballMap.set(4034.5, 'O/U 1st Quarter 34.5')
MOZZARTBETBasketballOutcomeMap.set('4034.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4034.5@2', 'o')
MOZZARTBETBasketballMap.set(4035.5, 'O/U 1st Quarter 35.5')
MOZZARTBETBasketballOutcomeMap.set('4035.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4035.5@2', 'o')
MOZZARTBETBasketballMap.set(4036.5, 'O/U 1st Quarter 36.5')
MOZZARTBETBasketballOutcomeMap.set('4036.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4036.5@2', 'o')
MOZZARTBETBasketballMap.set(4037.5, 'O/U 1st Quarter 37.5')
MOZZARTBETBasketballOutcomeMap.set('4037.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4037.5@2', 'o')
MOZZARTBETBasketballMap.set(4038.5, 'O/U 1st Quarter 38.5')
MOZZARTBETBasketballOutcomeMap.set('4038.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4038.5@2', 'o')
MOZZARTBETBasketballMap.set(4039.5, 'O/U 1st Quarter 39.5')
MOZZARTBETBasketballOutcomeMap.set('4039.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4039.5@2', 'o')
MOZZARTBETBasketballMap.set(4040.5, 'O/U 1st Quarter 40.5')
MOZZARTBETBasketballOutcomeMap.set('4040.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4040.5@2', 'o')
MOZZARTBETBasketballMap.set(4041.5, 'O/U 1st Quarter 41.5')
MOZZARTBETBasketballOutcomeMap.set('4041.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4041.5@2', 'o')
MOZZARTBETBasketballMap.set(4042.5, 'O/U 1st Quarter 42.5')
MOZZARTBETBasketballOutcomeMap.set('4042.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4042.5@2', 'o')
MOZZARTBETBasketballMap.set(4043.5, 'O/U 1st Quarter 43.5')
MOZZARTBETBasketballOutcomeMap.set('4043.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4043.5@2', 'o')
MOZZARTBETBasketballMap.set(4044.5, 'O/U 1st Quarter 44.5')
MOZZARTBETBasketballOutcomeMap.set('4044.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4044.5@2', 'o')
MOZZARTBETBasketballMap.set(4045.5, 'O/U 1st Quarter 45.5')
MOZZARTBETBasketballOutcomeMap.set('4045.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4045.5@2', 'o')
MOZZARTBETBasketballMap.set(4046.5, 'O/U 1st Quarter 46.5')
MOZZARTBETBasketballOutcomeMap.set('4046.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4046.5@2', 'o')
MOZZARTBETBasketballMap.set(4047.5, 'O/U 1st Quarter 47.5')
MOZZARTBETBasketballOutcomeMap.set('4047.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4047.5@2', 'o')
MOZZARTBETBasketballMap.set(4048.5, 'O/U 1st Quarter 48.5')
MOZZARTBETBasketballOutcomeMap.set('4048.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4048.5@2', 'o')
MOZZARTBETBasketballMap.set(4049.5, 'O/U 1st Quarter 49.5')
MOZZARTBETBasketballOutcomeMap.set('4049.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4049.5@2', 'o')
MOZZARTBETBasketballMap.set(4050.5, 'O/U 1st Quarter 50.5')
MOZZARTBETBasketballOutcomeMap.set('4050.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4050.5@2', 'o')
MOZZARTBETBasketballMap.set(4051.5, 'O/U 1st Quarter 51.5')
MOZZARTBETBasketballOutcomeMap.set('4051.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4051.5@2', 'o')
MOZZARTBETBasketballMap.set(4052.5, 'O/U 1st Quarter 52.5')
MOZZARTBETBasketballOutcomeMap.set('4052.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4052.5@2', 'o')
MOZZARTBETBasketballMap.set(4053.5, 'O/U 1st Quarter 53.5')
MOZZARTBETBasketballOutcomeMap.set('4053.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4053.5@2', 'o')
MOZZARTBETBasketballMap.set(4054.5, 'O/U 1st Quarter 54.5')
MOZZARTBETBasketballOutcomeMap.set('4054.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4054.5@2', 'o')
MOZZARTBETBasketballMap.set(4055.5, 'O/U 1st Quarter 55.5')
MOZZARTBETBasketballOutcomeMap.set('4055.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4055.5@2', 'o')
MOZZARTBETBasketballMap.set(4056.5, 'O/U 1st Quarter 56.5')
MOZZARTBETBasketballOutcomeMap.set('4056.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4056.5@2', 'o')
MOZZARTBETBasketballMap.set(4057.5, 'O/U 1st Quarter 57.5')
MOZZARTBETBasketballOutcomeMap.set('4057.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4057.5@2', 'o')
MOZZARTBETBasketballMap.set(4058.5, 'O/U 1st Quarter 58.5')
MOZZARTBETBasketballOutcomeMap.set('4058.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4058.5@2', 'o')
MOZZARTBETBasketballMap.set(4059.5, 'O/U 1st Quarter 59.5')
MOZZARTBETBasketballOutcomeMap.set('4059.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4059.5@2', 'o')
MOZZARTBETBasketballMap.set(4060.5, 'O/U 1st Quarter 60.5')
MOZZARTBETBasketballOutcomeMap.set('4060.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4060.5@2', 'o')
MOZZARTBETBasketballMap.set(4061.5, 'O/U 1st Quarter 61.5')
MOZZARTBETBasketballOutcomeMap.set('4061.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4061.5@2', 'o')
MOZZARTBETBasketballMap.set(4062.5, 'O/U 1st Quarter 62.5')
MOZZARTBETBasketballOutcomeMap.set('4062.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4062.5@2', 'o')
MOZZARTBETBasketballMap.set(4063.5, 'O/U 1st Quarter 63.5')
MOZZARTBETBasketballOutcomeMap.set('4063.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4063.5@2', 'o')
MOZZARTBETBasketballMap.set(4064.5, 'O/U 1st Quarter 64.5')
MOZZARTBETBasketballOutcomeMap.set('4064.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4064.5@2', 'o')
MOZZARTBETBasketballMap.set(4065.5, 'O/U 1st Quarter 65.5')
MOZZARTBETBasketballOutcomeMap.set('4065.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4065.5@2', 'o')
MOZZARTBETBasketballMap.set(4066.5, 'O/U 1st Quarter 66.5')
MOZZARTBETBasketballOutcomeMap.set('4066.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4066.5@2', 'o')
MOZZARTBETBasketballMap.set(4067.5, 'O/U 1st Quarter 67.5')
MOZZARTBETBasketballOutcomeMap.set('4067.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4067.5@2', 'o')
MOZZARTBETBasketballMap.set(4068.5, 'O/U 1st Quarter 68.5')
MOZZARTBETBasketballOutcomeMap.set('4068.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4068.5@2', 'o')
MOZZARTBETBasketballMap.set(4069.5, 'O/U 1st Quarter 69.5')
MOZZARTBETBasketballOutcomeMap.set('4069.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4069.5@2', 'o')
MOZZARTBETBasketballMap.set(4070.5, 'O/U 1st Quarter 70.5')
MOZZARTBETBasketballOutcomeMap.set('4070.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4070.5@2', 'o')
MOZZARTBETBasketballMap.set(4071.5, 'O/U 1st Quarter 71.5')
MOZZARTBETBasketballOutcomeMap.set('4071.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4071.5@2', 'o')
MOZZARTBETBasketballMap.set(4072.5, 'O/U 1st Quarter 72.5')
MOZZARTBETBasketballOutcomeMap.set('4072.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4072.5@2', 'o')
MOZZARTBETBasketballMap.set(4073.5, 'O/U 1st Quarter 73.5')
MOZZARTBETBasketballOutcomeMap.set('4073.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4073.5@2', 'o')
MOZZARTBETBasketballMap.set(4074.5, 'O/U 1st Quarter 74.5')
MOZZARTBETBasketballOutcomeMap.set('4074.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4074.5@2', 'o')
MOZZARTBETBasketballMap.set(4075.5, 'O/U 1st Quarter 75.5')
MOZZARTBETBasketballOutcomeMap.set('4075.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4075.5@2', 'o')
MOZZARTBETBasketballMap.set(4076.5, 'O/U 1st Quarter 76.5')
MOZZARTBETBasketballOutcomeMap.set('4076.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4076.5@2', 'o')
MOZZARTBETBasketballMap.set(4077.5, 'O/U 1st Quarter 77.5')
MOZZARTBETBasketballOutcomeMap.set('4077.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4077.5@2', 'o')
MOZZARTBETBasketballMap.set(4078.5, 'O/U 1st Quarter 78.5')
MOZZARTBETBasketballOutcomeMap.set('4078.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4078.5@2', 'o')
MOZZARTBETBasketballMap.set(4079.5, 'O/U 1st Quarter 79.5')
MOZZARTBETBasketballOutcomeMap.set('4079.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4079.5@2', 'o')
MOZZARTBETBasketballMap.set(4080.5, 'O/U 1st Quarter 80.5')
MOZZARTBETBasketballOutcomeMap.set('4080.5@1', 'u')
MOZZARTBETBasketballOutcomeMap.set('4080.5@2', 'o')
