import * as Sim from '../../sports/soccer/markets'
import * as SimBasketball from '../../sports/basketball/markets'

export const fixDateForAllBrowsers = (dateString: string) =>
  dateString.replace(/-/g, '/')

export const formatScore = (scr: string): number[] => {
  const [h, a] = scr.split('-')
  return [parseInt(h, 10), parseInt(a, 10)]
}

export const titleCase = (s: string) => {
  if (!s) return ''
  return s[0].toUpperCase() + s.slice(1).trim()
}

export const titleCaseSentence = (sentence: string) => {
  if (!sentence) return ''
  return sentence.replace(/\s+/g, ' ').split(' ').map(titleCase).join(' ')
}

export const truncateString = (input: string, length: number) => {
  return input.length > length ? input.substring(0, length) + '...' : input
}

export const splitTeams = (event: string) => {
  let team1: string, team2: string

  const splitOnDash = event.split(' - ')
  team1 = splitOnDash[0]
  team2 = splitOnDash[1]

  if (!team2) {
    const splitOnV = event.split(' V ')
    team1 = splitOnV[0]
    team2 = splitOnV[1]
  }

  return {
    team1: team1 ? titleCase(team1) : 'who ?',
    team2: team2 ? titleCase(team2) : 'who ?',
  }
}

export const showOutcome = (
  simOutcome: Sim.Outcome | SimBasketball.Outcome,
  event: string,
  spread?: string,
  outcome?: string,
  marketId?: number,
) => {
  const map = {
    '1': splitTeams(event).team1,
    'x': 'Draw',
    '2': splitTeams(event).team2,
    '12': 'Home / Away',
    '1x': 'Home / Draw',
    '2x': 'Away / Draw',
    'o': `Over ${spread || ''}`,
    'u': `Under ${spread || ''}`,
    'gg': 'GoalGoal',
    'ng': 'NoGoal',
    'ov': 'Over',
    'un': 'Under',
    '2/x': '2/X',
    '1/x': '1/X',
    'x/1': 'X/1',
    'x/2': 'X/2',
    'x/x': 'X/X',
  } as Partial<{ [key in Sim.Outcome | SimBasketball.Outcome]: string }>
  if (
    outcome === '1+fh&1+sh' ||
    outcome === '1+fh&2+sh' ||
    outcome === 'I1-3&II1-3' ||
    outcome === 'I1+&2+' ||
    outcome === 'I1-2&II1-2'
  )
    return outcome

  if (marketId === 60) return '|1+&2+'
  if (marketId === 61) return '|1-3&||1-3'
  if (marketId === 62) return 'I1-2&II1-2'
  if (marketId === 63) return '1+fh&1+sh'
  if (marketId === 64) return '1+fh&2+sh'

  return map[simOutcome] ?? titleCase(simOutcome)
}

export const showMarket = (
  market: Sim.Market | SimBasketball.Market,
  marketId?: number,
) => {
  if (marketId && [60, 61, 62, 63, 64].includes(marketId))
    return 'Number of Goals in Both Halves'

  return market
    .replace(/_|\s/g, ' ')
    .split(' ')
    .map((str) => {
      // add more...
      const translations = {
        OU: 'O/U',
      } as any // oh well...
      return translations[str] ?? str
    })
    .join(' ')
    .trim()
}

// trunc number to fixed decimal places without rounding
export const toFixed = (num: number, fixed: number) => {
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?')
  return num.toString().match(re)?.shift()
}
