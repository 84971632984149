import styled, { css } from 'styled-components'

export const Head = styled.div<{ $expand: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  background-color: ${(props) => props.theme.colours.S050};
  border-radius: 6px;
  transition: background-color 60ms linear 10ms;
  cursor: pointer;

  ${({ $expand }) =>
    $expand &&
    css`
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      transition: background-color 60ms ease-out;
    `};
`

export const Info = styled.div`
  flex: 1 1 auto;
  color: ${({ theme }) => theme.colours.N040};
  font-size: 12px;
  padding: 8px;

  .market-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: ${({ theme }) => theme.colours.N030};
  }
  .market {
    line-height: 16px;
  }

  .box {
    display: flex;
    align-items: center;

    .event {
      padding-left: 4px;
    }
  }
`

////BUTTON
export const ButtonWrapper = styled.div``

export const OddButton = styled.button<{ $selected: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  padding: 11px 10px 14px 18px;
  margin: 4px;
  background: ${({ theme }) => theme.colours.S050O};
  border: none;
  border-radius: 6px;
  outline: none;
  cursor: pointer;
  width: 72px;
  color: ${({ theme }) => theme.colours.AP050};
  path {
    fill: ${({ theme }) => theme.colours.AP050};
  }

  ${({ $selected }) =>
    $selected &&
    css`
      background: ${({ theme }) => theme.colours.AP050G};
      color: ${({ theme }) => theme.colours.ST100};
      path {
        fill: ${({ theme }) => theme.colours.ST100};
      }
    `};

  .value {
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
  }
`
