import { getSymbolToUse } from '../sports/adapter'
import { getSport, SportsCode } from '../sports/sportMap'
import { Source, SportID } from '../sports/types'

export type PostMessageBet9ja = {
  otp: string
  selections: PostMessageSelectionBet9ja[]
  stake: number
  source: Source
  force_win?: boolean
}

export type PostMessageSelectionBet9ja = {
  selection: string
  event: string
  market: string
  sign: string
  odd: number | string
  tournament_id: number
  tournament_name: string
}

export type PostMessage = {
  otp: string
  stake: number
  source: Source
  tenant_id: string
  selections: PostMessageSelection[]
  page?: string
  force_win?: boolean
  locale?: string
}

export type PostMessageSelection = {
  selection_id: string
  event_name: string
  market_name: string
  sign_name: string
  odd: number
  tournament_id: number | string
  tournament_name: string
  sport_name: string
  sport_id: SportID
  event_id: number
  market_id: number
  sign_id: string
  category_id?: string
  category_name?: string
  hnd?: null | string
  is_approved?: boolean
}

export const transformPostMessage = (pm: PostMessageBet9ja): PostMessage => {
  return {
    otp: pm.otp,
    stake: pm.stake,
    source: pm.source,
    tenant_id: 'bet9ja-tenant_id',
    force_win: pm?.force_win,
    selections: pm.selections.map((s) => {
      // get the symbol to split on
      const symbol = getSymbolToUse(pm.source)
      const isIsolutions =
        pm.source === 'desktop' ||
        pm.source === 'old-mobile' ||
        pm.source === 'self-service'

      // extract matchId and sport head, MKT, outcome
      // const [head, MKT, outcome] = selection.split(symbol)
      const hmo = s.selection.split(symbol)
      const head = hmo[0]
      let MKT = hmo[1]
      let outcome = hmo[2]

      if (isIsolutions) {
        if (s.market === 'Over/Under 1stQ') {
          MKT = `${hmo[1]} ${s.sign?.replace(
            /[^()](?=([^()]*\([^()]*\))*[^()]*$)/g,
            '',
          )}`
        }

        if (
          s.market === 'O/U Points 110-139' ||
          s.market === 'O/U Points 140-169' ||
          s.market === 'O/U Points 170-199' ||
          s.market === 'O/U Points 200-229' ||
          s.market === 'O/U Points 230-259' ||
          s.market === 'O/U Points 260-289' ||
          s.market === 'O/U Points 290-320'
        ) {
          MKT = `${hmo[1]} ${s.sign?.replace(/[^\d.]*/g, '')}`
          if (outcome.includes('Under')) outcome = 'Under'
          if (outcome.includes('Over')) outcome = 'Over'
        }
      }

      const [matchId, sport] = head.split('$')

      // find which sport map to use
      const sportMap = getSport(pm.source, sport as SportsCode)
      if (!sportMap) {
        return {} as PostMessageSelection
      }

      const { sportId, marketMap, findSimMarketId } = sportMap

      const simulateMarket = marketMap.get(MKT) // find if there is a matching market in the map
      // const supported = marketMap.has(MKT) // find if market is supported
      const simMarketID = findSimMarketId(simulateMarket, sportId) || 0
      // const spread = getSpread(s.sign, MKT, simMarketID, sportId)
      // const matchID = Number(matchId) // cast matchId
      s.odd = typeof s.odd === 'string' ? parseFloat(s.odd) : s.odd // cast odd and replace
      // const simOutcome = simulateMarket
      //   ? outcomeMap(MKT, outcome)
      //   : ('' as Outcome) // find the outcome

      return {
        selection_id: s.selection,
        event_name: s.event,
        market_name: s.market,
        sign_name: s.sign,
        odd: s.odd,
        tournament_id: s.tournament_id,
        tournament_name: s.tournament_name,
        sport_name: sport,
        sport_id: sport === 'B' ? 2 : 1,
        event_id: Number(matchId),
        market_id: simMarketID,
        sign_id: s.sign,
      }
    }),
  }
}

// this function will have to be different for bet9ja and mozzartbet-ke
export const validatePostMessage = (pm: PostMessage): PostMessage => {
  // check all post message properties
  const validpm =
    Object.prototype.hasOwnProperty.call(pm, 'otp') &&
    Object.prototype.hasOwnProperty.call(pm, 'selections')
  // && Object.prototype.hasOwnProperty.call(pm, 'stake')

  if (validpm) {
    const filteredPM = {
      ...pm,
      selections: pm.selections.filter(
        (selection) =>
          Object.prototype.hasOwnProperty.call(selection, 'selection_id') &&
          Object.prototype.hasOwnProperty.call(selection, 'odd') &&
          Object.prototype.hasOwnProperty.call(selection, 'event_id') &&
          Object.prototype.hasOwnProperty.call(selection, 'event_name') &&
          Object.prototype.hasOwnProperty.call(selection, 'market_id') &&
          Object.prototype.hasOwnProperty.call(selection, 'market_name') &&
          Object.prototype.hasOwnProperty.call(selection, 'sign_id') &&
          Object.prototype.hasOwnProperty.call(selection, 'sign_name') &&
          Object.prototype.hasOwnProperty.call(selection, 'sport_id') &&
          Object.prototype.hasOwnProperty.call(selection, 'sport_name') &&
          Object.prototype.hasOwnProperty.call(selection, 'tournament_id') &&
          Object.prototype.hasOwnProperty.call(selection, 'tournament_name'),
      ),
    }

    // cap the number of selections
    if (filteredPM.selections.length > 40) {
      throw { status: 1002 }
    }

    return filteredPM
  }

  throw { status: 1001 }
}
