import { getEndpoint } from 'src/api/endpoints'
import { ReportResponse } from '../../../api/types'
import { errorHandling } from '../../../api/utils'

/**
 * POST: create Report,
 * @param range  today = 1, yesterday = 2, last seven days = 3
 * @param sessionID user session ID
 */

export const getReports = async (
  range: number,
  sessionID: string,
): Promise<ReportResponse> => {
  const response = await fetch(`${getEndpoint()}/simulate/BetReport`, {
    method: 'post',
    headers: {
      'accept': 'application/json',
      'content-Type': 'application/json',
      'X-SID': `${sessionID}`,
    },
    body: JSON.stringify({ report_range: range }),
  }).catch((e) => {
    errorHandling(e)
  })

  if (!response?.ok) {
    errorHandling(await response?.json(), response?.status)
  }

  return await response?.json()
}
