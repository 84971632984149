import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { APIStatus, ErrorResponse } from '../../../api/types'
import { ACTIVETOURNAMENT } from '../../../config'
import { getSpread, getSymbolToUse } from '../../../sports/adapter'
import { findMarketFromID } from '../../../sports/marketUtils'
import { getSport, SportsCode } from '../../../sports/sportMap'
import { SimBet, Source, TournamentEvent } from '../../../sports/types'
import { AppThunk, RootState } from '../../../store/store'
import { createError } from '../../app/store/app.state'
import { getTournamentEvents } from '../api/getTournamentEvents'

export type TournamentState = {
  status: APIStatus
  events: TournamentEvent[]
  activeTournament: string
}

export const initialState: TournamentState = {
  status: 'idle',
  events: [] as TournamentEvent[],
  activeTournament: ACTIVETOURNAMENT,
}

const tournamentSlice = createSlice({
  name: 'tournament',
  initialState,
  reducers: {
    setStatus(state, { payload }: PayloadAction<APIStatus>) {
      state.status = payload
    },
    setEvents(state, { payload }: PayloadAction<TournamentEvent[]>) {
      state.events = payload
      state.status = 'idle'
    },
  },
})

export default tournamentSlice.reducer

export const { setStatus, setEvents } = tournamentSlice.actions

export const fetchTournamentEvents =
  (): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const { sessionID } = state.app
    const { activeTournament } = state.tournament

    dispatch(setStatus('loading'))

    try {
      const res = await getTournamentEvents(sessionID, activeTournament)

      if (res?.popular_events.length === 0) {
        return dispatch(setEvents([]))
      }

      const transformedEvents = res?.popular_events.map((e) => {
        return {
          event_id: e.event_id,
          event_name: e.event_name,
          market_id: e.market_id,
          sport_id: e.sport_id,
          stats: e.odds
            .map((o) => {
              return { option: o.option, percentage: o.percentage }
            })
            .sort((a, b) => {
              const order: Record<string, number> = { '1': 1, 'x': 2, '2': 3 }
              return order[a.option] - order[b.option]
            }),
          bets: e.odds.map((o) => {
            let symbol = ''
            let source: Source = ''
            o.code.includes('#')
              ? (source = 'desktop')
              : (source = 'new-mobile')
            symbol = getSymbolToUse(source)

            const [head, MKT] = o.code.split(symbol)
            const [, sport] = head.split('$')

            const sportMap = getSport(source, sport as SportsCode)
            if (!sportMap) {
              return {} as SimBet
            }

            const simulateMarket = findMarketFromID(e.market_id, e.sport_id)
            const spread = getSpread('', MKT, e?.market_id, e?.sport_id)
            const simMarketID =
              sportMap.findSimMarketId(simulateMarket, e.sport_id) || 0

            return {
              b9jBet: o.code,
              matchID: e.event_id,
              sport: e.sport_id,
              odd: o.odd,
              odd_id: o.odd_id,
              event: e.event_name,
              market: findMarketFromID(e.market_id, e.sport_id),
              simMarketID: simMarketID,
              outcome: o.option,
              simOutcome: o.option,
              supported: true,
              blocking: false,
              spread,
              tournament_id: e.tournament_id,
              tournament_name: e.tournament_name,
            } as SimBet
          }),
        }
      })
      dispatch(setEvents(transformedEvents))
    } catch (e) {
      dispatch(createError(e as ErrorResponse))
    }
  }

export const selectTournamentStatus = (state: RootState) =>
  state.tournament.status
export const selectTournamentEvents = (state: RootState) =>
  state.tournament.events
export const selectActiveTournament = (state: RootState) =>
  state.tournament.activeTournament
