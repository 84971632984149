import { SimMarket, Score, TimeSpan, OneXTwoHNDN } from './types'

export const logic1X2HNDN =
  (handicap: number) => (outcome: OneXTwoHNDN, score: Score) => {
    const oc: { [key in OneXTwoHNDN]: () => boolean } = {
      '1': () => score[0] > score[1] && score[0] - score[1] > handicap,
      'x': () => Math.abs(score[0] - score[1]) < handicap,
      '2': () => score[0] < score[1] && score[1] - score[0] > handicap,
    }
    return oc[outcome]() ?? false
  }

const oc1X2HNDN = ['1', 'x', '2'] as OneXTwoHNDN[]

const _1X2HNDN = (id: number, time: TimeSpan, handicap: number): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: oc1X2HNDN,
  logic: logic1X2HNDN(handicap),
})

export const FT_1X2_HNDN_55 = _1X2HNDN(2004, 'FT', 5.5)
