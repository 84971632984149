import { showOutcome } from '../../lib/utils/uiUtils'
import { PercentBar } from './percentBar'
import * as Sim from '../../sports/soccer/markets'
import { TExploreStats } from '../../sports/types'
import { BrandSimulate } from '../../components/svgs'
import {
  Flex,
  Title,
  Row,
  TextWrapper,
  Text,
  PercentWrapper,
} from './stats.style'
import { FormattedMessage } from 'react-intl'

interface StatsProps {
  stats?: TExploreStats[]
  withIcon?: boolean
  event: string
}

export const Stats: React.FC<StatsProps> = ({ stats, event, withIcon }) => {
  return (
    <div data-testid="stats">
      <Flex>
        <Title>
          <FormattedMessage
            id="stats_explore"
            defaultMessage="Market simulation stats"
          />
        </Title>
        <div>
          {withIcon && (
            <BrandSimulate width="16" height="16" viewBox="0 0 24 24" />
          )}
        </div>
      </Flex>

      <ul>
        {stats?.map((stat: TExploreStats) => {
          return (
            <Row key={`${event}-${stat?.option}`} data-testid="statsItems">
              <TextWrapper>
                <Text>{showOutcome(stat.option as Sim.Outcome, event)}</Text>
              </TextWrapper>
              <PercentWrapper>
                <PercentBar
                  min={0}
                  max={100}
                  value={stat.percentage}
                  label={showOutcome(stat.option as Sim.Outcome, event)}
                />
              </PercentWrapper>
            </Row>
          )
        })}
      </ul>
    </div>
  )
}
