import { FreeBet } from '../../../../api/types'
import { useNavigate } from 'react-router-dom'
import { useCountDown } from '../../../../lib/count-down/useCountDown'
import { clicksOnFreeBet } from '../../../betslip/store/betslip.state'
import { Medal } from '../../../../components/svgs'
import { FreeBetListItem, FreeBetCard } from './freeBetItem.style'
import { useAppDispatch } from '../../../../store/store'
import { ReactNode } from 'react'
import { getClientVariables } from 'src/config/client-variables'
import { FormattedMessage } from 'react-intl'

export type FreeBetItemProps = {
  freeBet: FreeBet
}
export const FreeBetNoContent: React.FC = () => {
  return (
    <div className="freeBetNoContent">
      <h1 className="heading">
        <FormattedMessage
          id="freeBetNoContent_heading"
          defaultMessage="Keep playing to be awarded free bets!"
        />
      </h1>
      <Medal height="32" width="24" viewBox="0 0 24 32" />
    </div>
  )
}

export const FreeBetContent: React.FC<FreeBetItemProps> = ({ freeBet }) => {
  const dispatch = useAppDispatch()
  const expiry = useCountDown(freeBet)
  const navigate = useNavigate()

  const handleClickFreeBet = () => {
    if (expiry?.output !== 'EXPIRED') {
      dispatch(clicksOnFreeBet(freeBet))
      navigate('/')
    }
  }
  return (
    <>
      <div className="amount">
        <div className="title">
          <FormattedMessage
            id="freeBetContent_title"
            defaultMessage="Free Bet"
          />
        </div>
        <div className="currency">
          {getClientVariables().currency} {freeBet?.amount}
        </div>
      </div>

      <div className="box">
        <div className="expiry">
          <FormattedMessage
            id="freeBetContent_expiry"
            defaultMessage="Expires in"
          />
          <span className="time"> {expiry?.output}</span>
        </div>
        <button
          onClick={handleClickFreeBet}
          className="btn"
          data-testid="btnMsg"
        >
          <FormattedMessage
            id="freeBetContent_btn"
            defaultMessage="Use this!"
          />
        </button>
      </div>
    </>
  )
}

export const FreeBetItem: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <FreeBetListItem data-testid="freeBet-item">
      <FreeBetCard>{children}</FreeBetCard>
    </FreeBetListItem>
  )
}
