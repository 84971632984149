import styled, { css } from 'styled-components'

export const Head = styled.div<{ $expand: boolean }>`
  position: relative;
  width: 100%;
  margin-top: 2px;
  padding: 7px 16px;
  background-color: ${(props) => props.theme.colours.S050};
  border-radius: 3px;
  transition: background-color 60ms linear 10ms;
  cursor: pointer;

  ${({ $expand }) =>
    $expand &&
    css`
      background-color: ${(props) => props.theme.colours.S050};
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      transition: background-color 60ms ease-out;
    `};
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 4px;

  .local-date {
    color: ${(props) => props.theme.colours.N030};
  }

  .win-amount {
    color: ${(props) => props.theme.colours.N030};
  }
`

export const Win = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${(props) => props.theme.colours.N030};
`

export const WinAmount = styled.div<{ win: number }>`
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.colours.N030};
  ${({ win }) =>
    win > 0 &&
    css`
      color: ${(props) => props.theme.colours.AP050};
    `}
`
