import { messagesInSpanish } from './es'
import { messagesInCroatian } from './hr'
import { messagesInSerbian } from './srb'
import { TMessages } from './types'

export const translations: { [key: string]: TMessages } = {
  'sr-Latn': messagesInSerbian,
  'es': messagesInSpanish,
  'hr': messagesInCroatian,
}

declare global {
  namespace FormatjsIntl {
    interface Message {
      ids: keyof TMessages
    }
  }
}
