import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  fetchExplore,
  selectActiveSport,
  selectActiveTournament,
  selectSelectedMarket,
  selectSportsBook,
  selectSportsBookByTournament,
} from '../store/explore.state'
import { StyledPage } from '../../../style/styles'
import { ExploreMenu } from '../components/explore-menu/explore-menu'
import { ExploreTabs } from '../components/exploreTabs/exploreTabs'
import { ExploreHeading } from '../components/exploreHeading/exploreHeading'
import {
  selectPermissions,
  selectRedirect,
  updateRedirect,
} from '../../app/store/app.state'
import styled from 'styled-components'
import { ErrorModal } from '../../../components/error-modal/errorModal'
import { useAppDispatch } from '../../../store/store'
import { ExploreLeagueTab } from '../components/exploreLeague/exploreLeagueTab'
import { ExploreList } from '../components/explore/exploreList'
import { FormattedMessage } from 'react-intl'

export const ExplorePage = () => {
  const dispatch = useAppDispatch()
  const redirect = useSelector(selectRedirect)
  const selected = useSelector(selectSelectedMarket)
  const activeSport = useSelector(selectActiveSport)
  const permissions = useSelector(selectPermissions)
  const activeTournaments = useSelector(selectActiveTournament)
  const tournamentsBets = useSelector(selectSportsBookByTournament)
  const bookBets = useSelector(selectSportsBook)

  useEffect(() => {
    dispatch(fetchExplore())
  }, [dispatch, selected, activeSport])

  if (redirect) {
    dispatch(updateRedirect(null))
  }

  return (
    <>
      <ErrorModal />
      <StyledPage>
        {permissions?.basketball ? <ExploreTabs /> : undefined}
        <ScrollWrapper>
          {activeSport === 'Soccer' ? <ExploreMenu /> : undefined}
          <ExploreHeading
            title={
              activeSport === 'Soccer' ? (
                <FormattedMessage
                  id="explore_soccerHeading"
                  defaultMessage="Top Popular Soccer Bets"
                />
              ) : (
                <FormattedMessage
                  id="explore_basketHeading"
                  defaultMessage="Top Popular Basketball Bets"
                />
              )
            }
          />
          {bookBets.length === 0 ? (
            <MessageWrapper>
              <p data-testid="message">
                <FormattedMessage
                  id="explorePage_msg"
                  defaultMessage="There is currently no information to display here"
                />
              </p>
            </MessageWrapper>
          ) : (
            <ExploreList bets={bookBets} />
          )}
          <ExploreLeagueTab />
          <ExploreHeading
            title={
              <FormattedMessage
                id="explore_leagueHeading"
                values={{ value1: activeTournaments?.tournament_name }}
                defaultMessage="Top {value1} Bets"
              />
            }
          />
          {tournamentsBets.length === 0 ? (
            <MessageWrapper>
              <p>
                <FormattedMessage
                  id="explorePage_msg"
                  defaultMessage="There is currently no information to display here"
                />
              </p>
            </MessageWrapper>
          ) : (
            <ExploreList bets={tournamentsBets} />
          )}
        </ScrollWrapper>
      </StyledPage>
    </>
  )
}

const MessageWrapper = styled.div`
  color: #fff;
  margin: 0 auto;
`
const ScrollWrapper = styled.div`
  overflow: auto;
`
