import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectActiveSport } from '../../store/explore.state'
import { ArrowRight } from '../../../../components/svgs'
import { Button, Wrapper, Arrow } from './exploreButton.style'

interface ExploreButtonProps {
  viewMore: boolean
  setViewMore(viewMore: boolean): void
}

export const ExploreButton: React.FC<ExploreButtonProps> = ({
  setViewMore,
  viewMore,
}) => {
  const activeSport = useSelector(selectActiveSport)

  const buttonContent = useMemo(() => {
    const sport = activeSport === 'Soccer' ? 'Soccer' : 'Basketball'
    const state = viewMore ? 'less' : 'more'

    return `Show ${state} ${sport}`
  }, [activeSport, viewMore])

  return (
    <Wrapper data-testid="exploreButton">
      <Button onClick={() => setViewMore(!viewMore)}>{buttonContent}</Button>
      <Arrow $viewMore={viewMore}>
        <ArrowRight width="24" height="16" viewBox="3 3 17 19" />
      </Arrow>
    </Wrapper>
  )
}
