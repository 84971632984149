import { PostMessage } from '../../lib/postMessageValidator'
import { Source } from '../../sports/types'
import { fakeOTPs } from '../mockCredentials'

export const mozzartbetKenyaPM_CS: PostMessage = {
  stake: 1000,
  otp: fakeOTPs.nofail_d,
  source: '',
  tenant_id: '123e4567-e89b-12d3-a456-426614174000',
  selections: [
    {
      selection_id: '0.2886399447196337',
      sport_name: 'Soccer',
      sport_id: 1,
      event_name: 'Atalanta - Bologna',
      event_id: 1498559,
      tournament_name: 'Serie A',
      tournament_id: 19,
      market_name: '1:0',
      market_id: 20,
      sign_name: 'Correct score of the match will be 1:0',
      sign_id: '10',
      odd: 7.5,
    },
    {
      selection_id: '0.13224644736493452',
      sport_name: 'Soccer',
      sport_id: 1,
      event_name: 'Catania - Chievo',
      event_id: 1498568,
      tournament_name: 'Serie A',
      tournament_id: 19,
      market_name: '2:0',
      market_id: 20,
      sign_name: 'Correct score of the match will be 2:0.',
      sign_id: '20',
      odd: 7.75,
    },
    {
      selection_id: '0.6556653752471819',
      sport_name: 'Soccer',
      sport_id: 1,
      event_name: 'Cesena - Genoa',
      event_id: 1498555,
      tournament_name: 'Serie A',
      tournament_id: 19,
      market_name: '3:2',
      market_id: 20,
      sign_name: 'Correct score of the match will be 3:2.',
      sign_id: '32',
      odd: 27,
    },
    {
      selection_id: '0.4947135414231556',
      sport_name: 'Soccer',
      sport_id: 1,
      event_name: 'Fiorentina - Inter',
      event_id: 1498562,
      tournament_name: 'Serie A',
      tournament_id: 19,
      market_name: '2:2',
      market_id: 20,
      sign_name: 'Correct score of the match will be 2:2.',
      sign_id: '22',
      odd: 17,
    },
    {
      selection_id: '0.8684268031164546',
      sport_name: 'Soccer',
      sport_id: 1,
      event_name: 'Juventus - Cagliari',
      event_id: 1498565,
      tournament_name: 'Serie A',
      tournament_id: 19,
      market_name: '1:2',
      market_id: 20,
      sign_name: 'Correct score of the match will be 1:2.',
      sign_id: '12',
      odd: 23,
    },
  ],
}
export const mozzartbetKenyaPM_GGNG: PostMessage = {
  stake: 1000,
  otp: fakeOTPs.nofail_d,
  source: '',
  tenant_id: '123e4567-e89b-12d3-a456-426614174000',
  selections: [
    {
      selection_id: '0.3431259713090513',
      sport_name: 'Soccer',
      sport_id: 1,
      event_name: 'Atalanta - Bologna',
      event_id: 1498559,
      tournament_name: 'Serie A',
      tournament_id: 19,
      market_name: 'gg',
      market_id: 130,
      sign_name: 'Both teams to score in the match',
      sign_id: '1',
      odd: 1.72,
    },
    {
      selection_id: '0.3499350751699899',
      sport_name: 'Soccer',
      sport_id: 1,
      event_name: 'Fiorentina - Inter',
      event_id: 1498562,
      tournament_name: 'Serie A',
      tournament_id: 19,
      market_name: 'ng',
      market_id: 130,
      sign_name:
        'Only one team scores in the match, or neither teams score in the match',
      sign_id: '2',
      odd: 1.92,
    },
  ],
}
export const mozzartbetKenyaPM_FTDC: PostMessage = {
  stake: 1000,
  otp: fakeOTPs.nofail_d,
  source: '',
  tenant_id: '123e4567-e89b-12d3-a456-426614174000',
  selections: [
    {
      selection_id:
        'Mi0xLS0xLTEuMjMtSG9tZSUyMHRlYW0lMjB3aWxsJTIwd2luJTIwb3IlMjB0aGUlMjBtYXRjaCUyMHdpbGwlMjBlbmQlMjBpbiUyMGElMjBkcmF3',
      sport_name: 'Football',
      sport_id: 1,
      event_name: 'Atalanta - Bologna',
      event_id: 1503310,
      tournament_id: '19',
      tournament_name: 'ITALIJA  1',
      market_name: 'Home team will win or the match will end in a draw',
      market_id: 2,
      sign_name: '1X',
      sign_id: '1',
      odd: 1.23,
    },
    {
      selection_id:
        'Mi0yLS0xLTEuMTctSG9tZSUyMG9yJTIwYXdheSUyMHRlYW0lMjB3aWxsJTIwd2luJTIwaW4lMjB0aGUlMjBtYXRjaA==',
      sport_name: 'Football',
      sport_id: 1,
      event_name: 'Cesena - Genoa',
      event_id: 1503307,
      tournament_id: '19',
      tournament_name: 'ITALIJA  1',
      market_name: 'Home or away team will win in the match',
      market_id: 2,
      sign_name: '12',
      sign_id: '2',
      odd: 1.17,
    },
    {
      selection_id:
        'Mi0zLS0xLTIuMDEtQXdheSUyMHRlYW0lMjB3aWxsJTIwd2luJTIwb3IlMjB0aGUlMjBtYXRjaCUyMHdpbGwlMjBlbmQlMjBpbiUyMGElMjBkcmF3',
      sport_name: 'Football',
      sport_id: 1,
      event_name: 'Fiorentina - Inter',
      event_id: 1503311,
      tournament_id: '19',
      tournament_name: 'ITALIJA  1',
      market_name: 'Away team will win or the match will end in a draw',
      market_id: 2,
      sign_name: 'X2',
      sign_id: '3',
      odd: 2.01,
    },
  ],
}

export const mozzartbetKenyaPM_HTDC: PostMessage = {
  stake: 1000,
  otp: fakeOTPs.nofail_d,
  source: '',
  tenant_id: '123e4567-e89b-12d3-a456-426614174000',
  selections: [
    {
      selection_id:
        'Mjk3LTEtLTEtMS4xNy1BdGFsYW50YSUyMHdpbGwlMjB3aW4lMjBmaXJzdCUyMGhhbGYlMjBvciUyMGZpcnN0JTIwaGFsZiUyMHdpbGwlMjBlbmQlMjBhcyUyMGRyYXc=',
      sport_name: 'Football',
      sport_id: 1,
      event_name: 'Atalanta - Bologna',
      event_id: 1503310,
      tournament_id: '19',
      tournament_name: 'ITALIJA  1',
      market_name:
        'Atalanta will win first half or first half will end as draw',
      market_id: 297,
      sign_name: '1X',
      sign_id: '1',
      odd: 1.17,
    },
    {
      selection_id:
        'Mjk3LTItLTEtMS4zMy1DZXNlbmElMjBvciUyMEdlbm9hJTIwd2lsbCUyMHdpbiUyMGZpcnN0JTIwaGFsZg==',
      sport_name: 'Football',
      sport_id: 1,
      event_name: 'Cesena - Genoa',
      event_id: 1503307,
      tournament_id: '19',
      tournament_name: 'ITALIJA  1',
      market_name: 'Cesena or Genoa will win first half',
      market_id: 297,
      sign_name: '12',
      sign_id: '2',
      odd: 1.33,
    },
    {
      selection_id:
        'Mjk3LTMtLTEtMS41My1JbnRlciUyMHdpbGwlMjB3aW4lMjBmaXJzdCUyMGhhbGYlMjBvciUyMGZpcnN0JTIwaGFsZiUyMHdpbGwlMjBlbmQlMjBhcyUyMGRyYXc=',
      sport_name: 'Football',
      sport_id: 1,
      event_name: 'Fiorentina - Inter',
      event_id: 1503311,
      tournament_id: '19',
      tournament_name: 'ITALIJA  1',
      market_name: 'Inter will win first half or first half will end as draw',
      market_id: 297,
      sign_name: 'X2',
      sign_id: '3',
      odd: 1.53,
    },
  ],
}

export const mozzartbetKenyaPM_FT1X2: PostMessage = {
  otp: fakeOTPs.nofail_d,
  tenant_id: '123e4567-e89b-12d3-a456-426614174000',
  source: '' as Source,
  selections: [
    {
      selection_id:
        'MS0xLS0xLTIuMDUtSG9tZSUyMHRlYW0lMjB3aWxsJTIwd2luJTIwaW4lMjB0aGUlMjBtYXRjaA==',
      sport_name: 'Football',
      sport_id: 1,
      event_name: 'Atalanta - Bologna',
      event_id: 1503310,
      tournament_id: '19',
      tournament_name: 'ITALIJA  1',
      market_name: 'Home team will win in the match',
      market_id: 1,
      sign_name: '1',
      sign_id: '1',
      odd: 2.05,
    },
    {
      selection_id:
        'MS0yLS0xLTMuNS1UaGUlMjBtYXRjaCUyMHdpbGwlMjBlbmQlMjBpbiUyMGElMjBkcmF3',
      sport_name: 'Football',
      sport_id: 1,
      event_name: 'Cesena - Genoa',
      event_id: 1503307,
      tournament_id: '19',
      tournament_name: 'ITALIJA  1',
      market_name: 'The match will end in a draw',
      market_id: 1,
      sign_name: 'X',
      sign_id: '2',
      odd: 3.5,
    },
    {
      selection_id:
        'MS0zLS0xLTQuNC1Bd2F5JTIwdGVhbSUyMHdpbGwlMjB3aW4lMjBpbiUyMHRoZSUyMG1hdGNo',
      sport_name: 'Football',
      sport_id: 1,
      event_name: 'Fiorentina - Inter',
      event_id: 1503311,
      tournament_id: '19',
      tournament_name: 'ITALIJA  1',
      market_name: 'Away team will win in the match',
      market_id: 1,
      sign_name: '2',
      sign_id: '3',
      odd: 4.4,
    },
  ],
  stake: 20,
}

export const mozzartbetKenyaPM_HT1X2: PostMessage = {
  otp: fakeOTPs.nofail_d,
  tenant_id: '123e4567-e89b-12d3-a456-426614174000',
  source: '' as Source,
  selections: [
    {
      selection_id:
        'NC0xLS0xLTIuNzUtQXRhbGFudGElMjB3aWxsJTIwd2luJTIwZmlyc3QlMjBoYWxm',
      sport_name: 'Football',
      sport_id: 1,
      event_name: 'Atalanta - Bologna',
      event_id: 1503310,
      tournament_id: '19',
      tournament_name: 'ITALIJA  1',
      market_name: 'Atalanta will win first half',
      market_id: 4,
      sign_name: '1',
      sign_id: '1',
      odd: 2.75,
    },
    {
      selection_id:
        'NC0yLS0xLTIuNC1GaXJzdCUyMGhhbGYlMjB3aWxsJTIwZW5kJTIwYXMlMjBkcmF3',
      sport_name: 'Football',
      sport_id: 1,
      event_name: 'Cesena - Genoa',
      event_id: 1503307,
      tournament_id: '19',
      tournament_name: 'ITALIJA  1',
      market_name: 'First half will end as draw',
      market_id: 4,
      sign_name: 'X',
      sign_id: '2',
      odd: 2.4,
    },
    {
      selection_id:
        'NC0zLS0xLTQuMS1JbnRlciUyMHdpbGwlMjB3aW4lMjBmaXJzdCUyMGhhbGY=',
      sport_name: 'Football',
      sport_id: 1,
      event_name: 'Fiorentina - Inter',
      event_id: 1503311,
      tournament_id: '19',
      tournament_name: 'ITALIJA  1',
      market_name: 'Inter will win first half',
      market_id: 4,
      sign_name: '2',
      sign_id: '3',
      odd: 4.1,
    },
  ],
  stake: 20,
}

export const mozzartbetKenyaPM_ODD_EVEN: PostMessage = {
  otp: 'eyJhbGciOiJIUzUxMiJ9.eyJtb2xsZXRBY3RpdmF0ZWQiOnRydWUsIm9tZWdhQWN0aXZhdGVkIjp0cnVlLCJzZXNzaW9uSWQiOiIxMzNkZjUxYWI0ODIzNDNjMmUwYzU0NmRhNWM0MWE3NGY0MTg5ZjVlIiwibGNNZW1iZXJJZCI6MTEzNzM0MywiY3VycmVuY3lJZCI6MSwiZXhwIjoxNjgwNjIyMzk4LCJ1c2VySWQiOjEsInVzZXJQbGF5c0Nhc2lubyI6dHJ1ZSwiaWF0IjoxNjgwMDE3NTk4LCJjb3VudHJ5SWQiOjF9.moDKTvyYRIWWDAi-CH_CwRWMfti6Cla_RblA0XSNSai3lqqdQT9ETyBdvfWVJ0gd78oo3rSItBuvequehfOObg',
  tenant_id: '123e4567-e89b-12d3-a456-426614174000',
  source: '' as Source,
  selections: [
    {
      selection_id:
        'My0yOS0tMS0xLjc1LU9kZCUyMG51bWJlciUyMG9mJTIwZ29hbHMlMjB3aWxsJTIwYmUlMjBzY29yZWQlMjBpbiUyMGElMjBtYXRjaA==',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Cesena - Genoa',
      event_id: 1503307,
      tournament_id: '19',
      tournament_name: 'ITALIJA  1',
      market_name: 'Odd number of goals will be scored in a match',
      market_id: 3,
      sign_name: 'even',
      sign_id: '29',
      odd: 1.75,
    },
    {
      selection_id:
        'My0zMC0tMS0xLjg1LUV2ZW4lMjBudW1iZXIlMjBvZiUyMGdvYWxzJTIwd2lsbCUyMGJlJTIwc2NvcmVkJTIwaW4lMjBhJTIwbWF0Y2g=',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Atalanta - Bologna',
      event_id: 1503310,
      tournament_id: '19',
      tournament_name: 'ITALIJA  1',
      market_name: 'Even number of goals will be scored in a match',
      market_id: 3,
      sign_name: 'odd',
      sign_id: '30',
      odd: 1.85,
    },
  ],
  stake: 20,
}

export const mozzartbetKenyaPM_FTOU: PostMessage = {
  otp: fakeOTPs.nofail_d,
  tenant_id: '123e4567-e89b-12d3-a456-426614174000',
  source: '' as Source,
  selections: [
    {
      selection_id:
        'My0xLS0xLTQuMS0xJTIwb3IlMjBsZXNzJTIwZ29hbHMlMjBpbiUyMHRoZSUyMG1hdGNo',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Aston Villa - Blackburn',
      event_id: 1503331,
      tournament_id: '20',
      tournament_name: 'ENGLESKA  1',
      market_name: '1 or less goals in the match',
      market_id: 3,
      sign_name: '0-1',
      sign_id: '1',
      odd: 4.1,
    },
    {
      selection_id:
        'My0xMi0tMS0xLjItMiUyMG9yJTIwbW9yZSUyMGdvYWxzJTIwaW4lMjB0aGUlMjBtYXRjaA==',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Bolton - Bradford',
      event_id: 1503337,
      tournament_id: '20',
      tournament_name: 'ENGLESKA  1',
      market_name: '2 or more goals in the match',
      market_id: 3,
      sign_name: '2+',
      sign_id: '12',
      odd: 1.2,
    },
    {
      selection_id:
        'My0yLS0xLTEuOC0yJTIwb3IlMjBsZXNzJTIwZ29hbHMlMjBpbiUyMHRoZSUyMG1hdGNo',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Chelsea - Arsenal',
      event_id: 1503329,
      tournament_id: '20',
      tournament_name: 'ENGLESKA  1',
      category_id: '0.47350729601573893',
      market_name: '2 or less goals in the match',
      market_id: 3,
      sign_name: '0-2',
      sign_id: '2',
      hnd: null,
      odd: 1.8,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id:
        'My00LS0xLTEuNzUtMyUyMG9yJTIwbW9yZSUyMGdvYWxzJTIwaW4lMjB0aGUlMjBtYXRjaA==',
      sport_name: 'Football',
      sport_id: 1,
      event_name: 'QPR - Liverpool',
      event_id: 1503346,
      tournament_id: '20',
      tournament_name: 'ENGLESKA  1',
      category_id: '0.9903701947599015',
      market_name: '3 or more goals in the match',
      market_id: 3,
      sign_name: '3+',
      sign_id: '4',
      hnd: null,
      odd: 1.75,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id:
        'My0xMy0tMS0xLjY5LTMlMjBvciUyMGxlc3MlMjBnb2FscyUyMGluJTIwdGhlJTIwbWF0Y2g=',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Catania - Chievo',
      event_id: 1503302,
      tournament_id: '19',
      tournament_name: 'ITALIJA  1',
      category_id: '0.8092409724286571',
      market_name: '3 or less goals in the match',
      market_id: 3,
      sign_name: '0-3',
      sign_id: '13',
      hnd: null,
      odd: 1.69,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id:
        'My01LS0xLTIuNjUtNCUyMG9yJTIwbW9yZSUyMGdvYWxzJTIwaW4lMjB0aGUlMjBtYXRjaA==',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Cesena - Genoa',
      event_id: 1503292,
      tournament_id: '19',
      tournament_name: 'ITALIJA  1',
      category_id: '0.8674299626972477',
      market_name: '4 or more goals in the match',
      market_id: 3,
      sign_name: '4+',
      sign_id: '5',
      hnd: null,
      odd: 2.65,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id:
        'My0yNi0tMS0xLjEtVG90YWwlMjBudW1iZXIlMjBvZiUyMGdvYWxzJTIwc2NvcmVkJTIwaW4lMjBhJTIwbWF0Y2glMjB3aWxsJTIwYmUlMjA0JTIwb3IlMjBsZXNz',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Birmingham - Blackpool',
      event_id: 1503309,
      tournament_id: '32',
      tournament_name: 'ENGLESKA  2',
      category_id: '0.772281268876613',
      market_name: 'Total number of goals scored in a match will be 4 or less',
      market_id: 3,
      sign_name: '0-4',
      sign_id: '26',
      hnd: null,
      odd: 1.1,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id:
        'My03LS0xLTUtNSUyMG9yJTIwbW9yZSUyMGdvYWxzJTIwaW4lMjB0aGUlMjBtYXRjaA==',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Brighton - Bristol C.',
      event_id: 1503312,
      tournament_id: '32',
      tournament_name: 'ENGLESKA  2',
      category_id: '0.6932903131249673',
      market_name: '5 or more goals in the match',
      market_id: 3,
      sign_name: '5+',
      sign_id: '7',
      hnd: null,
      odd: 5,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id:
        'My0xNy0tMS02LVRvdGFsJTIwbnVtYmVyJTIwb2YlMjBnb2FscyUyMHNjb3JlZCUyMGluJTIwYSUyMG1hdGNoJTIwd2lsbCUyMGJlJTIwNiUyMG9yJTIwbW9yZQ==',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Anderlecht - Germinal',
      event_id: 1503268,
      tournament_id: '15',
      tournament_name: 'BELGIJA  1',
      category_id: '0.8984765646254478',
      market_name: 'Total number of goals scored in a match will be 6 or more',
      market_id: 3,
      sign_name: '6+',
      sign_id: '17',
      hnd: null,
      odd: 6,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id:
        'My04LS0xLTI0LTclMjBvciUyMG1vcmUlMjBnb2FscyUyMGluJTIwdGhlJTIwbWF0Y2g=',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Lokeren - Mechelen',
      event_id: 1503321,
      tournament_id: '15',
      tournament_name: 'BELGIJA  1',
      category_id: '0.8052914196903445',
      market_name: '7 or more goals in the match',
      market_id: 3,
      sign_name: '7+',
      sign_id: '8',
      hnd: null,
      odd: 24,
      category_name: 'Test',
      is_approved: true,
    },
  ],
  stake: 20,
}

export const mozzartbetKenyaPM_NGOALS: PostMessage = {
  otp: fakeOTPs.nofail_d,
  tenant_id: '123e4567-e89b-12d3-a456-426614174000',
  source: '' as Source,
  selections: [
    {
      selection_id:
        'My0yNC0tMS0zLjA1LVRvdGFsJTIwbnVtYmVyJTIwb2YlMjBnb2FscyUyMHNjb3JlZCUyMGluJTIwYSUyMG1hdGNoJTIwd2lsbCUyMGJlJTIwMSUyMG9yJTIwMg==',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Catania - Chievo',
      event_id: 1503302,
      tournament_id: '19',
      tournament_name: 'ITALIJA  1',
      category_id: '0.6336169081984098',
      market_name: 'Total number of goals scored in a match will be 1 or 2',
      market_id: 3,
      sign_name: '1-2',
      sign_id: '24',
      hnd: null,
      odd: 3.05,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id:
        'My0yMC0tMS0xLjQ3LVRvdGFsJTIwbnVtYmVyJTIwb2YlMjBnb2FscyUyMHNjb3JlZCUyMGluJTIwYSUyMG1hdGNoJTIwd2lsbCUyMGJlJTIwYmV0d2VlbiUyMDElMjBhbmQlMjAz',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Cesena - Genoa',
      event_id: 1503292,
      tournament_id: '19',
      tournament_name: 'ITALIJA  1',
      category_id: '0.9650247019366265',
      market_name:
        'Total number of goals scored in a match will be between 1 and 3',
      market_id: 3,
      sign_name: '1-3',
      sign_id: '20',
      hnd: null,
      odd: 1.47,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id:
        'My0yNy0tMS0xLjE4LVRvdGFsJTIwbnVtYmVyJTIwb2YlMjBnb2FscyUyMHNjb3JlZCUyMGluJTIwYSUyMG1hdGNoJTIwd2lsbCUyMGJlJTIwYmV0d2VlbiUyMDElMjBhbmQlMjA0',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Aston Villa - Blackburn',
      event_id: 1503331,
      tournament_id: '20',
      tournament_name: 'ENGLESKA  1',
      category_id: '0.9452007584185049',
      market_name:
        'Total number of goals scored in a match will be between 1 and 4',
      market_id: 3,
      sign_name: '1-4',
      sign_id: '27',
      hnd: null,
      odd: 1.18,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id:
        'My0zNC0tMS0xLjA1LVRvdGFsJTIwbnVtYmVyJTIwb2YlMjBnb2FscyUyMHNjb3JlZCUyMGluJTIwYSUyMG1hdGNoJTIwd2lsbCUyMGJlJTIwYmV0d2VlbiUyMDElMjBhbmQlMjA1',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Bolton - Bradford',
      event_id: 1503337,
      tournament_id: '20',
      tournament_name: 'ENGLESKA  1',
      category_id: '0.3029717558474321',
      market_name:
        'Total number of goals scored in a match will be between 1 and 5',
      market_id: 3,
      sign_name: '1-5',
      sign_id: '34',
      hnd: null,
      odd: 1.05,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id:
        'My0zLS0xLTEuOTUtVG90YWwlMjBudW1iZXIlMjBvZiUyMGdvYWxzJTIwc2NvcmVkJTIwaW4lMjBhJTIwbWF0Y2glMjB3aWxsJTIwYmUlMjAyJTIwb3IlMjAz',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Chelsea - Arsenal',
      event_id: 1503329,
      tournament_id: '20',
      tournament_name: 'ENGLESKA  1',
      category_id: '0.24401670787594965',
      market_name: 'Total number of goals scored in a match will be 2 or 3',
      market_id: 3,
      sign_name: '2-3',
      sign_id: '3',
      hnd: null,
      odd: 1.95,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id:
        'My0xOC0tMS0xLjQ4LVRvdGFsJTIwbnVtYmVyJTIwb2YlMjBnb2FscyUyMHNjb3JlZCUyMGluJTIwYSUyMG1hdGNoJTIwd2lsbCUyMGJlJTIwYmV0d2VlbiUyMDIlMjBhbmQlMjA0',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'QPR - Liverpool',
      event_id: 1503346,
      tournament_id: '20',
      tournament_name: 'ENGLESKA  1',
      category_id: '0.6289189844256082',
      market_name:
        'Total number of goals scored in a match will be between 2 and 4',
      market_id: 3,
      sign_name: '2-4',
      sign_id: '18',
      hnd: null,
      odd: 1.48,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id:
        'My0yMS0tMS0xLjMyLVRvdGFsJTIwbnVtYmVyJTIwb2YlMjBnb2FscyUyMHNjb3JlZCUyMGluJTIwYSUyMG1hdGNoJTIwd2lsbCUyMGJlJTIwYmV0d2VlbiUyMDIlMjBhbmQlMjA1',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Dunfermline - Hearts',
      event_id: 1503351,
      tournament_id: '4',
      tournament_name: 'ŠKOTSKA  11',
      category_id: '0.5237800001069175',
      market_name:
        'Total number of goals scored in a match will be between 2 and 5',
      market_id: 3,
      sign_name: '2-5',
      sign_id: '21',
      hnd: null,
      odd: 1.32,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id:
        'My0xNC0tMS0xLjItVG90YWwlMjBudW1iZXIlMjBvZiUyMGdvYWxzJTIwc2NvcmVkJTIwaW4lMjBhJTIwbWF0Y2glMjB3aWxsJTIwYmUlMjBiZXR3ZWVuJTIwMiUyMGFuZCUyMDY=',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Kilmarnock - Motherwell',
      event_id: 1503365,
      tournament_id: '4',
      tournament_name: 'ŠKOTSKA  11',
      category_id: '0.7710952621142437',
      market_name:
        'Total number of goals scored in a match will be between 2 and 6',
      market_id: 3,
      sign_name: '2-6',
      sign_id: '14',
      hnd: null,
      odd: 1.2,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id:
        'My0xOS0tMS0yLjUtVG90YWwlMjBudW1iZXIlMjBvZiUyMGdvYWxzJTIwc2NvcmVkJTIwaW4lMjBhJTIwbWF0Y2glMjB3aWxsJTIwYmUlMjAzJTIwb3IlMjA0',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Rangers - Celtic',
      event_id: 1503366,
      tournament_id: '4',
      tournament_name: 'ŠKOTSKA  11',
      category_id: '0.07003776105889048',
      market_name: 'Total number of goals scored in a match will be 3 or 4',
      market_id: 3,
      sign_name: '3-4',
      sign_id: '19',
      hnd: null,
      odd: 2.5,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id:
        'My0yMi0tMS0yLjA1LVRvdGFsJTIwbnVtYmVyJTIwb2YlMjBnb2FscyUyMHNjb3JlZCUyMGluJTIwYSUyMG1hdGNoJTIwd2lsbCUyMGJlJTIwYmV0d2VlbiUyMDMlMjBhbmQlMjA1',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Ascoli - Bari',
      event_id: 1503353,
      tournament_id: '42',
      tournament_name: 'ITALIJA  2',
      category_id: '0.21003376008444885',
      market_name:
        'Total number of goals scored in a match will be between 3 and 5',
      market_id: 3,
      sign_name: '3-5',
      sign_id: '22',
      hnd: null,
      odd: 2.05,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id:
        'My0yOC0tMS0xLjgzLVRvdGFsJTIwbnVtYmVyJTIwb2YlMjBnb2FscyUyMHNjb3JlZCUyMGluJTIwYSUyMG1hdGNoJTIwd2lsbCUyMGJlJTIwYmV0d2VlbiUyMDMlMjBhbmQlMjA2',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Austria M. - Kapfenberg',
      event_id: 1503283,
      tournament_id: '9',
      tournament_name: 'AUSTRIJA  1',
      category_id: '0.0756923217736234',
      market_name:
        'Total number of goals scored in a match will be between 3 and 6',
      market_id: 3,
      sign_name: '3-6',
      sign_id: '28',
      hnd: null,
      odd: 1.83,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id:
        'My0yNS0tMS0zLjctVG90YWwlMjBudW1iZXIlMjBvZiUyMGdvYWxzJTIwc2NvcmVkJTIwaW4lMjBhJTIwbWF0Y2glMjB3aWxsJTIwYmUlMjA0JTIwb3IlMjA1',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Mattersburg - Rapid',
      event_id: 1503285,
      tournament_id: '9',
      tournament_name: 'AUSTRIJA  1',
      category_id: '0.8548048718079784',
      market_name: 'Total number of goals scored in a match will be 4 or 5',
      market_id: 3,
      sign_name: '4-5',
      sign_id: '25',
      hnd: null,
      odd: 3.7,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id:
        'My02LS0xLTIuNzUtVG90YWwlMjBudW1iZXIlMjBvZiUyMGdvYWxzJTIwc2NvcmVkJTIwaW4lMjBhJTIwbWF0Y2glMjB3aWxsJTIwYmUlMjBiZXR3ZWVuJTIwNCUyMGFuZCUyMDY=',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Sturm - Admira',
      event_id: 1503281,
      tournament_id: '9',
      tournament_name: 'AUSTRIJA  1',
      category_id: '0.9469847957826902',
      market_name:
        'Total number of goals scored in a match will be between 4 and 6',
      market_id: 3,
      sign_name: '4-6',
      sign_id: '6',
      hnd: null,
      odd: 2.75,
      category_name: 'Test',
      is_approved: true,
    },
  ],
  stake: 20,
}

export const mozzartbetKenyaPM_HTOU: PostMessage = {
  otp: fakeOTPs.nofail_a,
  tenant_id: '123e4567-e89b-12d3-a456-426614174000',
  source: '' as Source,
  locale: 'sr-Latn',
  selections: [
    {
      selection_id: 'OC04LS0xLTQuMS11bmRlZmluZWQ=',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Catania - Chievo',
      event_id: 1503302,
      tournament_id: '19',
      tournament_name: 'ITALIJA  1',
      category_id: '0.7034843261601058',
      market_name: 'null',
      market_id: 8,
      sign_name: '0',
      sign_id: '8',
      hnd: null,
      odd: 4.1,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id:
        'OC0xLS0xLTEuMjUtVG90YWwlMjBudW1iZXIlMjBvZiUyMGdvYWxzJTIwc2NvcmVkJTIwaW4lMjBhJTIwMXN0JTIwaGFsZiUyMHdpbGwlMjBiZSUyMDElMjBvciUyMG1vcmU=',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Cesena - Genoa',
      event_id: 1503292,
      tournament_id: '19',
      tournament_name: 'ITALIJA  1',
      category_id: '0.6613823887650967',
      market_name:
        'Total number of goals scored in a 1st half will be 1 or more',
      market_id: 8,
      sign_name: '1+',
      sign_id: '1',
      hnd: null,
      odd: 1.25,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id: 'OC01LS0xLTEuNDItdW5kZWZpbmVk',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Aston Villa - Blackburn',
      event_id: 1503331,
      tournament_id: '20',
      tournament_name: 'ENGLESKA  1',
      category_id: '0.22698237344659966',
      market_name: null as unknown as string,
      market_id: 8,
      sign_name: '0-1',
      sign_id: '5',
      hnd: null,
      odd: 1.42,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id:
        'OC0yLS0xLTIuNTUtVG90YWwlMjBudW1iZXIlMjBvZiUyMGdvYWxzJTIwc2NvcmVkJTIwaW4lMjBhJTIwMXN0JTIwaGFsZiUyMHdpbGwlMjBiZSUyMDIlMjBvciUyMG1vcmU=',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Bolton - Bradford',
      event_id: 1503337,
      tournament_id: '20',
      tournament_name: 'ENGLESKA  1',
      category_id: '0.5725774773744008',
      market_name:
        'Total number of goals scored in a 1st half will be 2 or more',
      market_id: 8,
      sign_name: '2+',
      sign_id: '2',
      hnd: null,
      odd: 2.55,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id: 'OC05LS0xLTEuMDMtdW5kZWZpbmVk',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'Chelsea - Arsenal',
      event_id: 1503329,
      tournament_id: '20',
      tournament_name: 'ENGLESKA  1',
      category_id: '0.8688067751459041',
      market_name: 'null',
      market_id: 8,
      sign_name: '0-2',
      sign_id: '9',
      hnd: null,
      odd: 1.03,
      category_name: 'Test',
      is_approved: true,
    },
    {
      selection_id:
        'OC0zLS0xLTctVG90YWwlMjBudW1iZXIlMjBvZiUyMGdvYWxzJTIwc2NvcmVkJTIwaW4lMjBhJTIwMXN0JTIwaGFsZiUyMHdpbGwlMjBiZSUyMDMlMjBvciUyMG1vcmU=',
      sport_name: 'Fudbal',
      sport_id: 1,
      event_name: 'QPR - Liverpool',
      event_id: 1503346,
      tournament_id: '20',
      tournament_name: 'ENGLESKA  1',
      category_id: '0.13281600875497523',
      market_name:
        'Total number of goals scored in a 1st half will be 3 or more',
      market_id: 8,
      sign_name: '3+',
      sign_id: '3',
      hnd: null,
      odd: 7,
      category_name: 'Test',
      is_approved: true,
    },
  ],
  stake: 20,
}

export const mozzartbetKenyaPM: PostMessage = {
  ...mozzartbetKenyaPM_HTOU,
}
