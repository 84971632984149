import { forwardRef, useRef } from 'react'
import { GoRight } from '../../../../components/svgs'
import { useTransform, useScroll } from 'framer-motion'
import { clamp } from '../../../../lib/utils/mathUtils'
import { Wrapper, Cartouche, Container, Item } from './explore-menu.style'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectActiveMarkets,
  selectActiveSport,
  selectSelectedMarket,
  setSelectedMarket,
} from '../../store/explore.state'
import { findMarketFromID } from '../../../../sports/marketUtils'
import { useSource } from '../../../../hooks/useSource'

export const ExploreMenu = () => {
  const ref = useRef<null | HTMLElement>(null)
  const { source } = useSource()
  const isMobile =
    source === 'old-mobile' ||
    source === 'new-mobile' ||
    source === 'mobile-lite'

  const { scrollXProgress } = useScroll(ref as any)
  const scrollXProgressLeft = useTransform(scrollXProgress, (x) =>
    clamp(0.3, 1, x),
  )
  const scrollXProgressRight = useTransform(scrollXProgress, (x) =>
    clamp(0.3, 1, x * -1 + 1),
  )

  return (
    <Wrapper data-testid="exploreMenu">
      {isMobile && (
        <Cartouche style={{ opacity: scrollXProgressLeft }}>
          <GoRight
            height="24"
            width="24"
            transform="rotate(180)"
            onClick={() => {
              ref?.current?.scrollTo({ left: 0, behavior: 'smooth' })
            }}
          />
        </Cartouche>
      )}

      <Markets ref={ref} />

      {isMobile && (
        <Cartouche style={{ opacity: scrollXProgressRight }}>
          <GoRight
            height="24"
            width="24"
            onClick={() => {
              ref?.current?.scrollTo({ left: 320, behavior: 'smooth' })
            }}
          />
        </Cartouche>
      )}
    </Wrapper>
  )
}

type IconsProps = {
  ref: null | HTMLElement
}
const Markets = forwardRef((props: IconsProps, ref: React.Ref<HTMLElement>) => {
  const selected = useSelector(selectSelectedMarket)
  const activeMarkets = useSelector(selectActiveMarkets)
  const activeSport = useSelector(selectActiveSport)
  const dispatch = useDispatch()

  return (
    <Container ref={ref} data-testid="markets">
      <Item
        selected={selected === 'explore'}
        onClick={() => dispatch(setSelectedMarket('explore'))}
      >
        <div className="market">{activeSport}</div>
      </Item>
      {activeMarkets.map((m) => (
        <Item
          selected={selected === m}
          onClick={() => dispatch(setSelectedMarket(m))}
          key={m}
        >
          <div className="market">
            {findMarketFromID(m, activeSport === 'Soccer' ? 1 : 2)}
          </div>
        </Item>
      ))}
    </Container>
  )
})
