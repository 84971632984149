import { SimMarket, Score, TimeSpan, TwoWay } from './types'

export const logicHND =
  (handicap: number) => (outcome: TwoWay, score: Score) => {
    const oc: { [key in TwoWay]: () => boolean } = {
      '1': () => score[0] + handicap > score[1],
      '2': () => score[1] + handicap > score[0],
    }
    return oc[outcome]() ?? false
  }

const ocHND = ['1', '2'] as TwoWay[]

const _HND = (id: number, time: TimeSpan, handicap: number): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: ocHND,
  logic: logicHND(handicap),
})

export const FT_HND_0355 = _HND(2302, 'FT', -35.5)
export const FT_HND_0345 = _HND(2303, 'FT', -34.5)
export const FT_HND_0335 = _HND(2304, 'FT', -33.5)
export const FT_HND_0325 = _HND(2305, 'FT', -32.5)
export const FT_HND_0315 = _HND(2306, 'FT', -31.5)
export const FT_HND_0305 = _HND(2307, 'FT', -30.5)
export const FT_HND_0295 = _HND(2308, 'FT', -29.5)
export const FT_HND_0285 = _HND(2309, 'FT', -28.5)
export const FT_HND_0275 = _HND(2310, 'FT', -27.5)
export const FT_HND_0265 = _HND(2311, 'FT', -26.5)
export const FT_HND_0255 = _HND(2312, 'FT', -25.5)
export const FT_HND_0245 = _HND(2313, 'FT', -24.5)
export const FT_HND_0235 = _HND(2314, 'FT', -23.5)
export const FT_HND_0225 = _HND(2315, 'FT', -22.5)
export const FT_HND_0215 = _HND(2316, 'FT', -21.5)
export const FT_HND_0205 = _HND(2317, 'FT', -20.5)
export const FT_HND_0195 = _HND(2318, 'FT', -19.5)
export const FT_HND_0185 = _HND(2319, 'FT', -18.5)
export const FT_HND_0175 = _HND(2320, 'FT', -17.5)
export const FT_HND_0165 = _HND(2321, 'FT', -16.5)
export const FT_HND_0155 = _HND(2322, 'FT', -15.5)
export const FT_HND_0145 = _HND(2323, 'FT', -14.5)
export const FT_HND_0135 = _HND(2324, 'FT', -13.5)
export const FT_HND_0125 = _HND(2325, 'FT', -12.5)
export const FT_HND_0115 = _HND(2326, 'FT', -11.5)
export const FT_HND_0105 = _HND(2327, 'FT', -10.5)
export const FT_HND_095 = _HND(2328, 'FT', -9.5)
export const FT_HND_085 = _HND(2329, 'FT', -8.5)
export const FT_HND_075 = _HND(2330, 'FT', -7.5)
export const FT_HND_065 = _HND(2331, 'FT', -6.5)
export const FT_HND_055 = _HND(2332, 'FT', -5.5)
export const FT_HND_045 = _HND(2333, 'FT', -4.5)
export const FT_HND_035 = _HND(2334, 'FT', -3.5)
export const FT_HND_025 = _HND(2335, 'FT', -2.5)
export const FT_HND_015 = _HND(2336, 'FT', -1.5)
export const FT_HND_005 = _HND(2337, 'FT', -0.5)
export const FT_HND_105 = _HND(2338, 'FT', 0.5)
export const FT_HND_115 = _HND(2339, 'FT', 1.5)
export const FT_HND_125 = _HND(2340, 'FT', 2.5)
export const FT_HND_135 = _HND(2341, 'FT', 3.5)
export const FT_HND_145 = _HND(2342, 'FT', 4.5)
export const FT_HND_155 = _HND(2343, 'FT', 5.5)
export const FT_HND_165 = _HND(2344, 'FT', 6.5)
export const FT_HND_175 = _HND(2345, 'FT', 7.5)
export const FT_HND_185 = _HND(2346, 'FT', 8.5)
export const FT_HND_195 = _HND(2347, 'FT', 9.5)
export const FT_HND_1105 = _HND(2348, 'FT', 10.5)
export const FT_HND_1115 = _HND(2349, 'FT', 11.5)
export const FT_HND_1125 = _HND(2350, 'FT', 12.5)
export const FT_HND_1135 = _HND(2351, 'FT', 13.5)
export const FT_HND_1145 = _HND(2352, 'FT', 14.5)
export const FT_HND_1155 = _HND(2353, 'FT', 15.5)
export const FT_HND_1165 = _HND(2354, 'FT', 16.5)
export const FT_HND_1175 = _HND(2355, 'FT', 17.5)
export const FT_HND_1185 = _HND(2356, 'FT', 18.5)
export const FT_HND_1195 = _HND(2357, 'FT', 19.5)
export const FT_HND_1205 = _HND(2358, 'FT', 20.5)
export const FT_HND_1215 = _HND(2359, 'FT', 21.5)
export const FT_HND_1225 = _HND(2360, 'FT', 22.5)
export const FT_HND_1235 = _HND(2361, 'FT', 23.5)
export const FT_HND_1245 = _HND(2362, 'FT', 24.5)
export const FT_HND_1255 = _HND(2363, 'FT', 25.5)
export const FT_HND_1265 = _HND(2364, 'FT', 26.5)
export const FT_HND_1275 = _HND(2365, 'FT', 27.5)
export const FT_HND_1285 = _HND(2366, 'FT', 28.5)
export const FT_HND_1295 = _HND(2367, 'FT', 29.5)
export const FT_HND_1305 = _HND(2368, 'FT', 30.5)
export const FT_HND_1315 = _HND(2369, 'FT', 31.5)
export const FT_HND_1325 = _HND(2370, 'FT', 32.5)
export const FT_HND_1335 = _HND(2371, 'FT', 33.5)
export const FT_HND_1345 = _HND(2372, 'FT', 34.5)
export const FT_HND_1355 = _HND(2373, 'FT', 35.5)
