import { BetResponse } from './types'

export const apiStatusHandling = (data: BetResponse): BetResponse | never => {
  const accountStatus = {
    '-5': 1150,
    '-2': 1100,
    '-1': 1101,
    '0': 1102,
    '1': 1103,
    '2': 1104,
  }

  const betStatus = {
    '-10': 1105,
    '-1': 1106,
    '0': 1107,
    '1': 1108,
  }

  if (data.account_status === -1) {
    throw { status: accountStatus[data.account_status] }
  }
  if (data.account_status === -5) {
    throw { status: accountStatus[data.account_status] }
  }
  if (data.status === -10) {
    throw { status: betStatus[data.status] }
  }

  return data
}
