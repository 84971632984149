import { SimMarket, Score, TimeSpan, HAOU } from './types'

export const logicHAOU =
  (handicap: number) => (outcome: HAOU, score: Score) => {
    const oc: { [key in HAOU]: () => boolean | 'settled' } = {
      'over home': () => (score[0] > handicap ? 'settled' : false),
      'under home': () => score[0] < handicap,
      'over away': () => (score[1] > handicap ? 'settled' : false),
      'under away': () => score[1] < handicap,
    }
    return oc[outcome]() ?? false
  }

const ocHAOU = ['over home', 'under home', 'over away', 'under away'] as HAOU[]

const _HAOU = (id: number, time: TimeSpan, handicap: number): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: ocHAOU,
  logic: logicHAOU(handicap),
})

export const FT_HAOU_05 = _HAOU(32, 'FT', 0.5)
export const FT_HAOU_15 = _HAOU(33, 'FT', 1.5)
export const HT_HAOU_05 = _HAOU(55, 'FT', 0.5)
export const HT_HAOU_15 = _HAOU(56, 'FT', 1.5)
export const FT_HAOU_25 = _HAOU(34, 'FT', 2.5)
export const FT_HAOU_35 = _HAOU(35, 'FT', 3.5)
