import { SimMarket, GGNGANDOU, Score, TimeSpan } from './types'
import { logicOUnS } from './OU'
import { logicGGNG } from './GG'

export const logicGGOU =
  (handicap: number) => (outcome: GGNGANDOU, score: Score) => {
    const oc: { [key in GGNGANDOU]: () => boolean | 'settled' } = {
      'gg & over': () =>
        logicOUnS(handicap)('o', score) && logicGGNG('gg', score),
      'gg & under': () =>
        logicOUnS(handicap)('u', score) && logicGGNG('gg', score),
      'ng & over': () =>
        logicOUnS(handicap)('o', score) && logicGGNG('ng', score),
      'ng & under': () =>
        logicOUnS(handicap)('u', score) && logicGGNG('ng', score),
    }
    return oc[outcome]() ?? false
  }

const ocGGOU = [
  'gg & over',
  'gg & under',
  'ng & over',
  'ng & under',
] as GGNGANDOU[]

const _GGOU = (id: number, time: TimeSpan, handicap = 0): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: ocGGOU,
  logic: logicGGOU(handicap),
})

export const FT_GGOU_25 = _GGOU(50, 'FT', 2.5)
export const FT_GGOU_35 = _GGOU(51, 'FT', 3.5)
