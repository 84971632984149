import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Rocket,
  Pedestrian,
  Speed,
  ArrowDropDown,
} from '../../../../components/svgs/index'
import {
  clicksSpeed,
  selectSpeedDrawer,
  selectSpeed,
  GameSpeed,
  closeSpeedDrawer,
} from '../../../app/store/app.state'
import {
  Selection,
  ButtonContainer,
  SelectionContainer,
} from './speedDrawer.style'
import { SetSpeedButton } from '../setSpeedButton/setSpeedButton'
import useOutsideClick from '../../../../hooks/useOutsideClick'
import { FormattedMessage } from 'react-intl'

type TSpeed = {
  speed: GameSpeed
  name: React.JSX.Element
  icon: React.JSX.Element
}

const speeds: TSpeed[] = [
  {
    speed: 'normal',
    name: (
      <FormattedMessage
        id="speedDrawer_normalSpeed"
        defaultMessage="Normal speed"
      />
    ),
    icon: <Pedestrian width="24" height="24" />,
  },
  {
    speed: 'fast',
    name: (
      <FormattedMessage
        id="speedDrawer_fastSpeed"
        defaultMessage="Fast speed"
      />
    ),
    icon: <Speed width="24" height="24" />,
  },
  {
    speed: 'turbo',
    name: (
      <FormattedMessage
        id="speedDrawer_turboSpeed"
        defaultMessage="Turbo speed"
      />
    ),
    icon: <Rocket width="24" height="24" />,
  },
]

export const SpeedDrawer = () => {
  const dispatch = useDispatch()
  const speed = useSelector(selectSpeed)
  const open = useSelector(selectSpeedDrawer)

  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, () => dispatch(closeSpeedDrawer()))

  const clicksSpeedButton = (speed: GameSpeed) => () => {
    dispatch(clicksSpeed(speed))
  }

  const getSelection = ({ speed, name, icon }: TSpeed) => (
    <Selection
      data-testid={`${speed}-normal`}
      onClick={clicksSpeedButton(speed)}
      key={speed}
    >
      <span className="icon">{icon}</span>
      {name}
    </Selection>
  )

  return (
    <ButtonContainer ref={wrapperRef} data-testid="button-container">
      <SetSpeedButton>
        {speeds
          .filter((s) => s.speed === speed)
          .map((s) => (
            <React.Fragment key={s.speed}>
              <span className="icon" style={{ marginRight: 'auto' }}>
                {s.icon}
              </span>
              {s.name}
              <ArrowDropDown
                width="24"
                height="24"
                style={{ marginLeft: 'auto' }}
              />
            </React.Fragment>
          ))}
      </SetSpeedButton>

      <SelectionContainer
        initial={{ height: 0 }}
        animate={{ height: open ? 'auto' : 0 }}
        transition={{ duration: 0.2 }}
        data-testid="sel-container"
      >
        {speeds.filter((s) => s.speed !== speed).map(getSelection)}
      </SelectionContainer>
    </ButtonContainer>
  )
}
