import { BRAND } from 'src/config'
import { SimBet, SourceID } from 'src/sports/types'
import { createMetadataObject } from './create-metadata-obj'
import { GameSpeed } from 'src/features/app/store/app.state'

export type TData = {
  bets: SimBet[]
  stake: number
  acceptOdds: boolean
  free_bet_uid: number
  source: SourceID
  odds: number
  sessionID: string
  game_speed: GameSpeed
}

export const transformPostBetRequest = (data: TData) => {
  const selections = data.bets.map((s) => {
    if (BRAND === 'bet9ja') {
      return {
        b9j_bet: s.b9jBet,
        sport_id: s.sport,
        event_name: s.event,
        event_id: s.matchID.toString(),
        selected_option: s.simOutcome,
        market_id: s.simMarketID || 0,
        odd: s.odd,
        odd_id: s.odd_id,
        tournament_id: s.tournament_id,
        tournament_name: s.tournament_name,
      }
    }
    return {
      selection_id: s.b9jBet,
      sport_id: s.sport,
      event_name: s.event,
      event_id: s.matchID.toString(),
      selected_option: s.simOutcome,
      market_id: s.simMarketID || 0,
      odd: s.odd,
      odd_id: s.odd_id,
      tournament_id: s.tournament_id,
      tournament_name: s.tournament_name,
    }
  })

  return {
    selections,
    stake: data.stake,
    accept_odds_changes: data.acceptOdds,
    free_bet_token: data.free_bet_uid,
    source: data.source,
    metadata: createMetadataObject(data.stake, data.odds, data.bets),
    game_speed: data.game_speed,
  }
}
