import { useSelector } from 'react-redux'
import { selectCanPrint } from '../../../app/store/app.state'
import { selectIsLoader } from '../../../game/store/game.state'
import { Spinner } from '../../../../components/spinner/spinner'
import { Wrapper } from './loader.style'
import { FormattedMessage } from 'react-intl'

export const Loader = () => {
  const canPrint = useSelector(selectCanPrint)
  const isLoader = useSelector(selectIsLoader)

  return (
    <>
      {isLoader && (
        <Wrapper data-testid="loader">
          <Spinner height="240px" size="240px" thick={2} color=" #F6B52D" />
          <div className="container">
            <p className="print">
              {canPrint ? (
                <FormattedMessage
                  id="loader_printingTicket"
                  defaultMessage="Printing Ticket"
                />
              ) : (
                <FormattedMessage
                  id="loader_simulating"
                  defaultMessage="Simulating"
                />
              )}
            </p>
            <p className="info">
              <FormattedMessage id="loader_info" defaultMessage="Please Wait" />
            </p>
          </div>
        </Wrapper>
      )}
    </>
  )
}
