import * as B9J from './types'
import * as Sim from '../../markets'

/** Bet9Ja ZOOM markets Maps into simulate markets */
export const B9jZoomSoccerMap = new Map<B9J.Markets, Sim.Market>()

/** Bet9Ja ZOOM outcomes Maps  into simulate outcomes */
export const B9JZoomSoccerOutcomeMap = new Map<B9J.ZoomOutcomes, Sim.Outcome>()

/** Bet9Ja ZOOM outcomes second maps to differentiate identical outcome codes */
export const B9JZoomSoccerOutcomeDIFFMap = new Map<
  B9J.ZoomOutcomesDIFF,
  Sim.Outcome
>()

/**
 * B9J Zoom events have exact same outcome codes for 1X2OU and 1X2AGGNG
 * A function that returns the simulate outcome code for Zoom events,
 * used by the backend for the simulation.
 * @param _b9jMkt unused
 * @param outcome the outcome from B9Ja code
 */
export const findZoomOutcome = (
  b9jMkt: B9J.Markets,
  outcome: B9J.ZoomOutcomes | B9J.ZoomOutcomesDIFF,
): Sim.Outcome => {
  if (
    b9jMkt.includes('1X2OU') ||
    b9jMkt.includes('1X2AGGNG') ||
    b9jMkt.includes('MULTIG')
  ) {
    return (
      B9JZoomSoccerOutcomeDIFFMap.get(outcome as B9J.ZoomOutcomesDIFF) ||
      ('' as Sim.Outcome)
    )
  } else {
    return (
      B9JZoomSoccerOutcomeMap.get(outcome as B9J.ZoomOutcomes) ||
      ('' as Sim.Outcome)
    )
  }
}

// zoom 1x2
B9jZoomSoccerMap.set('1X2', 'FT 1X2')
B9JZoomSoccerOutcomeMap.set('1', '1')
B9JZoomSoccerOutcomeMap.set('X', 'x')
B9JZoomSoccerOutcomeMap.set('2', '2')

// Over/Under
B9jZoomSoccerMap.set('OU@0.5', 'FT OU 0.5')
B9jZoomSoccerMap.set('OU@1.5', 'FT OU 1.5')
B9jZoomSoccerMap.set('OU@2.5', 'FT OU 2.5')
B9jZoomSoccerMap.set('OU@3.5', 'FT OU 3.5')
B9jZoomSoccerMap.set('OU@4.5', 'FT OU 4.5')
B9jZoomSoccerMap.set('OU@5.5', 'FT OU 5.5')
B9jZoomSoccerMap.set('OU@6.5', 'FT OU 6.5')
B9jZoomSoccerMap.set('OU@7.5', 'FT OU 7.5')
B9jZoomSoccerMap.set('OU@8.5', 'FT OU 8.5')
B9JZoomSoccerOutcomeMap.set('O', 'o')
B9JZoomSoccerOutcomeMap.set('U', 'u')

// Goal/No Goal (both team to score)
B9jZoomSoccerMap.set('GGNG', 'FT GG/NG')
B9JZoomSoccerOutcomeMap.set('GG', 'gg')
B9JZoomSoccerOutcomeMap.set('NG', 'ng')

// Double Chance
B9jZoomSoccerMap.set('DC', 'FT DC')
B9JZoomSoccerOutcomeMap.set('1X', '1x')
B9JZoomSoccerOutcomeMap.set('12', '12')
B9JZoomSoccerOutcomeMap.set('X2', '2x')

// 1X2 || Goal/No Goal -> chance Mix
B9jZoomSoccerMap.set('1X2OGGNG', 'Chance Mix')
B9JZoomSoccerOutcomeMap.set('1GG', '1 or gg')
B9JZoomSoccerOutcomeMap.set('XGG', 'x or gg')
B9JZoomSoccerOutcomeMap.set('2GG', '2 or gg')
B9JZoomSoccerOutcomeMap.set('1NG', '1 or ng')
B9JZoomSoccerOutcomeMap.set('XNG', 'x or ng')
B9JZoomSoccerOutcomeMap.set('2NG', '2 or ng')

// CHANCEMIXGGOU is not supported

// 1X2 || Over/Under ZOOM -> ChanceMix or OU
B9jZoomSoccerMap.set('1X2OROU@1.5', 'Chance Mix 1.5')
B9jZoomSoccerMap.set('1X2OROU@2.5', 'Chance Mix 2.5')
B9jZoomSoccerMap.set('1X2OROU@3.5', 'Chance Mix 3.5')
B9JZoomSoccerOutcomeMap.set('1O', '1 or ov')
B9JZoomSoccerOutcomeMap.set('XO', 'x or ov')
B9JZoomSoccerOutcomeMap.set('2O', '2 or ov')
B9JZoomSoccerOutcomeMap.set('1U', '1 or un')
B9JZoomSoccerOutcomeMap.set('XU', 'x or un')
B9JZoomSoccerOutcomeMap.set('2U', '2 or un')

// 1X2 && Over/Under
B9jZoomSoccerMap.set('1X2OU@1.5', '1X2 & O/U 1.5')
B9jZoomSoccerMap.set('1X2OU@2.5', '1X2 & O/U 2.5')
B9jZoomSoccerMap.set('1X2OU@3.5', '1X2 & O/U 3.5')
B9jZoomSoccerMap.set('1X2OU@4.5', '1X2 & O/U 4.5')
/********** !!! SAME AS 1X2OROU !!! ************/
/********** !!! PUSHED TO DIFF MAP !!! ************/
B9JZoomSoccerOutcomeDIFFMap.set('1O', '1 & over') // <-
B9JZoomSoccerOutcomeDIFFMap.set('1U', '1 & under') // <-
B9JZoomSoccerOutcomeDIFFMap.set('XO', 'x & over') // <-
B9JZoomSoccerOutcomeDIFFMap.set('XU', 'x & under') // <-
B9JZoomSoccerOutcomeDIFFMap.set('2O', '2 & over') // <-
B9JZoomSoccerOutcomeDIFFMap.set('2U', '2 & under') // <-

// Home/Away - Over/Under ZOOM
B9jZoomSoccerMap.set('12OU@0.5', 'H/A O/U 0.5')
B9jZoomSoccerMap.set('12OU@1.5', 'H/A O/U 1.5')
B9jZoomSoccerMap.set('12OU@2.5', 'H/A O/U 2.5')
B9jZoomSoccerMap.set('12OU@3.5', 'H/A O/U 3.5')
B9JZoomSoccerOutcomeMap.set('HO', 'over home')
B9JZoomSoccerOutcomeMap.set('HU', 'under home')
B9JZoomSoccerOutcomeMap.set('AO', 'over away')
B9JZoomSoccerOutcomeMap.set('AU', 'under away')

// 1X2 && Goal/No Goal ZOOM
B9jZoomSoccerMap.set('1X2AGGNG', '1X2 & GG/NG')
/********** !!! SAME AS 1X2OGGNG !!! ************/
/********** !!! PUSHED TO DIFF MAP !!! ************/
B9JZoomSoccerOutcomeDIFFMap.set('1GG', '1 & gg') // <-
B9JZoomSoccerOutcomeDIFFMap.set('1NG', '1 & ng') // <-
B9JZoomSoccerOutcomeDIFFMap.set('XGG', 'x & gg') // <-
B9JZoomSoccerOutcomeDIFFMap.set('XNG', 'x & ng') // <-
B9JZoomSoccerOutcomeDIFFMap.set('2GG', '2 & gg') // <-
B9JZoomSoccerOutcomeDIFFMap.set('2NG', '2 & ng') // <-

// Double Chance && Over/Under
B9jZoomSoccerMap.set('DCOU@1.5', 'DC & O/U 1.5')
B9jZoomSoccerMap.set('DCOU@2.5', 'DC & O/U 2.5')
B9jZoomSoccerMap.set('DCOU@3.5', 'DC & O/U 3.5')
B9jZoomSoccerMap.set('DCOU@4.5', 'DC & O/U 4.5')
B9JZoomSoccerOutcomeMap.set('1XO', '1x & over')
B9JZoomSoccerOutcomeMap.set('X2O', '2x & over')
B9JZoomSoccerOutcomeMap.set('12O', '12 & over')
B9JZoomSoccerOutcomeMap.set('1XU', '1x & under')
B9JZoomSoccerOutcomeMap.set('X2U', '2x & under')
B9JZoomSoccerOutcomeMap.set('12U', '12 & under')

//  Correct Score ZOOM
B9jZoomSoccerMap.set('CS', 'Correct Score')
B9JZoomSoccerOutcomeMap.set('1:0', '1-0')
B9JZoomSoccerOutcomeMap.set('2:0', '2-0')
B9JZoomSoccerOutcomeMap.set('2:1', '2-1')
B9JZoomSoccerOutcomeMap.set('3:0', '3-0')
B9JZoomSoccerOutcomeMap.set('3:1', '3-1')
B9JZoomSoccerOutcomeMap.set('3:2', '3-2')
B9JZoomSoccerOutcomeMap.set('4:0', '4-0')
B9JZoomSoccerOutcomeMap.set('4:1', '4-1')
B9JZoomSoccerOutcomeMap.set('4:2', '4-2')
B9JZoomSoccerOutcomeMap.set('4:3', '4-3')
B9JZoomSoccerOutcomeMap.set('0:1', '0-1')
B9JZoomSoccerOutcomeMap.set('0:2', '0-2')
B9JZoomSoccerOutcomeMap.set('1:2', '1-2')
B9JZoomSoccerOutcomeMap.set('0:3', '0-3')
B9JZoomSoccerOutcomeMap.set('1:3', '1-3')
B9JZoomSoccerOutcomeMap.set('2:3', '2-3')
B9JZoomSoccerOutcomeMap.set('0:4', '0-4')
B9JZoomSoccerOutcomeMap.set('1:4', '1-4')
B9JZoomSoccerOutcomeMap.set('2:4', '2-4')
B9JZoomSoccerOutcomeMap.set('3:4', '3-4')
B9JZoomSoccerOutcomeMap.set('0:0', '0-0')
B9JZoomSoccerOutcomeMap.set('1:1', '1-1')
B9JZoomSoccerOutcomeMap.set('2:2', '2-2')
B9JZoomSoccerOutcomeMap.set('3:3', '3-3')
B9JZoomSoccerOutcomeMap.set('4:4', '4-4')
B9JZoomSoccerOutcomeMap.set('OTH', 'other')

// Multi goal ZOOM
B9jZoomSoccerMap.set('MULTIG', 'Multi goal')
B9JZoomSoccerOutcomeDIFFMap.set('12', '1-2 goals')
B9JZoomSoccerOutcomeDIFFMap.set('13', '1-3 goals')
B9JZoomSoccerOutcomeDIFFMap.set('14', '1-4 goals')
B9JZoomSoccerOutcomeDIFFMap.set('15', '1-5 goals')
B9JZoomSoccerOutcomeDIFFMap.set('23', '2-3 goals')
B9JZoomSoccerOutcomeDIFFMap.set('24', '2-4 goals')
B9JZoomSoccerOutcomeDIFFMap.set('25', '2-5 goals')
B9JZoomSoccerOutcomeDIFFMap.set('26', '2-6 goals')
B9JZoomSoccerOutcomeDIFFMap.set('34', '3-4 goals')
B9JZoomSoccerOutcomeDIFFMap.set('35', '3-5 goals')
B9JZoomSoccerOutcomeDIFFMap.set('36', '3-6 goals')
B9JZoomSoccerOutcomeDIFFMap.set('45', '4-5 goals')
B9JZoomSoccerOutcomeDIFFMap.set('46', '4-6 goals')
B9JZoomSoccerOutcomeDIFFMap.set('56', '5-6 goals')
