import { getEndpoint } from 'src/api/endpoints'
import { errorHandling } from '../../../api/utils'

type TournamentOdd = {
  code: string
  option: string
  percentage: number
  odd: number
  odd_id: number
}

type TournamentGame = {
  event_id: number
  event_name: string
  market_id: number
  sport_id: number
  tournament_id: string
  tournament_name: string
  odds: TournamentOdd[]
}

export type TGetTournamentRes = {
  popular_events: TournamentGame[]
}

export const getTournamentEvents = async (
  sessionID: string,
  activeTournament: string,
): Promise<TGetTournamentRes> => {
  const response = await fetch(
    `${getEndpoint()}/api/explore/tournament/${activeTournament}`,
    {
      method: 'get',
      headers: {
        'accept': 'application/json',
        'X-SID': `${sessionID}`,
      },
    },
  ).catch((e) => {
    errorHandling(e)
  })

  if (!response?.ok) {
    errorHandling(await response?.json(), response?.status)
  }

  return await response?.json()
}
