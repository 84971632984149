import React, { useRef, useEffect } from 'react'
import { Wrapper, ListWrapper } from './myBets.style'
import { Filter } from '../filter/filter'
import { useSelector } from 'react-redux'
import {
  fetchBetList,
  getNextList,
  selectMyGamesList,
  selectMyGamesStatus,
} from '../../store/mygames.state'
import { Coupon } from '../coupon/coupon'
import { SpinnerLayer } from '../../../../components/spinner-layer/spinner-layer'
import { useAppDispatch } from '../../../../store/store'
import { FormattedMessage } from 'react-intl'

export const MyBets: React.FC = () => (
  <Wrapper>
    <Filter />
    <List />
  </Wrapper>
)

const List = () => {
  const dispatch = useAppDispatch()
  const betlist = useSelector(selectMyGamesList)
  const status = useSelector(selectMyGamesStatus)
  const listRef = useRef<HTMLDivElement>(null)

  const handleObserver = (entries: any) => {
    const target = entries[0]

    if (target.isIntersecting) {
      dispatch(getNextList())
    }
  }

  useEffect(() => {
    dispatch(fetchBetList(''))
    if (!listRef.current) {
      return
    }
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    }
    const observer = new IntersectionObserver(handleObserver, option)
    observer.observe(listRef.current)
  }, [])

  return (
    <ListWrapper data-testid="coupon-list">
      {betlist?.length === 0 ? (
        <ListWrapper>
          <p>
            <FormattedMessage
              id="no_info"
              defaultMessage="There is currently no information to display here"
            />
          </p>
        </ListWrapper>
      ) : (
        betlist?.map((bet) => <Coupon key={bet.bet_id} {...bet} />)
      )}
      <SpinnerLayer status={status} />
      <div ref={listRef} />
    </ListWrapper>
  )
}
