import { SimMarket, Score, TimeSpan, ThreeWay } from './types'

export const logic3Way = (outcome: ThreeWay, score: Score) => {
  const oc: { [key in ThreeWay]: () => boolean } = {
    1: () => score[0] > score[1],
    x: () => score[0] === score[1],
    2: () => score[0] < score[1],
  }
  return oc[outcome]() ?? false
}

const ocThreeWay = ['1', '2', 'x'] as ThreeWay[]

const _ThreeWay = (id: number, time: TimeSpan, handicap = 0): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: ocThreeWay,
  logic: logic3Way,
})

export const FT_THREE_WAY = _ThreeWay(2006, 'FT')
