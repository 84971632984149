import * as B9J from './types'
import * as Sim from '../../markets'

export const B9jBasketballMap = new Map<B9J.Markets, Sim.Market>()
export const B9JBasketballOutcomeMap = new Map<B9J.Outcomes, Sim.Outcome>()

export const findBasketballOutcome = (
  _b9jMkt: B9J.Markets,
  outcome: B9J.Outcomes,
): Sim.Outcome => {
  return B9JBasketballOutcomeMap.get(outcome) || ('' as Sim.Outcome)
}

// 2 way
B9jBasketballMap.set('12', '2 Way')
B9JBasketballOutcomeMap.set('1', '1')
B9JBasketballOutcomeMap.set('2', '2')
// 1x2 1st Quarter
B9jBasketballMap.set('1X21P', '1X2 1st Quarter')
B9JBasketballOutcomeMap.set('11Q', '1')
B9JBasketballOutcomeMap.set('21Q', '2')
B9JBasketballOutcomeMap.set('X1Q', 'x')
// 1x2 1st Half
B9jBasketballMap.set('1X21T', '1X2 1st Half')
B9JBasketballOutcomeMap.set('1HT', '1')
B9JBasketballOutcomeMap.set('2HT', '2')
B9JBasketballOutcomeMap.set('XHT', 'x')
// 1x2 5.5
B9jBasketballMap.set('1X2HNDN@5.5', '1X2 (5.5 points)')
B9JBasketballOutcomeMap.set('1H', '1')
B9JBasketballOutcomeMap.set('2H', '2')
B9JBasketballOutcomeMap.set('XH', 'x')
// Home Away
B9jBasketballMap.set('MARGINHOME', 'HomeAway')
B9jBasketballMap.set('MARGINAWAY', 'HomeAway')
B9JBasketballOutcomeMap.set('Home1-5', 'Home 1-5')
B9JBasketballOutcomeMap.set('Home6-10', 'Home 6-10')
B9JBasketballOutcomeMap.set('Home11-15', 'Home 11-15')
B9JBasketballOutcomeMap.set('Home16-20', 'Home 16-20')
B9JBasketballOutcomeMap.set('Home21-25', 'Home 21-25')
B9JBasketballOutcomeMap.set('Home25+', 'Home 26+')
B9JBasketballOutcomeMap.set('Away1-5', 'Away 1-5')
B9JBasketballOutcomeMap.set('Away6-10', 'Away 6-10')
B9JBasketballOutcomeMap.set('Away11-15', 'Away 11-15')
B9JBasketballOutcomeMap.set('Away16-20', 'Away 16-20')
B9JBasketballOutcomeMap.set('Away21-25', 'Away 21-25')
B9JBasketballOutcomeMap.set('Away25+', 'Away 26+')

// Over/Under
B9jBasketballMap.set('OUN@110.5', 'O/U 110.5')
B9jBasketballMap.set('OUN@111.5', 'O/U 111.5')
B9jBasketballMap.set('OUN@112.5', 'O/U 112.5')
B9jBasketballMap.set('OUN@113.5', 'O/U 113.5')
B9jBasketballMap.set('OUN@114.5', 'O/U 114.5')
B9jBasketballMap.set('OUN@115.5', 'O/U 115.5')
B9jBasketballMap.set('OUN@116.5', 'O/U 116.5')
B9jBasketballMap.set('OUN@117.5', 'O/U 117.5')
B9jBasketballMap.set('OUN@118.5', 'O/U 118.5')
B9jBasketballMap.set('OUN@119.5', 'O/U 119.5')
B9jBasketballMap.set('OUN@120.5', 'O/U 120.5')
B9jBasketballMap.set('OUN@121.5', 'O/U 121.5')
B9jBasketballMap.set('OUN@122.5', 'O/U 122.5')
B9jBasketballMap.set('OUN@123.5', 'O/U 123.5')
B9jBasketballMap.set('OUN@124.5', 'O/U 124.5')
B9jBasketballMap.set('OUN@125.5', 'O/U 125.5')
B9jBasketballMap.set('OUN@126.5', 'O/U 126.5')
B9jBasketballMap.set('OUN@127.5', 'O/U 127.5')
B9jBasketballMap.set('OUN@128.5', 'O/U 128.5')
B9jBasketballMap.set('OUN@129.5', 'O/U 129.5')
B9jBasketballMap.set('OUN@130.5', 'O/U 130.5')
B9jBasketballMap.set('OUN@131.5', 'O/U 131.5')
B9jBasketballMap.set('OUN@132.5', 'O/U 132.5')
B9jBasketballMap.set('OUN@133.5', 'O/U 133.5')
B9jBasketballMap.set('OUN@134.5', 'O/U 134.5')
B9jBasketballMap.set('OUN@135.5', 'O/U 135.5')
B9jBasketballMap.set('OUN@136.5', 'O/U 136.5')
B9jBasketballMap.set('OUN@137.5', 'O/U 137.5')
B9jBasketballMap.set('OUN@138.5', 'O/U 138.5')
B9jBasketballMap.set('OUN@139.5', 'O/U 139.5')
B9jBasketballMap.set('OUN@140.5', 'O/U 140.5')
B9jBasketballMap.set('OUN@141.5', 'O/U 141.5')
B9jBasketballMap.set('OUN@142.5', 'O/U 142.5')
B9jBasketballMap.set('OUN@143.5', 'O/U 143.5')
B9jBasketballMap.set('OUN@144.5', 'O/U 144.5')
B9jBasketballMap.set('OUN@145.5', 'O/U 145.5')
B9jBasketballMap.set('OUN@146.5', 'O/U 146.5')
B9jBasketballMap.set('OUN@147.5', 'O/U 147.5')
B9jBasketballMap.set('OUN@148.5', 'O/U 148.5')
B9jBasketballMap.set('OUN@149.5', 'O/U 149.5')
B9jBasketballMap.set('OUN@150.5', 'O/U 150.5')
B9jBasketballMap.set('OUN@151.5', 'O/U 151.5')
B9jBasketballMap.set('OUN@152.5', 'O/U 152.5')
B9jBasketballMap.set('OUN@153.5', 'O/U 153.5')
B9jBasketballMap.set('OUN@154.5', 'O/U 154.5')
B9jBasketballMap.set('OUN@155.5', 'O/U 155.5')
B9jBasketballMap.set('OUN@156.5', 'O/U 156.5')
B9jBasketballMap.set('OUN@157.5', 'O/U 157.5')
B9jBasketballMap.set('OUN@158.5', 'O/U 158.5')
B9jBasketballMap.set('OUN@159.5', 'O/U 159.5')
B9jBasketballMap.set('OUN@160.5', 'O/U 160.5')
B9jBasketballMap.set('OUN@161.5', 'O/U 161.5')
B9jBasketballMap.set('OUN@162.5', 'O/U 162.5')
B9jBasketballMap.set('OUN@163.5', 'O/U 163.5')
B9jBasketballMap.set('OUN@164.5', 'O/U 164.5')
B9jBasketballMap.set('OUN@165.5', 'O/U 165.5')
B9jBasketballMap.set('OUN@166.5', 'O/U 166.5')
B9jBasketballMap.set('OUN@167.5', 'O/U 167.5')
B9jBasketballMap.set('OUN@168.5', 'O/U 168.5')
B9jBasketballMap.set('OUN@169.5', 'O/U 169.5')
B9jBasketballMap.set('OUN@170.5', 'O/U 170.5')
B9jBasketballMap.set('OUN@171.5', 'O/U 171.5')
B9jBasketballMap.set('OUN@172.5', 'O/U 172.5')
B9jBasketballMap.set('OUN@173.5', 'O/U 173.5')
B9jBasketballMap.set('OUN@174.5', 'O/U 174.5')
B9jBasketballMap.set('OUN@175.5', 'O/U 175.5')
B9jBasketballMap.set('OUN@176.5', 'O/U 176.5')
B9jBasketballMap.set('OUN@177.5', 'O/U 177.5')
B9jBasketballMap.set('OUN@178.5', 'O/U 178.5')
B9jBasketballMap.set('OUN@179.5', 'O/U 179.5')
B9jBasketballMap.set('OUN@180.5', 'O/U 180.5')
B9jBasketballMap.set('OUN@181.5', 'O/U 181.5')
B9jBasketballMap.set('OUN@182.5', 'O/U 182.5')
B9jBasketballMap.set('OUN@183.5', 'O/U 183.5')
B9jBasketballMap.set('OUN@184.5', 'O/U 184.5')
B9jBasketballMap.set('OUN@185.5', 'O/U 185.5')
B9jBasketballMap.set('OUN@186.5', 'O/U 186.5')
B9jBasketballMap.set('OUN@187.5', 'O/U 187.5')
B9jBasketballMap.set('OUN@188.5', 'O/U 188.5')
B9jBasketballMap.set('OUN@189.5', 'O/U 189.5')
B9jBasketballMap.set('OUN@190.5', 'O/U 190.5')
B9jBasketballMap.set('OUN@191.5', 'O/U 191.5')
B9jBasketballMap.set('OUN@192.5', 'O/U 192.5')
B9jBasketballMap.set('OUN@193.5', 'O/U 193.5')
B9jBasketballMap.set('OUN@194.5', 'O/U 194.5')
B9jBasketballMap.set('OUN@195.5', 'O/U 195.5')
B9jBasketballMap.set('OUN@196.5', 'O/U 196.5')
B9jBasketballMap.set('OUN@197.5', 'O/U 197.5')
B9jBasketballMap.set('OUN@198.5', 'O/U 198.5')
B9jBasketballMap.set('OUN@199.5', 'O/U 199.5')
B9jBasketballMap.set('OUN@200.5', 'O/U 200.5')
B9jBasketballMap.set('OUN@201.5', 'O/U 201.5')
B9jBasketballMap.set('OUN@202.5', 'O/U 202.5')
B9jBasketballMap.set('OUN@203.5', 'O/U 203.5')
B9jBasketballMap.set('OUN@204.5', 'O/U 204.5')
B9jBasketballMap.set('OUN@205.5', 'O/U 205.5')
B9jBasketballMap.set('OUN@206.5', 'O/U 206.5')
B9jBasketballMap.set('OUN@207.5', 'O/U 207.5')
B9jBasketballMap.set('OUN@208.5', 'O/U 208.5')
B9jBasketballMap.set('OUN@209.5', 'O/U 209.5')
B9jBasketballMap.set('OUN@210.5', 'O/U 210.5')
B9jBasketballMap.set('OUN@211.5', 'O/U 211.5')
B9jBasketballMap.set('OUN@212.5', 'O/U 212.5')
B9jBasketballMap.set('OUN@213.5', 'O/U 213.5')
B9jBasketballMap.set('OUN@214.5', 'O/U 214.5')
B9jBasketballMap.set('OUN@215.5', 'O/U 215.5')
B9jBasketballMap.set('OUN@216.5', 'O/U 216.5')
B9jBasketballMap.set('OUN@217.5', 'O/U 217.5')
B9jBasketballMap.set('OUN@218.5', 'O/U 218.5')
B9jBasketballMap.set('OUN@219.5', 'O/U 219.5')
B9jBasketballMap.set('OUN@220.5', 'O/U 220.5')
B9jBasketballMap.set('OUN@221.5', 'O/U 221.5')
B9jBasketballMap.set('OUN@222.5', 'O/U 222.5')
B9jBasketballMap.set('OUN@223.5', 'O/U 223.5')
B9jBasketballMap.set('OUN@224.5', 'O/U 224.5')
B9jBasketballMap.set('OUN@225.5', 'O/U 225.5')
B9jBasketballMap.set('OUN@226.5', 'O/U 226.5')
B9jBasketballMap.set('OUN@227.5', 'O/U 227.5')
B9jBasketballMap.set('OUN@228.5', 'O/U 228.5')
B9jBasketballMap.set('OUN@229.5', 'O/U 229.5')
B9jBasketballMap.set('OUN@230.5', 'O/U 230.5')
B9jBasketballMap.set('OUN@231.5', 'O/U 231.5')
B9jBasketballMap.set('OUN@232.5', 'O/U 232.5')
B9jBasketballMap.set('OUN@233.5', 'O/U 233.5')
B9jBasketballMap.set('OUN@234.5', 'O/U 234.5')
B9jBasketballMap.set('OUN@235.5', 'O/U 235.5')
B9jBasketballMap.set('OUN@236.5', 'O/U 236.5')
B9jBasketballMap.set('OUN@237.5', 'O/U 237.5')
B9jBasketballMap.set('OUN@238.5', 'O/U 238.5')
B9jBasketballMap.set('OUN@239.5', 'O/U 239.5')
B9jBasketballMap.set('OUN@240.5', 'O/U 240.5')
B9jBasketballMap.set('OUN@241.5', 'O/U 241.5')
B9jBasketballMap.set('OUN@242.5', 'O/U 242.5')
B9jBasketballMap.set('OUN@243.5', 'O/U 243.5')
B9jBasketballMap.set('OUN@244.5', 'O/U 244.5')
B9jBasketballMap.set('OUN@245.5', 'O/U 245.5')
B9jBasketballMap.set('OUN@246.5', 'O/U 246.5')
B9jBasketballMap.set('OUN@247.5', 'O/U 247.5')
B9jBasketballMap.set('OUN@248.5', 'O/U 248.5')
B9jBasketballMap.set('OUN@249.5', 'O/U 249.5')
B9jBasketballMap.set('OUN@250.5', 'O/U 250.5')
B9jBasketballMap.set('OUN@251.5', 'O/U 251.5')
B9jBasketballMap.set('OUN@252.5', 'O/U 252.5')
B9jBasketballMap.set('OUN@253.5', 'O/U 253.5')
B9jBasketballMap.set('OUN@254.5', 'O/U 254.5')
B9jBasketballMap.set('OUN@255.5', 'O/U 255.5')
B9jBasketballMap.set('OUN@256.5', 'O/U 256.5')
B9jBasketballMap.set('OUN@257.5', 'O/U 257.5')
B9jBasketballMap.set('OUN@258.5', 'O/U 258.5')
B9jBasketballMap.set('OUN@259.5', 'O/U 259.5')
B9jBasketballMap.set('OUN@260.5', 'O/U 260.5')
B9jBasketballMap.set('OUN@261.5', 'O/U 261.5')
B9jBasketballMap.set('OUN@262.5', 'O/U 262.5')
B9jBasketballMap.set('OUN@263.5', 'O/U 263.5')
B9jBasketballMap.set('OUN@264.5', 'O/U 264.5')
B9jBasketballMap.set('OUN@265.5', 'O/U 265.5')
B9jBasketballMap.set('OUN@266.5', 'O/U 266.5')
B9jBasketballMap.set('OUN@267.5', 'O/U 267.5')
B9jBasketballMap.set('OUN@268.5', 'O/U 268.5')
B9jBasketballMap.set('OUN@269.5', 'O/U 269.5')
B9jBasketballMap.set('OUN@270.5', 'O/U 270.5')
B9jBasketballMap.set('OUN@271.5', 'O/U 271.5')
B9jBasketballMap.set('OUN@272.5', 'O/U 272.5')
B9jBasketballMap.set('OUN@273.5', 'O/U 273.5')
B9jBasketballMap.set('OUN@274.5', 'O/U 274.5')
B9jBasketballMap.set('OUN@275.5', 'O/U 275.5')
B9jBasketballMap.set('OUN@276.5', 'O/U 276.5')
B9jBasketballMap.set('OUN@277.5', 'O/U 277.5')
B9jBasketballMap.set('OUN@278.5', 'O/U 278.5')
B9jBasketballMap.set('OUN@279.5', 'O/U 279.5')
B9jBasketballMap.set('OUN@280.5', 'O/U 280.5')
B9jBasketballMap.set('OUN@281.5', 'O/U 281.5')
B9jBasketballMap.set('OUN@282.5', 'O/U 282.5')
B9jBasketballMap.set('OUN@283.5', 'O/U 283.5')
B9jBasketballMap.set('OUN@284.5', 'O/U 284.5')
B9jBasketballMap.set('OUN@285.5', 'O/U 285.5')
B9jBasketballMap.set('OUN@286.5', 'O/U 286.5')
B9jBasketballMap.set('OUN@287.5', 'O/U 287.5')
B9jBasketballMap.set('OUN@288.5', 'O/U 288.5')
B9jBasketballMap.set('OUN@289.5', 'O/U 289.5')
B9jBasketballMap.set('OUN@290.5', 'O/U 290.5')
B9jBasketballMap.set('OUN@291.5', 'O/U 291.5')
B9jBasketballMap.set('OUN@292.5', 'O/U 292.5')
B9jBasketballMap.set('OUN@293.5', 'O/U 293.5')
B9jBasketballMap.set('OUN@294.5', 'O/U 294.5')
B9jBasketballMap.set('OUN@295.5', 'O/U 295.5')
B9jBasketballMap.set('OUN@296.5', 'O/U 296.5')
B9jBasketballMap.set('OUN@297.5', 'O/U 297.5')
B9jBasketballMap.set('OUN@298.5', 'O/U 298.5')
B9jBasketballMap.set('OUN@299.5', 'O/U 299.5')
B9jBasketballMap.set('OUN@300.5', 'O/U 300.5')
B9jBasketballMap.set('OUN@301.5', 'O/U 301.5')
B9jBasketballMap.set('OUN@302.5', 'O/U 302.5')
B9jBasketballMap.set('OUN@303.5', 'O/U 303.5')
B9jBasketballMap.set('OUN@304.5', 'O/U 304.5')
B9jBasketballMap.set('OUN@305.5', 'O/U 305.5')
B9jBasketballMap.set('OUN@306.5', 'O/U 306.5')
B9jBasketballMap.set('OUN@307.5', 'O/U 307.5')
B9jBasketballMap.set('OUN@308.5', 'O/U 308.5')
B9jBasketballMap.set('OUN@309.5', 'O/U 309.5')
B9jBasketballMap.set('OUN@310.5', 'O/U 310.5')
B9jBasketballMap.set('OUN@311.5', 'O/U 311.5')
B9jBasketballMap.set('OUN@312.5', 'O/U 312.5')
B9jBasketballMap.set('OUN@313.5', 'O/U 313.5')
B9jBasketballMap.set('OUN@314.5', 'O/U 314.5')
B9jBasketballMap.set('OUN@315.5', 'O/U 315.5')
B9jBasketballMap.set('OUN@316.5', 'O/U 316.5')
B9jBasketballMap.set('OUN@317.5', 'O/U 317.5')
B9jBasketballMap.set('OUN@318.5', 'O/U 318.5')
B9jBasketballMap.set('OUN@319.5', 'O/U 319.5')
B9jBasketballMap.set('OUN@320.5', 'O/U 320.5')

// Over/Under 1st Quarter
B9jBasketballMap.set('T1@27.5', 'O/U 1st Quarter 27.5')
B9jBasketballMap.set('T1@28.5', 'O/U 1st Quarter 28.5')
B9jBasketballMap.set('T1@29.5', 'O/U 1st Quarter 29.5')
B9jBasketballMap.set('T1@30.5', 'O/U 1st Quarter 30.5')
B9jBasketballMap.set('T1@31.5', 'O/U 1st Quarter 31.5')
B9jBasketballMap.set('T1@32.5', 'O/U 1st Quarter 32.5')
B9jBasketballMap.set('T1@33.5', 'O/U 1st Quarter 33.5')
B9jBasketballMap.set('T1@34.5', 'O/U 1st Quarter 34.5')
B9jBasketballMap.set('T1@35.5', 'O/U 1st Quarter 35.5')
B9jBasketballMap.set('T1@36.5', 'O/U 1st Quarter 36.5')
B9jBasketballMap.set('T1@37.5', 'O/U 1st Quarter 37.5')
B9jBasketballMap.set('T1@38.5', 'O/U 1st Quarter 38.5')
B9jBasketballMap.set('T1@39.5', 'O/U 1st Quarter 39.5')
B9jBasketballMap.set('T1@40.5', 'O/U 1st Quarter 40.5')
B9jBasketballMap.set('T1@41.5', 'O/U 1st Quarter 41.5')
B9jBasketballMap.set('T1@42.5', 'O/U 1st Quarter 42.5')
B9jBasketballMap.set('T1@43.5', 'O/U 1st Quarter 43.5')
B9jBasketballMap.set('T1@44.5', 'O/U 1st Quarter 44.5')
B9jBasketballMap.set('T1@45.5', 'O/U 1st Quarter 45.5')
B9jBasketballMap.set('T1@46.5', 'O/U 1st Quarter 46.5')
B9jBasketballMap.set('T1@47.5', 'O/U 1st Quarter 47.5')
B9jBasketballMap.set('T1@48.5', 'O/U 1st Quarter 48.5')
B9jBasketballMap.set('T1@49.5', 'O/U 1st Quarter 49.5')
B9jBasketballMap.set('T1@50.5', 'O/U 1st Quarter 50.5')
B9jBasketballMap.set('T1@51.5', 'O/U 1st Quarter 51.5')
B9jBasketballMap.set('T1@52.5', 'O/U 1st Quarter 52.5')
B9jBasketballMap.set('T1@53.5', 'O/U 1st Quarter 53.5')
B9jBasketballMap.set('T1@54.5', 'O/U 1st Quarter 54.5')
B9jBasketballMap.set('T1@55.5', 'O/U 1st Quarter 55.5')
B9jBasketballMap.set('T1@56.5', 'O/U 1st Quarter 56.5')
B9jBasketballMap.set('T1@57.5', 'O/U 1st Quarter 57.5')
B9jBasketballMap.set('T1@58.5', 'O/U 1st Quarter 58.5')
B9jBasketballMap.set('T1@59.5', 'O/U 1st Quarter 59.5')
B9jBasketballMap.set('T1@60.5', 'O/U 1st Quarter 60.5')
B9jBasketballMap.set('T1@61.5', 'O/U 1st Quarter 61.5')
B9jBasketballMap.set('T1@62.5', 'O/U 1st Quarter 62.5')
B9jBasketballMap.set('T1@63.5', 'O/U 1st Quarter 63.5')
B9jBasketballMap.set('T1@64.5', 'O/U 1st Quarter 64.5')
B9jBasketballMap.set('T1@65.5', 'O/U 1st Quarter 65.5')
B9jBasketballMap.set('T1@66.5', 'O/U 1st Quarter 66.5')
B9jBasketballMap.set('T1@67.5', 'O/U 1st Quarter 67.5')
B9jBasketballMap.set('T1@68.5', 'O/U 1st Quarter 68.5')
B9jBasketballMap.set('T1@69.5', 'O/U 1st Quarter 69.5')
B9jBasketballMap.set('T1@70.5', 'O/U 1st Quarter 70.5')
B9jBasketballMap.set('T1@71.5', 'O/U 1st Quarter 71.5')
B9jBasketballMap.set('T1@72.5', 'O/U 1st Quarter 72.5')
B9jBasketballMap.set('T1@73.5', 'O/U 1st Quarter 73.5')
B9jBasketballMap.set('T1@74.5', 'O/U 1st Quarter 74.5')
B9jBasketballMap.set('T1@75.5', 'O/U 1st Quarter 75.5')
B9jBasketballMap.set('T1@76.5', 'O/U 1st Quarter 76.5')
B9jBasketballMap.set('T1@77.5', 'O/U 1st Quarter 77.5')
B9jBasketballMap.set('T1@78.5', 'O/U 1st Quarter 78.5')
B9jBasketballMap.set('T1@79.5', 'O/U 1st Quarter 79.5')
B9jBasketballMap.set('T1@80.5', 'O/U 1st Quarter 80.5')
B9JBasketballOutcomeMap.set('Over', 'o')
B9JBasketballOutcomeMap.set('Under', 'u')
B9JBasketballOutcomeMap.set('O', 'o')
B9JBasketballOutcomeMap.set('U', 'u')
