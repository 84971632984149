import { BRAND, Brand, TARGET } from 'src/config'

const values: { [key in Brand]: { [key: string]: string } } = {
  'bet9ja': {
    local: 'https://dev-simulate.appspot.com',
    dev: 'https://sim-b9ja-ng.svcs.dev.futurfly.com',
    stg: 'https://api.simulate.stg.novafutur.com',
    prod: 'https://api.simulate.novafutur.com',
  },
  'mozzartbet-ke': {
    local: 'https://dev-simulate.appspot.com',
    stg: 'https://sim-moz-ke.svcs.stg.futurfly.com',
    prod: 'https://sim-moz-ke.svcs.futurfly.com',
  },
  'certification': {
    stg: 'https://sim-cert-simulateapp.svcs.stg.futurfly.com',
  },
  'teapuesto-pe': {
    local: 'https://dev-simulate.appspot.com',
    stg: 'https://sim-tea-pe.svcs.stg.futurfly.com',
    prod: 'https://sim-tea-pe.svcs.futurfly.com',
  },
  'mozzartbet-srb': {
    local: 'https://dev-simulate.appspot.com',
    stg: 'https://sim-moz-srb.svcs.stg.futurfly.com',
    prod: 'https://sim-moz-srb.svcs.futurfly.com',
  },
  'yajuego-co': {
    local: 'https://dev-simulate.appspot.com',
    stg: 'https://sim-yajuego-co.svcs.stg.futurfly.com',
    prod: 'https://sim-yajuego-co.svcs.futurfly.com',
  },
  'mozzartbet-hrv': {
    local: 'https://dev-simulate.appspot.com',
    stg: 'https://sim-mozzartbet-hrv.svcs.stg.futurfly.com',
    prod: 'https://sim-mozzartbet-hrv.svcs.futurfly.com',
  },
  'bolabet-zmb': {
    local: 'https://dev-simulate.appspot.com',
    dev: 'https://simulate-bolabet-zambia.svcs.dev.futurfly.com',
    stg: 'https://sim-bolabet-zmb.svcs.stg.futurfly.com',
    prod: 'https://sim-bolabet-zmb.svcs.futurfly.com',
  },
}

export const getEndpoint = () => {
  if (!BRAND) throw { status: 500 }
  if (!TARGET) return values[BRAND]['local']
  return values[BRAND][TARGET]
}
