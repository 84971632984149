import styled, { css } from 'styled-components'

export const NotifWrapper = styled.div<{ color?: string; $isToast?: boolean }>`
  ${({ theme, color, $isToast }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    padding: 6px 10px 10px 10px;
    border-radius: 3px;
    position: relative;
    background: ${theme.colours.S050G};

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    ${$isToast &&
    css`
      border: 2px solid ${theme.colours.N040};
    `};

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 6px;
      height: 100%;
      background: ${color || 'transparent'};
      border-radius: 3px 0px 0px 3px;
    }

    .icon-arrow {
      cursor: pointer;

      path {
        fill: ${theme.colours.N030};
      }
    }

    .content-box {
      display: flex;
      align-items: center;
      width: 100%;

      .msg {
        font-size: 12px;
        font-weight: 500;
        padding: 0 15px;
        color: ${theme.colours.N030};
      }

      /* .dot {
        position: absolute;
        right: 1px;
        top: 1px;
        width: 8px;
        height: 8px;
        background-color: ${theme.colours.AP050};
        border-radius: 4px;
        border: 1px solid #515164;
      } */
    }

    .date {
      flex-shrink: 0;
      font-size: 10px;
      margin-left: auto;
      color: ${theme.colours.N040};
    }

    .icon {
      position: relative;

      path {
        fill: ${theme.colours.N040};
      }
    }

    .btnMsg {
      width: 80px;
      height: 32px;
      background: ${({ theme }) => theme.colours.AP050G};
      border: 0.7px solid ${({ theme }) => theme.colours.AP080};
      box-shadow:
        0px 3px 0px #b5892e,
        0px 6px 3px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      font-size: 12px;
      color: ${({ theme }) => theme.colours.ST100};
      font-weight: 900;
    }
  `}
`
