import { FormattedMessage } from 'react-intl'
import { getClientVariables } from 'src/config/client-variables'
import { useAppSelector } from 'src/store/store'
import { selectTaxValues, selectToWin } from '../../store/betslip.selectors'
import { BRAND } from 'src/config'

export const PotentialWin = () => {
  const toWin = useAppSelector(selectToWin)
  const taxValues = useAppSelector(selectTaxValues)

  if (BRAND === 'mozzartbet-hrv' || BRAND === 'certification') {
    return (
      <>
        <div className="heading">
          <FormattedMessage
            id="winBar_heading"
            defaultMessage="Potential Win"
          />{' '}
          {getClientVariables().currencyCode}
        </div>
        <div className="amount weight">{taxValues?.potential_win}</div>
      </>
    )
  }

  return (
    <>
      <div className="heading">
        <FormattedMessage id="winBar_heading" defaultMessage="Potential Win" />
      </div>
      <div className="amount weight">
        {toWin.toLocaleString(getClientVariables().countryCode, {
          maximumFractionDigits: 2,
        })}
      </div>
    </>
  )
}
