import { Outlet } from 'react-router-dom'
import { useSource } from '../../../hooks/useSource'
import { StyledPage } from '../../../style/styles'
import { ErrorModal } from '../../../components/error-modal/errorModal'
import { MyBetsNav } from '../components/myBets-nav/myBetsNav'
import { Receipt } from '../../receipt/components/receipt/receipt'
import { useAppDispatch } from '../../../store/store'
import { useSelector } from 'react-redux'
import { selectRedirect, updateRedirect } from '../../app/store/app.state'

export const MyBetsPage = () => {
  const dispatch = useAppDispatch()
  const redirect = useSelector(selectRedirect)
  const { isCashier } = useSource()

  if (redirect) {
    dispatch(updateRedirect(null))
  }

  return (
    <>
      <StyledPage>
        {isCashier ? <Receipt /> : undefined}
        <MyBetsNav />

        <Outlet />
      </StyledPage>
      <ErrorModal />
    </>
  )
}
