import { useDispatch, useSelector } from 'react-redux'
import { useActiveFreeBetCountDown } from '../../../../lib/count-down/useCountDown'
import {
  selectBlocking,
  selectCockpitValues,
  selectDisableQuickStake,
  selectStake,
} from '../../../betslip/store/betslip.selectors'
import { setStakeThunk } from '../../../betslip/store/betslip.state'
import { useNavigate } from 'react-router'
import { Gift } from '../../../../components/svgs'
import {
  Button,
  QuickStakesWrapper,
  FreeBet,
  FreeBetButton,
} from './quickStake.style'
import { selectIsCashier } from '../../../app/store/app.state'
import { getClientVariables } from 'src/config/client-variables'
import { FormattedMessage } from 'react-intl'
import { debounce } from 'src/lib/utils/asyncUtils'

export const QuickStakes = () => (
  <QuickStakesWrapper data-testid="QuickStakes">
    <QuickAmounts />
    <QuickFreeBet />
  </QuickStakesWrapper>
)

const QuickAmounts = () => {
  const dispatch = useDispatch()
  const disabled = useSelector(selectDisableQuickStake)
  const stake = useSelector(selectStake)
  const { quickStakesAmounts } = getClientVariables()

  return (
    <>
      {quickStakesAmounts.map((qAmount) => (
        <Button
          data-testid="qbtn"
          disabled={disabled}
          key={qAmount}
          onClick={debounce(() => dispatch(setStakeThunk(stake + qAmount)))}
        >
          + {qAmount}
        </Button>
      ))}
    </>
  )
}

const QuickFreeBet = () => {
  const navigate = useNavigate()
  const blockUI = useSelector(selectBlocking)
  const { displayCountDown, freeBetLength } = useActiveFreeBetCountDown()
  const { freeBetAmount } = useSelector(selectCockpitValues)
  const isCashier = useSelector(selectIsCashier)

  if (freeBetLength > 0 && !isCashier) {
    return (
      <div>
        <FreeBetButton
          $disabled={blockUI}
          onClick={() => navigate('/mybets/freebets')}
          $animation={!!freeBetAmount}
        >
          <FreeBet>
            <Gift width="24" height="24" viewBox="-1 -1 26 26" />
            <div>
              <div className="info">
                <FormattedMessage
                  id="quickFreeBet_freeBet"
                  defaultMessage="FREE BET"
                />
              </div>
              {displayCountDown}
            </div>
          </FreeBet>
        </FreeBetButton>
      </div>
    )
  }

  return <div />
}
