import { motion } from 'framer-motion'
import React, { ReactNode, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { distinctUntilChanged } from 'rxjs/operators'
import { SelectionResponse } from '../../../api/types'
import { SimSports } from '../../../config'
import { useSource } from '../../../hooks/useSource'
import {
  TimeLine,
  soccerTimeline$,
} from '../../../sports/soccer/timeline/soccerTimeline'
import { selectMenu } from '../../betslip/store/betslip.selectors'
import {
  selectGameDataAccStatus,
  selectGameSelections,
} from '../store/game.state'
import { PageWrapper } from '../../../style/styles'
import { GameBoost } from '../components/boost/boost'
import { CountDown } from '../components/count-down/countDown'
import { GameFooter } from '../components/game-footer/gameFooter'
import { GameHeader } from '../components/game-header/gameHeader'
import { BasketballItem } from '../components/game-item/basketballItem'
import { SoccerItem } from '../components/game-item/soccerItem'
import { Receipt } from '../../receipt/components/receipt/receipt'
import { BasketballGrey, SoccerGrey } from '../../../components/svgs'
import { EventHeader } from '../components/eventHeader/eventHeader'
import { GameWrapper, Wrapper } from './gamePage.style'
import { ErrorModal } from '../../../components/error-modal/errorModal'

type SelectionBySportName = Record<SimSports, SelectionResponse[]>

const sports: Record<number, SimSports> = {
  1: 'Soccer',
  2: 'Basketball',
}

const icons: { [key in SimSports]: JSX.Element } = {
  Soccer: <SoccerGrey width="14" height="14" viewBox="0 -1 14 14" />,
  Basketball: <BasketballGrey width="14" height="14" viewBox="0 -1 14 14" />,
}

export const GameLayout = () => {
  const { isCashier } = useSource()

  return (
    <>
      <ErrorModal />
      <PageWrapper>
        {isCashier ? <Receipt /> : undefined}
        <GameAnimations />
        <Game>
          <GameHeader />
          <GameContainer />
        </Game>
        <GameFooter />
      </PageWrapper>
    </>
  )
}

const Game: React.FC<{ children: ReactNode }> = ({ children }) => {
  const slideUI = useSelector(selectMenu)
  return (
    <motion.div
      initial="show"
      animate={slideUI ? 'show' : 'hide'}
      // variants={{ show: { y: 0 }, hide: { y: -48 } }}
      transition={{ ease: 'linear', duration: 0.2 }}
    >
      {children}
    </motion.div>
  )
}

export const GameContainer: React.FC = () => {
  const accountStatus = useSelector(selectGameDataAccStatus)
  const selections = useSelector(selectGameSelections)

  const selectionBySportName = selections.reduce<SelectionBySportName>(
    (acc, selection): SelectionBySportName => {
      const sportName = sports[selection.sport_id]

      return {
        ...acc,
        [sportName]: [...acc[sportName], selection],
      }
    },
    { Soccer: [], Basketball: [] },
  )

  return (
    <>
      {Object.entries(selectionBySportName).map(([sportName, selections]) => {
        if (selections.length === 0) {
          return
        }

        const icon = icons[sportName as SimSports]

        return (
          <>
            <GameWrapper>
              <EventHeader sport={sportName as SimSports} icon={icon} />
              {accountStatus !== -1 &&
                selections?.map((selection: any, idx: number) => (
                  <>
                    {selection.sport_id === 1 ? (
                      <SoccerItem
                        key={`soccer-item_${idx}`}
                        selection={selection}
                      />
                    ) : (
                      <BasketballItem
                        key={`basket-item_${idx}`}
                        selection={selection}
                      />
                    )}
                  </>
                ))}
            </GameWrapper>
          </>
        )
      })}
    </>
  )
}

export const GameAnimations = () => {
  const [state, setState] = useState<TimeLine>()

  useEffect(() => {
    const sub = soccerTimeline$
      .pipe(
        distinctUntilChanged(
          (x, y) => x.boost === y.boost && x.countDown === y.countDown,
        ),
      )
      .subscribe(setState)
    return () => sub.unsubscribe()
  }, [])

  if (state?.boost || state?.countDown)
    return (
      <>
        <Wrapper>
          <GameBoost display={state.boost} />
          <CountDown display={state.countDown} />
        </Wrapper>
      </>
    )

  return <></>
}
