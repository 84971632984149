import { TMessages } from './types'

export const messagesInCroatian: TMessages = {
  eventHeader_Soccer: 'Nogomet',
  eventHeader_Basketball: 'Košarka',
  eventHeader_1st: '1. ',
  eventHeader_2nd: '2. ',
  eventHeader_3rd: '3. ',
  eventHeader_4th: '4. ',
  eventHeader_Quarter: 'Četvrtina',
  eventHeader_Half: 'Poluvrijeme',
  countDown_kickoff: 'KREĆEMO!',
  goal_letter: 'GOOOL!',
  acceptOdds_changes: 'Prihvati promjenu tečaja',
  couponDetail_receipt: 'Ponovi tiskanje oklade',
  couponDetail_odds: 'Tečaj',
  couponHead_freeBet: 'Freebet',
  couponHead_won: 'Dobitan',
  couponHead_lost: 'Gubitan',
  emptyNotif_title: 'Sve obavijesti biti će prikazane ovdje',
  emptyNotif_subtitle: 'Primajte važne obavijesti i ostale važne informacije',
  stats_explore: 'Statistika simulacijskih igara',
  explore_soccerHeading: 'Najpopularnije nogometni događaji',
  explore_basketHeading: 'Najpopularniji košarkaški događaji',
  explore_leagueHeading: 'Top  {value1}  događaji',
  tournament_Heading: 'Najpopularniji događaji Premier lige',
  explorePage_msg: 'Nema informacija',
  filter_won: 'Dobitni',
  filter_lost: 'Gubitni',
  filter_all: 'Svi',
  freeBetTicket_won: 'OSVOJILI STE',
  freeBetTicket_rebet: 'Ponovite besplatnu okladu',
  freeBetTicket_info: 'Uplati jednom i odigraj dvaput',
  freeRebet_txt: 'Ponovite besplatnu okladu',
  freeBetNoContent_heading: 'Nastavite igru i ostvarite free bet!',
  freeBetContent_title: 'Freebet',
  freeBetContent_expiry: 'Ističe za',
  freeBetContent_btn: 'Iskoristi!',
  gameDisplay_heading: 'Rezultati će se uskoro prikazati!',
  gameDisplay_fulltime: 'KRAJ',
  gameFooter_win: 'Listić je DOBITAN!',
  gameFooter_lost: 'Listić je GUBITAN. Pokušajte ponovo.',
  gameFooter_betWon: 'LISTIĆ JE DOBITAN!',
  gameFooter_done: 'GOTOVO',
  gameFooter_rebet: 'PONOVI OKLADU',
  gameFooter_freeBet: 'Freebet',
  inputStake_amount: 'Unesite iznos',
  loader_printingTicket: 'Ispis listića',
  loader_simulating: 'Simuliranje',
  loader_info: 'Molimo, pričekajte',
  togglePrintButton_title: 'Ispis listića',
  myBetsNav_myBets: 'Moji listići',
  myBetsNav_freeBets: 'Rapoloživi freebet',
  navBar_linkExplore: 'Istraži',
  navBar_linkBetSlip: 'Listić',
  navBar_linkMyBets: 'Moji listići',
  navBar_linkReport: 'Izveštaj',
  navBar_linkTournament: 'Engleska Premier liga',
  placeBetButton_freeBet: 'Odigraj freebet listić',
  placeBetButton_bet: 'Postavi okladu',
  quickFreeBet_freeBet: 'FREEBET',
  receipt_not: 'Ne',
  receipt_certified: 'Potvrđen',
  receipt_copy: 'Kopija',
  receipt_originalTicket: 'ORIGINALNI LISTIĆ',
  receipt_ticketCopy: 'KOPIJA LISTIĆA',
  receipt_user: 'IGRAČ#',
  receipt_ticket: 'LISTIĆ#',
  receipt_selection: 'BROJ PAROVA',
  receipt_totalOdds: 'UKUPNI TEČAJ',
  receipt_totalStake: 'UKUPNE UPLATE',
  receipt_potWin: 'POT. DOBITAK',
  reportBox_lastUpdated: 'Posljednja promjena',
  reportBox_turnOver: 'Povratak',
  reportBox_paidOut: 'Isplaćeno',
  reportBox_tickets: 'Listići',
  reportBox_totalProfit: 'Ukupan profit',
  reportsFilter_thisWeek: 'Ovaj tjedan',
  reportsFilter_yesterday: 'Jučer',
  reportsFilter_today: 'Danas',
  scrollElement_info: 'Unesite ulog',
  scrollElement_odds: 'Tečaj',
  unsupportedSelection_info1: 'Izabranu igru ne podržava simulacija.',
  unsupportedSelection_info2: 'Ovo neće biti dodano na vaš listić.',
  selectionsBar_sel: '- Broj parova',
  selectionsBar_addMore: '+ Dodaj par',
  speedDrawer_normalSpeed: 'Normalna brzina',
  speedDrawer_fastSpeed: 'Brza simulacija',
  speedDrawer_turboSpeed: 'Turbo simulacija',
  totalOdds_heading: 'Ukupni tečaj',
  winBar_heading: 'Potencijalni dobitak',
  winBar_tax: 'Porez',
  winBar_taxBreakdown: 'Raspodjela poreza',
  winBar_taxPerStake: '',
  winBar_taxCalculated: 'Porez se obračunava na osnovu visine dobitka',
  winBar_payout: 'Isplata',
  notificationItem_msgFreeBet: 'Imate freebet!',
  notificationItem_msgFreeBetBtn: 'Iskoristi ga!',
  notificationItem_msgFreeBets: 'Imate besplatnih oklada!',
  notificationItem_msgFreeBetsBtn: 'Pogledaj!',
  notificationItem_msgFreeBetSelected:
    '{value1} {value2} izabranih freebet vrijednosti!',
  exploreBanner_btn: 'Provjeri!',
  exploreBanner_heading: 'Novo!',
  exploreBanner_title: 'Košarka',
  // reusable
  no_info: 'Trenutno nema informacija za prikazati.',
  btnContinue: 'OK, nastavi',
  clear: 'Očisti',
  done: 'Završi',
  // errors
  errorTest: 'Testna greška',
  error1000: 'Izbor se ne može simulirati',
  error500: 'Došlo je do pogreške...',
  error401: 'Sesija je istekla',
  error403: 'Neuspješan pristup',
  error404: 'Neuspješna prijava',
  error408: 'Zahtjev za vremensko ograničenje',
  error422: 'Listić nije valjan',
  error424: 'Vaš izbor je promjenjen',
  error425: 'Freebet nije valjan ili je istekao',
  errorPM: 'Nevažeća poruka',
  errorSelectionNum: 'Maksimalan broj parova na listiću je 40.',
  errorNoSelection: 'Niste dodali parove na listić',
  errorMinStake: 'Minimalan iznos uloga je {value2} {value1} ',
  errorMaxStake: 'Maksimalan iznos uloga je {value2} {value1} ',
  errorMaxWin:
    'Dobitak od  {value1} {value2}  premašuje maksimalan dobitak na listiću. ',
  errorAccountStatus_2: 'Dobitak nije isplaćen.',
  errorAccountStatus_1: 'Nemate dovoljno sredstava za uplatu listića.',
  errorAccountStatus0: 'Niste uplatili sredstva.',
  errorAccountStatus1: 'Listić je uplaćen.',
  errorAccountStatus2: 'Dobitak je isplaćen.',
  errorAccountStatus__5: 'Dosegnuti su limiti odgovornog klađenja',
  errorBetStatus_10: 'Listić nije uplaćen.',
  errorBetStatus_1: 'Gubitan',
  errorBetStatus0: 'Započni',
  errorBetStatus1: 'Isplaćen',
}
