import styled from 'styled-components'
import TogglePrintButton from '../menu/togglePrintButton'
import { NavBar } from '../navBar/navBar'

export const NavBarWrapper = (props: any) => {
  if (props.isDesktop && !props.isGameView) {
    return (
      <NavTop data-testid="navTop">
        {props.isCashier && <TogglePrintButton />}
        <NavBar />
      </NavTop>
    )
  }

  if (!props.isDesktop && !props.isGameView) {
    return (
      <NavBottom data-testid="navBottom">
        <NavBar />
      </NavBottom>
    )
  }

  return <></>
}

const NavBottom = styled.div`
  position: absolute;
  bottom: 0;
  background: ${(props) => props.theme.colours.S000};
  width: 100%;
  max-width: 1024px;
  border-radius: 3px 3px 0px 0px;
  box-shadow: 1px -1px 10px 0px rgba(0, 0, 0, 0.16);
`
const NavTop = styled.div`
  display: flex;
  justify-content: flex-end;
  background-image: ${({ theme }) => theme.colours.S090G};
`
