import { BRAND } from 'src/config'
import { darkTheme } from './dark.theme'
import { lightTheme } from './light.theme'

let theme = darkTheme

if (BRAND === 'teapuesto-pe') {
  theme = lightTheme
}

export default theme
