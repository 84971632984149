import * as YAJUEGO from './types'
import * as Sim from '../../markets'

export const YAJUEGOBasketballMap = new Map<YAJUEGO.Markets, Sim.Market>()
export const YAJUEGOBasketballOutcomeMap = new Map<
  YAJUEGO.Outcomes,
  Sim.Outcome
>()

export const findYajuegoBasketballOutcome = (
  market: YAJUEGO.Markets,
  outcome: YAJUEGO.Outcomes,
): Sim.Outcome => {
  return YAJUEGOBasketballOutcomeMap.get(outcome) || ('' as Sim.Outcome)
}

// 2 way
YAJUEGOBasketballMap.set('12', '2 Way')
YAJUEGOBasketballOutcomeMap.set('1', '1')
YAJUEGOBasketballOutcomeMap.set('2', '2')
// 1x2 1st Quarter
YAJUEGOBasketballMap.set('1X21P', '1X2 1st Quarter')
YAJUEGOBasketballOutcomeMap.set('1', '1')
YAJUEGOBasketballOutcomeMap.set('2', '2')
YAJUEGOBasketballOutcomeMap.set('X', 'x')
// 1x2 1st Half
YAJUEGOBasketballMap.set('1X21T', '1X2 1st Half')
YAJUEGOBasketballOutcomeMap.set('1', '1')
YAJUEGOBasketballOutcomeMap.set('2', '2')
YAJUEGOBasketballOutcomeMap.set('X', 'x')
// 1x2 5.5
// YAJUEGOBasketballMap.set('1X2HNDN@5.5', '1X2 (5.5 points)')
// YAJUEGOBasketballOutcomeMap.set('1H', '1')
// YAJUEGOBasketballOutcomeMap.set('2H', '2')
// YAJUEGOBasketballOutcomeMap.set('XH', 'x')
// Home Away
YAJUEGOBasketballMap.set('MARGINHOME', 'HomeAway')
YAJUEGOBasketballMap.set('MARGINAWAY', 'HomeAway')
YAJUEGOBasketballOutcomeMap.set('Home1-5', 'Home 1-5')
YAJUEGOBasketballOutcomeMap.set('Home6-10', 'Home 6-10')
YAJUEGOBasketballOutcomeMap.set('Home11-15', 'Home 11-15')
YAJUEGOBasketballOutcomeMap.set('Home16-20', 'Home 16-20')
YAJUEGOBasketballOutcomeMap.set('Home21-25', 'Home 21-25')
YAJUEGOBasketballOutcomeMap.set('HomeO26', 'Home 26+')
YAJUEGOBasketballOutcomeMap.set('Away1-5', 'Away 1-5')
YAJUEGOBasketballOutcomeMap.set('Away6-10', 'Away 6-10')
YAJUEGOBasketballOutcomeMap.set('Away11-15', 'Away 11-15')
YAJUEGOBasketballOutcomeMap.set('Away16-20', 'Away 16-20')
YAJUEGOBasketballOutcomeMap.set('Away21-25', 'Away 21-25')
YAJUEGOBasketballOutcomeMap.set('AwayO26', 'Away 26+')

// Over/Under
YAJUEGOBasketballMap.set('OUHND@110.5', 'O/U 110.5')
YAJUEGOBasketballMap.set('OUHND@111.5', 'O/U 111.5')
YAJUEGOBasketballMap.set('OUHND@112.5', 'O/U 112.5')
YAJUEGOBasketballMap.set('OUHND@113.5', 'O/U 113.5')
YAJUEGOBasketballMap.set('OUHND@114.5', 'O/U 114.5')
YAJUEGOBasketballMap.set('OUHND@115.5', 'O/U 115.5')
YAJUEGOBasketballMap.set('OUHND@116.5', 'O/U 116.5')
YAJUEGOBasketballMap.set('OUHND@117.5', 'O/U 117.5')
YAJUEGOBasketballMap.set('OUHND@118.5', 'O/U 118.5')
YAJUEGOBasketballMap.set('OUHND@119.5', 'O/U 119.5')
YAJUEGOBasketballMap.set('OUHND@120.5', 'O/U 120.5')
YAJUEGOBasketballMap.set('OUHND@121.5', 'O/U 121.5')
YAJUEGOBasketballMap.set('OUHND@122.5', 'O/U 122.5')
YAJUEGOBasketballMap.set('OUHND@123.5', 'O/U 123.5')
YAJUEGOBasketballMap.set('OUHND@124.5', 'O/U 124.5')
YAJUEGOBasketballMap.set('OUHND@125.5', 'O/U 125.5')
YAJUEGOBasketballMap.set('OUHND@126.5', 'O/U 126.5')
YAJUEGOBasketballMap.set('OUHND@127.5', 'O/U 127.5')
YAJUEGOBasketballMap.set('OUHND@128.5', 'O/U 128.5')
YAJUEGOBasketballMap.set('OUHND@129.5', 'O/U 129.5')
YAJUEGOBasketballMap.set('OUHND@130.5', 'O/U 130.5')
YAJUEGOBasketballMap.set('OUHND@131.5', 'O/U 131.5')
YAJUEGOBasketballMap.set('OUHND@132.5', 'O/U 132.5')
YAJUEGOBasketballMap.set('OUHND@133.5', 'O/U 133.5')
YAJUEGOBasketballMap.set('OUHND@134.5', 'O/U 134.5')
YAJUEGOBasketballMap.set('OUHND@135.5', 'O/U 135.5')
YAJUEGOBasketballMap.set('OUHND@136.5', 'O/U 136.5')
YAJUEGOBasketballMap.set('OUHND@137.5', 'O/U 137.5')
YAJUEGOBasketballMap.set('OUHND@138.5', 'O/U 138.5')
YAJUEGOBasketballMap.set('OUHND@139.5', 'O/U 139.5')
YAJUEGOBasketballMap.set('OUHND@140.5', 'O/U 140.5')
YAJUEGOBasketballMap.set('OUHND@141.5', 'O/U 141.5')
YAJUEGOBasketballMap.set('OUHND@142.5', 'O/U 142.5')
YAJUEGOBasketballMap.set('OUHND@143.5', 'O/U 143.5')
YAJUEGOBasketballMap.set('OUHND@144.5', 'O/U 144.5')
YAJUEGOBasketballMap.set('OUHND@145.5', 'O/U 145.5')
YAJUEGOBasketballMap.set('OUHND@146.5', 'O/U 146.5')
YAJUEGOBasketballMap.set('OUHND@147.5', 'O/U 147.5')
YAJUEGOBasketballMap.set('OUHND@148.5', 'O/U 148.5')
YAJUEGOBasketballMap.set('OUHND@149.5', 'O/U 149.5')
YAJUEGOBasketballMap.set('OUHND@150.5', 'O/U 150.5')
YAJUEGOBasketballMap.set('OUHND@151.5', 'O/U 151.5')
YAJUEGOBasketballMap.set('OUHND@152.5', 'O/U 152.5')
YAJUEGOBasketballMap.set('OUHND@153.5', 'O/U 153.5')
YAJUEGOBasketballMap.set('OUHND@154.5', 'O/U 154.5')
YAJUEGOBasketballMap.set('OUHND@155.5', 'O/U 155.5')
YAJUEGOBasketballMap.set('OUHND@156.5', 'O/U 156.5')
YAJUEGOBasketballMap.set('OUHND@157.5', 'O/U 157.5')
YAJUEGOBasketballMap.set('OUHND@158.5', 'O/U 158.5')
YAJUEGOBasketballMap.set('OUHND@159.5', 'O/U 159.5')
YAJUEGOBasketballMap.set('OUHND@160.5', 'O/U 160.5')
YAJUEGOBasketballMap.set('OUHND@161.5', 'O/U 161.5')
YAJUEGOBasketballMap.set('OUHND@162.5', 'O/U 162.5')
YAJUEGOBasketballMap.set('OUHND@163.5', 'O/U 163.5')
YAJUEGOBasketballMap.set('OUHND@164.5', 'O/U 164.5')
YAJUEGOBasketballMap.set('OUHND@165.5', 'O/U 165.5')
YAJUEGOBasketballMap.set('OUHND@166.5', 'O/U 166.5')
YAJUEGOBasketballMap.set('OUHND@167.5', 'O/U 167.5')
YAJUEGOBasketballMap.set('OUHND@168.5', 'O/U 168.5')
YAJUEGOBasketballMap.set('OUHND@169.5', 'O/U 169.5')
YAJUEGOBasketballMap.set('OUHND@170.5', 'O/U 170.5')
YAJUEGOBasketballMap.set('OUHND@171.5', 'O/U 171.5')
YAJUEGOBasketballMap.set('OUHND@172.5', 'O/U 172.5')
YAJUEGOBasketballMap.set('OUHND@173.5', 'O/U 173.5')
YAJUEGOBasketballMap.set('OUHND@174.5', 'O/U 174.5')
YAJUEGOBasketballMap.set('OUHND@175.5', 'O/U 175.5')
YAJUEGOBasketballMap.set('OUHND@176.5', 'O/U 176.5')
YAJUEGOBasketballMap.set('OUHND@177.5', 'O/U 177.5')
YAJUEGOBasketballMap.set('OUHND@178.5', 'O/U 178.5')
YAJUEGOBasketballMap.set('OUHND@179.5', 'O/U 179.5')
YAJUEGOBasketballMap.set('OUHND@180.5', 'O/U 180.5')
YAJUEGOBasketballMap.set('OUHND@181.5', 'O/U 181.5')
YAJUEGOBasketballMap.set('OUHND@182.5', 'O/U 182.5')
YAJUEGOBasketballMap.set('OUHND@183.5', 'O/U 183.5')
YAJUEGOBasketballMap.set('OUHND@184.5', 'O/U 184.5')
YAJUEGOBasketballMap.set('OUHND@185.5', 'O/U 185.5')
YAJUEGOBasketballMap.set('OUHND@186.5', 'O/U 186.5')
YAJUEGOBasketballMap.set('OUHND@187.5', 'O/U 187.5')
YAJUEGOBasketballMap.set('OUHND@188.5', 'O/U 188.5')
YAJUEGOBasketballMap.set('OUHND@189.5', 'O/U 189.5')
YAJUEGOBasketballMap.set('OUHND@190.5', 'O/U 190.5')
YAJUEGOBasketballMap.set('OUHND@191.5', 'O/U 191.5')
YAJUEGOBasketballMap.set('OUHND@192.5', 'O/U 192.5')
YAJUEGOBasketballMap.set('OUHND@193.5', 'O/U 193.5')
YAJUEGOBasketballMap.set('OUHND@194.5', 'O/U 194.5')
YAJUEGOBasketballMap.set('OUHND@195.5', 'O/U 195.5')
YAJUEGOBasketballMap.set('OUHND@196.5', 'O/U 196.5')
YAJUEGOBasketballMap.set('OUHND@197.5', 'O/U 197.5')
YAJUEGOBasketballMap.set('OUHND@198.5', 'O/U 198.5')
YAJUEGOBasketballMap.set('OUHND@199.5', 'O/U 199.5')
YAJUEGOBasketballMap.set('OUHND@200.5', 'O/U 200.5')
YAJUEGOBasketballMap.set('OUHND@201.5', 'O/U 201.5')
YAJUEGOBasketballMap.set('OUHND@202.5', 'O/U 202.5')
YAJUEGOBasketballMap.set('OUHND@203.5', 'O/U 203.5')
YAJUEGOBasketballMap.set('OUHND@204.5', 'O/U 204.5')
YAJUEGOBasketballMap.set('OUHND@205.5', 'O/U 205.5')
YAJUEGOBasketballMap.set('OUHND@206.5', 'O/U 206.5')
YAJUEGOBasketballMap.set('OUHND@207.5', 'O/U 207.5')
YAJUEGOBasketballMap.set('OUHND@208.5', 'O/U 208.5')
YAJUEGOBasketballMap.set('OUHND@209.5', 'O/U 209.5')
YAJUEGOBasketballMap.set('OUHND@210.5', 'O/U 210.5')
YAJUEGOBasketballMap.set('OUHND@211.5', 'O/U 211.5')
YAJUEGOBasketballMap.set('OUHND@212.5', 'O/U 212.5')
YAJUEGOBasketballMap.set('OUHND@213.5', 'O/U 213.5')
YAJUEGOBasketballMap.set('OUHND@214.5', 'O/U 214.5')
YAJUEGOBasketballMap.set('OUHND@215.5', 'O/U 215.5')
YAJUEGOBasketballMap.set('OUHND@216.5', 'O/U 216.5')
YAJUEGOBasketballMap.set('OUHND@217.5', 'O/U 217.5')
YAJUEGOBasketballMap.set('OUHND@218.5', 'O/U 218.5')
YAJUEGOBasketballMap.set('OUHND@219.5', 'O/U 219.5')
YAJUEGOBasketballMap.set('OUHND@220.5', 'O/U 220.5')
YAJUEGOBasketballMap.set('OUHND@221.5', 'O/U 221.5')
YAJUEGOBasketballMap.set('OUHND@222.5', 'O/U 222.5')
YAJUEGOBasketballMap.set('OUHND@223.5', 'O/U 223.5')
YAJUEGOBasketballMap.set('OUHND@224.5', 'O/U 224.5')
YAJUEGOBasketballMap.set('OUHND@225.5', 'O/U 225.5')
YAJUEGOBasketballMap.set('OUHND@226.5', 'O/U 226.5')
YAJUEGOBasketballMap.set('OUHND@227.5', 'O/U 227.5')
YAJUEGOBasketballMap.set('OUHND@228.5', 'O/U 228.5')
YAJUEGOBasketballMap.set('OUHND@229.5', 'O/U 229.5')
YAJUEGOBasketballMap.set('OUHND@230.5', 'O/U 230.5')
YAJUEGOBasketballMap.set('OUHND@231.5', 'O/U 231.5')
YAJUEGOBasketballMap.set('OUHND@232.5', 'O/U 232.5')
YAJUEGOBasketballMap.set('OUHND@233.5', 'O/U 233.5')
YAJUEGOBasketballMap.set('OUHND@234.5', 'O/U 234.5')
YAJUEGOBasketballMap.set('OUHND@235.5', 'O/U 235.5')
YAJUEGOBasketballMap.set('OUHND@236.5', 'O/U 236.5')
YAJUEGOBasketballMap.set('OUHND@237.5', 'O/U 237.5')
YAJUEGOBasketballMap.set('OUHND@238.5', 'O/U 238.5')
YAJUEGOBasketballMap.set('OUHND@239.5', 'O/U 239.5')
YAJUEGOBasketballMap.set('OUHND@240.5', 'O/U 240.5')
YAJUEGOBasketballMap.set('OUHND@241.5', 'O/U 241.5')
YAJUEGOBasketballMap.set('OUHND@242.5', 'O/U 242.5')
YAJUEGOBasketballMap.set('OUHND@243.5', 'O/U 243.5')
YAJUEGOBasketballMap.set('OUHND@244.5', 'O/U 244.5')
YAJUEGOBasketballMap.set('OUHND@245.5', 'O/U 245.5')
YAJUEGOBasketballMap.set('OUHND@246.5', 'O/U 246.5')
YAJUEGOBasketballMap.set('OUHND@247.5', 'O/U 247.5')
YAJUEGOBasketballMap.set('OUHND@248.5', 'O/U 248.5')
YAJUEGOBasketballMap.set('OUHND@249.5', 'O/U 249.5')
YAJUEGOBasketballMap.set('OUHND@250.5', 'O/U 250.5')
YAJUEGOBasketballMap.set('OUHND@251.5', 'O/U 251.5')
YAJUEGOBasketballMap.set('OUHND@252.5', 'O/U 252.5')
YAJUEGOBasketballMap.set('OUHND@253.5', 'O/U 253.5')
YAJUEGOBasketballMap.set('OUHND@254.5', 'O/U 254.5')
YAJUEGOBasketballMap.set('OUHND@255.5', 'O/U 255.5')
YAJUEGOBasketballMap.set('OUHND@256.5', 'O/U 256.5')
YAJUEGOBasketballMap.set('OUHND@257.5', 'O/U 257.5')
YAJUEGOBasketballMap.set('OUHND@258.5', 'O/U 258.5')
YAJUEGOBasketballMap.set('OUHND@259.5', 'O/U 259.5')
YAJUEGOBasketballMap.set('OUHND@260.5', 'O/U 260.5')
YAJUEGOBasketballMap.set('OUHND@261.5', 'O/U 261.5')
YAJUEGOBasketballMap.set('OUHND@262.5', 'O/U 262.5')
YAJUEGOBasketballMap.set('OUHND@263.5', 'O/U 263.5')
YAJUEGOBasketballMap.set('OUHND@264.5', 'O/U 264.5')
YAJUEGOBasketballMap.set('OUHND@265.5', 'O/U 265.5')
YAJUEGOBasketballMap.set('OUHND@266.5', 'O/U 266.5')
YAJUEGOBasketballMap.set('OUHND@267.5', 'O/U 267.5')
YAJUEGOBasketballMap.set('OUHND@268.5', 'O/U 268.5')
YAJUEGOBasketballMap.set('OUHND@269.5', 'O/U 269.5')
YAJUEGOBasketballMap.set('OUHND@270.5', 'O/U 270.5')
YAJUEGOBasketballMap.set('OUHND@271.5', 'O/U 271.5')
YAJUEGOBasketballMap.set('OUHND@272.5', 'O/U 272.5')
YAJUEGOBasketballMap.set('OUHND@273.5', 'O/U 273.5')
YAJUEGOBasketballMap.set('OUHND@274.5', 'O/U 274.5')
YAJUEGOBasketballMap.set('OUHND@275.5', 'O/U 275.5')
YAJUEGOBasketballMap.set('OUHND@276.5', 'O/U 276.5')
YAJUEGOBasketballMap.set('OUHND@277.5', 'O/U 277.5')
YAJUEGOBasketballMap.set('OUHND@278.5', 'O/U 278.5')
YAJUEGOBasketballMap.set('OUHND@279.5', 'O/U 279.5')
YAJUEGOBasketballMap.set('OUHND@280.5', 'O/U 280.5')
YAJUEGOBasketballMap.set('OUHND@281.5', 'O/U 281.5')
YAJUEGOBasketballMap.set('OUHND@282.5', 'O/U 282.5')
YAJUEGOBasketballMap.set('OUHND@283.5', 'O/U 283.5')
YAJUEGOBasketballMap.set('OUHND@284.5', 'O/U 284.5')
YAJUEGOBasketballMap.set('OUHND@285.5', 'O/U 285.5')
YAJUEGOBasketballMap.set('OUHND@286.5', 'O/U 286.5')
YAJUEGOBasketballMap.set('OUHND@287.5', 'O/U 287.5')
YAJUEGOBasketballMap.set('OUHND@288.5', 'O/U 288.5')
YAJUEGOBasketballMap.set('OUHND@289.5', 'O/U 289.5')
YAJUEGOBasketballMap.set('OUHND@290.5', 'O/U 290.5')
YAJUEGOBasketballMap.set('OUHND@291.5', 'O/U 291.5')
YAJUEGOBasketballMap.set('OUHND@292.5', 'O/U 292.5')
YAJUEGOBasketballMap.set('OUHND@293.5', 'O/U 293.5')
YAJUEGOBasketballMap.set('OUHND@294.5', 'O/U 294.5')
YAJUEGOBasketballMap.set('OUHND@295.5', 'O/U 295.5')
YAJUEGOBasketballMap.set('OUHND@296.5', 'O/U 296.5')
YAJUEGOBasketballMap.set('OUHND@297.5', 'O/U 297.5')
YAJUEGOBasketballMap.set('OUHND@298.5', 'O/U 298.5')
YAJUEGOBasketballMap.set('OUHND@299.5', 'O/U 299.5')
YAJUEGOBasketballMap.set('OUHND@300.5', 'O/U 300.5')
YAJUEGOBasketballMap.set('OUHND@301.5', 'O/U 301.5')
YAJUEGOBasketballMap.set('OUHND@302.5', 'O/U 302.5')
YAJUEGOBasketballMap.set('OUHND@303.5', 'O/U 303.5')
YAJUEGOBasketballMap.set('OUHND@304.5', 'O/U 304.5')
YAJUEGOBasketballMap.set('OUHND@305.5', 'O/U 305.5')
YAJUEGOBasketballMap.set('OUHND@306.5', 'O/U 306.5')
YAJUEGOBasketballMap.set('OUHND@307.5', 'O/U 307.5')
YAJUEGOBasketballMap.set('OUHND@308.5', 'O/U 308.5')
YAJUEGOBasketballMap.set('OUHND@309.5', 'O/U 309.5')
YAJUEGOBasketballMap.set('OUHND@310.5', 'O/U 310.5')
YAJUEGOBasketballMap.set('OUHND@311.5', 'O/U 311.5')
YAJUEGOBasketballMap.set('OUHND@312.5', 'O/U 312.5')
YAJUEGOBasketballMap.set('OUHND@313.5', 'O/U 313.5')
YAJUEGOBasketballMap.set('OUHND@314.5', 'O/U 314.5')
YAJUEGOBasketballMap.set('OUHND@315.5', 'O/U 315.5')
YAJUEGOBasketballMap.set('OUHND@316.5', 'O/U 316.5')
YAJUEGOBasketballMap.set('OUHND@317.5', 'O/U 317.5')
YAJUEGOBasketballMap.set('OUHND@318.5', 'O/U 318.5')
YAJUEGOBasketballMap.set('OUHND@319.5', 'O/U 319.5')
YAJUEGOBasketballMap.set('OUHND@320.5', 'O/U 320.5')

// Over/Under 1st Quarter
YAJUEGOBasketballMap.set('OUHND1P@27.5', 'O/U 1st Quarter 27.5')
YAJUEGOBasketballMap.set('OUHND1P@28.5', 'O/U 1st Quarter 28.5')
YAJUEGOBasketballMap.set('OUHND1P@29.5', 'O/U 1st Quarter 29.5')
YAJUEGOBasketballMap.set('OUHND1P@30.5', 'O/U 1st Quarter 30.5')
YAJUEGOBasketballMap.set('OUHND1P@31.5', 'O/U 1st Quarter 31.5')
YAJUEGOBasketballMap.set('OUHND1P@32.5', 'O/U 1st Quarter 32.5')
YAJUEGOBasketballMap.set('OUHND1P@33.5', 'O/U 1st Quarter 33.5')
YAJUEGOBasketballMap.set('OUHND1P@34.5', 'O/U 1st Quarter 34.5')
YAJUEGOBasketballMap.set('OUHND1P@35.5', 'O/U 1st Quarter 35.5')
YAJUEGOBasketballMap.set('OUHND1P@36.5', 'O/U 1st Quarter 36.5')
YAJUEGOBasketballMap.set('OUHND1P@37.5', 'O/U 1st Quarter 37.5')
YAJUEGOBasketballMap.set('OUHND1P@38.5', 'O/U 1st Quarter 38.5')
YAJUEGOBasketballMap.set('OUHND1P@39.5', 'O/U 1st Quarter 39.5')
YAJUEGOBasketballMap.set('OUHND1P@40.5', 'O/U 1st Quarter 40.5')
YAJUEGOBasketballMap.set('OUHND1P@41.5', 'O/U 1st Quarter 41.5')
YAJUEGOBasketballMap.set('OUHND1P@42.5', 'O/U 1st Quarter 42.5')
YAJUEGOBasketballMap.set('OUHND1P@43.5', 'O/U 1st Quarter 43.5')
YAJUEGOBasketballMap.set('OUHND1P@44.5', 'O/U 1st Quarter 44.5')
YAJUEGOBasketballMap.set('OUHND1P@45.5', 'O/U 1st Quarter 45.5')
YAJUEGOBasketballMap.set('OUHND1P@46.5', 'O/U 1st Quarter 46.5')
YAJUEGOBasketballMap.set('OUHND1P@47.5', 'O/U 1st Quarter 47.5')
YAJUEGOBasketballMap.set('OUHND1P@48.5', 'O/U 1st Quarter 48.5')
YAJUEGOBasketballMap.set('OUHND1P@49.5', 'O/U 1st Quarter 49.5')
YAJUEGOBasketballMap.set('OUHND1P@50.5', 'O/U 1st Quarter 50.5')
YAJUEGOBasketballMap.set('OUHND1P@51.5', 'O/U 1st Quarter 51.5')
YAJUEGOBasketballMap.set('OUHND1P@52.5', 'O/U 1st Quarter 52.5')
YAJUEGOBasketballMap.set('OUHND1P@53.5', 'O/U 1st Quarter 53.5')
YAJUEGOBasketballMap.set('OUHND1P@54.5', 'O/U 1st Quarter 54.5')
YAJUEGOBasketballMap.set('OUHND1P@55.5', 'O/U 1st Quarter 55.5')
YAJUEGOBasketballMap.set('OUHND1P@56.5', 'O/U 1st Quarter 56.5')
YAJUEGOBasketballMap.set('OUHND1P@57.5', 'O/U 1st Quarter 57.5')
YAJUEGOBasketballMap.set('OUHND1P@58.5', 'O/U 1st Quarter 58.5')
YAJUEGOBasketballMap.set('OUHND1P@59.5', 'O/U 1st Quarter 59.5')
YAJUEGOBasketballMap.set('OUHND1P@60.5', 'O/U 1st Quarter 60.5')
YAJUEGOBasketballMap.set('OUHND1P@61.5', 'O/U 1st Quarter 61.5')
YAJUEGOBasketballMap.set('OUHND1P@62.5', 'O/U 1st Quarter 62.5')
YAJUEGOBasketballMap.set('OUHND1P@63.5', 'O/U 1st Quarter 63.5')
YAJUEGOBasketballMap.set('OUHND1P@64.5', 'O/U 1st Quarter 64.5')
YAJUEGOBasketballMap.set('OUHND1P@65.5', 'O/U 1st Quarter 65.5')
YAJUEGOBasketballMap.set('OUHND1P@66.5', 'O/U 1st Quarter 66.5')
YAJUEGOBasketballMap.set('OUHND1P@67.5', 'O/U 1st Quarter 67.5')
YAJUEGOBasketballMap.set('OUHND1P@68.5', 'O/U 1st Quarter 68.5')
YAJUEGOBasketballMap.set('OUHND1P@69.5', 'O/U 1st Quarter 69.5')
YAJUEGOBasketballMap.set('OUHND1P@70.5', 'O/U 1st Quarter 70.5')
YAJUEGOBasketballMap.set('OUHND1P@71.5', 'O/U 1st Quarter 71.5')
YAJUEGOBasketballMap.set('OUHND1P@72.5', 'O/U 1st Quarter 72.5')
YAJUEGOBasketballMap.set('OUHND1P@73.5', 'O/U 1st Quarter 73.5')
YAJUEGOBasketballMap.set('OUHND1P@74.5', 'O/U 1st Quarter 74.5')
YAJUEGOBasketballMap.set('OUHND1P@75.5', 'O/U 1st Quarter 75.5')
YAJUEGOBasketballMap.set('OUHND1P@76.5', 'O/U 1st Quarter 76.5')
YAJUEGOBasketballMap.set('OUHND1P@77.5', 'O/U 1st Quarter 77.5')
YAJUEGOBasketballMap.set('OUHND1P@78.5', 'O/U 1st Quarter 78.5')
YAJUEGOBasketballMap.set('OUHND1P@79.5', 'O/U 1st Quarter 79.5')
YAJUEGOBasketballMap.set('OUHND1P@80.5', 'O/U 1st Quarter 80.5')
YAJUEGOBasketballOutcomeMap.set('O', 'o')
YAJUEGOBasketballOutcomeMap.set('U', 'u')
