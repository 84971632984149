import { useDispatch, useSelector } from 'react-redux'
import { clicksDone, setStakeThunk } from '../../../betslip/store/betslip.state'
import {
  selectKeyboard,
  selectDisableDigit,
  selectStake,
} from '../../../betslip/store/betslip.selectors'
import { Backspace } from '../../../../components/svgs/index'
import { KeyboardWrapper, Button, Row } from './keyboard.style'
import { MutableRefObject } from 'react'
import { FormattedMessage } from 'react-intl'
import { debounce } from 'src/lib/utils/asyncUtils'

interface IKeyboard {
  betslipRef: MutableRefObject<HTMLDivElement | null>
  children?: React.ReactNode
}

export const Keyboard: React.FC<IKeyboard> = ({ betslipRef }) => (
  <KeyboardMotion betslipRef={betslipRef}>
    <KeyboardDigits />
    <KeyboardActions />
  </KeyboardMotion>
)

// --------------------------------------------------------------------------------

const KeyboardMotion: React.FC<IKeyboard> = ({ children, betslipRef }) => {
  const open = useSelector(selectKeyboard)

  const scrollDown = () => {
    const r = betslipRef?.current

    r?.scroll({
      behavior: 'smooth',
      top: r.scrollHeight - r.clientHeight,
    })
  }
  return (
    <KeyboardWrapper
      data-testid="keyboard-wrapper"
      onAnimationComplete={() => {
        scrollDown()
      }}
      variants={{
        opened: {
          height: 91,
          overflow: 'hidden',
          transition: {
            duration: 0.2,
            delay: 0,
          },
        },
        closed: {
          height: 0,
          overflow: 'hidden',
          transition: {
            duration: 0.2,
            delay: 0,
          },
        },
      }}
      animate={open ? 'opened' : 'closed'}
    >
      {children}
    </KeyboardWrapper>
  )
}

// --------------------------------------------------------------------------------

export const KeyboardDigits: React.FC = () => {
  const dispatch = useDispatch()
  const disabled = useSelector(selectDisableDigit)
  const stake = useSelector(selectStake)
  const handleValueClick = (n: number) =>
    dispatch(setStakeThunk(Number(stake.toString() + n.toString())))

  return (
    <Row>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((n) => (
        <Button
          key={`keyboard-button-${n}`}
          data-testid={`keyboard-button-${n}`}
          width="100%"
          onClick={debounce(() => handleValueClick(n))}
          disabled={disabled}
        >
          {n}
        </Button>
      ))}
    </Row>
  )
}

// --------------------------------------------------------------------------------

const KeyboardActions = () => {
  const dispatch = useDispatch()
  const handleDone = () => dispatch(clicksDone())
  const stake = useSelector(selectStake)

  const handleDelete = () => {
    const popLast = Number(stake.toString().slice(0, -1))
    const newStake = isNaN(popLast) ? 0 : popLast
    dispatch(setStakeThunk(newStake))
  }
  const handleClear = () => dispatch(setStakeThunk(0))

  return (
    <Row>
      <Button
        data-testid="keyboard-button-clear"
        width="30%"
        onClick={handleClear}
      >
        <FormattedMessage id="clear" defaultMessage="Clear" />
      </Button>
      <Button
        data-testid="keyboard-button-delete"
        width="30%"
        onClick={debounce(handleDelete)}
      >
        <span className="delete-button">
          <Backspace width="24" height="24" viewBox="0 0 24 24" />
        </span>
      </Button>
      <Button
        data-testid="keyboard-button-done"
        width="40%"
        onClick={handleDone}
      >
        <FormattedMessage id="done" defaultMessage="Done" />
      </Button>
    </Row>
  )
}
