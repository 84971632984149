import { logic1X2 } from './1X2'
import { logicOUnS } from './OU'
import { SimMarket, Score, TimeSpan, HTFTOU } from './types'

export const logicHTFTOU =
  (handicap: number) => (outcome: HTFTOU, score: Score) => {
    const oc: { [key in HTFTOU]: () => boolean } = {
      '1 / 1 & over': () =>
        logic1X2('1', score) && logicOUnS(handicap)('o', score),
      '1 / x & over': () =>
        logic1X2('1', score) && logicOUnS(handicap)('o', score),
      '1 / 2 & over': () =>
        logic1X2('1', score) && logicOUnS(handicap)('o', score),
      'x / 1 & over': () =>
        logic1X2('x', score) && logicOUnS(handicap)('o', score),
      'x / x & over': () =>
        logic1X2('x', score) && logicOUnS(handicap)('o', score),
      'x / 2 & over': () =>
        logic1X2('x', score) && logicOUnS(handicap)('o', score),
      '2 / 1 & over': () =>
        logic1X2('2', score) && logicOUnS(handicap)('o', score),
      '2 / x & over': () =>
        logic1X2('2', score) && logicOUnS(handicap)('o', score),
      '2 / 2 & over': () =>
        logic1X2('2', score) && logicOUnS(handicap)('o', score),
      '1 / 1 & under': () =>
        logic1X2('1', score) && logicOUnS(handicap)('u', score),
      'x / 1 & under': () =>
        logic1X2('x', score) && logicOUnS(handicap)('u', score),
      'x / x & under': () =>
        logic1X2('x', score) && logicOUnS(handicap)('u', score),
      'x / 2 & under': () =>
        logic1X2('x', score) && logicOUnS(handicap)('u', score),
      '2 / 2 & under': () =>
        logic1X2('2', score) && logicOUnS(handicap)('u', score),
    }
    return oc[outcome]() ?? false
  }

const ocHTFT = [
  '1 / 1 & over',
  '1 / x & over',
  '1 / 2 & over',
  'x / 1 & over',
  'x / x & over',
  'x / 2 & over',
  '2 / 1 & over',
  '2 / x & over',
  '2 / 2 & over',
  '1 / 1 & under',
  'x / 1 & under',
  'x / x & under',
  'x / 2 & under',
  '2 / 2 & under',
] as HTFTOU[]

const _HTFT = (id: number, time: TimeSpan, handicap = 0): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: ocHTFT,
  logic: logicHTFTOU(handicap),
})

export const FT_HTFTOU15 = _HTFT(58, 'FT', 1.5)
export const FT_HTFTOU25 = _HTFT(59, 'FT', 2.5)
