import { SimMarket, Score, TimeSpan, MOZZARTBET_CUSTOM } from './types'

export const logicMozzarbetCustom =
  (handicap: number) => (outcome: MOZZARTBET_CUSTOM, score: Score) => {
    const oc: { [key in MOZZARTBET_CUSTOM]: () => boolean } = {
      'o': () => score[0] + score[1] > handicap,
      'In range': () =>
        score[0] + score[1] > 0 && score[0] + score[1] < handicap,
    }
    return oc[outcome]() ?? false
  }

const ocMozzarbetCustom = ['o', 'In range'] as MOZZARTBET_CUSTOM[]

const _MOZZARTBET_CUSTOM = (
  id: number,
  time: TimeSpan,
  handicap = 0,
): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: ocMozzarbetCustom,
  logic: logicMozzarbetCustom(handicap),
})

export const MOZZARTBET_CUSTOM_63 = _MOZZARTBET_CUSTOM(63, 'FT', 0.5)
export const MOZZARTBET_CUSTOM_61 = _MOZZARTBET_CUSTOM(61, 'FT', 4)
export const MOZZARTBET_CUSTOM_64 = _MOZZARTBET_CUSTOM(64, 'FT', 0.5)
export const MOZZARTBET_CUSTOM_60 = _MOZZARTBET_CUSTOM(60, 'FT', 1000)
export const MOZZARTBET_CUSTOM_62 = _MOZZARTBET_CUSTOM(62, 'FT', 3)
