import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../../../store/store'
import { SimBet } from '../../../sports/types'
import { BetResponse, FreeBet, Incentives, NewOdds } from '../../../api/types'
import { checkIfBlocking, filterSelections } from '../../../store/utils'
import { TIcons } from '../../../components/svgs'
import { getClientVariables } from 'src/config/client-variables'
import { getTaxBreakdown, TaxBreakdownRes } from '../api/tax-breakdown'
import { BRAND } from 'src/config'

// reducer ----------------------------------------------------------------------------

export type TNotif = {
  icon: TIcons
  msg: string
  btn: string
  color?: string
  date?: string
  hasBeenViewed?: boolean
  values?: any
}

export type BetslipState = {
  bets: SimBet[]
  stake: number
  keyboard: boolean
  menu: boolean
  incentives: Incentives
  activeFreeBet: FreeBet
  freeBetsDrawer: boolean
  notification: TNotif
  taxValues: undefined | TaxBreakdownRes
}

const initialState: BetslipState = {
  bets: [] as SimBet[],
  stake: 0,
  keyboard: false,
  menu: true,
  incentives: {} as Incentives,
  activeFreeBet: {} as FreeBet,
  freeBetsDrawer: false,
  notification: {
    icon: '' as TIcons,
    msg: '',
    btn: '',
  },
  taxValues: undefined,
}

export const betslipSlice = createSlice({
  name: 'betslip',
  initialState,
  reducers: {
    createBetslip: (state, { payload }: PayloadAction<SimBet[]>) => {
      state.bets = checkIfBlocking(payload)
    },
    clicksOnExploreItem: (state, { payload }: PayloadAction<SimBet>) => {
      const copy = state.bets
      const betIndex = copy.findIndex((bet) => bet.b9jBet === payload.b9jBet)
      if (betIndex !== -1) {
        copy.splice(betIndex, 1)
        state.bets = checkIfBlocking(copy)
        return
      }
      copy.push(payload)
      state.bets = checkIfBlocking(copy)
    },
    removeSelection: (state, { payload }: PayloadAction<string>) => {
      const filter = state.bets.filter((bet) => bet.b9jBet !== payload)
      state.bets = checkIfBlocking(filter)
    },
    updateStake: (state, { payload }: PayloadAction<number>) => {
      state.stake = payload
    },
    updateOdds: (state, { payload }: PayloadAction<BetResponse>) => {
      state.bets.forEach((bet) => {
        payload.selections.forEach((s) => {
          if (s.b9j_bet === bet.b9jBet) {
            bet.odd = s.odd
          }
        })
      })
    },
    updateOddsFromError: (state, { payload }: PayloadAction<NewOdds>) => {
      Object.keys(payload)
        .map((x) => parseInt(x.split('$')[0], 10))
        .map((x) => state.bets.find((y) => y.matchID === x))
        .forEach((bet, i) => {
          bet && (bet.odd = Object.values(payload)[i])
        })
      state.bets = state.bets.filter((x) => x.odd !== 0)
    },
    clicksInput: (state) => {
      state.keyboard = !state.keyboard
    },
    clicksDone: (state) => {
      state.keyboard = false
    },
    closeKeyboard: (state) => {
      state.keyboard = false
    },
    createIncentives: (state, { payload }: PayloadAction<Incentives>) => {
      state.incentives = payload
    },
    toggleFreeBetsDrawer: (state) => {
      state.freeBetsDrawer = !state.freeBetsDrawer
    },
    createNotification: (state, { payload }: PayloadAction<TNotif>) => {
      state.notification = payload
    },
    setFreeBet: (state, { payload }: PayloadAction<FreeBet>) => {
      state.activeFreeBet = payload
      state.freeBetsDrawer = false
      state.stake = payload.amount
      state.keyboard = false
      state.notification = {
        icon: 'thumbUp',
        msg: 'notificationItem_msgFreeBetSelected',
        btn: '',
        values: {
          value1: getClientVariables().currency,
          value2: payload.amount,
        },
      }
    },
    deleteFreeBet: (state, { payload }: PayloadAction<number>) => {
      state.incentives.free_bets = state.incentives.free_bets.filter(
        (freeBet) => freeBet.free_bet_uid !== payload,
      )
    },
    clearFreeBet: (state) => {
      state.activeFreeBet = {} as FreeBet
      state.stake = 0
      state.notification = {} as TNotif
    },
    closeNotification: (state) => {
      state.notification = {} as TNotif
    },
    showMenu: (state, { payload }: PayloadAction<boolean>) => {
      state.menu = payload
    },
    setTaxValues: (state, { payload }: PayloadAction<TaxBreakdownRes>) => {
      state.taxValues = payload
    },
  },
})

export default betslipSlice.reducer

// Actions -------------------------------------------------------------------------------

export const {
  createBetslip,
  clicksOnExploreItem,
  createIncentives,
  removeSelection,
  updateStake,
  updateOdds,
  updateOddsFromError,
  clicksInput,
  clicksDone,
  closeKeyboard,
  setFreeBet,
  deleteFreeBet,
  clearFreeBet,
  toggleFreeBetsDrawer,
  createNotification,
  closeNotification,
  showMenu,
  setTaxValues,
} = betslipSlice.actions

// thunks -------------------------------------------------------------------------------

export const clicksOnFreeBet =
  (freeBet: FreeBet): AppThunk =>
  async (dispatch) => {
    dispatch(setFreeBet(freeBet))
    setTimeout(() => {
      dispatch(closeNotification())
    }, 5000)
  }

export const setStakeThunk =
  (stake: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(updateStake(stake))

    if (BRAND === 'mozzartbet-hrv' || BRAND === 'certification') {
      const oddsArray = filterSelections(getState().betslip.bets).map(
        ({ odd }) => odd,
      )

      const response = await getTaxBreakdown(
        getState().app.sessionID,
        stake,
        oddsArray,
      )

      if (response) dispatch(setTaxValues(response))
    }
  }
