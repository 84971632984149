import { SimMarket, Score, TimeSpan, HTFT, ThreeWay } from './types'
import { basketballTimeline$ } from '../../basketball/timeline/basketballTimeline'
import { logic3Way } from './ThreeWay'

let out = {
  ht: false,
  ft: false,
}

export const logicHTFT = (outcome: HTFT, score: Score) => {
  const clock = basketballTimeline$.value.clock

  clock === 0 && (out = { ht: false, ft: false })

  const curry1X2 = (score: Score) => (outcome: ThreeWay) =>
    logic3Way(outcome, score)

  const mkt = curry1X2(score)

  const conditions: { [key in HTFT]: () => boolean } = {
    '1/1': () => (clock <= 24 ? (out.ht = mkt('1')) : (out.ft = mkt('1'))),
    '1/x': () => (clock <= 24 ? (out.ht = mkt('1')) : (out.ft = mkt('x'))),
    '1/2': () => (clock <= 24 ? (out.ht = mkt('1')) : (out.ft = mkt('2'))),
    'x/1': () => (clock <= 24 ? (out.ht = mkt('x')) : (out.ft = mkt('1'))),
    'x/x': () => (clock <= 24 ? (out.ht = mkt('x')) : (out.ft = mkt('x'))),
    'x/2': () => (clock <= 24 ? (out.ht = mkt('x')) : (out.ft = mkt('2'))),
    '2/1': () => (clock <= 24 ? (out.ht = mkt('2')) : (out.ft = mkt('1'))),
    '2/x': () => (clock <= 24 ? (out.ht = mkt('2')) : (out.ft = mkt('x'))),
    '2/2': () => (clock <= 24 ? (out.ht = mkt('2')) : (out.ft = mkt('2'))),
  }

  conditions[outcome]()

  if (clock <= 24 && out.ht) {
    return true
  }

  if (clock >= 24 && out.ht && out.ft) {
    return true
  }

  return false
}

const ocHTFT = [
  '1/1',
  '1/x',
  '1/2',
  'x/1',
  'x/x',
  'x/2',
  '2/1',
  '2/x',
  '2/2',
] as HTFT[]

const _HTFT = (id: number, time: TimeSpan, handicap = 0): SimMarket => ({
  id,
  time,
  handicap,
  outcomes: ocHTFT,
  logic: logicHTFT,
})

export const HT_FT = _HTFT(2005, 'FT')
