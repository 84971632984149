import { useSelector } from 'react-redux'
import {
  selectPlaceBet,
  selectStake,
} from '../../../betslip/store/betslip.selectors'
import { closeKeyboard } from '../../../betslip/store/betslip.state'
import { placeBet } from '../../../game/store/game.state'
import { useAppDispatch } from '../../../../store/store'
import { Spinner } from '../../../../components/spinner/spinner'
import { Gift } from '../../../../components/svgs'
import { Button } from './placeBetButton.style'
import { getClientVariables } from 'src/config/client-variables'
import { FormattedMessage } from 'react-intl'

export const PlaceBetButton = () => {
  const dispatch = useAppDispatch()
  const stake = useSelector(selectStake)
  const { disablePlaceBet, sessionID, freeBetAmount } =
    useSelector(selectPlaceBet)

  const handlePlaceBet = () => {
    dispatch(placeBet())
  }

  return (
    <Button
      disabled={disablePlaceBet}
      onClick={handlePlaceBet}
      onFocus={() => dispatch(closeKeyboard())}
      data-testid="placeBetButton"
    >
      {!sessionID ? (
        <Spinner height={'1vh'} size={'35px'} />
      ) : freeBetAmount ? (
        <span className="stake">
          <Gift width="20" height="16" viewBox="5 1 20 20" />
          <FormattedMessage
            id="placeBetButton_freeBet"
            defaultMessage="Place Simulate Free Bet"
          />
        </span>
      ) : (
        <p className="stake">
          <FormattedMessage
            id="placeBetButton_bet"
            defaultMessage="Place Bet"
          />{' '}
          {stake.toLocaleString(getClientVariables().countryCode, {
            style: 'currency',
            currency: getClientVariables().currencyCode,
            currencyDisplay: 'narrowSymbol',
          })}
        </p>
      )}
    </Button>
  )
}
