import { FormattedMessage } from 'react-intl'
import { Checkthick } from 'src/components/svgs'
import { getClientVariables } from 'src/config/client-variables'
import { ResultWrapper } from './gameFooter.style'

interface GameResultProps {
  win: number
  status: number
}

export const GameResult: React.FC<GameResultProps> = ({ win, status }) => {
  const isWin = status === 1
  const isLost = status === -1

  return (
    <ResultWrapper
      animate={{ y: -67 }}
      initial={{ y: 90 }}
      exit={{ y: 90 }}
      transition={{ ease: 'linear', duration: 0.2 }}
      $isWin={isWin}
    >
      <div className="container">
        {isWin && (
          <>
            <div className="won">
              <Checkthick width="12" height="12" viewBox="2 -5 30 28" />
              <FormattedMessage id="gameFooter_win" defaultMessage="You won!" />
            </div>
            <div className="won-amount">
              {win.toLocaleString(getClientVariables().countryCode, {
                style: 'currency',
                currency: getClientVariables().currencyCode,
                currencyDisplay: 'narrowSymbol',
                maximumFractionDigits: 2,
              })}
            </div>
          </>
        )}
        {isLost && (
          <div className="lost">
            <FormattedMessage
              id="gameFooter_lost"
              defaultMessage="You haven't won this time, Please try again"
            />
          </div>
        )}
      </div>
    </ResultWrapper>
  )
}
