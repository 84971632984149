import styled, { css } from 'styled-components'

export const TabsWrapper = styled.div`
  display: flex;
  max-width: 100%;
  margin: 4px;
`
export const Tab = styled.button<{ $active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 30px;
  line-height: 16px;
  background-color: ${({ theme }) => theme.colours.N070};
  color: ${({ theme }) => theme.colours.N040};
  border: none;
  border-radius: 6px 0px 6px 6px;
  clip-path: polygon(0 0, 100% 0, 96% 100%, 0% 100%);
  cursor: pointer;

  .emoji {
    opacity: 0.4;
    margin: 0 2px;
  }

  &:last-child {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 4% 100%);
    border-radius: 0px 6px 6px 0px;
  }

  ${({ $active }) =>
    $active &&
    css`
      background-color: #3b3d48;
      color: ${({ theme }) => theme.colours.N030};
      border-radius: 6px 0px 0px 6px;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

      .emoji {
        opacity: 1;
      }

      &:last-child {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        border-radius: 0px 6px 6px 0px;
      }
    `};
`
