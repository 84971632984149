import * as Sim from '../../markets'
import * as TEAPUESTO from './types'

export const TEAPUESTOBasketballMap = new Map<TEAPUESTO.Markets, Sim.Market>()
export const TEAPUESTOBasketballOutcomeMap = new Map<
  TEAPUESTO.Outcomes,
  Sim.Outcome
>()

export const findteapuestoBasketballOutcome = (
  _b9jMkt: TEAPUESTO.Markets,
  outcome: TEAPUESTO.Outcomes,
): Sim.Outcome => {
  return TEAPUESTOBasketballOutcomeMap.get(outcome) || ('' as Sim.Outcome)
}

// 2 way
TEAPUESTOBasketballMap.set('7327', '2 Way')
TEAPUESTOBasketballOutcomeMap.set('13947', '1')
TEAPUESTOBasketballOutcomeMap.set('13952', '2')
// 1x2 1st Quarter
TEAPUESTOBasketballMap.set('12087', '1X2 1st Quarter')
TEAPUESTOBasketballOutcomeMap.set('24847', '1')
TEAPUESTOBasketballOutcomeMap.set('24857', '2')
TEAPUESTOBasketballOutcomeMap.set('24852', 'x')
// 1x2 1st Half
TEAPUESTOBasketballMap.set('6192', '1X2 1st Half')
TEAPUESTOBasketballOutcomeMap.set('10307', '1')
TEAPUESTOBasketballOutcomeMap.set('10317', '2')
TEAPUESTOBasketballOutcomeMap.set('10312', 'x')
// FT Over/Under
