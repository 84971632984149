import * as Sim from './soccer/markets'
import * as SimBasketball from './basketball/markets'

import {
  B9jSoccerMap,
  findSoccerOutcome,
} from './soccer/maps/bet9ja/b9jSoccerMap'
import {
  B9jZoomSoccerMap,
  findZoomOutcome,
} from './soccer/maps/bet9ja/b9jZoomMap'
import {
  ISolSoccerMap,
  findISolOutcome,
} from './soccer/maps/iSol/iSolSoccerMap'
import {
  B9jBasketballMap,
  findBasketballOutcome,
} from './basketball/maps/bet9ja/b9jBasketballMap'
import {
  ISolBasketballMap,
  findIsolBasketballOutcome,
} from './basketball/maps/iSol/iSolBasketballMap'
import { Source, SportID } from './types'
import { findSimMarketId } from './marketUtils'
import { BRAND } from '../config'
import {
  findMozzarbetKESoccerOutcome,
  MOZZARTBETSoccerMap,
} from './soccer/maps/mozzartbet/mozzartbetSoccerMap'
import {
  findMozzartbetKEBasketballOutcome,
  MOZZARTBETBasketballMap,
} from './basketball/maps/mozzartbet/mozzartbetBasketballMap'
import {
  TEAPUESTOBasketballMap,
  findteapuestoBasketballOutcome,
} from './basketball/maps/teapuesto/teapuestoBasketballMap'
import {
  TEAPUESTOSoccerMap,
  findteapuestoSoccerOutcome,
} from './soccer/maps/teapuesto/teapuestoSoccerMap'
import {
  YAJUEGOSoccerMap,
  findYajuegoSoccerOutcome,
} from './soccer/maps/yajuego-co/yajuego-coSoccerMap'
import {
  YAJUEGOBasketballMap,
  findYajuegoBasketballOutcome,
} from './basketball/maps/yajuego-co/yajuego-coBasketballMap'
import {
  BOLABETBasketballMap,
  findBolabetBasketballOutcome,
} from './basketball/maps/bolabet-zmb/bolabetBasketballMap'
import {
  BOLABETSoccerMap,
  findBolabetSoccerOutcome,
} from './soccer/maps/bolabet-zmb/bolabetSoccerMap'
type Client = {
  b9j: SportDefinition
  isol: SportDefinition
  mozzartbet?: SportDefinition
  teapuesto?: SportDefinition
  yajuego?: SportDefinition
  bolabet?: SportDefinition
}

export type SportDefinition = {
  name: SportName
  sportId: SportID
  marketMap: Map<unknown, Sim.Market | SimBasketball.Market>
  // TODO CREATE THE UNION OF ALL OUTCOMES TO TYPE
  outcomeMap: (mkt: any, outcome: any) => Sim.Outcome | SimBasketball.Outcome
  findSimMarketId: (market: any, sport_id: number) => number | undefined
}
export type SportsCode = 'S' | 'VS' | 'B'
export type SportName = 'Soccer' | 'Z.Soccer' | 'Basketball'

export const getSport = (src: Source, sport: SportsCode) => {
  if (
    BRAND === 'mozzartbet-ke' ||
    BRAND === 'mozzartbet-srb' ||
    BRAND === 'mozzartbet-hrv'
  ) {
    return Sports.get(sport)?.mozzartbet
  }

  if (BRAND === 'teapuesto-pe') {
    return Sports.get(sport)?.teapuesto
  }

  if (BRAND === 'yajuego-co') {
    return Sports.get(sport)?.yajuego
  }

  if (BRAND === 'bolabet-zmb') {
    return Sports.get(sport)?.bolabet
  }

  if (src === 'old-mobile' || src === 'desktop' || src === 'self-service')
    return Sports.get(sport)?.isol

  return Sports.get(sport)?.b9j
}

/** Maps sports to logic */
export const Sports = new Map<SportsCode, Client>()

Sports.set('S', {
  b9j: {
    name: 'Soccer',
    sportId: 1,
    marketMap: B9jSoccerMap,
    outcomeMap: findSoccerOutcome,
    findSimMarketId: findSimMarketId,
  },
  isol: {
    name: 'Soccer',
    sportId: 1,
    marketMap: ISolSoccerMap,
    outcomeMap: findISolOutcome,
    findSimMarketId: findSimMarketId,
  },
  mozzartbet: {
    name: 'Soccer',
    sportId: 1,
    marketMap: MOZZARTBETSoccerMap,
    outcomeMap: findMozzarbetKESoccerOutcome,
    findSimMarketId: findSimMarketId,
  },
  teapuesto: {
    name: 'Soccer',
    sportId: 1,
    marketMap: TEAPUESTOSoccerMap,
    outcomeMap: findteapuestoSoccerOutcome,
    findSimMarketId: findSimMarketId,
  },
  yajuego: {
    name: 'Soccer',
    sportId: 1,
    marketMap: YAJUEGOSoccerMap,
    outcomeMap: findYajuegoSoccerOutcome,
    findSimMarketId: findSimMarketId,
  },
  bolabet: {
    name: 'Soccer',
    sportId: 1,
    marketMap: BOLABETSoccerMap,
    outcomeMap: findBolabetSoccerOutcome,
    findSimMarketId: findSimMarketId,
  },
})

Sports.set('VS', {
  b9j: {
    name: 'Z.Soccer',
    sportId: 1,
    marketMap: B9jZoomSoccerMap,
    outcomeMap: findZoomOutcome,
    findSimMarketId: findSimMarketId,
  },
  isol: {
    name: 'Z.Soccer',
    sportId: 1,
    marketMap: ISolSoccerMap,
    outcomeMap: findISolOutcome,
    findSimMarketId: findSimMarketId,
  },
})

Sports.set('B', {
  b9j: {
    name: 'Basketball',
    sportId: 2,
    marketMap: B9jBasketballMap,
    outcomeMap: findBasketballOutcome,
    findSimMarketId: findSimMarketId,
  },
  isol: {
    name: 'Basketball',
    sportId: 2,
    marketMap: ISolBasketballMap,
    outcomeMap: findIsolBasketballOutcome,
    findSimMarketId: findSimMarketId,
  },
  mozzartbet: {
    name: 'Basketball',
    sportId: 2,
    marketMap: MOZZARTBETBasketballMap,
    outcomeMap: findMozzartbetKEBasketballOutcome,
    findSimMarketId: findSimMarketId,
  },
  teapuesto: {
    name: 'Basketball',
    sportId: 2,
    marketMap: TEAPUESTOBasketballMap,
    outcomeMap: findteapuestoBasketballOutcome,
    findSimMarketId: findSimMarketId,
  },
  yajuego: {
    name: 'Basketball',
    sportId: 2,
    marketMap: YAJUEGOBasketballMap,
    outcomeMap: findYajuegoBasketballOutcome,
    findSimMarketId: findSimMarketId,
  },
  bolabet: {
    name: 'Basketball',
    sportId: 2,
    marketMap: BOLABETBasketballMap,
    outcomeMap: findBolabetBasketballOutcome,
    findSimMarketId: findSimMarketId,
  },
})
