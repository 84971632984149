import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useCountDown } from '../../../../lib/count-down/useCountDown'
import { selectIsCashier } from '../../../app/store/app.state'
import { selectFreeBets } from '../../../betslip/store/betslip.selectors'
import { clicksOnFreeBet, TNotif } from '../../../betslip/store/betslip.state'
import { useAppDispatch } from '../../../../store/store'
import {
  Gift,
  TIcons,
  NotifBorder,
  Checkthick,
} from '../../../../components/svgs'
import { NotifWrapper } from './notificationItem.style'
import { FormattedMessage } from 'react-intl'

const dynamicSVG = (name: TIcons): ReactNode => {
  const refs = {
    gift: <Gift width="24px" height="24px" viewBox="0 0 24 24" />,
    checkthick: <Checkthick width="24px" height="24px" viewBox="0 0 24 24" />,
    notifBorder: <NotifBorder width="24px" height="24px" viewBox="0 0 24 24" />,
    //...
  } as { [key in TIcons]: ReactNode }

  return refs[name] || <path />
}

export interface INotificationProps {
  notif: TNotif
  isToast?: boolean
}
export const NotificationItem: React.FC<INotificationProps> = ({
  notif,
  isToast,
}) => {
  const { icon, msg, color, date, values, btn } = notif
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isCashier = useSelector(selectIsCashier)

  const freeBetsList = useSelector(selectFreeBets)
  const expiry = useCountDown(freeBetsList[0])

  const handleClickFreeBet = () => {
    if (freeBetsList.length === 1 && expiry?.output !== 'EXPIRED') {
      dispatch(clicksOnFreeBet(freeBetsList[0]))
      return
    }
    navigate('/mybets/freebets')
  }
  if (isCashier) return <div></div>

  return (
    <NotifWrapper color={color} $isToast={isToast} data-testid="notifWrapper">
      <div className="content-box">
        <span className="icon" data-testid="icon">
          {dynamicSVG(icon)}
        </span>
        <span className="msg">
          <Message msg={msg} values={values} />{' '}
        </span>
        {date && <span className="date">{date}</span>}
      </div>

      {btn && (
        <button onClick={handleClickFreeBet} className="btnMsg" title={btn}>
          <Button btn={btn} />
        </button>
      )}
    </NotifWrapper>
  )
}

const Message = ({ msg, values }: { msg: string; values: any }) => {
  if (msg === 'notificationItem_msgFreeBet')
    return (
      <FormattedMessage
        id="notificationItem_msgFreeBet"
        values={{ ...values }}
        defaultMessage="You have a freebet!"
      />
    )
  if (msg === 'notificationItem_msgFreeBets')
    return (
      <FormattedMessage
        id="notificationItem_msgFreeBets"
        values={{ ...values }}
        defaultMessage="You have some freebets!"
      />
    )
  if (msg === 'notificationItem_msgFreeBetSelected')
    return (
      <FormattedMessage
        id="notificationItem_msgFreeBetSelected"
        values={{ ...values }}
        defaultMessage="{value1} {value2} free bet selected!"
      />
    )

  return <></>
}

const Button = ({ btn }: { btn: string }) => {
  if (btn === 'notificationItem_msgFreeBetBtn')
    return (
      <FormattedMessage
        id="notificationItem_msgFreeBetBtn"
        defaultMessage="Use it!"
      />
    )
  if (btn === 'notificationItem_msgFreeBetsBtn')
    return (
      <FormattedMessage
        id="notificationItem_msgFreeBetsBtn"
        defaultMessage="Show me!"
      />
    )
  return <></>
}
