import { useSelector } from 'react-redux'
import { selectFilter, filterList } from '../../store/mygames.state'
import { useAppDispatch } from '../../../../store/store'
import {
  FilterWrapper,
  FilterContainer,
  SelectorUnderlay,
  Selector,
} from './filter.style'
import { FormattedMessage } from 'react-intl'

export const Filter = () => {
  const dispatch = useAppDispatch()
  const filter = useSelector(selectFilter)

  return (
    <FilterWrapper>
      <FilterContainer>
        <SelectorUnderlay filter={filter} data-testid="selUnderlay" />
        <Selector
          onClick={() => dispatch(filterList('won'))}
          selected={filter === 'won'}
        >
          <FormattedMessage id="filter_won" defaultMessage="Won" />
        </Selector>
        <Selector
          onClick={() => dispatch(filterList('lost'))}
          selected={filter === 'lost'}
        >
          <FormattedMessage id="filter_lost" defaultMessage="Lost" />
        </Selector>
        <Selector
          onClick={() => dispatch(filterList('all'))}
          selected={filter === 'all'}
        >
          <FormattedMessage id="filter_all" defaultMessage="All" />
        </Selector>
      </FilterContainer>
    </FilterWrapper>
  )
}
