import { FormattedMessage } from 'react-intl'
import { Label, Box, HiddenCheckBox, VisibleCheckBox } from './checkbox.style'

interface ICheckbox {
  checked: boolean
  onChange(): void
}

export const CheckBox: React.FC<ICheckbox> = ({ checked, onChange }) => {
  return (
    <label>
      <Label>
        <FormattedMessage
          id="acceptOdds_changes"
          defaultMessage="Accept odds changes"
        />
      </Label>
      <Box>
        <HiddenCheckBox onChange={onChange} />
        <VisibleCheckBox checked={checked} data-testid="tick">
          {checked && (
            <svg viewBox="0 0 24 24">
              <title>tick</title>
              <polyline points="20 6 9 17 4 12" />
            </svg>
          )}
        </VisibleCheckBox>
      </Box>
    </label>
  )
}
