import { AnimatePresence } from 'framer-motion'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { resetSoccerTimeLine } from '../../../../sports/soccer/timeline/soccerTimeline'
import { changeView, selectIsCashier } from '../../../app/store/app.state'
import { selectFreeBets } from '../../../betslip/store/betslip.selectors'
import {
  placeBet,
  resetGameData,
  selectGameFooterInfos,
} from '../../store/game.state'
import { GameResult } from './gameResult'
import { useAppDispatch } from '../../../../store/store'
import { FreeRebet } from '../free-rebet/freeRebet'
import { getClientVariables } from 'src/config/client-variables'
import { Wrapper, ButtonDone, RebetButton } from './gameFooter.style'

export const GameFooter = () => {
  const dispatch = useAppDispatch()
  const isCashier = useSelector(selectIsCashier)
  const freeBetsList = useSelector(selectFreeBets)

  const { stake, status, wasFreeBet, win, visible, freeBetAmount, view } =
    useSelector(selectGameFooterInfos)

  const handleDone = () => {
    if (view !== 'betslip') {
      dispatch(changeView('betslip'))
    }
    dispatch(resetGameData())
    resetSoccerTimeLine()
  }

  const handleBetAgain = () => {
    dispatch(placeBet())
  }

  if (visible && isCashier && freeBetsList.length > 0) {
    return (
      <>
        <GameResult win={win} status={status} />

        <Wrapper
          data-testid="component-game-Footer"
          animate={{ y: 1 }}
          initial={{ y: 45 }}
          exit={{ y: 45 }}
          status={status}
          transition={{ ease: 'linear', duration: 0.2 }}
        >
          <FreeRebet />
        </Wrapper>
      </>
    )
  }

  return (
    <AnimatePresence>
      {visible && (
        <>
          <GameResult win={win} status={status} />
          <Wrapper
            data-testid="component-game-Footer"
            animate={{ y: 0 }}
            initial={{ y: 45 }}
            exit={{ y: 45 }}
            status={status}
            transition={{ ease: 'linear', duration: 0.2 }}
          >
            <ButtonDone
              data-testid="game-done"
              onClick={handleDone}
              width={wasFreeBet ? '100%' : '33%'}
              className="footer-btn"
            >
              <FormattedMessage id="gameFooter_done" defaultMessage="Done" />
            </ButtonDone>

            {!wasFreeBet && !freeBetAmount && (
              <RebetButton onClick={handleBetAgain} className="footer-btn">
                {freeBetAmount ? (
                  <>
                    <FormattedMessage
                      id="gameFooter_freeBet"
                      defaultMessage="Free Bet"
                    />
                    :{' '}
                    {freeBetAmount.toLocaleString(
                      getClientVariables().countryCode,
                      {
                        style: 'currency',
                        currency: getClientVariables().currencyCode,
                        currencyDisplay: 'narrowSymbol',
                        maximumFractionDigits: 2,
                      },
                    )}
                  </>
                ) : (
                  <>
                    <FormattedMessage
                      id="gameFooter_rebet"
                      defaultMessage="Rebet"
                    />{' '}
                    {stake.toLocaleString(getClientVariables().countryCode, {
                      style: 'currency',
                      currency: getClientVariables().currencyCode,
                      currencyDisplay: 'narrowSymbol',
                      maximumFractionDigits: 2,
                    })}
                  </>
                )}
              </RebetButton>
            )}
          </Wrapper>
        </>
      )}
    </AnimatePresence>
  )
}
