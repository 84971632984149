import { useAppSelector } from 'src/store/store'
import { selectTaxValues } from '../../store/betslip.selectors'
import styled from 'styled-components'
import { getClientVariables } from 'src/config/client-variables'

export const TaxStakes = () => {
  const taxValues = useAppSelector(selectTaxValues)

  const stakeTaxCalculation = taxValues
    ? `${taxValues.stake_tax_amount.toLocaleString(
        getClientVariables().countryCode,
        {
          style: 'currency',
          currency: getClientVariables().currencyCode,
          currencyDisplay: 'code',
        },
      )} (${taxValues.stake_tax_rate * 100}% MT) = ${taxValues.stake_after_tax.toLocaleString(
        getClientVariables().countryCode,
        {
          style: 'currency',
          currency: getClientVariables().currencyCode,
          currencyDisplay: 'code',
        },
      )}`
    : `${getClientVariables().currencyCode}`

  return <Wrapper>{stakeTaxCalculation}</Wrapper>
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  color: ${(props) => props.theme.colours.N030};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 8px;
  padding-top: 0;
`
