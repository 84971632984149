import { SimBet, Source } from '../sports/types'
import { BetResponse } from '../api/types'
import { getClientVariables } from 'src/config/client-variables'

/**
 * Removes the incentives from the /Bet response object
 * @param bet API /Bet response
 */

/* eslint-disable @typescript-eslint/no-unused-vars */
export const omitIncentives = (bet: BetResponse) => {
  const clone = (({ incentives, ...o }) => o)(bet)
  return clone
}

/**
 * Returns a group with a filtered selection.
 * @param matchGroups an array of selections.
 */
export const filterSelections = (bets: SimBet[]): SimBet[] => {
  return bets.filter((bet: SimBet) => bet.supported === true)
}

/**
 * Returns the reduction of all active odds
 * @param odds an array of all the odds to be reduced
 */
export const reduceOdds = (odds: number[]): number => {
  return odds.reduce((curr, next) => curr * next)
}

/**
 * Returns the total odds
 * @param filteredGroup the group without unsuported selections
 */
export const totalOdds = (filteredBets: SimBet[]): number => {
  const oddsArray = filteredBets.map(({ odd }) => odd)

  if (oddsArray.length > 1) {
    return reduceOdds(oddsArray)
  }

  if (oddsArray.length === 1) {
    return oddsArray[0]
  }

  return 0.0
}

/**
 * Returns the total win amount by computing the stake and the odds
 * @param odds the total odds
 * @param stake the stake
 */
export const totalToWin = (
  odds: number,
  stake: number,
  freeBetAmount?: number,
): number => {
  if (freeBetAmount) return parseFloat((odds * stake - stake).toFixed(2))
  return parseFloat((odds * stake).toFixed(2))
}

/**
 * will mark each bets as blocking
 * @param bets all bets in betslip
 */
export const checkIfBlocking = (bets: SimBet[]): SimBet[] => {
  const occurences = bets
    .filter((b) => b.supported === true)
    .map((b) => b.matchID)
    .reduce((obj, b) => {
      obj[b] = ++obj[b] || 1
      return obj
    }, {} as any)

  return bets.map((bet) => {
    return occurences[bet.matchID] > 1
      ? { ...bet, blocking: true }
      : { ...bet, blocking: false }
  })
}

/**
 * Prevent place bet
 * @param stake ...
 * @param toWin ...
 * @param activeSelections ...
 */
export const shield = (
  stake: number,
  toWin: number,
  activeSelections: number,
  source: Source,
) => {
  const { maxStake, maxWin, minStake, currency } = getClientVariables(source)

  if (activeSelections <= 0) {
    throw { status: 1003 }
  }
  if (stake > maxStake) {
    throw {
      status: 1004,
      values: {
        value1: currency,
        value2: `${maxStake.toLocaleString()}`,
      },
    }
  }
  if (stake < minStake) {
    throw {
      status: 1005,
      values: {
        value1: currency,
        value2: `${minStake.toLocaleString()}`,
      },
    }
  }
  if (toWin >= maxWin) {
    throw {
      status: 1006,
      values: {
        value1: currency,
        value2: `${maxWin.toLocaleString()}`,
      },
    }
  }
}
