import * as YAJUEGO from './types'
import * as Sim from '../../markets'

export const YAJUEGOSoccerMap = new Map<YAJUEGO.Markets, Sim.Market>()
export const YAJUEGOSoccerOutcomeMap = new Map<YAJUEGO.Outcomes, Sim.Outcome>()

export const findYajuegoSoccerOutcome = (
  market: YAJUEGO.Markets,
  outcome: YAJUEGO.Outcomes,
): Sim.Outcome => {
  return YAJUEGOSoccerOutcomeMap.get(outcome) || ('' as Sim.Outcome)
}

// 1x2
YAJUEGOSoccerMap.set('1X2', 'FT 1X2')
// 1x2 halftime
YAJUEGOSoccerMap.set('1X21T', 'HT 1X2')
YAJUEGOSoccerOutcomeMap.set('1', '1')
YAJUEGOSoccerOutcomeMap.set('X', 'x')
YAJUEGOSoccerOutcomeMap.set('2', '2')

// Over/Under
YAJUEGOSoccerMap.set('OU@0.5', 'FT OU 0.5')
YAJUEGOSoccerMap.set('OU@1.5', 'FT OU 1.5')
YAJUEGOSoccerMap.set('OU@2.5', 'FT OU 2.5')
YAJUEGOSoccerMap.set('OU@3.5', 'FT OU 3.5')
YAJUEGOSoccerMap.set('OU@4.5', 'FT OU 4.5')
YAJUEGOSoccerMap.set('OU@5.5', 'FT OU 5.5')
YAJUEGOSoccerMap.set('OU@6.5', 'FT OU 6.5')
YAJUEGOSoccerMap.set('OU@7.5', 'FT OU 7.5')
YAJUEGOSoccerMap.set('OU@8.5', 'FT OU 8.5')
YAJUEGOSoccerMap.set('OU1T@0.5', 'HT_OU_0.5')
YAJUEGOSoccerMap.set('OU1T@1.5', 'HT_OU_1.5')
YAJUEGOSoccerMap.set('OU1T@2.5', 'HT_OU_2.5')
YAJUEGOSoccerOutcomeMap.set('O', 'o')
YAJUEGOSoccerOutcomeMap.set('U', 'u')

// Goal/No Goal
YAJUEGOSoccerMap.set('GGNG', 'FT GG/NG')
YAJUEGOSoccerMap.set('GGNG1T', 'HT_GG/NG')
YAJUEGOSoccerOutcomeMap.set('Y', 'gg')
YAJUEGOSoccerOutcomeMap.set('N', 'ng')

// Double Chance
YAJUEGOSoccerMap.set('DC', 'FT DC')
YAJUEGOSoccerMap.set('DC1T', 'HT_DC')
YAJUEGOSoccerOutcomeMap.set('1X', '1x')
YAJUEGOSoccerOutcomeMap.set('12', '12')
YAJUEGOSoccerOutcomeMap.set('X2', '2x')

// 1X2 Or Goal/No Goal
YAJUEGOSoccerMap.set('1X2ORGGNG', 'Chance Mix')
YAJUEGOSoccerOutcomeMap.set('1GG_or', '1 or gg')
YAJUEGOSoccerOutcomeMap.set('XGG_or', 'x or gg')
YAJUEGOSoccerOutcomeMap.set('2GG_or', '2 or gg')
YAJUEGOSoccerOutcomeMap.set('1NG_or', '1 or ng')
YAJUEGOSoccerOutcomeMap.set('XNG_or', 'x or ng')
YAJUEGOSoccerOutcomeMap.set('2NG_or', '2 or ng')

// 1X2 && Goal/No Goal
YAJUEGOSoccerMap.set('1X2GGNG', '1X2 & GG/NG')
YAJUEGOSoccerOutcomeMap.set('1GG', '1 & gg')
YAJUEGOSoccerOutcomeMap.set('1NG', '1 & ng')
YAJUEGOSoccerOutcomeMap.set('XGG', 'x & gg')
YAJUEGOSoccerOutcomeMap.set('XNG', 'x & ng')
YAJUEGOSoccerOutcomeMap.set('2GG', '2 & gg')
YAJUEGOSoccerOutcomeMap.set('2NG', '2 & ng')

// 1X2 || Over/Under
YAJUEGOSoccerMap.set('1X2OROU@1.5', 'Chance Mix 1.5')
YAJUEGOSoccerMap.set('1X2OROU@2.5', 'Chance Mix 2.5')
YAJUEGOSoccerMap.set('1X2OROU@3.5', 'Chance Mix 3.5')
YAJUEGOSoccerOutcomeMap.set('1O_or', '1 or ov')
YAJUEGOSoccerOutcomeMap.set('XO_or', 'x or ov')
YAJUEGOSoccerOutcomeMap.set('2O_or', '2 or ov')
YAJUEGOSoccerOutcomeMap.set('1U_or', '1 or un')
YAJUEGOSoccerOutcomeMap.set('XU_or', 'x or un')
YAJUEGOSoccerOutcomeMap.set('2U_or', '2 or un')

// 1X2 && Over/Under
YAJUEGOSoccerMap.set('1X2ANDOU@1.5', '1X2 & O/U 1.5')
YAJUEGOSoccerMap.set('1X2ANDOU@2.5', '1X2 & O/U 2.5')
YAJUEGOSoccerMap.set('1X2ANDOU@3.5', '1X2 & O/U 3.5')
YAJUEGOSoccerMap.set('1X2ANDOU@4.5', '1X2 & O/U 4.5')
YAJUEGOSoccerOutcomeMap.set('1O', '1 & over')
YAJUEGOSoccerOutcomeMap.set('1U', '1 & under')
YAJUEGOSoccerOutcomeMap.set('XO', 'x & over')
YAJUEGOSoccerOutcomeMap.set('XU', 'x & under')
YAJUEGOSoccerOutcomeMap.set('2O', '2 & over')
YAJUEGOSoccerOutcomeMap.set('2U', '2 & under')

// Home/Away - Over/Under
YAJUEGOSoccerMap.set('OUHOME@0.5', 'H/A O/U 0.5')
YAJUEGOSoccerMap.set('OUAWAY@0.5', 'H/A O/U 0.5')
YAJUEGOSoccerMap.set('OUHOME@1.5', 'H/A O/U 1.5')
YAJUEGOSoccerMap.set('OUAWAY@1.5', 'H/A O/U 1.5')
YAJUEGOSoccerMap.set('OUHOME@2.5', 'H/A O/U 2.5')
YAJUEGOSoccerMap.set('OUAWAY@2.5', 'H/A O/U 2.5')
YAJUEGOSoccerMap.set('OUHOME@3.5', 'H/A O/U 3.5')
YAJUEGOSoccerMap.set('OUAWAY@3.5', 'H/A O/U 3.5')
YAJUEGOSoccerOutcomeMap.set('OH', 'over home')
YAJUEGOSoccerOutcomeMap.set('UH', 'under home')
YAJUEGOSoccerOutcomeMap.set('OA', 'over away')
YAJUEGOSoccerOutcomeMap.set('UA', 'under away')

// Double Chance && Over/Under
YAJUEGOSoccerMap.set('DCOUEX@1.5', 'DC & O/U 1.5')
YAJUEGOSoccerMap.set('DCOUEX@2.5', 'DC & O/U 2.5')
YAJUEGOSoccerMap.set('DCOUEX@3.5', 'DC & O/U 3.5')
YAJUEGOSoccerMap.set('DCOUEX@4.5', 'DC & O/U 4.5')
YAJUEGOSoccerOutcomeMap.set('1XO', '1x & over')
YAJUEGOSoccerOutcomeMap.set('X2O', '2x & over')
YAJUEGOSoccerOutcomeMap.set('12O', '12 & over')
YAJUEGOSoccerOutcomeMap.set('1XU', '1x & under')
YAJUEGOSoccerOutcomeMap.set('X2U', '2x & under')
YAJUEGOSoccerOutcomeMap.set('12U', '12 & under')

// Correct Score
YAJUEGOSoccerMap.set('CSAAMS', 'Correct Score')
YAJUEGOSoccerOutcomeMap.set('0:0', '0-0')
YAJUEGOSoccerOutcomeMap.set('0:1', '0-1')
YAJUEGOSoccerOutcomeMap.set('0:2', '0-2')
YAJUEGOSoccerOutcomeMap.set('0:3', '0-3')
YAJUEGOSoccerOutcomeMap.set('0:4', '0-4')
YAJUEGOSoccerOutcomeMap.set('1:0', '1-0')
YAJUEGOSoccerOutcomeMap.set('1:1', '1-1')
YAJUEGOSoccerOutcomeMap.set('1:2', '1-2')
YAJUEGOSoccerOutcomeMap.set('1:3', '1-3')
YAJUEGOSoccerOutcomeMap.set('1:4', '1-4')
YAJUEGOSoccerOutcomeMap.set('2:0', '2-0')
YAJUEGOSoccerOutcomeMap.set('2:1', '2-1')
YAJUEGOSoccerOutcomeMap.set('2:2', '2-2')
YAJUEGOSoccerOutcomeMap.set('2:3', '2-3')
YAJUEGOSoccerOutcomeMap.set('2:4', '2-4')
YAJUEGOSoccerOutcomeMap.set('3:0', '3-0')
YAJUEGOSoccerOutcomeMap.set('3:1', '3-1')
YAJUEGOSoccerOutcomeMap.set('3:2', '3-2')
YAJUEGOSoccerOutcomeMap.set('3:4', '3-4')
YAJUEGOSoccerOutcomeMap.set('3:3', '3-3')
YAJUEGOSoccerOutcomeMap.set('4:0', '4-0')
YAJUEGOSoccerOutcomeMap.set('4:1', '4-1')
YAJUEGOSoccerOutcomeMap.set('4:2', '4-2')
YAJUEGOSoccerOutcomeMap.set('4:3', '4-3')
YAJUEGOSoccerOutcomeMap.set('4:4', '4-4')
YAJUEGOSoccerOutcomeMap.set('OTH', 'other')

// Multi C.Score (4) -> MARKET 29
YAJUEGOSoccerMap.set('MCS5', 'C. Score Multi')
YAJUEGOSoccerOutcomeMap.set('00110110', '0-0 / 1-1 / 0-1 / 1-0')
YAJUEGOSoccerOutcomeMap.set('20213031', '2-0 / 2-1 / 3-0 / 3-1')
YAJUEGOSoccerOutcomeMap.set('02120313', '0-2 / 1-2 / 0-3 / 1-3')
YAJUEGOSoccerOutcomeMap.set('22233233', '2-2 / 2-3 / 3-2 / 3-3')
YAJUEGOSoccerOutcomeMap.set('04142434', '0-4 / 1-4 / 2-4 / 3-4')
YAJUEGOSoccerOutcomeMap.set('10414243', '4-0 / 4-1 / 4-2 / 4-3')
YAJUEGOSoccerOutcomeMap.set('44O', '4-4 / other')

// Multi goal
YAJUEGOSoccerMap.set('MG', 'Multi goal')
YAJUEGOSoccerOutcomeMap.set('1-2', '1-2 goals')
YAJUEGOSoccerOutcomeMap.set('1-3', '1-3 goals')
YAJUEGOSoccerOutcomeMap.set('1-4', '1-4 goals')
YAJUEGOSoccerOutcomeMap.set('1-5', '1-5 goals')
YAJUEGOSoccerOutcomeMap.set('1-6', '1-6 goals')
YAJUEGOSoccerOutcomeMap.set('2-3', '2-3 goals')
YAJUEGOSoccerOutcomeMap.set('2-4', '2-4 goals')
YAJUEGOSoccerOutcomeMap.set('2-5', '2-5 goals')
YAJUEGOSoccerOutcomeMap.set('2-6', '2-6 goals')
YAJUEGOSoccerOutcomeMap.set('3-4', '3-4 goals')
YAJUEGOSoccerOutcomeMap.set('3-5', '3-5 goals')
YAJUEGOSoccerOutcomeMap.set('3-6', '3-6 goals')
YAJUEGOSoccerOutcomeMap.set('4-5', '4-5 goals')
YAJUEGOSoccerOutcomeMap.set('4-6', '4-6 goals')
YAJUEGOSoccerOutcomeMap.set('5-6', '5-6 goals')

// Halft Time Full Time
YAJUEGOSoccerMap.set('HTFT', 'HT_FT')
YAJUEGOSoccerOutcomeMap.set('1/1', '1/1')
YAJUEGOSoccerOutcomeMap.set('1/X', '1/x')
YAJUEGOSoccerOutcomeMap.set('1/2', '1/2')
YAJUEGOSoccerOutcomeMap.set('X/1', 'x/1')
YAJUEGOSoccerOutcomeMap.set('X/X', 'x/x')
YAJUEGOSoccerOutcomeMap.set('X/2', 'x/2')
YAJUEGOSoccerOutcomeMap.set('2/1', '2/1')
YAJUEGOSoccerOutcomeMap.set('2/X', '2/x')
YAJUEGOSoccerOutcomeMap.set('2/2', '2/2')

// Odd EVen
YAJUEGOSoccerMap.set('OE', 'FT_ODD/EVEN')
YAJUEGOSoccerMap.set('OE1T', 'HT_ODD/EVEN')
YAJUEGOSoccerOutcomeMap.set('OD', 'odd')
YAJUEGOSoccerOutcomeMap.set('EV', 'even')
