import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 8px;
  path {
    fill: ${({ theme }) => theme.colours.AP050};
  }
`
export const Button = styled.button`
  color: ${({ theme }) => theme.colours.AP050};
  background: transparent;
  border: none;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  padding: 0;
  cursor: pointer;
`

export const Arrow = styled.div<{ $viewMore: boolean }>`
  display: flex;

  svg {
    transform: ${({ $viewMore }) =>
      $viewMore ? 'rotate(270deg)' : 'rotate(90deg)'};
  }
`
