import * as Sim from '../../markets'
import * as BOLABET from './types'

export const BOLABETBasketballMap = new Map<BOLABET.Markets, Sim.Market>()
export const BOLABETBasketballOutcomeMap = new Map<
  BOLABET.Outcomes,
  Sim.Outcome
>()

export const findBolabetBasketballOutcome = (
  _b9jMkt: BOLABET.Markets,
  outcome: BOLABET.Outcomes,
): Sim.Outcome => {
  return BOLABETBasketballOutcomeMap.get(outcome) || ('' as Sim.Outcome)
}

// 2 way
BOLABETBasketballMap.set('6', '2 Way')
BOLABETBasketballOutcomeMap.set('12', '1')
BOLABETBasketballOutcomeMap.set('13', '2')
// 1x2 1st Quarter
BOLABETBasketballMap.set('479', '1X2 1st Quarter')
BOLABETBasketballOutcomeMap.set('1213', '1')
BOLABETBasketballOutcomeMap.set('1216', '2')
BOLABETBasketballOutcomeMap.set('1215', 'x')
// 1x2 1st Half
BOLABETBasketballMap.set('75', '1X2 1st Half')
BOLABETBasketballOutcomeMap.set('217', '1')
BOLABETBasketballOutcomeMap.set('219', '2')
BOLABETBasketballOutcomeMap.set('218', 'x')
