import styled from 'styled-components'

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 56px;
  left: 0;
  z-index: 60;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.7);
`

export const ModalWrapper = styled.div`
  margin-bottom: 80px;
  padding: 8px;
  background: ${(props) => props.theme.colours.S060};
  border-radius: 3px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }

  .header-title {
    color: ${(props) => props.theme.colours.AP050};
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
  }

  .tax-details {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: ${(props) => props.theme.colours.N030};
  }
`
