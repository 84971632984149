import {
  ReceiptContainer,
  ReceiptWrapper,
  ReceiptHeader,
  ReceiptInfo,
  ReceiptResults,
  // ReceiptStatus,
  BoldText,
} from './receipt.style'
import {
  Bet9jaPF,
  PipePF,
  LogoSIM,
  LogoTextSIM,
} from '../../../../components/svgs'
// import { getEnv } from '../../app/envs'
import { selectReceiptData } from '../../store/receipt.state'
import { useSelector } from 'react-redux'
import {
  showOutcome,
  showMarket,
  fixDateForAllBrowsers,
} from '../../../../lib/utils/uiUtils'
import { findMarketFromID } from '../../../../sports/marketUtils'
import { FormattedMessage } from 'react-intl'

export const Receipt = () => {
  const receiptData = useSelector(selectReceiptData)

  const fixedDate = fixDateForAllBrowsers(receiptData.placement_date)
  const date = new Date(fixedDate)
  const localDate = date.toLocaleDateString()
  const localTime = date.toLocaleTimeString()

  return (
    <ReceiptContainer id="section-to-print">
      <ReceiptWrapper>
        {receiptData.original ? undefined : (
          <div id="watermark">
            <span>
              <FormattedMessage id="receipt_not" defaultMessage="Not" />
            </span>
            <span>
              <FormattedMessage
                id="receipt_certified"
                defaultMessage="Certified"
              />
            </span>
            <span>
              <FormattedMessage id="receipt_copy" defaultMessage="Copy" />
            </span>
          </div>
        )}
        <div id="content">
          <ReceiptHeader>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Bet9jaPF width="32" height="11" />
              <PipePF style={{ margin: '0 9px' }} width="2" height="12" />
              <LogoSIM
                width="18"
                height="18"
                viewBox="0 -7 45 45"
                fill="#252525"
              />
              <LogoTextSIM
                width="58"
                height="7"
                viewBox="32 -2 10 10"
                fill="#252525"
              />
            </div>
            <div>
              <BoldText>
                {receiptData.original ? (
                  <FormattedMessage
                    id="receipt_originalTicket"
                    defaultMessage="ORIGINAL TICKET"
                  />
                ) : (
                  <FormattedMessage
                    id="receipt_ticketCopy"
                    defaultMessage="TICKET COPY"
                  />
                )}
              </BoldText>
            </div>
          </ReceiptHeader>

          <ReceiptInfo>
            <div className="ticket_info">
              <div className="ticket_info_left">
                <div>
                  <BoldText>
                    <FormattedMessage
                      id="receipt_user"
                      defaultMessage="USER#"
                    />
                  </BoldText>
                  <BoldText>
                    <FormattedMessage
                      id="receipt_ticket"
                      defaultMessage="TICKET#"
                    />
                  </BoldText>
                </div>
                <div>
                  <span>{receiptData.client_id || 'n/a'}</span>
                  <span>{receiptData.bet_id || 'n/a'}</span>
                </div>
              </div>
              <div className="ticket_info_right">
                <span>{`${localTime}`}</span>
                <span>{`${localDate}`}</span>
              </div>
            </div>
            <hr />
            <div className="result_info">
              <div className="result_info_left">
                <div>
                  <BoldText>
                    <FormattedMessage
                      id="receipt_selection"
                      defaultMessage="SELECTIONS"
                    />
                  </BoldText>
                  <BoldText>
                    <FormattedMessage
                      id="receipt_totalOdds"
                      defaultMessage="TOTAL ODDS"
                    />
                  </BoldText>
                </div>
                <div>
                  <span>{receiptData.selections.length || 'n/a'}</span>
                  <span>{receiptData.total_odds.toFixed(2) || 'n/a'}</span>
                </div>
              </div>
              <div className="result_info_right">
                <div className="al">
                  <BoldText>
                    {' '}
                    <FormattedMessage
                      id="receipt_totalStake"
                      defaultMessage="TOTAL STAKE"
                    />
                  </BoldText>
                  <BoldText>
                    <FormattedMessage
                      id="receipt_potWin"
                      defaultMessage="POT. WIN"
                    />
                  </BoldText>
                </div>
                <div>
                  <span>{receiptData.stake || 'n/a'}</span>
                  <span>{receiptData.potencial_win.toFixed(2) || 'n/a'}</span>
                </div>
              </div>
            </div>
          </ReceiptInfo>

          <ReceiptResults>
            {receiptData.selections.map((s: any, i: number) => {
              return (
                <div key={s.event_name} data-testid="receiptItems">
                  <div className="game">
                    <div className="game_bet">
                      <div>
                        <span className="bet">
                          {showOutcome(
                            s.selected_option,
                            s.event_name,
                            s.spread,
                          )}{' '}
                        </span>
                        <span>
                          {showMarket(
                            findMarketFromID(s.market_id, s.sport_id),
                          )}
                        </span>
                      </div>
                      <span id="dot"></span>
                      <span className="odd">{s.odd}</span>
                    </div>
                    <div className="game_result">
                      <span>{s.event_name}</span>
                      <span id="dot"></span>
                      {/* <span className="game_result_ht">
                        ({s.half_time_result}){' '}
                        <span className="result">{s.full_time_result}</span>
                      </span> */}
                    </div>
                  </div>
                  {receiptData.selections.length - 1 === i ? undefined : <hr />}
                </div>
              )
            })}
          </ReceiptResults>

          {/* {!receiptData.original && (
            <ReceiptStatus>
              <>
                <div>
                  <span>RETURNS</span>
                </div>
                <span className="bold">
                  {getClientVariables.currency}
                  {receiptData.win}
                </span>
              </>
            </ReceiptStatus>
          )} */}
        </div>
      </ReceiptWrapper>
    </ReceiptContainer>
  )
}
