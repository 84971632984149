import { Source, SourceID } from '../sports/types'
import { FilterList } from '../features/my-games/store/mygames.state'
import { ErrorResponse } from './types'

/**
 * Maps source to Backend number.
 * @param source Application sending the data
 */
export const switchOnSource = (source: Source): SourceID => {
  const src: { [key in Source]: SourceID } = {
    'new-mobile': 0,
    'old-mobile': 1,
    'desktop': 2,
    'self-service': 3,
    'new-desktop': 4,
    'mobile-lite': 5,
    '': 0,
  }

  return src[source] || 0
}

/**
 * Maps Filter to BackEnd number
 * @param f mygames filter value
 */
export const switchOnFilter = (f: FilterList) => {
  switch (f) {
    case 'lost':
      return -1
    case 'won':
      return 1
    case 'all':
      return 0
  }
}

export const errorHandling = (res: any, status = 500): ErrorResponse => {
  const say = (code: number) => {
    const out: { [key: number]: string } = {
      401: 'error401',
      403: 'error403',
      404: 'error404',
      408: 'error408',
      422: 'error422',
      424: 'error424',
      425: 'error425',
    }

    return out[code] || 'error500'
  }

  const errorResponse: ErrorResponse = {
    msg: say(status),
    status: status,
    data: res || {},
  }

  throw errorResponse
}
