import { useSelector } from 'react-redux'
import { useCountDown } from '../../../../lib/count-down/useCountDown'
import { selectFreeBets } from '../../../betslip/store/betslip.selectors'
import { placeBet } from '../../store/game.state'
import { useAppDispatch } from '../../../../store/store'
import { Clock, Rebet } from '../../../../components/svgs'
import { FRebet } from './freeRebet.style'
import { getClientVariables } from 'src/config/client-variables'
import { FormattedMessage } from 'react-intl'

export const FreeRebet = () => {
  const { currency } = getClientVariables()
  const dispatch = useAppDispatch()

  const freeBetsList = useSelector(selectFreeBets)
  const freeBet = freeBetsList[0]
  const expiry = useCountDown(freeBet)

  return (
    <FRebet onClick={() => dispatch(placeBet())}>
      <div className="rebet" key={freeBet?.free_bet_uid}>
        <div className="rebet__icon">
          <Rebet width="48" height="48" viewBox="0 0 24 24" />
        </div>
        <div className="rebet__txt">
          <FormattedMessage id="freeRebet_txt" defaultMessage="Free rebet" />{' '}
          {currency} {freeBet?.amount}
        </div>

        <div className="rebet__countdown">
          <div className="icon-clock">
            <Clock width="16" height="16" viewBox="0 0 24 24" />
          </div>
          <div className="time">{expiry?.output}</div>
        </div>
      </div>
    </FRebet>
  )
}
