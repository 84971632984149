import { TMessages } from './types'

export const messagesInSerbian: TMessages = {
  eventHeader_Soccer: 'Fudbal',
  eventHeader_Basketball: 'Košarka',
  eventHeader_1st: '1. ',
  eventHeader_2nd: '2. ',
  eventHeader_3rd: '3. ',
  eventHeader_4th: '4. ',
  eventHeader_Quarter: 'Četvrtina',
  eventHeader_Half: 'poluvreme',
  countDown_kickoff: 'STARTUJEMO!',
  goal_letter: 'GOL!!!',
  acceptOdds_changes: 'Prihvati promenu kvota',
  couponDetail_receipt: 'Ponovi štampu opklade',
  couponDetail_odds: 'Kvota',
  couponHead_freeBet: 'Free bet',
  couponHead_won: 'Dobitan',
  couponHead_lost: 'Gubitan',
  emptyNotif_title: 'Sva obaveštenja biće prikazana ovde',
  emptyNotif_subtitle: 'Primajte važna obaveštenja i druge informacije',
  stats_explore: 'Statistika simulacije igre',
  explore_soccerHeading: 'Najpopularniji fudbalski mečevi',
  explore_basketHeading: 'Najpopularniji košarkaški mečevi',
  explore_leagueHeading: 'Top {value1} mečeva',
  tournament_Heading: 'Najpopularniji mečevi Premier lige',
  explorePage_msg: 'Nema informacija',
  filter_won: 'Dobitni',
  filter_lost: 'Gubitni',
  filter_all: 'Svi',
  freeBetTicket_won: 'OSVOJILI STE',
  freeBetTicket_rebet: 'Ponovite besplatnu opkladu',
  freeBetTicket_info: 'Uplati jednom i odigraj dvaput',
  freeRebet_txt: 'Ponovite besplatnu opkladu',
  freeBetNoContent_heading: 'Nastavite igru i ostvarite free bet!',
  freeBetContent_title: 'Free bet',
  freeBetContent_expiry: 'Ističe za',
  freeBetContent_btn: 'Iskoristi!',
  gameDisplay_heading: 'REZULTATI SE PRIKAZUJU ISPOD',
  gameDisplay_fulltime: 'KRAJ',
  gameFooter_win: 'Tiket je DOBITAN!',
  gameFooter_lost: 'Tiket je GUBITAN. Pokušajte ponovo.',
  gameFooter_betWon: 'TIKET JE DOBITAN!',
  gameFooter_done: 'NAZAD',
  gameFooter_rebet: 'PONOVI OPKLADU',
  gameFooter_freeBet: 'Free bet',
  inputStake_amount: 'Unesite iznos',
  loader_printingTicket: 'Štampanje karte',
  loader_simulating: 'Simuliranje',
  loader_info: 'Molimo, sačekajte',
  togglePrintButton_title: 'Odštampaj tiket',
  myBetsNav_myBets: 'Istorija tiketa',
  myBetsNav_freeBets: 'Rapoloživ freebet',
  navBar_linkExplore: 'Istraži',
  navBar_linkBetSlip: 'Tiket',
  navBar_linkMyBets: 'Istorija tiketa',
  navBar_linkReport: 'Izveštaj',
  navBar_linkTournament: 'Engleska Premier liga',
  placeBetButton_freeBet: 'Odigraj freebet tiket',
  placeBetButton_bet: 'Postavi opkladu',
  quickFreeBet_freeBet: 'FREEBET',
  receipt_not: 'Ne',
  receipt_certified: 'Sertifikovan',
  receipt_copy: 'Kopija',
  receipt_originalTicket: 'ORIGINALNI TIKET',
  receipt_ticketCopy: 'KOPIJA TIKETA',
  receipt_user: 'IGRAČ#',
  receipt_ticket: 'TIKET#',
  receipt_selection: 'BROJ PAROVA',
  receipt_totalOdds: 'KVOTA',
  receipt_totalStake: 'UPLATA',
  receipt_potWin: 'POT. DOBITAK',
  reportBox_lastUpdated: 'Poslednja promena',
  reportBox_turnOver: 'Povraćaj',
  reportBox_paidOut: 'Isplata',
  reportBox_tickets: 'Tiketi',
  reportBox_totalProfit: 'Ukupan profit',
  reportsFilter_thisWeek: 'Ove nedelje',
  reportsFilter_yesterday: 'Juče',
  reportsFilter_today: 'Danas',
  scrollElement_info: 'Unesite ulog',
  scrollElement_odds: 'Kvota',
  unsupportedSelection_info1: 'Izabranu igu nije moguće simulirati.',
  unsupportedSelection_info2: 'Ona neće biti dodata na tiket.',
  selectionsBar_sel: 'Broj parova',
  selectionsBar_addMore: '+ Dodaj par',
  speedDrawer_normalSpeed: 'Normalna simulacija',
  speedDrawer_fastSpeed: 'Brza simulacija',
  speedDrawer_turboSpeed: 'Turbo simulacija',
  totalOdds_heading: 'Kvota',
  winBar_heading: 'Potencijalni dobitak',
  winBar_tax: 'порез',
  winBar_taxBreakdown: 'Так Бреакдовн',
  winBar_taxPerStake: '',
  winBar_taxCalculated: 'Porez se obračunava po kategoriji pobeda',
  winBar_payout: 'Isplata',
  notificationItem_msgFreeBet: 'Imate freebet!',
  notificationItem_msgFreeBetBtn: 'Iskoristite ga!',
  notificationItem_msgFreeBets: 'Imate besplatnu opkladu!',
  notificationItem_msgFreeBetsBtn: 'Pogledaj!',
  notificationItem_msgFreeBetSelected:
    '{value1} {value2} freebet vrednosti izabrano!',
  exploreBanner_btn: 'Proveri!',
  exploreBanner_heading: 'Novo!',
  exploreBanner_title: 'Košarka',
  // reusable
  no_info: 'Trenutno nema tiketa za prikaz.',
  btnContinue: 'OK',
  clear: 'Očisti',
  done: 'Zatvori',
  // errors
  errorTest: 'Testna greška',
  error1000: 'Izbor se ne može simulirati',
  error500: 'Došlo je do greške...',
  error401: 'Sesija je istekla',
  error403: 'Neuspešan pristup',
  error404: 'Neuspešana prijava',
  error408: 'Zahtev za vremensko ograničenje',
  error422: 'Tiket nije validan',
  error424: 'Vaš izbor je promenjen',
  error425: 'Freebet nije validan ili je istekao',
  errorPM: 'Nevažeća poruka',
  errorSelectionNum: 'Maksimalan broj parova na tiketu je 40.',
  errorNoSelection: 'Niste dodali parove na tiket',
  errorMinStake: 'Minimalni iznos uloga je {value2} {value1} ',
  errorMaxStake: 'Maksimalni iznos uloga je {value2} {value1} ',
  errorMaxWin:
    'Dobitak na tiketu premašuje maksimalni iznos dobitka na tiketu. Iznos maksimalnog dobitka je {value2} {value1}. ',
  errorAccountStatus_2: 'Dobitak nije isplaćen',
  errorAccountStatus_1: 'Nemate dovoljno sredstava za uplatu tiketa.',
  errorAccountStatus0: 'Niste uplatili sredstva',
  errorAccountStatus1: 'Tiket je uplaćen',
  errorAccountStatus2: 'Dobitak je isplaćen',
  errorAccountStatus__5: 'Dostignute su granice odgovornog igranja',
  errorBetStatus_10: 'Tiket nije uplaćen',
  errorBetStatus_1: 'Gubitan',
  errorBetStatus0: 'Započni',
  errorBetStatus1: 'Isplaćen',
}
